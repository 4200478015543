import { CaretRightOutlined, UpOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Collapse, Divider, Form, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import Portal from '../../../components/Portal';
import { UPDATE_PROFILE } from '../../users/graphql/Mutations';
import ChangePasswordModal from './ChangePasswordModal';

const { required, firstName, lastName, email } = formValidatorRules;

const MyAccountSettingForm = ({ initialValues }) => {
  const {
    state: { permissions },
    initializeAuth,
    getToken,
    dispatch,
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const [loading, setLoading] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [collapseActiveKeys, setCollapseActiveKeys] = useState([
    'personal',
    'changePassword',
  ]);

  const [updateProfile] = useMutation(UPDATE_PROFILE, {
    onCompleted: (res) => {
      dispatch({ type: 'SET_CURRENT_USER', data: res?.updateProfile?.data });
      initializeAuth(getToken(), res?.updateProfile?.data);
    },
    onError: () => {
      form?.setFieldsValue(initialValues);
      setLoading(false);
    },
  });

  const onValuesChange = () => {
    setDisableBtn(false);
    dispatch({ type: 'SET_SHOW_PROMPT', data: true });
  };
  const handleFinish = (values) => {
    dispatch({ type: 'SET_SHOW_PROMPT', data: false });
    setLoading(true);
    const userObj = {
      ...values,
      roles: initialValues?.roles,
    };
    updateProfile({
      variables: {
        data: userObj,
      },
    });
    setDisableBtn(true);
    setLoading(false);
  };

  const items = [
    {
      forceRender: true,
      label: 'PERSONAL',
      key: 'personal',
      children: (
        <>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter First Name' },
                  firstName,
                ]}
                label="First Name"
                name="firstName"
              >
                <InputComponent placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter Last Name' },
                  lastName,
                ]}
                label="Last Name"
                name="lastName"
              >
                <InputComponent placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={4}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Email' }, email]}
                label="Email"
                name="email"
              >
                <InputComponent placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
    {
      forceRender: true,
      label: 'CHANGE PASSWORD',
      key: 'changePassword',
      children: (
        <AccessControl allowedPermissions={['FET_MY_ACCOUNT_SETTINGS_UPDATE']}>
          <Button
            className="common-button save-btn"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            type="primary"
            id="postal-save-btn"
            onClick={() => setPasswordVisible(true)}
          >
            Change Password
          </Button>
        </AccessControl>
      ),
    },
  ];

  const contactCollapseItems = [
    {
      forceRender: true,
      label: 'Need Help or Found a Bug?',
      key: 'helpPanel',
      children: (
        <div className="contact-support-section">
          Submit a support ticket; our team typically responds within a few
          hours to 24.
          <br />
          <a
            target="_blank"
            rel="noreferrer"
            href="https://forms.clickup.com/606124/f/jfxc-63000/ZA8Q93NUI6Y7KWNF4N"
          >
            Customer Support
          </a>
        </div>
      ),
    },
    {
      forceRender: true,
      label: 'Need Additional Assistance?',
      key: 'additionalAssistancePanel',
      children: (
        <div className="contact-support-section">
          Connect with technical Support <br /> 888-ROI-PATH
        </div>
      ),
    },
  ];

  return (
    <div className="content-section">
      <AccessControl allowedPermissions={['FET_MY_ACCOUNT_SETTINGS_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button save-btn"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            type="primary"
            id="account-save-btn"
            loading={loading}
            disabled={disableBtn}
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      {passwordVisible && (
        <ChangePasswordModal
          open={passwordVisible}
          setVisible={setPasswordVisible}
        />
      )}
      <Form
        form={form}
        initialValues={initialValues}
        name="accountSettingForm"
        onValuesChange={onValuesChange}
        onFinish={handleFinish}
        validateTrigger="onChange"
        layout="vertical"
      >
        <fieldset
          disabled={
            !checkPermissions(permissions, ['FET_MY_ACCOUNT_SETTINGS_UPDATE'])
          }
        >
          <Collapse
            bordered={false}
            activeKey={collapseActiveKeys}
            onChange={(key) => setCollapseActiveKeys(key)}
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
            items={items}
          />
        </fieldset>
      </Form>
      <div className="form-divider-text contact-divider">Contact Support</div>
      <Divider className="form-divider" />
      <div className="contact-support-collapse">
        <Collapse
          bordered
          ghost
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          defaultActiveKey="helpPanel"
          items={contactCollapseItems}
        />
      </div>
    </div>
  );
};

export default MyAccountSettingForm;
