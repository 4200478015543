import { gql } from '@apollo/client';

export const UPDATE_TENANT = gql`
  mutation updateTenant($data: TenantUpdateInput) {
    updateTenant(data: $data) {
      status
      data {
        id
        name
        brandLogo
        userId
        email
        phoneNo
        isActive
        colourPallette
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_SPONSOR = gql`
  mutation updateSponsor($data: SponsorUpdateInput) {
    updateSponsor(data: $data) {
      status
      data {
        id
        firstName
        lastName
        logo
        userId
        email
        phoneNo
        colourPallette
      }
    }
  }
`;

export const CREATE_LINE_OF_BUSINESS = gql`
  mutation createLineOfBusiness($data: LineOfBusinessInput!) {
    createLineOfBusiness(data: $data) {
      data {
        id
        label
        key
        description
        isActive
        tenantId
        referenceId
        subAreas {
          id
          label
          key
          description
          estimation
          isActive
          waiverText
          priceGuaranteeText
          offerText
          lineOfBusinessId
          referenceId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const UPDATE_INDUSTRY_LOB = gql`
  mutation updateIndustryLOB($data: IndustryLOBInput!) {
    updateIndustryLOB(data: $data) {
      message
    }
  }
`;

export const UPDATE_USER_STEP = gql`
  mutation updateUserStep($data: UserStepInput) {
    updateUserStep(data: $data) {
      status
      data {
        id
        nextStep
        userId
        data
      }
    }
  }
`;

export const CREATE_TENANT_REGION = gql`
  mutation createRegion($data: RegionInput!) {
    createRegion(data: $data) {
      status
      data {
        id
        name
        zipCodes
        description
        managerId
        contactNumber
        regionData
        address
        officeName
        tenantId
        createdBy
        modifiedBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const AUTO_LOGIN = gql`
  mutation autoLogin {
    autoLogin {
      authToken
      user {
        id
        firstName
        lastName
        email
        phoneNo
        roles
        profileImage {
          url
          key
          name
          contentType
          extension
        }
        isActive
        emailVerified
        tenantId
      }
    }
  }
`;

export const CREATE_QUESTIONNAIRE = gql`
  mutation createSampleQuestionnaire {
    createSampleQuestionnaire {
      status
      data {
        title
      }
    }
  }
`;
