import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import {
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from 'react-router-dom';
import DocumentIconComponent from '../../../app/components/iconComponents/DocumentIconComponent';
import GalleryIconComponent from '../../../app/components/iconComponents/GalleryIconComponent';
import InfoIconComponent from '../../../app/components/iconComponents/InfoIconComponent';
import { MODULES, ROUTES } from '../../../common/constants';

const infoTitle = (
  <div>
    <InfoIconComponent className="tab-icons" />
    <span className="card-title">Information</span>
  </div>
);

const infoTitleProductItems = (
  <div>
    <InfoIconComponent className="tab-icons" />
    <span className="card-title">{MODULES?.PRODUCTS}</span>
  </div>
);

const infoTitleGallery = (
  <div>
    <GalleryIconComponent className="tab-icons" />
    <span className="card-title">Gallery</span>
  </div>
);

const infoTitleDocuments = (
  <div>
    <DocumentIconComponent className="tab-icons" />
    <span className="card-title">Documents</span>
  </div>
);

const ProductTabs = ({ children }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const navigateType = useNavigationType();

  useEffect(
    () => {
      if (navigateType === 'POP') {
        navigate(location?.pathname, {
          state: location?.state,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, location],
  );

  const handleSelectTab = (key) => {
    switch (key) {
      case 'INFORMATION':
        if (location?.pathname?.includes('/add')) {
          navigate(`${ROUTES?.PRODUCTS}/add`, {
            state: location?.state,
          });
        } else if (location?.pathname?.includes('/edit')) {
          navigate(`${ROUTES?.PACKAGES}/edit/${id}`, {
            state: location?.state,
          });
        } else {
          navigate(`${ROUTES?.PACKAGES}/view/${id}`, {
            state: location?.state,
          });
        }
        break;

      case 'PRODUCTS':
        if (location?.pathname?.includes('/edit')) {
          navigate(`${ROUTES?.PACKAGES}/edit${ROUTES?.PRODUCTS}/${id}`, {
            state: location?.state,
          });
        } else {
          navigate(`${ROUTES?.PACKAGES}/view${ROUTES?.PRODUCTS}/${id}`, {
            state: location?.state,
          });
        }
        break;

      case 'GALLERY':
        if (location?.pathname?.includes('/edit')) {
          navigate(`${ROUTES?.PACKAGES}/edit${ROUTES?.GALLERY}/${id}`, {
            state: location?.state,
          });
        } else {
          navigate(`${ROUTES?.PACKAGES}/view${ROUTES?.GALLERY}/${id}`, {
            state: location?.state,
          });
        }
        break;

      case 'DOCUMENTS':
        if (location?.pathname?.includes('/edit')) {
          navigate(`${ROUTES?.PACKAGES}/edit${ROUTES?.DOCUMENTS}/${id}`, {
            state: location?.state,
          });
        } else {
          navigate(`${ROUTES?.PACKAGES}/view${ROUTES?.DOCUMENTS}/${id}`, {
            state: location?.state,
          });
        }
        break;

      default:
        break;
    }
  };

  const tabItems = [
    {
      label: infoTitle,
      key: 'INFORMATION',
      children,
    },
    !location?.pathname?.includes('/add') && {
      label: infoTitleProductItems,
      key: 'PRODUCTS',
      children,
    },
    !location?.pathname?.includes('/add') && {
      label: infoTitleGallery,
      key: 'GALLERY',
      children,
    },
    !location?.pathname?.includes('/add') && {
      label: infoTitleDocuments,
      key: 'DOCUMENTS',
      children,
    },
  ];

  const getActiveKey = () => {
    if (location?.pathname?.includes(ROUTES?.PRODUCTS)) {
      return 'PRODUCTS';
    }
    if (location?.pathname?.includes(ROUTES?.GALLERY)) {
      return 'GALLERY';
    }
    if (location?.pathname?.includes(ROUTES?.DOCUMENTS)) {
      return 'DOCUMENTS';
    }
    return 'INFORMATION';
  };

  return (
    <div className="card-container">
      <Tabs
        type="card"
        items={tabItems}
        activeKey={getActiveKey()}
        onChange={handleSelectTab}
      />
    </div>
  );
};

export default ProductTabs;
