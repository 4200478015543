import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_PROSPECT, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ProspectCreate from './pages/ProspectCreate';
import ProspectList from './pages/ProspectList';
import ProspectView from './pages/ProspectView';
import './prospectModule.less';

const ProspectsWrapper = () => (
  <div className="prospects-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl allowedPermissions={PERMISSION_PROSPECT} showNoAccess>
            <ProspectList />
          </AccessControl>
        }
      />
      <Route
        path="/add"
        element={
          <AccessControl
            allowedPermissions={['FET_PROSPECT_CREATE']}
            showNoAccess
          >
            <ProspectCreate />
          </AccessControl>
        }
      />
      <Route
        path="/view/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_PROSPECT_VIEW']}
            showNoAccess
          >
            <ProspectView />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default ProspectsWrapper;
