import { useMutation } from '@apollo/client';
import { Card, Divider } from 'antd';
import { find } from 'lodash';
import React, { useEffect, useState } from 'react';
import '../../../products/productModule.less';
import '../../addOnProduct.less';
import { REMOVE_ADD_ON_PRODUCT } from '../../graphql/Mutations';
import SelectedProduct from './SelectedProduct';
import SuggestedProducts from './SuggestedProducts';

const productTempType = 'temporary';

const ProductAddOn = ({
  addOnProductSuggestions,
  selectedProduct,
  setSelectedProduct,
  paginationProp,
  setPaginationProp,
  questionnaireId,
  addOnLoading,
  setIsFormValuesChanged,
  industries,
}) => {
  const [
    addOnProductSuggestionsData,
    setAddOnProductSuggestionsData,
  ] = useState(addOnProductSuggestions);

  const [removeAddOnProduct] = useMutation(REMOVE_ADD_ON_PRODUCT, {
    onError: () => {
      // do nothing
    },
  });

  const addSelectedProduct = (product) => {
    const doesProductExist = find(
      selectedProduct,
      (item) => item?.id === product?.id,
    );

    if (!doesProductExist) {
      setSelectedProduct((prev) => [
        ...prev,
        { ...product, product_type: productTempType },
      ]);
    }
    setPaginationProp((prev) => ({
      ...prev,
      skip: 0,
      products: [
        ...selectedProduct,
        { ...product, product_type: productTempType },
      ],
    }));
    setIsFormValuesChanged(true);
  };
  const removeSelectedProduct = async (product) => {
    setSelectedProduct((prev) =>
      prev?.filter((item) => item?.id !== product?.id),
    );

    if (product?.product_type !== productTempType) {
      await removeAddOnProduct({
        variables: {
          where: { productItemId: product?.id, questionnaireId },
        },
      });
    } else {
      setAddOnProductSuggestionsData((prev) => [...prev, product]);
    }

    setPaginationProp((prev) => ({
      ...prev,
      skip: 0,
      products: selectedProduct?.filter((item) => item?.id !== product?.id),
    }));
    setIsFormValuesChanged(true);
  };

  useEffect(() => {
    if (addOnProductSuggestions) {
      setAddOnProductSuggestionsData(
        addOnProductSuggestions?.map((item) => ({
          ...item,
          qualities: ['GOOD'],
          min: 1,
          max: 10,
        })),
      );
    }
  }, [addOnProductSuggestions, setAddOnProductSuggestionsData]);

  return (
    <div>
      <Card>
        <div className="setting-content-heading">
          <h5>Add On Product</h5>
          <span className="panel-description">
            Add On Product settings of this questionnaire
          </span>
        </div>
        <div className="setting-content add-on-products">
          <SelectedProduct
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            removeSelectedProduct={removeSelectedProduct}
            setIsFormValuesChanged={setIsFormValuesChanged}
          />
          <Divider />
          <SuggestedProducts
            selectedProduct={selectedProduct}
            addOnProductSuggestions={addOnProductSuggestions}
            setAddOnProductSuggestionsData={setAddOnProductSuggestionsData}
            addOnProductSuggestionsData={addOnProductSuggestionsData}
            addSelectedProduct={addSelectedProduct}
            paginationProp={paginationProp}
            setPaginationProp={setPaginationProp}
            addOnLoading={addOnLoading}
            industries={industries}
          />
        </div>
      </Card>
    </div>
  );
};

export default ProductAddOn;
