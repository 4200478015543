import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Switch } from 'antd';
import React from 'react';
import EditorComponent from '../../../../../../components/EditorComponent';
import InputComponent from '../../../../../../components/InputComponent';

const HighLightYourArea = () => (
    <>
      <Form.Item
        label="Title"
        name={['widgetConfiguration', 'common', 'highlightYourArea', 'title']}
      >
        <InputComponent placeholder="Enter Title" />
      </Form.Item>
      <Form.Item
        label="Hint"
        name={['widgetConfiguration', 'common', 'highlightYourArea', 'hint']}
        rules={[
          {
            required: true,
            message: 'Please Enter Hint',
          },
        ]}
      >
        <InputComponent placeholder="Enter Hint" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Description</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'common',
            'highlightYourArea',
            'descriptionCheck',
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        label="Description"
        name={[
          'widgetConfiguration',
          'common',
          'highlightYourArea',
          'descriptionText',
        ]}
      >
        <EditorComponent placeholder="Enter Description" />
      </Form.Item>
      <Form.Item
        label="Result Label"
        name={[
          'widgetConfiguration',
          'common',
          'highlightYourArea',
          'resultLabel',
        ]}
        rules={[
          {
            required: true,
            message: 'Please Enter Result Label',
          },
        ]}
      >
        <InputComponent placeholder="Enter Result Label" />
      </Form.Item>
      <Form.Item
        label="Action Button Label"
        name={[
          'widgetConfiguration',
          'common',
          'highlightYourArea',
          'actionButtonLabel',
        ]}
        rules={[
          {
            required: true,
            message: 'Please Enter Action Button Label',
          },
        ]}
      >
        <InputComponent placeholder="Enter Action Button Label" />
      </Form.Item>
      <Form.Item
        label="Tips Button Label"
        name={[
          'widgetConfiguration',
          'common',
          'highlightYourArea',
          'tipsButtonLabel',
        ]}
        rules={[
          {
            required: true,
            message: 'Please Enter Tips Button Label',
          },
        ]}
      >
        <InputComponent placeholder="Enter Tips Button Label" />
      </Form.Item>
    </>
  );

export default HighLightYourArea;
