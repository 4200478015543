import { DeleteOutlined, DragOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  rectSortingStrategy,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Card, Empty, message, Modal, Popconfirm } from 'antd';
import { isEmpty, kebabCase, map, split } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import clickRateImg from '../../../assets/click-rate.svg';
import timeSpendImg from '../../../assets/time.svg';
import { QUESTIONNAIRE_TYPE, ROUTES } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import { CHANGE_PAGE_ORDER, DELETE_PAGE_CONFIG } from '../graphql/Mutations';

const { confirm } = Modal;

const Draggable = ({
  index = 0,
  handleDeletePage,
  item = null,
  questionnaireRoute = null,
  loading = false,
  currentTemplate = null,
  type = QUESTIONNAIRE_TYPE?.ROQ_PATH,
  isSponsorQuestionnaire = false,
}) => {
  const {
    state: { isSponsor },
  } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { id = null } = useParams();

  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: index + 1,
    strategy: rectSortingStrategy,
  });

  const style = transform
    ? {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS?.Translate?.toString(transform),
        transition,
      }
    : undefined;

  const handlers = {
    ...attributes,
    ...listeners,
  };

  return (
    <div
      id="questionnaire-sortable-item"
      className="d-flex align-center justify-center"
      ref={setNodeRef}
      style={style}
      key={item?.id}
    >
      {item?.allowDrag &&
        ((isSponsorQuestionnaire && isSponsor) ||
          (!isSponsorQuestionnaire && !isSponsor)) && (
          <DragOutlined {...handlers} />
        )}

      <Card
        bordered={false}
        className={`template-card ${
          currentTemplate === kebabCase(item?.pageKey) ? 'selected-card' : ''
        } ${item?.allowDrag ? 'draggable-card' : ''}`}
        key={item?.id}
        onClick={() => {
          navigate(
            `${questionnaireRoute}/${id}${ROUTES?.PAGE}/${kebabCase(
              item?.pageKey,
            )}`,
            {
              state: location?.state,
              search: '?sp=false',
            },
          );
        }}
      >
        <div className="card-title">{item?.title}</div>
        <div className="click-rate">
          <img
            className="template-img"
            alt="click-rate-img"
            src={clickRateImg}
          />
          {item?.pageAnalytics?.clickRate || 0}%
          <span className="click-rate-text">Click-Through Rate</span>
        </div>
        <div className="time-spend">
          <div className="time-row">
            <img className="template-img" alt="time-img" src={timeSpendImg} />
            <span>{item?.pageAnalytics?.averageTimeSpent || 0}s</span>
            <div className="time-details">
              <span>Average spent</span>
            </div>
          </div>
        </div>
        {item?.allowDrag && (
          <Popconfirm
            title={
              <p className="m-0 p-0">
                {type === QUESTIONNAIRE_TYPE?.ROQ_PATH ? (
                  <span>
                    All your Skip page conditions related to this page will be
                    removed.
                    <br />
                    Are you sure to delete?
                  </span>
                ) : (
                  'Are you sure to delete?'
                )}
              </p>
            }
            // commented for now will use this in future
            // getPopupContainer={() =>
            //   // eslint-disable-next-line no-undef
            //   document?.querySelector('.left-content')
            // }
            onConfirm={(e) => handleDeletePage(e, item)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              size="small"
              type="primary"
              icon={<DeleteOutlined />}
              loading={loading}
              danger
              ghost
              className="delete-btn pointer"
              disabled={
                !isSponsor &&
                isSponsorQuestionnaire &&
                !item?.pageConfiguration?.addedByTenant
              }
            >
              Delete
            </Button>
          </Popconfirm>
        )}
      </Card>
    </div>
  );
};

const LeftSideContent = ({
  pageSequencesData = [],
  refetchPageSequence,
  type = QUESTIONNAIRE_TYPE?.ROQ_BUILDER,
  isSponsorQuestionnaire = false,
}) => {
  const {
    state: { isSponsor },
  } = useContext(AppContext);
  const { id = null, pageKey: pageKeyParam } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const questionnaireRoute =
    type === QUESTIONNAIRE_TYPE?.ROQ_BUILDER
      ? ROUTES?.QUESTIONNAIRES_PRIMARY
      : ROUTES?.QUESTIONNAIRES_SECONDARY;

  const [currentTemplate, setCurrentTemplate] = useState('0');
  const [dragLoading, setDragLoading] = useState(false);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

  const [changePageOrder] = useMutation(CHANGE_PAGE_ORDER, {
    onCompleted() {},
    onError() {},
  });

  const [deletePageConfig, { loading }] = useMutation(DELETE_PAGE_CONFIG, {
    onCompleted() {},
    onError() {},
  });

  useEffect(() => {
    let pageKeyValue = pageKeyParam;
    if (pageKeyParam?.includes('?sp')) {
      pageKeyValue = split(pageKeyParam, '?sp')?.[0];
    } else {
      pageKeyValue = pageKeyParam;
    }
    setCurrentTemplate(pageKeyValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname]);

  const handleDeletePage = async (e, page) => {
    setDragLoading(true);
    e?.stopPropagation();
    await deletePageConfig({
      variables: {
        where: {
          id: page?.pageConfiguration?.id,
        },
      },
    });
    const res = await refetchPageSequence();
    if (res?.data) {
      const data = res?.data?.pageSequences?.data;
      if (kebabCase(page?.pageKey) === kebabCase(currentTemplate)) {
        setDragLoading(false);
        navigate(
          `${questionnaireRoute}/${id}${ROUTES?.PAGE}/${
            isEmpty(data)
              ? 'create'
              : kebabCase(res?.data?.pageSequences?.data?.[0]?.pageKey)
          }?sp=false`,
          {
            state: {
              ...location?.state,
            },
          },
        );
      }
    }
    setDragLoading(false);
  };

  const handleDragEnd = async ({ over, active }) => {
    if (!over) return;

    const oldIndex = active?.id - 1;
    const newIndex = over?.id - 1;

    if (oldIndex !== newIndex) {
      if (type === QUESTIONNAIRE_TYPE?.ROQ_PATH) {
        confirm({
          centered: true,
          wrapClassName: 'confirm-modal',
          okButtonProps: { className: 'common-button', type: 'primary' },
          cancelButtonProps: { className: 'common-button discard-button' },
          content:
            'There are Skip Page Conditions added on some pages. Are you sure want to reorder this page, they will be deleted ?',
          onOk: async () => {
            setDragLoading(true);
            try {
              const response = await changePageOrder({
                variables: {
                  data: {
                    oldIndex: pageSequencesData?.[oldIndex]?.order,
                    newIndex: pageSequencesData?.[newIndex]?.order,
                  },
                  where: {
                    questionnaireId: id,
                    type,
                  },
                },
              });
              if (response) {
                await refetchPageSequence();
                setDragLoading(false);
              }
            } catch (error) {
              message?.error(error?.response || 'got some problem');
              setDragLoading(false);
            }
          },
          onCancel: () => {},
        });
      } else {
        setDragLoading(true);
        try {
          const response = await changePageOrder({
            variables: {
              data: {
                oldIndex: pageSequencesData?.[oldIndex]?.order,
                newIndex: pageSequencesData?.[newIndex]?.order,
              },
              where: {
                questionnaireId: id,
                type,
              },
            },
          });
          if (response) {
            await refetchPageSequence();
            setDragLoading(false);
          }
        } catch (error) {
          message?.error(error?.response || 'got some problem');
          setDragLoading(false);
        }
      }
    }
  };

  return (
    <div className="left-content">
      <div className="header-text">Pages</div>
      <LoaderComponent spinning={dragLoading} setHeight={84}>
        <DndContext
          sensors={sensors}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext
            strategy={verticalListSortingStrategy}
            items={pageSequencesData?.map((_, index) => index + 1)}
          >
            {pageSequencesData?.length > 0 ? (
              map(pageSequencesData, (item, index) => (
                <div key={index + 1}>
                  <Draggable
                    index={index}
                    item={item}
                    type={type}
                    loading={loading}
                    currentTemplate={currentTemplate}
                    questionnaireRoute={questionnaireRoute}
                    handleDeletePage={handleDeletePage}
                    isSponsorQuestionnaire={isSponsorQuestionnaire}
                  />
                </div>
              ))
            ) : (
              <div className="template-no-data">
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </SortableContext>
        </DndContext>
      </LoaderComponent>
      <Button
        type="primary"
        disabled={
          type !== QUESTIONNAIRE_TYPE?.ROQ_PATH &&
          isSponsorQuestionnaire &&
          !isSponsor
        }
        icon={<PlusOutlined />}
        className="common-button add-page-btn"
        size="small"
        onClick={() => {
          navigate(
            `${questionnaireRoute}/${id}${ROUTES?.PAGE}/create?sp=false`,
            {
              state: { ...location?.state },
            },
          );
        }}
      >
        Add Page
      </Button>
    </div>
  );
};

export default LeftSideContent;
