import { forEach } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AppContext } from '../AppContext';
import Error404 from '../Error404';
import {
  PERMISSION_ACTIVITY_LOGS,
  PERMISSION_API_SETTING,
  PERMISSION_CAMPAIGNS,
  PERMISSION_DASHBOARD,
  PERMISSION_DATA_ASSIGNMENT,
  PERMISSION_DYNAMIC_FIELDS,
  PERMISSION_HOURS_OF_OPERATION_SETTING,
  PERMISSION_IMPORT_EXPORT_QUEUE,
  PERMISSION_INDUSTRY,
  PERMISSION_LEAD,
  PERMISSION_MANUFACTURER,
  PERMISSION_MY_ACCOUNT_SETTING,
  PERMISSION_PLAN_SETTING,
  PERMISSION_PRICING_MODIFIER,
  PERMISSION_PRODUCT,
  PERMISSION_PRODUCT_CATEGORY,
  PERMISSION_PRODUCT_ITEM,
  PERMISSION_PRODUCT_PRICING,
  PERMISSION_PRODUCT_RANKING,
  PERMISSION_PROSPECT,
  PERMISSION_QUESTIONNAIRE,
  PERMISSION_QUOTE,
  PERMISSION_REGION,
  PERMISSION_REST_API_LOGS,
  PERMISSION_RETAILER,
  PERMISSION_RETAILER_SETTING,
  PERMISSION_ROLE,
  PERMISSION_TENANT_SETTING,
  PERMISSION_TENANT_SPONSOR_ASSOCIATION,
  PERMISSION_USER,
  PERMISSION_USER_PERMISSION,
  ROUTES,
} from '../common/constants';
import { checkPermissions } from '../common/utils';
import AccessControl from '../components/AccessControl';
import LoaderComponent from '../components/LoaderComponent';
import ActivityLogsWrapper from '../modules/activityLogs';
import ApiLogsWRapper from '../modules/apiLogs';
import ApiSettingWrapper from '../modules/apiSetting';
import BrandSettingWrapper from '../modules/brandSetting';
import BrandWrapper from '../modules/brands';
import CampaignViewerWrapper from '../modules/campaigns';
import ContactWrapper from '../modules/contacts';
import CustomerWrapper from '../modules/customers';
import DashboardWrapper from '../modules/dashboard';
import AssignmentJobsWrapper from '../modules/dataAssignment';
import DynamicFieldsWrapper from '../modules/dynamicFields';
import HoursOfOperationWrapper from '../modules/hoursOfOperation';
import ImportExportQueueWrapper from '../modules/importExportQueue';
import ImportWrapper from '../modules/imports';
import IndustryWrapper from '../modules/industries';
import JobWrapper from '../modules/jobs';
import ManufacturerWrapper from '../modules/manufacturers';
import MyAccountSettingWrapper from '../modules/myAccountSetting';
import MyQuestionnaireWrapper from '../modules/myQuestionnaire';
import NotificationWrapper from '../modules/notification';
import OnBoardingWrapper from '../modules/onboarding';
import PermissionWrapper from '../modules/permissions';
import PlanSettingWrapper from '../modules/planSetting';
import PricingModifierWrapper from '../modules/pricingModifiers';
import ProductCategoryWrapper from '../modules/productCategories';
import ProductItemWrapper from '../modules/productItems';
import ProductPricingWrapper from '../modules/productPricing';
import ProductRankingWrapper from '../modules/productRanking';
import ProductWrapper from '../modules/products';
import PropertyWrapper from '../modules/properties';
import ProspectsWrapper from '../modules/prospects';
import QuoteViewerWrapper from '../modules/quoteViewer';
import QuoteWrapper from '../modules/quotes';
import RegionWrapper from '../modules/regions';
import RolesWrapper from '../modules/roles';
import SponsorTenantsWrapper from '../modules/sponsorTenants';
import TenantSettingWrapper from '../modules/tenantSetting';
import UserWrapper from '../modules/users';
import sideMenuConfig from './components/sidebar/sideMenuConfig';

const ContentRoutes = () => {
  const [loading, setLoading] = useState(true);
  const [redirectTo, setRedirectTo] = useState([]);
  const {
    state: { permissions, isSponsor },
    dispatch,
  } = useContext(AppContext);

  useEffect(() => {
    const redirectArray = [];
    forEach(sideMenuConfig, (item, index) => {
      if (item?.children?.length > 0) {
        return forEach(item?.children, (child, childIndex) => {
          if (
            permissions?.length > 0 &&
            child?.permissions?.length > 0 &&
            checkPermissions(permissions, child?.permissions)
          ) {
            redirectArray?.push(child?.key);
            if (childIndex === item?.children?.length - 1) {
              if (index === sideMenuConfig?.length - 1) {
                setLoading(false);
              }
            }
          } else if (index === sideMenuConfig?.length - 1) {
            setLoading(false);
          }
        });
      }
      if (checkPermissions(permissions, item?.permissions)) {
        redirectArray?.push(item?.key);
        if (index === sideMenuConfig?.length - 1) {
          setLoading(false);
        }
      } else if (index === sideMenuConfig?.length - 1) {
        setLoading(false);
      }
    });
    dispatch({ type: 'SET_PERMISSION_REDIRECT', data: redirectArray?.[0] });
    if (!redirectArray?.length) {
      setLoading(false);
    }
    setRedirectTo(redirectArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoaderComponent />;

  return (
    <>
      <Routes>
        <Route
          path={ROUTES?.MAIN}
          element={
            redirectTo?.length > 0 ? (
              <Navigate to={redirectTo?.[0]} replace />
            ) : null
          }
        />
        <Route
          path={`${ROUTES?.DASHBOARD}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_DASHBOARD}
              showNoAccess
            >
              <DashboardWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.QUESTIONNAIRES}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_QUESTIONNAIRE}
              showNoAccess
            >
              <MyQuestionnaireWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.CONTACTS}/*`}
          element={
            <AccessControl
              allowedPermissions={['PERMISSION_CONTACT']}
              showNoAccess
            >
              <ContactWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.CUSTOMERS}/*`}
          element={
            <AccessControl
              allowedPermissions={['PERMISSION_CUSTOMER']}
              showNoAccess
            >
              <CustomerWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.PROPERTIES}/*`}
          element={
            <AccessControl
              allowedPermissions={['PERMISSION_PROPERTY']}
              showNoAccess
            >
              <PropertyWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.PRODUCTS}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_PRODUCT_ITEM}
              showNoAccess
            >
              <ProductItemWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.PACKAGES}/*`}
          element={
            <AccessControl allowedPermissions={PERMISSION_PRODUCT} showNoAccess>
              <ProductWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.PRODUCT_PRICING}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_PRODUCT_PRICING}
              showNoAccess
            >
              <ProductPricingWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.QUESTIONNAIRE_RESULT_VALIDATION}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_PRODUCT_RANKING}
              showNoAccess
            >
              <ProductRankingWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.PRICING_MODIFIERS}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_PRICING_MODIFIER}
              showNoAccess
            >
              <PricingModifierWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.PRODUCT_CATEGORIES}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_PRODUCT_CATEGORY}
              showNoAccess
            >
              <ProductCategoryWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.MANUFACTURERS}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_MANUFACTURER}
              showNoAccess
            >
              <ManufacturerWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.REGIONS}/*`}
          element={
            <AccessControl allowedPermissions={PERMISSION_REGION} showNoAccess>
              <RegionWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.RETAILERS}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_RETAILER}
              showNoAccess
            >
              <BrandWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.USERS}/*`}
          element={
            <AccessControl allowedPermissions={PERMISSION_USER} showNoAccess>
              <UserWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.ROLES}/*`}
          element={
            <AccessControl allowedPermissions={PERMISSION_ROLE} showNoAccess>
              <RolesWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.PERMISSIONS}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_USER_PERMISSION}
              showNoAccess
            >
              <PermissionWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.REST_API_LOGS}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_REST_API_LOGS}
              showNoAccess
            >
              <ApiLogsWRapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.ACTIVITY_LOGS}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_ACTIVITY_LOGS}
              showNoAccess
            >
              <ActivityLogsWrapper />
            </AccessControl>
          }
        />
        {isSponsor ? (
          <Route
            path={`${ROUTES?.SPONSOR_SETTING}/*`}
            element={
              <AccessControl
                allowedPermissions={PERMISSION_TENANT_SETTING}
                showNoAccess
              >
                <TenantSettingWrapper />
              </AccessControl>
            }
          />
        ) : (
          <Route
            path={`${ROUTES?.TENANT_SETTING}/*`}
            element={
              <AccessControl
                allowedPermissions={PERMISSION_TENANT_SETTING}
                showNoAccess
              >
                <TenantSettingWrapper />
              </AccessControl>
            }
          />
        )}
        <Route
          path={`${ROUTES?.API_SETTING}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_API_SETTING}
              showNoAccess
            >
              <ApiSettingWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.HOURS_OF_OPERATION}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_HOURS_OF_OPERATION_SETTING}
              showNoAccess
            >
              <HoursOfOperationWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.LEADS}/*`}
          element={
            <AccessControl allowedPermissions={PERMISSION_LEAD} showNoAccess>
              <QuoteViewerWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.QUOTES}/*`}
          element={
            <AccessControl allowedPermissions={PERMISSION_QUOTE} showNoAccess>
              <QuoteWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.CAMPAIGNS}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_CAMPAIGNS}
              showNoAccess
            >
              <CampaignViewerWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.DYNAMIC_FIELDS}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_DYNAMIC_FIELDS}
              showNoAccess
            >
              <DynamicFieldsWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.BUSINESS_INFORMATION}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_RETAILER_SETTING}
              showNoAccess
            >
              <BrandSettingWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.PLAN_SETTING}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_PLAN_SETTING}
              showNoAccess
            >
              <PlanSettingWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.MY_ACCOUNT_SETTING}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_MY_ACCOUNT_SETTING}
              showNoAccess
            >
              <MyAccountSettingWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.IMPORT_EXPORT_QUEUE}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_IMPORT_EXPORT_QUEUE}
              showNoAccess
            >
              <ImportExportQueueWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.JOBS}/*`}
          element={
            <AccessControl allowedPermissions={['SHOW_JOB']} showNoAccess>
              <JobWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.IMPORTS}/*`}
          element={
            <AccessControl allowedPermissions={['SHOW_IMPORT']} showNoAccess>
              <ImportWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.ONBOARDING}/*`}
          element={<OnBoardingWrapper />}
        />
        <Route
          path={`${ROUTES?.NOTIFICATION}/*`}
          element={
            <AccessControl
              allowedPermissions={['SHOW_NOTIFICATION']}
              showNoAccess
            >
              <NotificationWrapper />
            </AccessControl>
          }
        />
        <Route
          path={`${ROUTES?.INDUSTRIES}/*`}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_INDUSTRY}
              showNoAccess
            >
              <IndustryWrapper />
            </AccessControl>
          }
        />
        {isSponsor && (
          <>
            <Route
              path={`${ROUTES?.ASSIGNMENT_JOBS}/*`}
              element={
                <AccessControl
                  allowedPermissions={PERMISSION_DATA_ASSIGNMENT}
                  showNoAccess
                >
                  <AssignmentJobsWrapper />
                </AccessControl>
              }
            />
            <Route
              path={`${ROUTES?.PROSPECTS}/*`}
              element={
                <AccessControl
                  allowedPermissions={PERMISSION_PROSPECT}
                  showNoAccess
                >
                  <ProspectsWrapper />
                </AccessControl>
              }
            />
          </>
        )}
        {isSponsor && (
          <Route
            path={`${ROUTES?.SPONSOR_TENANTS}/*`}
            element={
              <AccessControl
                allowedPermissions={PERMISSION_TENANT_SPONSOR_ASSOCIATION}
                showNoAccess
              >
                <SponsorTenantsWrapper />
              </AccessControl>
            }
          />
        )}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </>
  );
};

export default ContentRoutes;
