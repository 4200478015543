import React from 'react';
import PanelContent from '../../../../modules/dashboard/components/DashboardPanelComponent';
import '../dashboardPanel.less';
import PanelBtn from './PanelBtn';

const DashboardPanel = () => (
    <>
      <PanelBtn />
      <div className="side-panel-wrapper dashboard-panel">
        <PanelContent />
      </div>
    </>
  );

export default DashboardPanel;
