import { Card } from 'antd';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from './AppContext';

const NoAccess = () => {
  const {
    state: { permissionRedirect },
  } = useContext(AppContext);
  return (
    <Card bordered={false} className="no-access-page">
      <div className="gx-page-error-container">
        <div className="gx-page-error-content">
          <h2 className="gx-text-center">
            Oops, an error has occurred. Access not found!
          </h2>
          <p className="gx-text-center">
            <Link className="gx-btn gx-btn-primary" to={permissionRedirect}>
              Home
            </Link>
          </p>
        </div>
      </div>
    </Card>
  );
};

export default NoAccess;
