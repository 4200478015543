import { gql } from '@apollo/client';

export const UPDATE_TENANT = gql`
  mutation updateTenant($data: TenantUpdateInput) {
    updateTenant(data: $data) {
      message
      status
    }
  }
`;

export const UPDATE_SPONSOR = gql`
  mutation updateSponsor($data: SponsorUpdateInput, $where: SponsorWhereInput) {
    updateSponsor(data: $data, where: $where) {
      message
      status
    }
  }
`;
