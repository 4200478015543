import {
  CloseOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Drawer, message } from 'antd';
import { debounce, uniqBy } from 'lodash';
import moment from 'moment/moment';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HAS_UNREAD_NOTIFICATION } from '../app/components/header/graphql/Query';
import { AppContext } from '../AppContext';
import { SKIP_RECORD } from '../common/constants';
import { CLEAR_NOTIFICATION, NOTIFICATION_READ } from './graphql/Mutation';
import { GET_NOTIFICATIONS } from './graphql/Query';
import './styles/NotificationDrawerComponent.less';

let scrollDebounce;

const formatTimeAgo = (date) => moment(date)?.fromNow();

const MODULES_ROUTES_WRAPPER = {
  USER: '/users',
  PROSPECT: '/prospects',
};

const MessageComponent = ({ notification, handleNotificationClick }) => {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const content = notification?.template?.content;
  const targetText = 'Click here to view more details';

  // Check if the content includes the target text
  const containsTargetText = content?.includes(targetText);

  // Split the content by the target text if it exists
  const [beforeText, afterText] = containsTargetText
    ? content?.split(targetText)
    : [content, ''];

  return (
    <div>
      <span>{beforeText}</span>
      {containsTargetText && (
        <span
          className="notification-link"
          onClick={(e) => {
            e?.stopPropagation();
            navigate(
              `${MODULES_ROUTES_WRAPPER?.[notification?.moduleType]}/edit/${
                notification?.moduleData?.id
              }`,
              {
                state: { ...location?.state },
              },
            );
            dispatch({
              type: 'SET_DRAWER',
              data: false,
            });
            handleNotificationClick(notification?.id, notification?.isRead);
          }}
        >
          {targetText}
        </span>
      )}
      <span>{afterText}</span>
    </div>
  );
};

const initialNotificationFilter = {
  skip: 0,
  limit: 20,
  sortOn: 'createdAt',
  sortBy: 'DESC',
};

const NotificationDrawer = () => {
  const {
    dispatch,
    state: { openDrawer, hasUnreadNotification },
  } = useContext(AppContext);

  const [notifications, setNotifications] = useState([]);
  const [isEnd, setIsEnd] = useState(false);
  const [debounceCall, setDebounceCall] = useState(0);
  const [loading, setLoading] = useState(true);

  const [getNotifications] = useLazyQuery(GET_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIsEnd(res?.notifications?.data?.length < SKIP_RECORD);
      setNotifications([...res?.notifications?.data]);
      setLoading(false);
      dispatch({
        type: 'SET_HAS_UNREAD_NOTIFICATION',
        data: false,
      });
    },
    onError() {
      setLoading(false);
    },
  });

  const [hasUnReadNotification] = useLazyQuery(HAS_UNREAD_NOTIFICATION, {
    fetchPolicy: 'no-cache',
    onError: () => {},
  });

  const [notificationRead] = useMutation(NOTIFICATION_READ, {
    onError() {},
  });

  const [clearNotification] = useMutation(CLEAR_NOTIFICATION, {
    onCompleted() {
      setIsEnd(false);
      setDebounceCall(0);
    },
    onError() {},
  });

  useEffect(() => {
    if (openDrawer) {
      getNotifications({
        variables: {
          filter: initialNotificationFilter,
          where: {
            isAdmin: false,
          },
        },
      });
    }
  }, [openDrawer]);

  const handleNotificationClick = async (
    notificationId = null,
    isRead = false,
  ) => {
    if (!isRead) {
      try {
        const unreadRes = await notificationRead({
          variables: {
            data: {
              notificationId,
            },
          },
        });

        if (unreadRes?.data) {
          const filteredUnreadNotifications = notifications?.map(
            (notification) => {
              if (notification?.id === notificationId) {
                return {
                  ...notification,
                  isRead: true,
                };
              }
              return {
                ...notification,
              };
            },
          );
          setNotifications(uniqBy([...filteredUnreadNotifications], 'id'));
        }
      } catch (error) {
        message?.destroy();
        message?.error(error?.message);
      }
    }
  };

  const clearNotifications = async (id) => {
    if (id) {
      try {
        const unreadRes = await clearNotification({
          variables: {
            where: {
              id,
            },
          },
        });

        if (unreadRes?.data) {
          const filteredUnClearedNotifications = notifications?.filter(
            (notification) => notification?.id !== id,
          );
          setNotifications(uniqBy([...filteredUnClearedNotifications], 'id'));
        }
      } catch (error) {
        message?.destroy();
        message?.error(error.message);
      }
    } else {
      setNotifications([]);
      clearNotification();
    }
  };

  const handleNotificationScroll = (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !isEnd) {
        setLoading(true);
        setDebounceCall((prevState) => prevState + 1);
        getNotifications({
          variables: {
            filter: {
              ...initialNotificationFilter,
              skip: (debounceCall + 1) * SKIP_RECORD,
            },
            where: {
              isAdmin: false,
            },
          },
        });
      }
    }, 500);
    scrollDebounce();
  };

  return (
    <Drawer
      closeIcon={null}
      loading={loading}
      title={
        <div className="d-flex justify-between">
          <div className="d-flex align-center">
            <Button
              type="text"
              onClick={() => {
                dispatch({ type: 'SET_DRAWER', data: false });
                hasUnReadNotification();
              }}
              icon={<LeftOutlined className="back-icon" />}
            />
            <span>Notifications</span>
          </div>
          <div>
            {hasUnreadNotification && (
              <RedoOutlined
                onClick={() => {
                  getNotifications({
                    variables: {
                      filter: initialNotificationFilter,
                      where: {
                        isAdmin: false,
                      },
                    },
                  });
                }}
                className="mr-8 refresh-icon"
              />
            )}
            <Button
              onClick={() => {
                clearNotifications();
              }}
              className="mr-4"
              shape="round"
            >
              Clear all
            </Button>
          </div>
        </div>
      }
      onClose={() => {
        dispatch({ type: 'SET_DRAWER', data: false });
      }}
      width={520}
      destroyOnClose
      open={openDrawer}
    >
      <div className="notification-wrapper" onScroll={handleNotificationScroll}>
        {notifications.map((notification) => (
          <div
            key={notification?.id}
            onClick={() =>
              handleNotificationClick(
                notification?.id,
                notification?.isRead,
                notification?.moduleData,
                notification?.moduleType,
              )
            }
            className={`notification ${
              notification?.isRead ? 'read-notification' : 'unread-notification'
            } `}
          >
            <div className="d-flex align-start">
              <div className="notification-icon">
                <InfoCircleOutlined />
              </div>
              <div className="notification-content">
                <div className="notification-title">
                  {notification?.template?.title}
                </div>
                <div className="mt-4">
                  <MessageComponent
                    notification={notification}
                    handleNotificationClick={handleNotificationClick}
                  />
                </div>
              </div>
            </div>
            <div className="actions">
              <div className="d-flex justify-end">
                <CloseOutlined
                  onClick={(e) => {
                    e?.stopPropagation();
                    clearNotifications(notification?.id);
                  }}
                />
              </div>
              <div className="notification-time-section">
                {formatTimeAgo(notification?.createdAt)}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
};

export default NotificationDrawer;
