import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Switch } from 'antd';
import { cloneDeep, debounce, find, map, uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { ROLE_KEYS, SKIP_RECORD } from '../../../common/constants';
import { checkPermissions } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import TableComponent from '../../../components/TableComponent';
import { MANAGE_PERMISSION } from '../graphql/Mutations';
import { GET_PERMISSIONS } from '../graphql/Queries';

let scrollDebounce = null;

const PermissionTable = () => {
  const {
    state: { pageSize, permissions },
  } = useContext(AppContext);

  const [permissionLoading, setPermissionLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [permissionData, setPermissionData] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [dataIsEnd, setDataIsEnd] = useState(false);
  const [loadingSwitch, setLoadingSwitch] = useState(null);

  const [getPermissions] = useLazyQuery(GET_PERMISSIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const permissionCopy = [...permissionData, ...res?.permissions?.data];
        setPermissionData(permissionCopy);
        setScrollFlag(false);
      } else {
        setPermissionData(res?.permissions?.data);
      }
      setDataIsEnd(
        uniqBy(res?.permissions?.data, 'feature')?.length < SKIP_RECORD,
      );
      setPermissionLoading(false);
    },
    onError() {
      setPermissionLoading(false);
    },
  });

  const [managePermission] = useMutation(MANAGE_PERMISSION, {
    onError() {
      setLoadingSwitch(null);
      setBtnLoading(false);
    },
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, [
      'FET_USER_PERMISSION_LIST',
    ]);
    if (permitted) {
      getPermissions({
        variables: {
          filter: {
            skip: 0,
            limit: SKIP_RECORD,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const tableContent = document?.getElementsByClassName(
      'ant-table-body',
    )?.[0];
    if (tableContent) {
      tableContent?.addEventListener('scroll', (event) => {
        const { target } = event;
        const { scrollTop, scrollHeight, offsetHeight } = target || {};

        if (scrollDebounce) {
          scrollDebounce?.cancel();
          scrollDebounce = null;
        }
        scrollDebounce = debounce(() => {
          const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
          if (scrolledToBottom && !dataIsEnd) {
            setScrollFlag(true);
            getPermissions({
              variables: {
                filter: {
                  skip: uniqBy(permissionData, 'feature')?.length,
                  limit: SKIP_RECORD,
                },
              },
            });
          }
        }, 500);
        scrollDebounce();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const onChangePermission = async ({
    checked = false,
    record = null,
    roleName = '',
    recordIndex = -1,
    roleIndex = -1,
  }) => {
    setLoadingSwitch({
      recordIndex,
      roleIndex,
    });
    setBtnLoading(true);
    const response = await managePermission({
      variables: {
        data: {
          feature: record?.feature,
          action: record?.action,
          role: roleName,
          actionTaken: checked,
        },
      },
    });
    if (
      response?.data?.managePermission &&
      recordIndex > -1 &&
      roleIndex > -1
    ) {
      const updatedData = cloneDeep(permissionData);
      updatedData[recordIndex].roles[roleIndex].permission = checked;
      setPermissionData(updatedData);
      setLoadingSwitch(null);
      setBtnLoading(false);
    }
  };

  let sameKey;
  const columns = [
    {
      title: 'FEATURE',
      dataIndex: 'featureLabel',
      className: 'max-width-column',
      key: 'featureLabel',
      fixed: 'left',
      onCell: (record) => {
        const count = permissionData?.filter(
          (item) => item?.featureLabel === record?.featureLabel,
        )?.length;
        if (!(sameKey !== record?.featureLabel)) {
          return { rowSpan: 0 };
        }
        sameKey = record?.featureLabel;
        return { rowSpan: count };
      },
      render: (value) => value,
    },
    {
      title: 'ACTIONS',
      dataIndex: 'action',
      className: 'max-width-column',
      key: 'action',
      fixed: 'left',
      width: 150,
    },
    {
      title: 'ROLES',
      children: map(permissionData?.[0]?.roles, (item, index) => ({
        title: item?.name,
        className: 'max-width-column',
        align: 'center',
        dataIndex: 'roles',
        key: item?.id,
        width: 200,
        render: (roles, record, recordIndex) => {
          const isChecked = find(roles, (role) => role?.key === item?.key)
            ?.permission;
          return (
            <Switch
              className="common-switch"
              disabled={
                [ROLE_KEYS?.TENANT_ADMIN, ROLE_KEYS?.SPONSOR_ADMIN]?.includes(
                  item?.key,
                ) ||
                !checkPermissions(permissions, ['FET_USER_PERMISSION_UPDATE'])
              }
              checked={isChecked}
              loading={
                loadingSwitch?.recordIndex === recordIndex &&
                loadingSwitch?.roleIndex === index
                  ? btnLoading
                  : false
              }
              onChange={(checked) =>
                onChangePermission({
                  checked,
                  record,
                  roleName: item?.key,
                  recordIndex,
                  roleIndex: index,
                })
              }
              checkedChildren={isChecked && <CheckOutlined />}
              unCheckedChildren={!isChecked && <CloseOutlined />}
            />
          );
        },
      })),
    },
  ];

  return (
    <AccessControl
      allowedPermissions={['FET_USER_PERMISSION_LIST']}
      showNoAccess
    >
      <div className="common-table permission-table">
        {pageSize && (
          <TableComponent
            setHeight={180}
            columns={[...columns?.filter((item) => item !== false)]}
            loadingData={permissionLoading || btnLoading}
            data={permissionData || []}
            rowKey={(obj) => obj?.id}
          />
        )}
      </div>
    </AccessControl>
  );
};
export default PermissionTable;
