import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Empty,
  InputNumber,
  Popconfirm,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import FilterSelectedIconComponent from '../../../../app/components/iconComponents/FilterSelectedComponent';
import FilterIcon from '../../../../assets/filter.svg';
import { SKIP_RECORD } from '../../../../common/constants';
import { formatPrice } from '../../../../common/utils';
import LoaderComponent from '../../../../components/LoaderComponent';
import SearchComponent from '../../../../components/SearchComponent';
import SelectComponent from '../../../../components/SelectComponent';
import TableComponent from '../../../../components/TableComponent';
import { PRODUCT_ITEM_FILTER } from '../../../productItems/graphql/Queries';

const { Option } = SelectComponent;

let scrollDebounce = null;

const SuggestedProducts = ({
  addOnProductSuggestionsData,
  setAddOnProductSuggestionsData,
  addSelectedProduct,
  paginationProp,
  setPaginationProp,
  selectedProduct,
  industries,
  addOnLoading,
}) => {
  const {
    state: { pageSize },
  } = useContext(AppContext);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const initialProductItemFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
  };
  const [productItemFilter, setProductItemFilter] = useState(
    initialProductItemFilter,
  );
  const [filters, setFilters] = useState({
    name: [],
  });
  const [filtersCopyState, setFiltersCopyState] = useState({
    name: [],
  });
  const { industryId, lineOfBusinessId, subAreaId } = industries;

  const handleChangeQualityValue = (value, index, type) => {
    if (!value) {
      return;
    }
    const dataCopy = [...addOnProductSuggestionsData];

    if (type === 'qualities') {
      dataCopy[index].qualities = value;
    } else if (type === 'min') {
      dataCopy[index].min = value;
    } else if (type === 'max') {
      dataCopy[index].max = value;
    }

    setAddOnProductSuggestionsData(dataCopy);
  };

  const [productItemFilters] = useLazyQuery(PRODUCT_ITEM_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.productItemFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.productItemFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.productItemFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);

    const filtersCopy = {
      ...filters,
      [dataIndex]: [],
    };

    setFilters(filtersCopy);

    setProductItemFilter({
      ...productItemFilter,
      skip: 0,
    });
    setFilterIndex(dataIndex);
    setFilterVisible(false);

    productItemFilters({
      variables: {
        filter: {
          sortOn: dataIndex,
          sortBy: 'ASC',
          skip: 0,
          limit: 20,
          search: value,
          distinct: true,
          getDBField: dataIndex,
          industryId,
          lineOfBusinessId,
          subAreaId,
        },
      },
    });
  };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          default:
            productItemFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex,
                  industryId,
                  lineOfBusinessId,
                  subAreaId,
                },
              },
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleReset = (clearFilters, dataIndex, close) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: [],
    };

    setFilters(filtersCopy);
    setPaginationProp((prev) => ({
      ...prev,
      products: selectedProduct,
      search: productItemFilter?.search ?? '',
      sortOn: prev?.sortOn,
      sortBy: prev?.sortB,
    }));
    setProductItemFilter({
      ...productItemFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    close();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const getFilterData = (confirm) => {
    setProductItemFilter({
      ...productItemFilter,
      skip: 0,
    });
    setFilters(filtersCopyState);
    setPaginationProp((prev) => ({
      ...prev,
      products: selectedProduct,
      current: 1,
      skip: 0,
      pageSize: 10,
      where: filtersCopyState,
    }));
    if (confirm) {
      confirm();
    }
  };

  const filterPopup = (dataIndex, isPrice = false) => ({
    filterDropdown: ({ confirm, clearFilters, close }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          <SearchComponent
            className="list-search-box filter-search"
            id="search-container-id-roles"
            placeholder="Search..."
            name={dataIndex}
            getData={(value) => handleSearch(value, dataIndex)}
          />
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => (
                <div
                  className="filter-checkbox-section"
                  key={item?.key || item}
                >
                  <Checkbox
                    value={item?.key || item}
                    checked={filtersCopyState?.[dataIndex]?.includes(
                      item?.key || item,
                    )}
                    key={item?.key || item}
                    onChange={(e) => changeFilter(e, dataIndex)}
                    className="common-checkbox"
                  >
                    {isPrice ? (
                      <span title={formatPrice(item)}>{formatPrice(item)}</span>
                    ) : (
                      <>
                        {dataIndex === 'isActive' ? (
                          <span title={item === true ? 'Active' : 'Inactive'}>
                            {item === true ? 'Active' : 'Inactive'}
                          </span>
                        ) : (
                          <span title={item?.label || item?.toString()}>
                            {item?.label || item?.toString()}
                          </span>
                        )}
                      </>
                    )}
                  </Checkbox>
                </div>
              ))
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="common-button discard-button filter-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex, close)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button filter-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        default:
          productItemFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex,
                industryId,
                lineOfBusinessId,
                subAreaId,
              },
            },
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const columns = [
    {
      title: 'SKU',
      dataIndex: 'sku',
      key: 'sku',
      sorter: true,
      fixed: 'left',
    },
    {
      title: 'PRODUCT',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      sorter: true,
      width: 250,
      ...filterPopup('name'),
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industryId',
      key: 'industryId',
      ellipsis: true,
      width: 190,
      render: (_, record = {}) => <span>{record?.industry?.label}</span>,
    },
    {
      title: 'LINE OF BUSINESS',
      dataIndex: 'lineOfBusinessId',
      key: 'lineOfBusinessId',
      ellipsis: true,
      width: 190,
      render: (_, record = {}) => <div>{record?.lineOfBusiness?.label}</div>,
    },
    {
      title: 'MIN',
      dataIndex: 'min',
      key: 'min',
      ellipsis: true,
      fixed: 'right',
      width: 130,
      render: (min, record, index) => (
        <div>
          <InputNumber
            type="number"
            className="quantity-component"
            min={1}
            value={min || 1}
            precision={0}
            onChange={(e) => handleChangeQualityValue(e, index, 'min')}
          />
        </div>
      ),
    },
    {
      title: 'MAX',
      dataIndex: 'max',
      key: 'max',
      ellipsis: true,
      fixed: 'right',
      width: 130,
      render: (max, record, index) => (
        <div>
          <InputNumber
            type="number"
            min={1}
            className="quantity-component"
            value={max || 1}
            precision={0}
            onChange={(e) => handleChangeQualityValue(e, index, 'max')}
          />
        </div>
      ),
    },
    {
      title: 'QUALITY',
      dataIndex: 'qualities',
      key: 'qualities',
      ellipsis: true,
      fixed: 'right',
      width: 130,
      render: (qualities, record, index = 0) => (
        <div>
          <SelectComponent
            placeholder="Select Qualities"
            notFoundContent={<Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
            onChange={(e) => handleChangeQualityValue(e, index, 'qualities')}
            getPopupContainer={null}
            allowClear={false}
            popupClassName="add-on-table-select"
            value={qualities}
            mode="multiple"
          >
            <Option key="GOOD" value="GOOD">
              Good
            </Option>
            <Option key="BETTER" value="BETTER">
              Better
            </Option>
            <Option key="BEST" value="BEST">
              Best
            </Option>
          </SelectComponent>
        </div>
      ),
    },
    {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => {
        const { min, max, qualities } = record;
        const validation = max > min && qualities?.length > 0;
        return (
          <Popconfirm
            title={
              !validation
                ? 'Please verify the lowest and highest values you provided or the qualities you have selected.'
                : 'Are you sure to add?'
            }
            onConfirm={() => {
              addSelectedProduct(record);
            }}
            okText={validation ? 'Yes' : ''}
            cancelText={validation ? 'No' : 'Cancel'}
          >
            <Button
              className="common-button"
              size="small"
              id="productItem-table-add-btn"
              type="primary"
            >
              Add
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const handleTableChange = (pagination, _, sorter) => {
    const { field, order } = sorter;
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setPaginationProp((prev) => ({
      ...prev,
      ...pagination,
      products: selectedProduct,
      skip,
      search: productItemFilter?.search ?? '',
      sortOn: field,
      sortBy: order === 'ascend' ? 'ASC' : 'DESC',
    }));
  };

  const onSearchChange = (value) => {
    setProductItemFilter({
      ...productItemFilter,
      skip: value
        ? 0
        : productItemFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    setPaginationProp((prev) => ({
      ...prev,
      current: 1,
      products: selectedProduct,
      search: value ?? '',
      skip: 0,
    }));
  };

  return (
    <div>
      <h5>Suggested Product</h5>
      <div className="fill-width search-checkbox mb-16">
        <SearchComponent
          className="list-search-box modal-search"
          id="search-container-id"
          placeholder="Search all fields"
          name="Products"
          getData={onSearchChange}
        />
      </div>
      <div className="common-table product-item-table">
        <TableComponent
          columns={[...columns?.filter((item) => item !== false)]}
          fullHeight={false}
          loading={addOnLoading}
          scroll={{ x: 'max-content' }}
          data={addOnProductSuggestionsData || []}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(obj) => obj?.id}
        />
      </div>
    </div>
  );
};

export default SuggestedProducts;
