import { Col, Divider, Row } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import { formatPhoneNumber } from '../../../common/utils';

const ManufacturerDetails = ({ data = {}, logoUrl = '' }) => {
  const {
    contactNumber = '',
    name = '',
    isActive = true,
    certification = '',
    location = {},
    fax = '',
    url = '',
    sponsorName = '',
  } = data;

  const {
    state: { isSponsor },
  } = useContext(AppContext);

  return (
    <div className="content-section">
      <Row className="required-row">
        <span className="form-divider-text">MANDATORY</span>
        <Divider className="form-divider" />

        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Title *</span>
            <span className="field-value">{name}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Contact Number *</span>
            <span className="field-value">
              {formatPhoneNumber(contactNumber) || '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Status *</span>
            <span className="field-value">
              {isActive ? 'Active' : 'InActive'}
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <span className="form-divider-text">OPTIONAL</span>
        <Divider className="form-divider" />
        {!isSponsor && sponsorName && (
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Sponsor</span>
              <span className="field-value">{sponsorName || '-'}</span>
            </div>
          </Col>
        )}
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">Logo</span>
            <img
              src={logoUrl?.url || placeholderImage}
              alt="logo"
              onError={(e) => {
                e.target.src = placeholderImage;
              }}
              className="field-logo"
            />
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div className="field-detail">
            <span className="field-label">Certification</span>
            <div
              className="editor-render"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: certification || '-' }}
            />
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Address</span>
            <span className="field-value">{location?.addressLine1 || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Country</span>
            <span className="field-value">{location?.country || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">State</span>
            <span className="field-value">{location?.state || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">City</span>
            <span className="field-value">{location?.city || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Postal Code</span>
            <span className="field-value">{location?.zipCode || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Fax</span>
            <span className="field-value">{formatPhoneNumber(fax) || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">URL</span>
            <span className="field-value">{url || '-'}</span>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ManufacturerDetails;
