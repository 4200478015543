import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import CampaignForm from '../components/CampaignForm';
import { CREATE_CAMPAIGN } from '../graphql/Mutations';

function CampaignCreate() {
  const navigate = useNavigate();
  const location = useLocation();
  const [submitLoading, setSubmitLoading] = useState(false);

  const [createProductCategoryMutate] = useMutation(CREATE_CAMPAIGN, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  useEffect(() => {
    const unListen = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, { state: { ...location?.state } });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues,
    };

    const variables = newFormValues;

    try {
      const response = await createProductCategoryMutate({
        variables: { data: { ...variables } },
      });
      if (response?.data?.createCampaign) {
        navigate(ROUTES.CAMPAIGNS, { state: { ...location?.state } });
      }
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    description: '',
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.CAMPAIGNS} />
          <span className="portal-header">Add Campaign</span>
        </div>
      </Portal>
      <CampaignForm
        campaignData={initialValues}
        handleSubmit={handleSubmit}
        isSubmit={submitLoading}
      />
    </Card>
  );
}

export default CampaignCreate;
