import { CheckOutlined, CloseOutlined, UpOutlined } from '@ant-design/icons';
import { Collapse, Form, Switch } from 'antd';
import React from 'react';
import { strippedString } from '../../../../../../common/utils';
import InputComponent from '../../../../../../components/InputComponent';

const CustomerInfoForm = ({ form }) => {
  const enableAddressInfo = Form?.useWatch(
    ['widgetConfiguration', 'addressInfo', 'enable'],
    form,
  );
  const addressInfoDescriptionCheck = Form?.useWatch(
    ['widgetConfiguration', 'addressInfo', 'description', 'enable'],
    form,
  );

  const isPhoneCheck = Form?.useWatch(
    ['widgetConfiguration', 'personalInfo', 'phone', 'enable'],
    form,
  );

  const contactCollapseItems = [
    {
      key: 'PERSONAL_INFO',
      forceRender: true,
      label: (
        <div className="d-flex flex-vertical align-start">
          <span>Personal Info</span>
        </div>
      ),
      children: (
        <>
          <Form.Item
            name={['widgetConfiguration', 'personalInfo', 'firstName', 'label']}
            label="First Name"
            rules={[
              {
                required: true,
                message: 'Please Enter First Name',
              },
            ]}
          >
            <InputComponent placeholder="Enter First Name" />
          </Form.Item>
          <Form.Item
            name={['widgetConfiguration', 'personalInfo', 'lastName', 'label']}
            label="Last Name"
            rules={[
              {
                required: true,
                message: 'Please Enter Last Name',
              },
            ]}
          >
            <InputComponent placeholder="Enter Last Name" />
          </Form.Item>
          <Form.Item
            name={['widgetConfiguration', 'personalInfo', 'email', 'labelText']}
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please Enter Email',
              },
            ]}
          >
            <InputComponent placeholder="Enter Email" />
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Phone</span>
            <Form.Item
              name={['widgetConfiguration', 'personalInfo', 'phone', 'enable']}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <Form.Item
            name={['widgetConfiguration', 'personalInfo', 'phone', 'labelText']}
            rules={[
              {
                required: true,
                message: 'Please Enter Phone',
              },
            ]}
            hidden={!isPhoneCheck}
          >
            <InputComponent placeholder="Enter Phone" />
          </Form.Item>
        </>
      ),
    },
    {
      key: 'ADDRESS_INFO',
      forceRender: true,
      label: (
        <div className="d-flex flex-vertical align-start">
          <span>Address Info</span>
        </div>
      ),
      children: (
        <>
          {/* Enable */}
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Enable</span>
            <Form.Item
              name={['widgetConfiguration', 'addressInfo', 'enable']}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <div>
            {/* Title */}
            <Form.Item
              name={[
                'widgetConfiguration',
                'addressInfo',
                'title',
                'labelText',
              ]}
              label="Title Label"
              hidden={!enableAddressInfo}
              rules={[
                {
                  required: true,
                  message: 'Please Enter Title',
                },
              ]}
            >
              <InputComponent placeholder="Address" />
            </Form.Item>

            {/* Description */}
            <div className="d-flex justify-between align-center">
              {enableAddressInfo && (
                <span className="switch-logo">Description</span>
              )}
              <Form.Item
                name={[
                  'widgetConfiguration',
                  'addressInfo',
                  'description',
                  'enable',
                ]}
                className="mb-0"
                valuePropName="checked"
                hidden={!enableAddressInfo}
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <Form.Item
              name={[
                'widgetConfiguration',
                'addressInfo',
                'description',
                'labelText',
              ]}
              hidden={!addressInfoDescriptionCheck || !enableAddressInfo}
              rules={[
                {
                  validator(_, value) {
                    if (
                      enableAddressInfo &&
                      addressInfoDescriptionCheck &&
                      (!value || !strippedString(value)?.length)
                    ) {
                      return Promise?.reject(
                        new Error('Please Enter Description'),
                      );
                    }
                    return Promise?.resolve();
                  },
                },
              ]}
            >
              <InputComponent placeholder="Enter Description" />
            </Form.Item>
          </div>
        </>
      ),
    },
  ];

  return (
    <Collapse
      className="common-collapse questionnaire-collapse"
      expandIconPosition="end"
      expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 0 : 180} />}
      defaultActiveKey={['PERSONAL_INFO', 'ADDRESS_INFO']}
      ghost
      items={contactCollapseItems}
    />
  );
};

export default CustomerInfoForm;
