import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import Portal from '../../../components/Portal';
import QuestionnaireTabs from '../components/QuestionnaireTabs';
import SecondaryQuestionnaireTable from '../components/SecondaryQuestionnaireTable';
import '../myQuestionnaireModule.less';

const SecondaryQuestionnaireList = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <span className="portal-header">My Questionnaire</span>
      </Portal>
      {pageSize && (
        <QuestionnaireTabs>
          <div className="questionnaire-module">
            <SecondaryQuestionnaireTable />
          </div>
        </QuestionnaireTabs>
      )}
    </Card>
  );
};

export default SecondaryQuestionnaireList;
