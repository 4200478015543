import { HistoryOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { formatDate, formatPrice } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';
import TableComponent from '../../../components/TableComponent';
import { GET_PRODUCT_PRICING_HISTORIES } from '../graphql/Queries';

const HistoryModal = (props) => {
  const {
    historyModalConfig: { open = false, id = null, title = '' },
    setHistoryModalConfig,
  } = props;
  const {
    state: { pageSize },
  } = useContext(AppContext);

  const initialPaginationValue = useMemo(
    () => ({
      total: 0,
      current: 1,
    }),
    [],
  );

  const initialFilter = useMemo(
    () => ({
      skip: 0,
      limit: pageSize,
    }),
    [pageSize],
  );

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [filter, setFilter] = useState(initialFilter);

  const [fetchProductPricingHistories, { loading, data }] = useLazyQuery(
    GET_PRODUCT_PRICING_HISTORIES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.productPricingHistories?.count,
        };
        setPaginationProp(pagination);
      },
      onError() {},
    },
  );

  const columns = useMemo(
    () => [
      {
        title: 'PRODUCT COST',
        dataIndex: 'productCost',
        key: 'productCost',
        className: 'max-width-column',
        ellipsis: true,
        align: 'right',
        width: 130,
        render: (productCost = null) => (
          <span title={formatPrice(productCost)}>
            {formatPrice(productCost)}
          </span>
        ),
      },
      {
        title: 'PRODUCT PRICE',
        dataIndex: 'productPrice',
        key: 'productPrice',
        className: 'max-width-column',
        ellipsis: true,
        align: 'right',
        width: 130,
        render: (productPrice = null) => (
          <span title={formatPrice(productPrice)}>
            {formatPrice(productPrice)}
          </span>
        ),
      },
      {
        title: 'MARGIN PRICE',
        dataIndex: 'marginPrice',
        key: 'marginPrice',
        className: 'max-width-column',
        ellipsis: true,
        align: 'right',
        width: 130,
        render: (marginPrice = null) => (
          <span title={formatPrice(marginPrice)}>
            {formatPrice(marginPrice)}
          </span>
        ),
      },
      {
        title: 'MSRP',
        dataIndex: 'msrp',
        key: 'msrp',
        className: 'max-width-column',
        ellipsis: true,
        align: 'right',
        width: 130,
        render: (msrp = null) => (
          <span title={msrp ? formatPrice(msrp) : '-'}>
            {msrp ? formatPrice(msrp) : '-'}
          </span>
        ),
      },
      {
        title: 'UPDATED AT',
        dataIndex: 'createdAt',
        key: 'createdAt',
        className: 'max-width-column',
        ellipsis: true,
        render: (createdAt = null) => <span>{formatDate(createdAt)}</span>,
      },
    ],
    [],
  );

  useEffect(() => {
    if (open) {
      fetchProductPricingHistories({
        variables: { where: { id }, filter },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setFilter({
      ...filter,
      skip,
      limit: pagination?.pageSize,
    });
    fetchProductPricingHistories({
      variables: {
        filter: {
          ...filter,
          skip,
          limit: pagination?.pageSize,
        },
        where: { id },
      },
    });
  };

  return (
    <ModalComponent
      width={750}
      loading={loading}
      classNames={{ body: 'history-modal-body' }}
      title={
        title || (
          <>
            <HistoryOutlined />
            <span className="ml-12">History</span>
          </>
        )
      }
      open={open}
      footer={null}
      closable
      onCancel={() =>
        setHistoryModalConfig({ open: false, id: null, title: '' })
      }
      destroyOnClose
    >
      <TableComponent
        className="history-table"
        data={data?.productPricingHistories?.data || []}
        columns={columns}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        setHeight={100}
        rowKey={(obj) => obj?.id}
      />
    </ModalComponent>
  );
};

export default HistoryModal;
