import { useLoadScript } from '@react-google-maps/api';
import { Button, Col, Form, Row } from 'antd';
import { map } from 'lodash';
import React, { useEffect } from 'react';
import MarkerIcon from '../../../../../../assets/marker-icon.svg';
import SelectComponent from '../../../../../../components/SelectComponent';
import GoogleMapComponent from '../../../previewComponents/globalComponents/GoogleMapComponent';
import InputComponent from '../../../previewComponents/globalComponents/InputComponent';
import './postalCode.less';

const { Option } = SelectComponent;

const PostalWidgetComponent = ({ widgetConfig = null }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const updateHeight = () => {
    // eslint-disable-next-line no-undef
    const mapComponent = document?.getElementsByClassName(
      'common-map-cpq',
    )?.[0];
    if (mapComponent) {
      mapComponent.style.height = `${mapComponent?.clientWidth}px`;
    }
  };

  useEffect(() => {
    updateHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('resize', updateHeight);
    // eslint-disable-next-line no-undef
    return () => window?.removeEventListener('resize', updateHeight);
  });

  return (
    <div className="postal-code-page">
      {widgetConfig?.zipCodeWidget?.map && (
        <div className="map-section" id="map-area">
          {isLoaded && <GoogleMapComponent loadError={loadError} />}
        </div>
      )}
      <div className="zip-section">
        <Form layout="vertical" initialValues={{ zipCode: 'XXXXXX' }}>
          <Form.Item name="zipCode">
            <InputComponent
              readOnly
              allowClear
              placeholder="Enter The Postal Code"
              suffix={
                widgetConfig?.zipCodeWidget?.geolocation && (
                  <Button
                    className="marker-btn"
                    type="primary"
                    icon={
                      <img
                        src={MarkerIcon}
                        alt="import-icon"
                        width={11}
                        height={13}
                      />
                    }
                  />
                )
              }
            />
          </Form.Item>
          {widgetConfig?.zipCodeWidget?.enableFullAddress && (
            <>
              <Row gutter={20}>
                <Col span={24}>
                  <Form.Item name="name">
                    <InputComponent allowClear placeholder="Name*" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="email">
                    <InputComponent allowClear placeholder="Email*" />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name="street">
                <InputComponent readOnly allowClear placeholder="Street*" />
              </Form.Item>

              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item name="city">
                    <SelectComponent placeholder="City*" disabled>
                      {map([], (city) => (
                        <Option key={city?.id} value={city?.name}>
                          {city?.name}
                        </Option>
                      ))}
                    </SelectComponent>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="state">
                    <SelectComponent placeholder="State*" disabled>
                      {map([], (state) => (
                        <Option key={state?.id} value={state?.name}>
                          {state?.name}
                        </Option>
                      ))}
                    </SelectComponent>
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
        </Form>
      </div>
    </div>
  );
};

export default PostalWidgetComponent;
