import Icon from '@ant-design/icons';
import React from 'react';

const infoIcon = () => (
    <svg
      width="12"
      height="12"
      aria-label="Info Icon"
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.667 0.166016H1.33366C0.689327 0.166016 0.166992 0.68835 0.166992 1.33268V3.66602V8.33268V10.666C0.166992 11.3103 0.689327 11.8327 1.33366 11.8327H10.667C11.3113 11.8327 11.8337 11.3103 11.8337 10.666V8.33268V3.66602V1.33268C11.8337 0.68835 11.3113 0.166016 10.667 0.166016ZM1.33366 10.666V8.33268H10.667V10.666H1.33366ZM1.33366 3.66602H10.667V1.33268H1.33366V3.66602ZM1.33366 7.16601V4.83268H10.667V7.16601H1.33366Z"
        fill="currentColor"
      />
    </svg>
  );

const InfoIconComponent = (props) => (
  <Icon
    aria-label="Info Icon"
    alt="Info Icon"
    component={infoIcon}
    {...props}
  />
);

export default InfoIconComponent;
