import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Col, Row, Steps } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import UserIconComponent from '../../../app/components/iconComponents/UserIconComponent';
import { ReactComponent as BrandLogoIcon } from '../../../assets/onboarding/brand-logo.svg';
import { ReactComponent as ColorPaletteIcon } from '../../../assets/onboarding/color-palette.svg';
import { ReactComponent as InviteIcon } from '../../../assets/onboarding/invite.svg';
import { ReactComponent as IndustryIcon } from '../../../assets/onboarding/lob.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/onboarding/onboarding-success.svg';
import { ReactComponent as RegionIcon } from '../../../assets/onboarding/region.svg';
import { ROUTES, TABLET_BREAKPOINT } from '../../../common/constants';
import { getLogo } from '../../../common/utils';

const { Step } = Steps;

const StepProcess = ({ children }) => {
  const {
    getOnboardingData,
    state: { logoUrl, windowWidth, isSponsor },
    dispatch,
  } = useContext(AppContext);
  const data = getOnboardingData();
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (data?.data?.brandLogo && !logoUrl) {
      getLogo({
        logoObject: data?.data?.[isSponsor ? 'logo' : 'brandLogo'],
        dispatch,
        isBase64: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoUrl === null]);

  useEffect(() => {
    if (location?.pathname?.includes('add-logo')) {
      setCurrentStep(0);
    }
    if (location?.pathname?.includes('color-palette')) {
      setCurrentStep(1);
    }
    if (location?.pathname?.includes('industry')) {
      setCurrentStep(2);
    }
    if (location?.pathname?.includes('region')) {
      setCurrentStep(3);
    }
    // currently we are no showing package screen in onboarding flow, but kept this code for future use
    // if (location?.pathname?.includes('packages')) {
    //   setCurrentStep(4);
    // }
    if (location?.pathname?.includes('invite')) {
      setCurrentStep(5);
    }
    if (location?.pathname?.includes('success')) {
      setCurrentStep(6);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeStep = (step) => {
    switch (step) {
      case 0:
        navigate(`${ROUTES?.ONBOARDING}/add-logo`);
        break;
      case 1:
        navigate(`${ROUTES?.ONBOARDING}/color-palette`);
        break;
      case 2:
        navigate(`${ROUTES?.ONBOARDING}/industry`);
        break;
      case 3:
        navigate(`${ROUTES?.ONBOARDING}/region`);
        break;
      case 4:
      case 5:
        // currently we are no showing package screen in onboarding flow, but kept this code for future use
        //  navigate(`${ROUTES?.ONBOARDING}/packages`);
        // break;
        navigate(`${ROUTES?.ONBOARDING}/invite`);
        break;
      case 6:
        navigate(`${ROUTES?.ONBOARDING}/success`);
        break;

      default:
        navigate(`${ROUTES?.ONBOARDING}/add-logo`);
        break;
    }
  };

  const setSrc = (
    index = null,
    src = '',
    selectedSrc = '',
    completedSrc = '',
  ) => {
    if (index === 0) {
      if (currentStep === index) {
        return selectedSrc;
      }
      return completedSrc;
    }
    if (index === 6) {
      if (currentStep === index) {
        return selectedSrc;
      }
      return src;
    }
    if (currentStep > index) {
      return completedSrc;
    }
    if (currentStep === index) {
      return selectedSrc;
    }
    return src;
  };

  const steps = [
    {
      title: 'Business Logo',
      src: setSrc(
        0,
        '',
        <BrandLogoIcon className="selected-icon" />,
        <CheckCircleOutlined className="selected-icon font-size-18" />,
      ),
    },
    {
      title: 'Color Palette',
      src: setSrc(
        1,
        <ColorPaletteIcon className="without-selected-icon" />,
        <ColorPaletteIcon className="selected-icon" />,
        <CheckCircleOutlined className="selected-icon font-size-18" />,
      ),
    },
    {
      title: 'Industry',
      src: setSrc(
        2,
        <IndustryIcon className="without-selected-icon" />,
        <IndustryIcon className="selected-icon" />,
        <CheckCircleOutlined className="selected-icon font-size-18" />,
      ),
    },
    {
      title: 'Region',
      src: setSrc(
        3,
        <RegionIcon className="without-selected-icon" />,
        <RegionIcon className="selected-icon" />,
        <CheckCircleOutlined className="selected-icon font-size-18" />,
      ),
    },
    // currently we are no showing package screen in onboarding flow, but kept this code for future use
    // {
    //   title: 'Packages',
    //   src: setSrc(
    //     4,
    //     <ProductIcon className="without-selected-icon" />,
    //     <ProductIcon className="selected-icon" />,
    //     <CheckCircleOutlined className="selected-icon font-size-18" />
    //   )
    // },
    {
      title: 'Invite',
      src: setSrc(
        5,
        <InviteIcon className="without-selected-icon" />,
        <InviteIcon className="selected-icon" />,
        <CheckCircleOutlined className="selected-icon font-size-18" />,
      ),
    },
    {
      title: 'Success',
      src: setSrc(
        6,
        <SuccessIcon className="without-selected-icon" />,
        <CheckCircleOutlined className="selected-icon font-size-18" />,
      ),
    },
  ];

  const checkDisabled = (index) => {
    if (data?.nextStep === 'DATA_ONBOARD1') {
      if (index > 0) {
        return true;
      }
      return false;
    }
    if (data?.nextStep === 'DATA_ONBOARD2') {
      if (index > 1) {
        return true;
      }
      return false;
    }
    if (data?.nextStep === 'DATA_ONBOARD3') {
      if (index > 2) {
        return true;
      }
      return false;
    }
    if (data?.nextStep === 'DATA_ONBOARD4') {
      if (index > 3) {
        return true;
      }
      return false;
    }
    if (data?.nextStep === 'DATA_ONBOARD5') {
      if (index > 4) {
        return true;
      }
      return false;
    }
    if (data?.nextStep === 'DATA_ONBOARD6') {
      if (index > 5) {
        return true;
      }
      return false;
    }
  };

  const centerContent = () => (
    <Col
      className={windowWidth < TABLET_BREAKPOINT ? 'col-height' : ''}
      xxl={{ span: 12, pull: 0 }}
      xl={{
        span: 12,
        pull: windowWidth >= TABLET_BREAKPOINT && windowWidth < 1320 ? 2 : 0,
      }}
      lg={{ span: 24, pull: 0 }}
      md={{ span: 23, pull: 0 }}
      sm={{ span: 24, pull: 0 }}
      xs={{ span: 24, pull: 0 }}
    >
      <Steps
        size="small"
        labelPlacement="vertical"
        current={currentStep}
        className="steps"
        onChange={handleChangeStep}
      >
        {steps?.map((item, index) => (
          <Step
            disabled={checkDisabled(index)}
            key={item?.title}
            description={
              currentStep === index ? (
                <span className="selected-step">{item?.title}</span>
              ) : (
                item?.title
              )
            }
            icon={item?.src}
          />
        ))}
      </Steps>
      <div className="steps-content">{children}</div>
    </Col>
  );

  return (
    <>
      <Row className="onboarding-steps">
        <Col
          className={`profile-section d-flex ${
            windowWidth >= TABLET_BREAKPOINT
              ? 'flex-vertical justify-between'
              : ''
          } ${currentStep === 6 ? 'col-hide' : ''}`}
        >
          <div
            className={`${
              currentStep === 0 ? 'profile-image-hide' : 'profile-logo'
            }`}
          >
            {logoUrl && (
              <img alt="logo" src={logoUrl} className="profile-logo" />
            )}
          </div>
          <span
            className={`d-flex align-start ${
              windowWidth < TABLET_BREAKPOINT ? 'section-distance' : 'user-name'
            }`}
          >
            <UserIconComponent className="icon-distance" />
            <span className="d-flex flex-vertical">
              <span
                className={`bold-fonts side-color mobile-width ${
                  windowWidth < TABLET_BREAKPOINT ? 'mobile-width' : ''
                }`}
              >
                {`${data?.user?.firstName} ${data?.user?.lastName}`}
              </span>
              <span
                className="mt-4 click-link side-color"
                onClick={() => navigate(ROUTES?.LOGOUT)}
              >
                LOGOUT
              </span>
            </span>
          </span>
        </Col>
        {windowWidth >= TABLET_BREAKPOINT && centerContent()}
        <Col
          className={`right-section d-flex ${
            windowWidth >= TABLET_BREAKPOINT
              ? `flex-vertical justify-between`
              : 'align-center'
          } ${currentStep === 6 ? 'col-hide' : ''}`}
        >
          <span
            className={`d-flex click-link side-color ${
              windowWidth >= TABLET_BREAKPOINT ? 'align-center' : ''
            }`}
            onClick={() => navigate(ROUTES?.MAIN)}
          >
            <CloseCircleOutlined className="icon-distance" />
            Skip Onboarding
          </span>
          <span
            className={`d-flex justify-end click-link side-color ${
              windowWidth >= TABLET_BREAKPOINT ? 'align-center' : 'left-margin'
            }`}
          >
            <QuestionCircleOutlined className="icon-distance" />
            Help
          </span>
        </Col>
        {windowWidth < TABLET_BREAKPOINT && centerContent()}
      </Row>
    </>
  );
};

export default StepProcess;
