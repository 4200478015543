import { QuestionCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Popover,
  Radio,
  Row,
  TimePicker,
} from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  DYNAMIC_DATE_ALLOW_TYPE,
  DYNAMIC_DATE_DEFAULT_VALUE,
  DYNAMIC_DATE_TIME_TYPE,
  DYNAMIC_DATE_TYPE,
  REGEX,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import NumberComponent from '../../../components/NumberComponent';
import SelectComponent from '../../../components/SelectComponent';
import { GET_DYNAMIC_FIELD_TIME_FORMATS } from '../graphql/Queries';

const { required, requiredWhiteSpaceAllowed } = formValidatorRules;

const { Option } = SelectComponent;

const { RangePicker } = TimePicker;

const DateTimeFormData = ({ position = 'top', isDisabled = false, form }) => {
  const [dateFormats, setDateFormats] = useState(null);
  const [filteredDateFormats, setFilteredDateFormats] = useState([]);

  const date = form?.getFieldValue('dateTimeType')?.includes('DATE');
  const time = form?.getFieldValue('dateTimeType')?.includes('TIME');

  const [dynamicFieldTimeFormats] = useLazyQuery(
    GET_DYNAMIC_FIELD_TIME_FORMATS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        const dateFormatsCopy = res?.dynamicFieldTimeFormats?.data;
        setDateFormats(dateFormatsCopy);
        if (date && time) {
          setFilteredDateFormats(dateFormatsCopy?.dateTimeFormats);
        } else if (date) {
          setFilteredDateFormats(dateFormatsCopy?.dateFormats);
        } else if (time) {
          setFilteredDateFormats(dateFormatsCopy?.timeFormats);
        } else {
          setFilteredDateFormats([]);
        }
      },
      onError() {
        setDateFormats(null);
      },
    },
  );

  useEffect(() => {
    dynamicFieldTimeFormats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeDateTime = (value) => {
    const dateFormatsCopy = { ...dateFormats };
    const dateValue = value?.includes('DATE');
    const timeValue = value?.includes('TIME');
    if (dateValue && timeValue) {
      setFilteredDateFormats(dateFormatsCopy?.dateTimeFormats);
    } else if (dateValue) {
      setFilteredDateFormats(dateFormatsCopy?.dateFormats);
    } else if (timeValue) {
      setFilteredDateFormats(dateFormatsCopy?.timeFormats);
    } else {
      setFilteredDateFormats([]);
    }
    form?.setFieldsValue({ format: null });
  };

  return (
    <div className="date-time-form-data">
      {position === 'bottom' && (
        <>
          <span className="form-divider-text">FIELD SETTINGS</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="dateTimeType"
                rules={[
                  {
                    ...required,
                    message: 'Please Select Date or Time',
                    type: 'array',
                  },
                ]}
              >
                <Checkbox.Group
                  disabled={isDisabled}
                  className="fill-width"
                  onChange={handleChangeDateTime}
                >
                  {map(DYNAMIC_DATE_TIME_TYPE, (item) => (
                      <div key={item?.key}>
                        <Checkbox
                          value={item?.key}
                          className="common-checkbox mb-8"
                        >
                          {item?.label}
                        </Checkbox>
                      </div>
                    ))}
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="format"
                label="Date/Time Format"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Date/Time Format',
                  },
                ]}
              >
                <SelectComponent
                  disabled={isDisabled}
                  placeholder="Select Date/Time Format"
                >
                  {map(filteredDateFormats, (item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={18} xxl={18}>
              {form?.getFieldValue('format') && (
                <div className="field-detail">
                  <span className="field-label">Preview</span>
                  <span className="field-value">
                    {moment()?.format(form?.getFieldValue('format'))}
                  </span>
                </div>
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item name="dateType">
                <Radio.Group className="common-radio" disabled={isDisabled}>
                  {map(DYNAMIC_DATE_TYPE, (item) => (
                    <Radio key={item?.key} value={item?.key}>
                      {item?.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="allowDateTime"
                rules={[
                  {
                    ...required,
                    message: 'Please Select Allow Past or Future',
                    type: 'array',
                  },
                ]}
              >
                <Checkbox.Group disabled={isDisabled} className="fill-width">
                  {map(DYNAMIC_DATE_ALLOW_TYPE, (item) => (
                      <div key={item?.key}>
                        <Checkbox value={item?.key} className="common-checkbox">
                          {item?.label}
                          <Popover className="pointer" content={item?.tooltip}>
                            <QuestionCircleOutlined className="ml-4" />
                          </Popover>
                        </Checkbox>
                      </div>
                    ))}
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          {(date || time) && form?.getFieldValue('dateType') === 'EXACT' && (
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  name="dateTimeBooleanValue"
                  className="mb-0"
                  valuePropName="checked"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          getFieldValue('required') &&
                          (getFieldValue('readOnly') || getFieldValue('hidden'))
                        ) {
                          if (!value) {
                            return Promise?.reject(
                              new Error(
                                `Please Select ${
                                  time && !date
                                    ? 'Default to system time'
                                    : 'Default'
                                }`,
                              ),
                            );
                          }
                        }
                        return Promise?.resolve();
                      },
                    }),
                  ]}
                >
                  <Checkbox className="common-checkbox">
                    {time && !date ? 'Default to system time' : 'Default'}
                    <Popover
                      className="pointer"
                      content="This is sample tooltip"
                    >
                      <QuestionCircleOutlined className="ml-4" />
                    </Popover>
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          )}
          {((date && time) || date) &&
            form?.getFieldValue('dateType') === 'RANGE' && (
              <Row gutter={16}>
                <Col span={6}>
                  <Form.Item
                    rules={[
                      ({ getFieldValue }) => ({
                        required:
                          getFieldValue('required') &&
                          (getFieldValue('readOnly') ||
                            getFieldValue('hidden')),
                        message: 'Please Enter Day(s)',
                      }),
                      {
                        validator(_, value) {
                          if (!REGEX?.NUMBER?.test(value)) {
                            // eslint-disable-next-line prefer-promise-reject-errors
                            return Promise?.reject('Should be a valid Number');
                          }
                          if (Number(value) < 1) {
                            return Promise?.reject(
                              new Error('Enter at least 1 Day'),
                            );
                          }
                          return Promise?.resolve();
                        },
                      },
                    ]}
                    name="rangeDefaultValue"
                    label="Default Day(s)"
                  >
                    <NumberComponent
                      min={1}
                      allowClear
                      placeholder="Enter Default Day(s)"
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
          {time && !date && form?.getFieldValue('dateType') === 'RANGE' && (
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  rules={[
                    ({ getFieldValue }) => ({
                      required:
                        getFieldValue('required') &&
                        (getFieldValue('readOnly') || getFieldValue('hidden')),
                      message: 'Please Select Time',
                      type: 'array',
                    }),
                  ]}
                  name="timeDefaultValue"
                  label="Default Time"
                >
                  <RangePicker
                    className="common-range-picker fill-width"
                    popupClassName="common-range-picker"
                    use12Hours
                    minuteStep={15}
                    format={form?.getFieldValue('format') || 'HH:mm:ss'}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {date && !time && form?.getFieldValue('dateTimeBooleanValue') && (
            <Row gutter={16}>
              <Col span={6}>
                <Form.Item
                  name="dateDefaultValue"
                  rules={[
                    ({ getFieldValue }) => ({
                      required:
                        getFieldValue('required') &&
                        (getFieldValue('readOnly') || getFieldValue('hidden')),
                      message: 'Please Select Date',
                    }),
                  ]}
                >
                  <Radio.Group className="common-radio">
                    {map(DYNAMIC_DATE_DEFAULT_VALUE, (item) => (
                      <Radio key={item?.key} value={item?.key}>
                        {item?.label}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default DateTimeFormData;
