import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_USER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import UserCreate from './pages/UserCreate';
import UserEdit from './pages/UserEdit';
import UserList from './pages/UserList';
import './userModule.less';

const UserWrapper = () => (
  <div className="user-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl allowedPermissions={PERMISSION_USER} showNoAccess>
            <UserList />
          </AccessControl>
        }
      />
      <Route
        path="/add"
        element={
          <AccessControl allowedPermissions={['FET_USER_CREATE']} showNoAccess>
            <UserCreate />
          </AccessControl>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_USER_UPDATE', 'FET_USER_VIEW']}
            showNoAccess
          >
            <UserEdit />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default UserWrapper;
