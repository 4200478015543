import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_IMPORT_EXPORT_QUEUE, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ImportExportQueueList from './pages/ImportExportQueueList';

const ImportExportQueueWrapper = () => (
  <div className="import-export-queue-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_IMPORT_EXPORT_QUEUE}
            showNoAccess
          >
            <ImportExportQueueList />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default ImportExportQueueWrapper;
