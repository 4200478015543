import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_TENANT_SETTING, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import TenantSettingPage from './pages/TenantSettingPage';
import './tenantSettingModule.less';

const TenantSettingWrapper = () => (
  <div className="tenant-setting-wrapper">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_TENANT_SETTING}
            showNoAccess
          >
            <TenantSettingPage />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default TenantSettingWrapper;
