import { nth } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import settingsIcon from '../../../../assets/settings-icon.svg';

const PanelBtn = ({
  sideCloseBtn = null,
  sideOpenBtn = null,
  className = '',
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const whereFilter = location?.state;
  const query = new URLSearchParams(location?.search);
  const [sp, setSp] = useState(query?.get('sp') !== 'false');
  const toggleSider = (e) => {
    // eslint-disable-next-line no-unused-expressions
    e?.preventDefault();
    // eslint-disable-next-line no-undef
    const getID = document?.getElementById('sidePanelToggleStatus');
    const temp = getID?.className?.split(' ');
    const temp2 = nth(temp, 5);
    const urlSearchObj = new URLSearchParams(location?.search);
    if (temp2) {
      urlSearchObj?.set('sp', 'false');
      getID?.classList?.remove('side-panel-open');
    } else {
      urlSearchObj?.delete('sp');
      getID?.classList?.add('side-panel-open');
    }
    navigate(`${location?.pathname}?${urlSearchObj?.toString()}`, {
      state: { ...whereFilter },
    });
  };

  useEffect(() => {
    setSp(!location?.search?.indexOf('sp=false') !== -1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.search, location?.pathname]);

  return (
    <div
      className={`side-panel-toggle-btn-wrap ${
        !sideCloseBtn && !sideOpenBtn ? 'default-btn-position' : ''
      } ${className}`}
    >
      <img
        onClick={toggleSider}
        src={sp ? sideCloseBtn || settingsIcon : sideOpenBtn || settingsIcon}
        alt="side-panel-btn"
      />
    </div>
  );
};

export default PanelBtn;
