import { ArrowRightOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Dropdown } from 'antd';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import ExportIcon from '../../../assets/export.svg';
import { exportOptions, ROUTES } from '../../../common/constants';
import {
  checkPermissions,
  getEntityFieldName,
  handleExportCommon,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import { Columns } from '../entityColumns/Columns';
import { GET_BATCH_ENTITY_RECORDS } from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1,
};

const EntityRecordTable = () => {
  const {
    state: { pageSize, permissions },
  } = useContext(AppContext);

  const initialEntityRecordFilter = useMemo(
    () => ({
      skip: 0,
      limit: pageSize,
      sortOn: 'entityName',
      sortBy: 'DESC',
    }),
    [pageSize],
  );

  const { 'batch-entity-id': batchEntityId, id } = useParams();
  const location = useLocation();

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [searchValue, setSearchValue] = useState(null);

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  // kept for future use
  const handleTableChange = () => {};

  const [fetchBatchEntityRecord, { loading, data }] = useLazyQuery(
    GET_BATCH_ENTITY_RECORDS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const pagination = {
          ...paginationProp,
          defaultPageSize: pageSize,
          total: res?.batches?.count,
        };
        setPaginationProp(pagination);
      },
      onError() {},
    },
  );

  useEffect(() => {
    const permitted = checkPermissions(permissions, [
      'FET_DATA_ASSIGNMENT_LIST',
    ]);

    if (permitted) {
      fetchBatchEntityRecord({
        variables: {
          filter: initialEntityRecordFilter,
          where: {
            id: batchEntityId,
            entityName: location?.state?.entityName,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id,
      });
    },
  };

  const onSearchChange = () => {
    // need to update this code
  };

  const handleExport = async (e) => {
    const { key } = e;
    setExportLoading(true);
    await handleExportCommon({
      key,
      selectedKeys,
      // module: 'BRAND', // change this in future
    });
    setExportLoading(false);
  };

  return (
    <div>
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton
            customLink={`${ROUTES?.ASSIGNMENT_JOBS}/edit/${id}${ROUTES?.ENTITY_FILTERS}`}
          />
          <span className="portal-header">
            {location?.state?.batchName} <ArrowRightOutlined />{' '}
            {getEntityFieldName(location?.state?.entityName)}
          </span>
        </div>
      </Portal>
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_BRAND_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Search name or other detail.."
              name="EntityRecords"
              getData={onSearchChange}
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </AccessControl>
        </div>
        <div className="header-buttons">
          <AccessControl allowedPermissions={['FET_DATA_ASSIGNMENT_EXPORT']}>
            <Dropdown
              overlayClassName="export-btn-dropdown"
              menu={{ items: exportOptions, onClick: handleExport }}
              placement="bottom"
            >
              <Button
                className="common-button data-assignment-export-btn ml-8"
                size="small"
                icon={<img src={ExportIcon} alt="export-icon" width={11} />}
                id="brand-table-export-btn"
                loading={exportLoading}
                type="primary"
              >
                Export
              </Button>
            </Dropdown>
          </AccessControl>
        </div>
      </div>
      <AccessControl
        allowedPermissions={['FET_DATA_ASSIGNMENT_LIST']}
        showNoAccess
      >
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={loading}
              columns={[
                ...Columns(location?.state?.entityName)?.filter(
                  (item) => item !== false,
                ),
              ]}
              data={data?.batchEntityRecords || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowSelection={rowSelection}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default EntityRecordTable;
