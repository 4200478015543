import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import RoleForm from '../components/RoleForm';
import { UPDATE_ROLE } from '../graphql/Mutations';
import { GET_ROLE } from '../graphql/Queries';

function RoleEdit() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { role } = {} } = useQuery(GET_ROLE, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    },
  });

  const [updateRole] = useMutation(UPDATE_ROLE, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues,
    };

    const variables = { data: newFormValues, where: { id: role?.id } };

    try {
      const response = await updateRole({
        variables: { ...variables },
      });
      if (response?.data?.updateRole) {
        setSubmitLoading(false);
        navigate(ROUTES?.ROLES);
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Navigate to={ROUTES?.ROLES} replace />;
  }

  const initialValues = {
    ...role,
    description: role?.description || '',
    isActive: role?.isActive,
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.ROLES} />
          {role && <span className="portal-header">{role?.label}</span>}
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <RoleForm
          isEdit
          roleData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      )}
    </Card>
  );
}

export default RoleEdit;
