import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Form, Radio, Row, Switch } from 'antd';
import { map } from 'lodash';
import React, { useCallback, useEffect } from 'react';
import {
  DYNAMIC_TEXT_TYPE,
  REGEX,
} from '../../../../../../../../common/constants';
import InputComponent from '../../../../../../../../components/InputComponent';
import NumberComponent from '../../../../../../../../components/NumberComponent';

const CustomTextFieldForm = ({ form, setFormValues, formValues }) => {
  const textType = Form?.useWatch(
    ['widgetConfiguration', 'config', 'textType'],
    form,
  );
  const showDescription = Form?.useWatch(
    ['widgetConfiguration', 'config', 'description'],
    form,
  );
  const showDefaultValue = Form?.useWatch(
    ['widgetConfiguration', 'config', 'defaultCheck'],
    form,
  );
  const showTooltip = Form?.useWatch(
    ['widgetConfiguration', 'config', 'tooltip'],
    form,
  );

  const isReadOnly = Form?.useWatch(
    ['widgetConfiguration', 'config', 'rules'],
    form,
  )?.readOnly;

  useEffect(() => {
    if (isReadOnly) {
      form?.setFieldsValue({
        widgetConfiguration: {
          config: {
            defaultCheck: true,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadOnly]);

  const updateMax = useCallback(() => {
    if (textType === 'SINGLE_LINE') {
      return 255;
    }
    if (textType === 'MULTI_LINE') {
      return 1000;
    }
    return 5000;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, textType]);

  const handleChangeTextType = (e) => {
    switch (e?.target?.value) {
      case 'SINGLE_LINE':
        form?.setFieldsValue({
          widgetConfiguration: {
            config: { textLength: { minLength: 1, maxLength: 255 } },
          },
        });
        setFormValues({
          ...formValues,
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            config: {
              ...formValues?.widgetConfiguration?.config,
              textLength: { minLength: 1, maxLength: 255 },
              textType: 'SINGLE_LINE',
            },
          },
        });
        break;
      case 'MULTI_LINE':
        form?.setFieldsValue({
          widgetConfiguration: {
            config: { textLength: { minLength: 1, maxLength: 1000 } },
          },
        });
        setFormValues({
          ...formValues,
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            config: {
              ...formValues?.widgetConfiguration?.config,
              textLength: { minLength: 1, maxLength: 1000 },
              textType: 'MULTI_LINE',
            },
          },
        });
        break;
      case 'RICH_TEXT':
        form?.setFieldsValue({
          widgetConfiguration: {
            config: { textLength: { minLength: 1, maxLength: 5000 } },
          },
        });
        setFormValues({
          ...formValues,
          widgetConfiguration: {
            ...formValues?.widgetConfiguration,
            config: {
              ...formValues?.widgetConfiguration?.config,
              textLength: { minLength: 1, maxLength: 5000 },
              textType: 'RICH_TEXT',
            },
          },
        });
        break;
      default:
        break;
    }
  };

  const max = updateMax();
  return (
    <div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'label']}
        label="Label"
        rules={[{ required: true, message: 'Please Enter Label' }]}
      >
        <InputComponent name="label" />
      </Form.Item>
      <Form.Item
        name={['widgetConfiguration', 'config', 'textType']}
        className="d-flex"
      >
        <Radio.Group
          className="common-radio d-flex custom-radio"
          onChange={handleChangeTextType}
        >
          {map(DYNAMIC_TEXT_TYPE, (item) => (
            <Radio key={item?.key} value={item?.key}>
              {item?.label}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
      <Row className="d-flex justify-between">
        <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
          <Form.Item
            name={['widgetConfiguration', 'config', 'textLength', 'minLength']}
            dependencies={[
              ['widgetConfiguration', 'config', 'textType'],
              ['widgetConfiguration', 'config', 'textLength', 'maxLength'],
            ]}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!REGEX?.NUMBER?.test(value)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject('Should be a valid Number');
                  }
                  if (value && Number(value) < 1) {
                    return Promise?.reject(
                      new Error('Minimum character length should be 1'),
                    );
                  }
                  if (value && Number(value) > max) {
                    return Promise?.reject(
                      new Error(`Maximum character length should be ${max}`),
                    );
                  }
                  if (
                    getFieldValue([
                      'widgetConfiguration',
                      'config',
                      'textLength',
                      'maxLength',
                    ]) &&
                    value &&
                    Number(value) >
                      getFieldValue([
                        'widgetConfiguration',
                        'config',
                        'textLength',
                        'maxLength',
                      ])
                  ) {
                    return Promise?.reject(
                      new Error(
                        `Please Enter value less than ${getFieldValue([
                          'widgetConfiguration',
                          'config',
                          'textLength',
                          'maxLength',
                        ])}`,
                      ),
                    );
                  }
                  return Promise?.resolve();
                },
              }),
            ]}
            label="Min. Character Length"
          >
            <NumberComponent
              min={1}
              max={updateMax()}
              allowClear
              placeholder="Enter Min. Char. Length"
            />
          </Form.Item>
        </Col>
        <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
          <Form.Item
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!REGEX?.NUMBER?.test(value)) {
                    // eslint-disable-next-line prefer-promise-reject-errors
                    return Promise?.reject('Should be a valid Number');
                  }
                  if (value && Number(value) < 1) {
                    return Promise?.reject(
                      new Error('Minimum character length should be 1'),
                    );
                  }
                  if (value && Number(value) > max) {
                    return Promise?.reject(
                      new Error(`Maximum character length should be ${max}`),
                    );
                  }
                  if (
                    getFieldValue([
                      'widgetConfiguration',
                      'config',
                      'textLength',
                      'minLength',
                    ]) &&
                    value &&
                    Number(value) <
                      getFieldValue([
                        'widgetConfiguration',
                        'config',
                        'textLength',
                        'minLength',
                      ])
                  ) {
                    return Promise?.reject(
                      new Error(
                        `Please Enter value greater than ${getFieldValue([
                          'widgetConfiguration',
                          'config',
                          'textLength',
                          'minLength',
                        ])}`,
                      ),
                    );
                  }
                  return Promise?.resolve();
                },
              }),
            ]}
            name={['widgetConfiguration', 'config', 'textLength', 'maxLength']}
            label="Max. Character Length"
            dependencies={[
              ['widgetConfiguration', 'config', 'textType'],
              ['widgetConfiguration', 'config', 'textLength', 'minLength'],
            ]}
          >
            <NumberComponent
              min={1}
              max={updateMax()}
              allowClear
              placeholder="Enter Max. Char. Length"
            />
          </Form.Item>
        </Col>
      </Row>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Default Value</span>
        <Form.Item
          name={['widgetConfiguration', 'config', 'defaultCheck']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'default']}
        hidden={!showDefaultValue}
        rules={[
          {
            required: showDefaultValue || isReadOnly,
            message: 'Please Enter Default Value',
          },
        ]}
      >
        <InputComponent placeholder="Enter Default Value" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Tooltip</span>
        <Form.Item
          name={['widgetConfiguration', 'config', 'tooltip']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'tooltipValue']}
        hidden={!showTooltip}
        rules={[
          {
            required: showTooltip,
            message: 'Please Enter Tooltip Value',
          },
        ]}
      >
        <InputComponent placeholder="Enter Tooltip Value" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Description</span>
        <Form.Item
          name={['widgetConfiguration', 'config', 'description']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'descriptionValue']}
        hidden={!showDescription}
        rules={[
          {
            required: showDescription,
            message: 'Please Enter Description',
          },
        ]}
      >
        <InputComponent.TextArea
          className="common-textarea"
          autoSize={{ minRows: 2, maxRows: 4 }}
          placeholder="Enter Description"
        />
      </Form.Item>
    </div>
  );
};

export default CustomTextFieldForm;
