import { gql } from '@apollo/client';

export const CREATE_PROSPECT = gql`
  mutation createProspect($data: CreateProspectInput!) {
    createProspect(data: $data) {
      status
      message
    }
  }
`;

export const DELETE_PROSPECT = gql`
  mutation deleteProspect($where: UniqueWhereInput!) {
    deleteProspect(where: $where) {
      message
      status
    }
  }
`;

export const CANCEL_PROSPECT_INVITATION = gql`
  mutation cancelProspectInvitation($where: UniqueWhereInput!) {
    cancelProspectInvitation(where: $where) {
      message
      status
    }
  }
`;

export const RESEND_PROSPECT_INVITATION = gql`
  mutation resendProspectInvitation($where: UniqueWhereInput!) {
    resendProspectInvitation(where: $where) {
      message
      status
    }
  }
`;
