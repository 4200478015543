import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { messageContext } from '../../../app/components/AppContextHolder';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { cpqRetailUrl } from '../../../common/utils';
import '../onboarding.less';
import StepProcess from '../pages/StepProcess';

const SuccessPage = () => {
  const {
    getOnboardingData,
    getCurrentUser,
    state: { isSponsor },
  } = useContext(AppContext);
  const navigate = useNavigate();

  const onBoardingData = getOnboardingData();

  const handleChangeRoute = (page) => {
    const userData = getCurrentUser();
    if (!userData?.emailVerified) {
      messageContext?.destroy();
      messageContext?.warning('Please verify your e-mail');
    }
    switch (page) {
      case 'dashboard':
        navigate(ROUTES?.DASHBOARD);
        break;

      case 'cpq':
        // eslint-disable-next-line no-undef
        window
          ?.open(
            `https://${cpqRetailUrl(
              onBoardingData?.data?.subDomain,
              isSponsor,
            )}/${onBoardingData?.data?.slug}`,
            '_blank',
          )
          ?.focus();
        break;

      default:
        navigate(ROUTES?.MAIN);
        break;
    }
  };
  return (
    <div className="steps">
      <StepProcess>
        <div className="success-page">
          <CheckCircleFilled className="check-icon" />
          <span className="steps-content-title success-space">
            You are all set!
          </span>
          <span className="steps-content-description">
            Tenant Portal is free-to-use.
          </span>
          <Button
            type="primary"
            className="continue-button submit-btn"
            onClick={() => handleChangeRoute('dashboard')}
          >
            Continue to your Dashboard
          </Button>
          {/* commented this button for now as per DRF-7107 */}
          {/* <Button
          className="cpq-button"
          disabled={!onBoardingData?.data?.subDomain}
          onClick={() => handleChangeRoute('cpq')}
        >
          View your CPQ (Questionnaire)
        </Button> */}
        </div>
      </StepProcess>
    </div>
  );
};

export default SuccessPage;
