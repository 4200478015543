import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { messageContext } from '../../../app/components/AppContextHolder';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import ProductForm from '../components/ProductForm';
import CardWrapper from '../components/ProductTabs';
import { CREATE_PRODUCT } from '../graphql/Mutations';

function ProductCreate() {
  const navigate = useNavigate();
  const location = useLocation();
  const navigationType = useNavigationType();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [createProduct] = useMutation(CREATE_PRODUCT, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  useEffect(
    () => {
      if (navigationType === 'POP') {
        navigate(location?.pathname, {
          state: location?.state,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigationType, location, navigate],
  );

  const handleSubmit = async (formValues) => {
    const uuid = uuidv4();
    setSubmitLoading(true);

    const newFormValues = {
      ...formValues,
      costPerDay: formValues?.costPerDay || null,
      uuid,
    };

    const variables = newFormValues;

    try {
      const response = await createProduct({
        variables: { data: { ...variables } },
      });
      if (response?.data?.createProduct) {
        const { id } = response?.data?.createProduct?.data;
        navigate(`${ROUTES?.PACKAGES}/edit${ROUTES?.PRODUCTS}/${id}`, {
          state: { ...location?.state },
        });
        setTimeout(() => {
          messageContext?.info(
            <span>
              Re-rank your package
              <span
                onClick={() =>
                  navigate(ROUTES?.QUESTIONNAIRE_RESULT_VALIDATION)
                }
                className="product-ranking-here-btn"
              >
                {' '}
                here
              </span>
            </span>,
          );
        }, 2000);
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    description: '',
    score: 50,
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PACKAGES} />
          <span className="portal-header">Add Package</span>
        </div>
      </Portal>
      <CardWrapper>
        <ProductForm
          productData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      </CardWrapper>
    </Card>
  );
}

export default ProductCreate;
