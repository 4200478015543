import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Form, Radio, Row, Switch } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { DYNAMIC_BOOLEAN_DEFAULT_CHECKED } from '../../../../../../../../common/constants';
import InputComponent from '../../../../../../../../components/InputComponent';

const CustomBooleanForm = ({ form }) => {
  const showDescription = Form?.useWatch(
    ['widgetConfiguration', 'config', 'description'],
    form,
  );

  const showTooltip = Form?.useWatch(
    ['widgetConfiguration', 'config', 'tooltip'],
    form,
  );

  const isReadOnly = Form?.useWatch(
    ['widgetConfiguration', 'config', 'rules'],
    form,
  )?.readOnly;

  return (
    <div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'label']}
        label="Label"
        rules={[{ required: true, message: 'Please Enter Label' }]}
      >
        <InputComponent name="label" />
      </Form.Item>
      <Row className="d-flex justify-between">
        <Col xs={12} sm={12} md={12} lg={12} xl={11} xxl={11}>
          <Form.Item
            name={['widgetConfiguration', 'config', 'trueValue']}
            label="True"
            rules={[{ required: true, message: 'Please Enter True Value' }]}
          >
            <InputComponent allowClear placeholder="Enter True Value" />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={11} xxl={11}>
          <Form.Item
            name={['widgetConfiguration', 'config', 'falseValue']}
            label="False"
            rules={[{ required: true, message: 'Please Enter False Value' }]}
          >
            <InputComponent allowClear placeholder="Enter False Value" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={['widgetConfiguration', 'config', 'booleanDefaultValue']}
        className="fill-width"
        rules={[
          { required: isReadOnly, message: 'Please Select default Value' },
        ]}
      >
        <Radio.Group className="common-radio fill-width d-flex">
          <Row className="fill-width" justify="space-between">
            {map(DYNAMIC_BOOLEAN_DEFAULT_CHECKED, (item) => (
              <Col
                key={item?.key}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={11}
                xxl={11}
              >
                <Radio key={item?.key} value={item?.key}>
                  {item?.label}
                </Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Tooltip</span>
        <Form.Item
          name={['widgetConfiguration', 'config', 'tooltip']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'tooltipValue']}
        hidden={!showTooltip}
        rules={[
          {
            required: showTooltip,
            message: 'Please Enter Tooltip Value',
          },
        ]}
      >
        <InputComponent placeholder="Enter Tooltip Value" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Description</span>
        <Form.Item
          name={['widgetConfiguration', 'config', 'description']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'descriptionValue']}
        hidden={!showDescription}
        rules={[
          {
            required: showDescription,
            message: 'Please Enter Description',
          },
        ]}
      >
        <InputComponent.TextArea
          className="common-textarea"
          autoSize={{ minRows: 2, maxRows: 4 }}
          placeholder="Enter Description"
        />
      </Form.Item>
    </div>
  );
};

export default CustomBooleanForm;
