import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { messageContext } from '../../app/components/AppContextHolder';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/LoaderComponent';
import './authModule.less';
import { PROSPECT_INVITATION_STATUS } from './graphql/Mutations';

const ProspectInvite = () => {
  const navigate = useNavigate();
  const { search = '' } = useLocation();

  const getQuery = () => new URLSearchParams(search);

  const token = getQuery()?.get('token');
  const id = getQuery()?.get('pid');
  const status = getQuery()?.get('status');

  const [ChangeProspectInvitationStatus] = useMutation(
    PROSPECT_INVITATION_STATUS,
  );

  useEffect(() => {
    (async () => {
      if (token && id && (status === 'ACCEPTED' || status === 'REJECTED')) {
        try {
          const res = await ChangeProspectInvitationStatus({
            variables: {
              data: {
                id,
                token,
                status,
              },
            },
          });
          // eslint-disable-next-line no-undef
          window.location =
            res?.data?.changeProspectInvitationStatus?.resetPasswordUrl;
        } catch (error) {
          navigate(ROUTES?.MAIN, { replace: true });
        }
      } else {
        messageContext?.error('Invalid URL!');
        navigate(ROUTES?.MAIN, { replace: true });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <LoaderComponent />;
};

export default ProspectInvite;
