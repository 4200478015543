import React from 'react';
import BooleanFormData from './BooleanFormData';
import DateTimeFormData from './DateTimeFormData';
import FileUploadFormData from './FileUploadFormData';
import NumericFormData from './NumericFormData';
import PickListFormData from './PickListFormData';
import TextFormData from './TextFormData';

const DynamicFieldFormData = (props) => {
  const {
    position = 'top',
    selectedDynamicKey,
    isDisabled,
    handleChangeTextType,
    checkedList,
    setCheckedList,
    validationTriggered = false,
    form,
    setRefetchData,
    hasPickListData = false,
    dynamicFieldsData = null,
  } = props;
  switch (selectedDynamicKey) {
    case 'PICK_LIST':
      return (
        <PickListFormData
          position={position}
          isDisabled={isDisabled}
          form={form}
          setRefetchData={setRefetchData}
          dynamicFieldsData={dynamicFieldsData}
          hasPickListData={hasPickListData}
        />
      );
    case 'TEXT':
      return (
        <TextFormData
          position={position}
          isDisabled={isDisabled}
          handleChangeTextType={handleChangeTextType}
          form={form}
        />
      );
    case 'NUMBER':
      return <NumericFormData position={position} isDisabled={isDisabled} />;
    case 'DATE_TIME':
      return (
        <DateTimeFormData
          position={position}
          isDisabled={isDisabled}
          form={form}
        />
      );
    case 'BOOLEAN':
      return <BooleanFormData position={position} isDisabled={isDisabled} />;
    case 'UPLOAD':
      return (
        <FileUploadFormData
          position={position}
          isDisabled={isDisabled}
          checkedList={checkedList}
          setCheckedList={setCheckedList}
          validationTriggered={validationTriggered}
          form={form}
        />
      );

    default:
      return <span>Please select any field type</span>;
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
};

export default DynamicFieldFormData;
