import { Col, Divider, Popover, Row, Table, Tag } from 'antd';
import { capitalize, isArray, isEmpty, map, pickBy, startCase } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ShowMoreIconComponent from '../../../app/components/iconComponents/ShowMoreIconComponent';
import { AppContext } from '../../../AppContext';
import { DYNAMIC_FIELDS_DATA_KEYS } from '../../../common/constants';
import {
  createUnitInputParameterMessage,
  formatDate,
  formatPhoneNumber,
  formatPrice,
  formatUnits,
} from '../../../common/utils';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import RenderUploadPreview from '../../productItems/components/RenderUploadPreview';
import '../quoteModule.less';

const columns = [
  {
    title: 'PRODUCT',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    className: 'max-width-column',
    render: (name) => <span title={name}>{name}</span>,
  },
  {
    title: 'QUOTE DESCRIPTION',
    dataIndex: 'quoteDescription',
    key: 'quoteDescription',
    ellipsis: true,
    width: 180,
    render: (quoteDescription) => (
      <span title={quoteDescription}>{quoteDescription}</span>
    ),
  },
  {
    title: 'PRODUCT TYPE',
    dataIndex: 'type',
    key: 'type',
    ellipsis: true,
    render: (type) => <span title={type}>{type}</span>,
  },
  {
    title: 'PRICE',
    dataIndex: 'price',
    key: 'price',
    className: 'max-width-column',
    align: 'right',
    width: 130,
    render: (price = 0) => (
      <span title={formatPrice(price)}>{formatPrice(price)}</span>
    ),
  },
  {
    title: 'QTY',
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'right',
    className: 'max-width-column',
    width: 130,
    render: (quantity = 0) => <span title={quantity}>{quantity}</span>,
  },
  {
    title: 'TOTAL PRICE',
    dataIndex: 'total',
    key: 'total',
    align: 'right',
    width: 140,
    render: (total = 0) => (
      <span title={formatPrice(total)}>{formatPrice(total)}</span>
    ),
  },
];

const conditionRenderer = (record) => {
  let showMoreButton = false;

  const conditionObj = {
    brands: record?.conditions?.brands || [],
    manufacturers: record?.conditions?.manufacturers || [],
    regions: record?.conditions?.regions || [],
    unitInputParameter: record?.conditions?.unitInputParameter || {},
  };

  const finalConditionObj = pickBy(conditionObj, (value) => !isEmpty(value));

  const total = Object?.keys(finalConditionObj)?.length;
  const firstCondition =
    finalConditionObj[Object?.keys(finalConditionObj)?.[0]] || [];

  const content = (
    <div key={record?.id} className="pricing-condition">
      {map(finalConditionObj, (value, key) => {
        if (key === 'unitInputParameter') {
          return (
            <div className="condition">
              <span className="condition-title">
                {key === 'brands' ? 'Retailers' : startCase(key)} :
              </span>
              <span className="condition-data">
                {createUnitInputParameterMessage(value)}
              </span>
            </div>
          );
        }
        return (
          <div className="condition" key={`${key}${record?.id}`}>
            <span className="condition-title">
              {key === 'brands' ? 'Retailers' : startCase(key)} :
            </span>
            {map(value, (val, index) => (
              <div key={`${index}${record?.id}${val?.id}`}>
                <>
                  {key === 'regions' ? (
                    <>
                      &nbsp;
                      <Tag className="region-tag">{val?.name || '-'}</Tag>
                    </>
                  ) : (
                    <span className="condition-data" key={val?.id}>
                      {val?.name || val?.label}
                    </span>
                  )}
                </>
              </div>
            ))?.reduce((prev, curr) => {
              if (prev === '') {
                return curr;
              }
              return [prev, ', ', curr];
            }, '')}
            <br />
          </div>
        );
      })}
    </div>
  );

  if (
    total > 1 ||
    record?.brands?.length > 2 ||
    record?.manufacturers?.length > 2 ||
    Object?.keys(finalConditionObj)?.[0] === 'regions'
  ) {
    showMoreButton = true;
  } else {
    showMoreButton = false;
  }
  return (
    <div key={record?.id} className="pricing-condition">
      <span key={record?.id} className="condition-title">
        {Object?.keys(finalConditionObj)?.length &&
        Object?.keys(finalConditionObj)?.[0] === 'brands'
          ? 'Retailers'
          : startCase(Object?.keys(finalConditionObj)?.[0])}{' '}
        :
      </span>

      {isArray(firstCondition) ? (
        <>
          {map(firstCondition?.slice(0, 2), (firstObj, index) => (
            <span
              key={`${index}${firstObj?.id}`}
              className="condition-max-width"
            >
              {Object?.keys(finalConditionObj)?.length > 0 &&
              Object?.keys(finalConditionObj)?.[0] === 'regions' ? (
                <Tag className="condition-region" key={firstObj?.id}>
                  {firstObj?.name}
                </Tag>
              ) : (
                <span className="condition-data" key={firstObj?.id}>
                  {firstObj?.name || firstObj?.label}
                </span>
              )}
            </span>
          ))?.reduce((prev, curr) => {
            if (prev === '') {
              return curr;
            }
            return [prev, ', ', curr];
          }, '')}
        </>
      ) : (
        <>
          <div className="condition">
            <span className="condition-data">
              {createUnitInputParameterMessage(firstCondition)}
            </span>
          </div>
        </>
      )}

      {showMoreButton && (
        <Popover
          overlayClassName="pricing-popover"
          placement="rightBottom"
          content={content}
        >
          <Tag className="single-item pointer more-shadow ml-5">
            <span className="content-text">
              {total - 1 > 0 ? `${total - 1} more` : 'show more'}
              <ShowMoreIconComponent className="show-more-icon" />
            </span>
          </Tag>
        </Popover>
      )}
    </div>
  );
};

const quoteModifierColumns = [
  {
    title: 'TITLE',
    dataIndex: 'title',
    key: 'title',
    width: 200,
    ellipsis: true,
    className: 'max-width-column',
  },
  {
    title: 'CONDITION',
    dataIndex: 'conditions',
    key: 'conditions',
    ellipsis: true,
    className: 'max-width-column',
    render: (conditions, record) => <>{conditionRenderer(record)}</>,
  },
  {
    title: 'MODIFIER ON',
    dataIndex: 'modifier_on',
    key: 'modifierOn',
    ellipsis: true,
    width: 160,
    render: (modifierOn) => <span>{`${modifierOn} Value`}</span>,
  },
  {
    title: 'GOOD',
    dataIndex: 'pricingValue',
    key: 'pricingValue',
    ellipsis: true,
    align: 'right',
    width: 130,
    onHeaderCell: () => ({
      style: {
        textAlign: 'right',
      },
    }),
    render: (amt, record) => {
      if (record?.pricingValueType === '$') {
        return (
          <span>
            {record?.pricingOperation === 'ADD' ? '' : '-'}
            {record?.pricingValueType}
            {formatUnits(amt)}
          </span>
        );
      }
      return (
        <span>
          {record?.pricingOperation === 'ADD' ? '' : '-'}
          {formatUnits(amt)}
          {record?.pricingValueType}
        </span>
      );
    },
  },
  {
    title: 'BETTER',
    dataIndex: 'pricingValueBetter',
    key: 'pricingValueBetter',
    ellipsis: true,
    onHeaderCell: () => ({
      style: {
        textAlign: 'center',
      },
    }),
    align: 'right',
    width: 130,
    render: (amt, record) => {
      if (record?.pricingValueType === '$') {
        return (
          <span>
            {record?.pricingOperation === 'ADD' ? '' : '-'}
            {record?.pricingValueType}
            {formatUnits(amt)}
          </span>
        );
      }
      return (
        <span>
          {record?.pricingOperation === 'ADD' ? '' : '-'}
          {formatUnits(amt)}
          {record?.pricingValueType}
        </span>
      );
    },
  },
  {
    title: 'BEST',
    dataIndex: 'pricingValueBest',
    key: 'pricingValueBest',
    ellipsis: true,
    onHeaderCell: () => ({
      style: {
        textAlign: 'center',
      },
    }),
    width: 130,
    align: 'right',
    render: (amt, record) => {
      if (record?.pricingValueType === '$') {
        return (
          <span>
            {record?.pricingOperation === 'ADD' ? '' : '-'}
            {record?.pricingValueType}
            {formatUnits(amt)}
          </span>
        );
      }
      return (
        <span>
          {record?.pricingOperation === 'ADD' ? '' : '-'}
          {formatUnits(amt)}
          {record?.pricingValueType}
        </span>
      );
    },
  },
];

const suggestedColumn = [
  {
    title: 'QUALITY',
    dataIndex: 'quality',
    key: 'quality',
    ellipsis: true,
    className: 'max-width-column',
  },
  {
    title: 'TITLE',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    className: 'max-width-column',
    render: (name) => <span>{name}</span>,
  },
  {
    title: 'SELLING PRICE',
    dataIndex: 'sellingPrice',
    key: 'sellingPrice',
    ellipsis: true,
    className: 'max-width-column',
    render: (name) => <span>{name}</span>,
  },
];

const QuoteViewDetail = ({ quote, loading }) => {
  const {
    title = '-',
    sku = '-',
    manufacturer = '-',
    quality = '-',
    amount = '-',
    createdAt = '-',
    customerName = '-',
    sponsorName = '-',
    contactNumber = '-',
    leadNumber = '-',
    status = '-',
    brand = {},
    email = '-',
    suggestedQuotes = {},
    customFields = [],
    systemFields = [],
    addOnProducts = {},
  } = quote ?? {};

  const {
    state: { isSponsor, globalDateFormat },
  } = useContext(AppContext);

  const { good = {}, better = {}, best = {} } = suggestedQuotes || {};
  const navigate = useNavigate();
  const location = useLocation();

  const suggestedTableData = useMemo(
    () => [
      {
        quality: good?.quoteLabel ?? 'Good',
        title: `${good?.title ?? ''} ${
          title === good?.title ? '(Selected)' : ''
        }`,
        sellingPrice: good?.sellingPrice ?? '0',
      },
      {
        quality: better?.quoteLabel ?? 'Better',
        title: `${better?.title ?? ''} ${
          title === better?.title ? '(Selected)' : ''
        }`,
        sellingPrice: better?.sellingPrice ?? '0',
      },
      {
        quality: best?.quoteLabel ?? 'Best',
        title: `${best?.title ?? ''} ${
          title === best?.title ? '(Selected)' : ''
        }`,
        sellingPrice: best?.sellingPrice ?? '0',
      },
    ],
    [good, better, best, title],
  );

  const quoteModifierData = useMemo(() => {
    const quoteModifierClone = [];
    if (!isEmpty(good?.pricingModifierObj) && title === good?.title) {
      quoteModifierClone?.push(...good?.pricingModifierObj);
    } else if (
      !isEmpty(better?.pricingModifierObj) &&
      title === better?.title
    ) {
      quoteModifierClone?.push(...better?.pricingModifierObj);
    } else if (!isEmpty(best?.pricingModifierObj) && title === best?.title) {
      quoteModifierClone?.push(...best?.pricingModifierObj);
    }

    return quoteModifierClone;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [good, better, best]);

  const { addOnProductItemInfo, productItemInfo } =
    suggestedQuotes?.[quality?.toLowerCase()] || {};

  useEffect(() => {
    const unListen = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, { state: { ...location?.state } });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  const {
    productTableData,
    totalAmount,
    totalPrices,
    totalQuantity,
  } = useMemo(() => {
    let totalPrice = 0;
    let totalQty = 0;
    let individualPrice = 0;

    const productTableDataInfo = [
      ...(productItemInfo?.map((item) => {
        const { name, price, quantity, quoteDescription } = item;
        totalPrice += price * quantity;
        individualPrice += +price;
        totalQty += quantity;
        return {
          name,
          price,
          quantity,
          type: 'Package Product',
          total: price * quantity,
          quoteDescription,
        };
      }) ?? []),
      ...(isArray(addOnProducts)
        ? addOnProducts?.map((item) => {
            const { title: addOnTitle, amount: price, quantity } = item;
            const quoteDescription = addOnProductItemInfo?.find(
              (items) => items?.name === addOnTitle,
            )?.quoteDescription;

            totalPrice += quantity * price;
            individualPrice += +price;
            totalQty += quantity;
            return {
              name: addOnTitle,
              price,
              quantity,
              total: price * quantity,
              type: 'Add On Products',
              quoteDescription,
            };
          }) ?? []
        : []),
    ];

    return {
      productTableData: productTableDataInfo,
      totalAmount: totalPrice,
      totalPrices: individualPrice,
      totalQuantity: totalQty,
    };
  }, [addOnProducts, addOnProductItemInfo, productItemInfo]);

  return (
    <>
      <div className="quote-viewer">
        <div className="content-section quote-viewer-detail">
          <Row className="content-section quote-viewer-detail">
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <div className="field-detail">
                <span className="field-label">Selected Package</span>
                <span className="field-value">{title || '-'}</span>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Package SKU</span>
                <span className="field-value">{sku || '-'}</span>
              </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">
                  Manufacturer(Primary Product)
                </span>
                <span className="field-value">{manufacturer || '-'}</span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Quote Amt.</span>
                <span className="field-value">
                  {formatPrice(amount || 0) || '-'}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Package Level</span>
                <span className="field-value">{quality || '-'}</span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Order Time & Date</span>
                <span className="field-value">
                  {formatDate(createdAt, globalDateFormat) || '-'}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Customer</span>
                <span className="field-value">{customerName || '-'}</span>
              </div>
            </Col>
            {!isSponsor && sponsorName && (
              <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
                <div className="field-detail">
                  <span className="field-label">Sponsor</span>
                  <span className="field-value">{sponsorName || '-'}</span>
                </div>
              </Col>
            )}
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Lead Number</span>
                <span className="field-value">{leadNumber || '-'}</span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Lead Status</span>
                <span className="field-value">{capitalize(status) || '-'}</span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Retailer</span>
                <span className="field-value">{brand || '-'}</span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Phone number</span>
                <span className="field-value">
                  {formatPhoneNumber(contactNumber) || '-'}
                </span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Email</span>
                <span className="field-value">{email || '-'}</span>
              </div>
            </Col>
            {isArray(customFields) &&
              customFields?.length > 0 &&
              customFields
                ?.filter(
                  (items) =>
                    items?.fieldType !== DYNAMIC_FIELDS_DATA_KEYS?.UPLOAD &&
                    !items?.value?.[0]?.file?.uid,
                )
                ?.map((items) => {
                  if (items?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN) {
                    return (
                      <Col
                        key={items?.id}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={6}
                        xxl={6}
                      >
                        <div className="field-detail">
                          <span className="field-label">{items?.label}</span>
                          <span className="field-value">
                            {items?.value?.[0]?.blockId
                              ? items?.value?.[0]?.label
                              : items?.valueLabel || '-'}
                          </span>
                        </div>
                      </Col>
                    );
                  }
                  if (
                    items?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST
                  ) {
                    return (
                      <Col
                        key={items?.id}
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={6}
                        xxl={6}
                      >
                        <div className="field-detail">
                          <span className="field-label">{items?.label}</span>
                          <span className="pick-list-field-value">
                            {items?.value
                              ?.map((pickListData) => pickListData?.label)
                              .join(' , ')}
                          </span>
                        </div>
                      </Col>
                    );
                  }
                  return (
                    <Col
                      key={items?.id}
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={6}
                      xxl={6}
                    >
                      <div className="field-detail">
                        <span className="field-label">{items?.label}</span>
                        <span className="field-value">
                          {items?.value?.[0]?.uuid
                            ? items?.value?.[0]?.label
                            : items?.value?.[0] || '-'}
                        </span>
                      </div>
                    </Col>
                  );
                })}

            {customFields
              ?.filter(
                (items) =>
                  items?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.UPLOAD,
              )
              ?.map((items) => (
                <Col
                  key={items?.id}
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={6}
                  xxl={6}
                >
                  <div key={items?.label} className="field-detail">
                    <span className="field-label">{items?.label}</span>
                    <RenderUploadPreview item={items} isQuoteDetail />
                  </div>
                </Col>
              ))}

            {isArray(systemFields) &&
              systemFields?.length > 0 &&
              systemFields?.map((items) => {
                if (items?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN) {
                  return (
                    <Col
                      key={items?.id}
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={6}
                      xxl={6}
                    >
                      <div className="field-detail">
                        <span className="field-label">{items?.label}</span>
                        <span className="field-value">
                          {items?.value?.[0]?.blockId
                            ? items?.value?.[0]?.label
                            : items?.valueLabel || '-'}
                        </span>
                      </div>
                    </Col>
                  );
                }
                if (items?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST) {
                  return (
                    <Col
                      key={items?.id}
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={6}
                      xxl={6}
                    >
                      <div className="field-detail">
                        <span className="field-label">{items?.label}</span>
                        <span className="pick-list-field-value">
                          {items?.value
                            ?.map((pickListData) => pickListData?.label)
                            .join(' , ')}
                        </span>
                      </div>
                    </Col>
                  );
                }
                return (
                  <Col
                    key={items?.id}
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={6}
                    xxl={6}
                  >
                    <div className="field-detail">
                      <span className="field-label">{items?.label}</span>
                      <span className="field-value">
                        {items?.value?.[0]?.blockId
                          ? items?.value?.[0]?.label
                          : items?.value?.[0] || '-'}
                      </span>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
      <div className="quote-viewer">
        <div className="content-section quote-viewer-detail">
          <div className="card-container">
            <div className="common-table product-sub-item-table">
              <span className="form-divider-text">Package Details</span>
              <Divider className="form-divider" />
              <TableComponent
                columns={[...columns?.filter((item) => item !== false)]}
                data={productTableData}
                loading={loading}
                fullHeight={false}
                summary={() => (
                  <Table.Summary>
                    <Table.Summary.Row>
                      <Table.Summary.Cell />
                      <Table.Summary.Cell />
                      <Table.Summary.Cell>
                        <span>Total</span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <span className="d-flex justify-end">
                          {formatPrice(totalPrices || 0)}
                        </span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <span className="d-flex justify-end">
                          {totalQuantity}
                        </span>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <span className="d-flex justify-end">
                          {formatPrice(totalAmount)}
                        </span>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="quote-viewer mt-8">
        <div className="content-section quote-viewer-detail">
          <span className="form-divider-text">Quote Modifier Conditions</span>
          <Divider className="form-divider" />
          <div className="common-table">
            <TableComponent
              columns={[
                ...quoteModifierColumns?.filter((item) => item !== false),
              ]}
              data={quoteModifierData}
              loading={loading}
              rowKey="id"
              fullHeight={false}
              className="quote-modifier-table"
            />
          </div>
        </div>
      </div>
      <div className="quote-viewer mt-8">
        <div className="content-section quote-viewer-detail">
          <span className="form-divider-text">Suggested Packages</span>
          <Divider className="form-divider" />

          <TableComponent
            columns={[...suggestedColumn?.filter((item) => item !== false)]}
            data={suggestedTableData}
            loading={loading}
            fullHeight={false}
          />
        </div>
      </div>
    </>
  );
};

export default QuoteViewDetail;
