import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Form, Popover, Radio, Switch } from 'antd';
import React from 'react';
import { uploadImageNormalize } from '../../../../../../common/utils';
import DraggerUploadComponent from '../../../../../../components/DraggerUploadComponent';
import InputComponent from '../../../../../../components/InputComponent';

const StepsForm = ({ form = null, handleChangeDefault }) => {
  const showStepsImage = Form?.useWatch(
    ['widgetConfiguration', 'stairs', 'steps', 'pictureCheck'],
    form,
  );

  return (
    <>
      <Form.Item
        label="Title"
        name={['widgetConfiguration', 'stairs', 'steps', 'title']}
        rules={[
          {
            required: true,
            message: 'Please Enter Title',
          },
        ]}
      >
        <InputComponent placeholder="Enter Title" />
      </Form.Item>
      <Form.Item
        name={['widgetConfiguration', 'stairs', 'steps', 'defaultCheck']}
        valuePropName="checked"
      >
        <Radio
          className="common-radio"
          value="default"
          onChange={() => handleChangeDefault('STEPS')}
        >
          Default
        </Radio>
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">
          Picture{' '}
          <Popover
            overlayClassName="pointer"
            content="Add an image icon for displaying alongside this content."
          >
            <QuestionCircleOutlined />
          </Popover>
        </span>
        <Form.Item
          name={['widgetConfiguration', 'stairs', 'steps', 'pictureCheck']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'stairs', 'steps', 'picture']}
        hidden={!showStepsImage}
        normalize={uploadImageNormalize}
        valuePropName="fileList"
      >
        <DraggerUploadComponent>
          <p className="icon-header">
            <DownloadOutlined />
          </p>
          <p className="upload-title">
            Click or drag file to this area to upload
          </p>
        </DraggerUploadComponent>
      </Form.Item>
      <Form.Item
        label="Width"
        name={['widgetConfiguration', 'stairs', 'steps', 'width']}
        rules={[
          {
            required: true,
            message: 'Please Enter Placeholder Text For Width',
          },
        ]}
      >
        <InputComponent placeholder="Enter Placeholder Text For Width" />
      </Form.Item>
      <Form.Item
        label="Depth/Run"
        name={['widgetConfiguration', 'stairs', 'steps', 'depth']}
        rules={[
          {
            required: true,
            message: 'Please Enter Placeholder Text For Depth/Run',
          },
        ]}
      >
        <InputComponent placeholder="Enter Placeholder Text For Depth/Run" />
      </Form.Item>
      <Form.Item
        label="Height/Rise"
        name={['widgetConfiguration', 'stairs', 'steps', 'height']}
        rules={[
          {
            required: true,
            message: 'Please Enter Placeholder Text For Height/Rise',
          },
        ]}
      >
        <InputComponent placeholder="Enter Placeholder Text For Height/Rise" />
      </Form.Item>
    </>
  );
};

export default StepsForm;
