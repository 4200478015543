import { useMutation } from '@apollo/client';
import { Button, Card, Form, Spin, Statistic } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import logo from '../../assets/images/logo-with-text.png';
import {
  LOCKED_ACCOUNT_MINUTES,
  RESEND_OTP_ACCOUNT_MINUTES,
  RESEND_OTP_TIME,
  ROUTES,
  USER_EMAIL,
} from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import './OtpVerfication.less';
import './authModule.less';
import { RESEND_OTP, VERIFY_OTP } from './graphql/Mutations';

const { required } = formValidatorRules;

const getOTPTime = () =>
  // eslint-disable-next-line no-undef
  localStorage?.getItem(RESEND_OTP_TIME) || null;

const getUserCred = () =>
  // eslint-disable-next-line no-undef
  localStorage?.getItem(USER_EMAIL) || null;

const OtpVerification = () => {
  const [form] = Form.useForm();
  const [otpValue, setOtpValue] = useState();
  const { navigate } = useRouter();
  const { initializeAuth } = useContext(AppContext);
  const [showResetButton, setShowResetButton] = useState(false);

  const currentTime = moment();
  const timeUnix = getOTPTime();

  const differenceInSeconds = timeUnix - currentTime;

  const [verifyOtp, { loading: verifyLoading }] = useMutation(VERIFY_OTP, {
    onError(error) {
      if (error?.graphQLErrors?.[0]?.extensions?.code === 'ACCOUNT_LOCKED') {
        // eslint-disable-next-line no-undef
        localStorage.setItem(
          RESEND_OTP_TIME,
          moment()?.add(LOCKED_ACCOUNT_MINUTES, 'minutes')?.valueOf() ?? null,
        );
        navigate(ROUTES?.LOGIN);
      }
    }, // Always write this method for error handling in all mutation.
  });

  const [resendOtp, { loading: resendOtpLoading }] = useMutation(RESEND_OTP, {
    onError(error) {
      if (error?.graphQLErrors?.[0]?.extensions?.code === 'ACCOUNT_LOCKED') {
        // eslint-disable-next-line no-undef
        localStorage.setItem(
          RESEND_OTP_TIME,
          moment()?.add(LOCKED_ACCOUNT_MINUTES, 'minutes')?.valueOf() ?? null,
        );
        navigate(ROUTES?.LOGIN);
      }
    }, // Always write this method for error handling in all mutation.
  });

  useEffect(() => {
    if (!getUserCred()) {
      navigate(ROUTES?.LOGIN);
    }
  }, [getUserCred]);

  function successCallback(accessToken, userData) {
    initializeAuth(accessToken, userData);
    navigate(ROUTES?.MAIN);
  }

  async function resendOtpFunction() {
    const response = await resendOtp({
      variables: {
        email: getUserCred(),
      },
    });

    if (response?.data) {
      // eslint-disable-next-line no-undef
      localStorage.setItem(
        RESEND_OTP_TIME,
        moment()?.add(RESEND_OTP_ACCOUNT_MINUTES, 'minutes')?.valueOf() ?? null,
      );
      setShowResetButton(false);
    }
  }

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: getUserCred(),
        otp: values?.otp?.trim(),
      };

      const response = await verifyOtp({
        variables: { data: { ...formValues } },
      });
      if (response?.data) {
        // eslint-disable-next-line no-undef
        localStorage?.clear();
        const accessToken = response?.data?.verifyOtp?.authToken;
        const userData = response?.data?.verifyOtp?.user;
        if (successCallback) {
          successCallback(accessToken, userData);
        }
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error('error from login => ', error);
    }
  };
  return (
    <Spin
      spinning={resendOtpLoading || verifyLoading}
      wrapperClassName="full-width"
    >
      <div className="auth-bg">
        <div className="gx-login-container">
          <div className="login-wrap d-flex align-center justify-start">
            <Card className="full-width login-card">
              <div className="otp-div">
                <div className="gx-login-header gx-text-center fill-width mb-24 mt-24">
                  <img src={logo} width={287} alt="Pocket Testament League" />
                </div>
                <h2 className="text-left mt-8 d-flex justify-center align-center">
                  OTP Verification
                </h2>
                <p className="otp-email-verification">
                  Please enter the 6-digit verification code you received in
                  your email.{' '}
                  <span>
                    {getUserCred()} <Link to={ROUTES?.LOGIN}>change</Link>
                  </span>
                </p>
                <Form
                  name="Login"
                  onFinish={onFinish}
                  size="large"
                  form={form}
                  className="gx-login-form gx-form-row0"
                >
                  <div>
                    <Form.Item
                      name="otp"
                      className="d-flex justify-center align-center mt-24"
                      rules={[
                        { required, message: 'Please enter otp to continue!' },
                        () => ({
                          validator(rule, value) {
                            if (value) {
                              if (value?.length < 6) {
                                // eslint-disable-next-line prefer-promise-reject-errors
                                return Promise?.reject(
                                  'Length of otp should be 6',
                                );
                              }
                            }
                            return Promise?.resolve();
                          },
                        }),
                      ]}
                    >
                      <OTPInput
                        value={otpValue}
                        onChange={setOtpValue}
                        numInputs={6}
                        inputType="number"
                        renderInput={(props) => <input {...props} />}
                        shouldAutoFocus
                        focusStyle="otp-input-focus"
                        className="otp-input"
                      />
                    </Form.Item>
                  </div>
                  <div className="d-flex justify-center">
                    {showResetButton ? (
                      <Button
                        type="link"
                        className="mb-8"
                        onClick={() => {
                          setOtpValue(0);
                          form?.resetFields();
                          resendOtpFunction();
                        }}
                      >
                        click here to resend OTP
                      </Button>
                    ) : (
                      <>
                        <div>Resend OTP in </div>
                        <Statistic.Countdown
                          className="mb-8 otp-countdown"
                          format="mm:ss"
                          value={moment() + differenceInSeconds}
                          onFinish={() => setShowResetButton(true)}
                        />
                      </>
                    )}
                  </div>

                  <Form.Item className="full-width mb-8">
                    <Button
                      type="primary"
                      className="full-width"
                      htmlType="submit"
                    >
                      Verify
                    </Button>
                  </Form.Item>
                  <Form.Item className="text-center mb-8">
                    <Link to={ROUTES?.LOGIN}>Back to Login Page</Link>
                  </Form.Item>
                </Form>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default OtpVerification;
