import Icon from '@ant-design/icons';
import React from 'react';

const secondaryQuestionnaireIcon = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.24762 1.16536C4.68645 0.793766 5.32283 0.582031 6 0.582031C6.67717 0.582031 7.31355 0.793766 7.75238 1.16536H8.33333C8.97766 1.16536 9.5 1.6877 9.5 2.33203L10.0833 2.33203C10.7277 2.33203 11.25 2.85437 11.25 3.4987V12.2487C11.25 12.893 10.7277 13.4154 10.0833 13.4154H1.91667C1.27233 13.4154 0.75 12.893 0.75 12.2487V3.4987C0.75 2.85437 1.27233 2.33203 1.91667 2.33203L2.5 2.33203C2.5 1.6877 3.02233 1.16536 3.66667 1.16536H4.24762ZM8.33333 4.08203C8.76516 4.08203 9.1422 3.84742 9.34392 3.4987H10.0833V12.2487H1.91667V3.4987H2.65608C2.8578 3.84742 3.23484 4.08203 3.66667 4.08203H8.33333ZM4.75028 2.33203L4.92471 2.131C5.11636 1.91013 5.52716 1.7487 6 1.7487C6.47284 1.7487 6.88364 1.91013 7.07529 2.131L7.24972 2.33203H8.33333V2.91536H3.66667V2.33203H4.48412H4.75028Z"
        fill="currentColor"
      />
    </svg>
  );

const SecondaryQuestionnaireIcon = (props) => (
  <Icon component={secondaryQuestionnaireIcon} {...props} />
);

export default SecondaryQuestionnaireIcon;
