import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './import.less';
import EditItem from './pages/EditItem';
import ImportList from './pages/ImportList';
import UploadFile from './pages/UploadFile';
import VerifyImport from './pages/VerifyImport';

const ImportWrapper = () => (
  <div className="import">
    <Routes>
      <Route path={ROUTES?.MAIN} element={<ImportList />} />
      <Route path="/upload" element={<UploadFile />} />
      <Route path="/:fileId/edit/:itemId" element={<EditItem />} />
      <Route path="/:fileId" element={<VerifyImport />} />
    </Routes>
  </div>
);

export default ImportWrapper;
