import { Select } from 'antd';
import { isArray, map } from 'lodash';
import React, { useMemo } from 'react';
import './styles/SelectComponent.less';

const SelectComponent = (props) => {
  const {
    children,
    className = '',
    placeholder = '',
    options,
    ...rest
  } = props;

  // this is used for antd v4 children compatibility in antd v5.
  const items = useMemo(
    () =>
      isArray(children)
        ? map(children, (item) => ({
            ...item?.props,
            label: item?.props?.children,
          }))
        : children?.props?.children?.length > 0 && [
            {
              ...children?.props,
              label: children?.props?.children,
            },
          ],
    [children],
  );

  return (
    <Select
      showAction={['focus']}
      className={`select-width ${className}`}
      placeholder={placeholder}
      optionFilterProp="children"
      getPopupContainer={(trigger) => trigger?.parentNode}
      showSearch
      allowClear
      options={options || items}
      maxTagCount="responsive"
      {...rest}
      aria-label={placeholder}
    />
  );
};

export default SelectComponent;

SelectComponent.Option = Select?.Option;
SelectComponent.OptGroup = Select?.OptGroup;
