import { QuestionCircleOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Col, Popover, Row } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import EditIcon from '../../../assets/edit.svg';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import { ROUTES } from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import { GET_LINE_OF_BUSINESS } from '../graphql/Queries';

const LineOfBusinessDetails = () => {
  const { id: industryId, lobId } = useParams();
  const navigate = useNavigate();
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  const { data } = useQuery(GET_LINE_OF_BUSINESS, {
    variables: {
      id: lobId,
    },
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (res?.lineOfBusiness?.logo?.url) {
        setLogoUrl({
          url: res?.lineOfBusiness?.logo?.url,
          title: res?.lineOfBusiness?.logo?.url?.split('_')?.[1],
        });
      }
      setLoading(false);
    },
    onError() {},
  });

  return (
    <div className="content-section">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton
            customLink={`${ROUTES?.INDUSTRIES}/view/${industryId}`}
          />
          <span className="portal-header">
            {data?.lineOfBusiness?.industry?.label}
          </span>
        </div>
      </Portal>
      <AccessControl allowedPermissions={['FET_INDUSTRY_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={EditIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="manufacturer-table-save-btn"
            type="primary"
            onClick={() =>
              navigate(`${ROUTES?.INDUSTRIES}/edit/${industryId}/edit/${lobId}`)
            }
          >
            Edit
          </Button>
        </Portal>
      </AccessControl>
      {loading ? (
        <LoaderComponent setHeight={40} />
      ) : (
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Line Of Business *</span>
              <span className="field-value">{data?.lineOfBusiness?.label}</span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">
                Alias{' '}
                <Popover placement="top" content="This will be alias for label">
                  <QuestionCircleOutlined />
                </Popover>
              </span>
              <span className="field-value">
                {data?.lineOfBusiness?.alias || '-'}
              </span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={4} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Status *</span>
              <span className="field-value">
                {data?.lineOfBusiness?.isActive ? 'Active' : 'InActive'}
              </span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={12}>
            <div className="field-detail">
              <span className="field-label">Reference Id</span>
              <span className="field-value">
                {data?.lineOfBusiness?.referenceId || '-'}
              </span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <div className="field-detail">
              <span className="field-label">Industry *</span>
              <span className="field-value">
                {data?.lineOfBusiness?.industry?.label}
              </span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Icon *</span>
              <img
                src={logoUrl?.url || placeholderImage}
                alt="logo"
                onError={(e) => {
                  e.target.src = placeholderImage;
                }}
                className="field-logo"
              />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Description</span>
              <div
                className="editor-render"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: data?.lineOfBusiness?.description || '-',
                }}
              />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default LineOfBusinessDetails;
