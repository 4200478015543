import { UpOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import React, { useEffect } from 'react';
import {
  DIMENSION_LOB_COMMON,
  DIMENSION_LOB_STAIRS,
  DIMENSION_TABS,
} from '../../../../../../common/constants';
import PanelHeader from '../../../previewComponents/globalComponents/PanelHeader';
import HighLightYourArea from '../components/HighLightYourArea';
import ManualEntry from '../components/ManualEntry';
import MapEntryResult from '../components/MapEntryResult';
import MeasurementTipsForManualEntry from '../components/MeasurementTipsForManualEntry';
import MeasurementTipsForMap from '../components/MeasurementTipsForMap';
import StepsForm from '../components/StepsForm';
import TotalRiseForm from '../components/TotalRiseForm';
import '../preview/dimensionWidget.less';

const DimensionWidgetForm = ({
  form = null,
  setSelectedDimensionTab,
  selectedDimensionTab = 'common',
  formValues = null,
  setFormValues,
  setSelectedDimensionProject,
  selectedDimensionProject = null,
}) => {
  useEffect(() => {
    if (DIMENSION_TABS?.common?.includes(selectedDimensionTab)) {
      setSelectedDimensionProject(DIMENSION_LOB_COMMON);
    }
    if (DIMENSION_TABS?.stairs?.includes(selectedDimensionTab)) {
      setSelectedDimensionProject(DIMENSION_LOB_STAIRS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDimensionTab]);

  const handleChangeDefault = (stairsOption = '') => {
    const stairsCheck = stairsOption === 'STEPS';
    form?.setFieldsValue({
      widgetConfiguration: {
        stairs: {
          steps: {
            defaultCheck: stairsCheck,
          },
          totalRise: {
            defaultCheck: !stairsCheck,
          },
        },
      },
    });
    setFormValues({
      ...formValues,
      widgetConfiguration: {
        ...formValues?.widgetConfiguration,
        stairs: {
          ...formValues?.widgetConfiguration?.stairs,
          steps: {
            ...formValues?.widgetConfiguration?.stairs?.steps,
            defaultCheck: stairsCheck,
          },
          totalRise: {
            ...formValues?.widgetConfiguration?.stairs?.totalRise,
            defaultCheck: !stairsCheck,
          },
        },
      },
    });
  };

  const handleChangeDefaultForOtherTabs = (option = '') => {
    const mapEntryCheck = option === 'MAP_ENTRY';
    form?.setFieldsValue({
      widgetConfiguration: {
        common: {
          mapEntry: {
            default: mapEntryCheck,
          },
          manualEntry: {
            default: !mapEntryCheck,
          },
        },
      },
    });
    setFormValues({
      ...formValues,
      widgetConfiguration: {
        ...formValues?.widgetConfiguration,
        common: {
          ...formValues?.widgetConfiguration?.common,
          mapEntry: {
            ...formValues?.widgetConfiguration?.common?.mapEntry,
            defaultCheck: mapEntryCheck,
          },
          manualEntry: {
            ...formValues?.widgetConfiguration?.common?.manualEntry,
            defaultCheck: !mapEntryCheck,
          },
        },
      },
    });
  };

  const otherCollapseItems = [
    {
      forceRender: true,
      key: 'MAP_ENTRY',
      label: (
        <PanelHeader
          title="Map Entry"
          formSelectedKey="MAP_ENTRY"
          previewSelectedKey={selectedDimensionTab}
          setPreviewSelectedKey={setSelectedDimensionTab}
        />
      ),
      children: (
        <MapEntryResult
          form={form}
          handleChangeDefaultForOtherTabs={handleChangeDefaultForOtherTabs}
        />
      ),
    },
    {
      forceRender: true,
      key: 'HIGHLIGHT_YOUR_AREA',
      label: (
        <PanelHeader
          title="HighLight your area"
          formSelectedKey="HIGHLIGHT_YOUR_AREA"
          previewSelectedKey={selectedDimensionTab}
          setPreviewSelectedKey={setSelectedDimensionTab}
        />
      ),
      children: <HighLightYourArea form={form} />,
    },
    {
      forceRender: true,
      key: 'MEASUREMENT_TIP_FOR_MAP_ENTRY',
      label: (
        <PanelHeader
          title="Measurement Tips - Map Entry"
          formSelectedKey="MEASUREMENT_TIP_FOR_MAP_ENTRY"
          previewSelectedKey={selectedDimensionTab}
          setPreviewSelectedKey={setSelectedDimensionTab}
        />
      ),
      children: <MeasurementTipsForMap form={form} />,
    },
    {
      forceRender: true,
      ke: 'MANUAL_ENTRY',
      label: (
        <PanelHeader
          title="Manual Entry"
          formSelectedKey="MANUAL_ENTRY"
          previewSelectedKey={selectedDimensionTab}
          setPreviewSelectedKey={setSelectedDimensionTab}
        />
      ),
      children: (
        <ManualEntry
          form={form}
          handleChangeDefaultForOtherTabs={handleChangeDefaultForOtherTabs}
        />
      ),
    },
    {
      forceRender: true,
      key: 'MEASUREMENT_TIP_FOR_MANUAL_ENTRY',
      label: (
        <PanelHeader
          title="Measurement Tips For Manual Entry"
          formSelectedKey="MEASUREMENT_TIP_FOR_MANUAL_ENTRY"
          previewSelectedKey={selectedDimensionTab}
          setPreviewSelectedKey={setSelectedDimensionTab}
        />
      ),
      children: <MeasurementTipsForManualEntry />,
    },
  ];

  const stairsCollapseItems = [
    {
      forceRender: true,
      key: 'STEPS',
      label: (
        <PanelHeader
          title="Steps"
          formSelectedKey="STEPS"
          previewSelectedKey={selectedDimensionTab}
          setPreviewSelectedKey={setSelectedDimensionTab}
        />
      ),
      children: (
        <StepsForm form={form} handleChangeDefault={handleChangeDefault} />
      ),
    },
    {
      forceRender: true,
      key: 'TOTAL_RISE',
      label: (
        <PanelHeader
          title="Total Rise 123"
          formSelectedKey="TOTAL_RISE"
          previewSelectedKey={selectedDimensionTab}
          setPreviewSelectedKey={setSelectedDimensionTab}
        />
      ),
      children: (
        <TotalRiseForm form={form} handleChangeDefault={handleChangeDefault} />
      ),
    },
  ];

  const dimensionCollapseItems = [
    {
      forceRender: true,
      key: 'common',
      label: (
        <PanelHeader
          title="Others"
          formSelectedKey={DIMENSION_LOB_COMMON}
          previewSelectedKey={selectedDimensionProject}
          setPreviewSelectedKey={setSelectedDimensionProject}
        />
      ),
      children: (
        <Collapse
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <UpOutlined rotate={isActive ? 0 : 180} />
          )}
          className="common-collapse questionnaire-collapse"
          ghost
          items={otherCollapseItems}
        />
      ),
    },
    {
      forceRender: true,
      key: 'stairs',
      label: (
        <PanelHeader
          title="Stairs"
          formSelectedKey={DIMENSION_LOB_STAIRS}
          previewSelectedKey={selectedDimensionProject}
          setPreviewSelectedKey={setSelectedDimensionProject}
        />
      ),
      children: (
        <Collapse
          expandIconPosition="end"
          expandIcon={({ isActive }) => (
            <UpOutlined rotate={isActive ? 0 : 180} />
          )}
          className="common-collapse questionnaire-collapse"
          ghost
          items={stairsCollapseItems}
        />
      ),
    },
  ];
  return (
    <>
      <div className="section-header mt-16">
        <span className="section-title">Dimensions</span>
      </div>

      <Collapse
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <UpOutlined rotate={isActive ? 0 : 180} />
        )}
        ghost
        className="common-collapse questionnaire-collapse"
        items={dimensionCollapseItems}
      />
    </>
  );
};

export default DimensionWidgetForm;
