import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import ContactDetail from '../components/ContactDetail';
import CardWrapper from '../components/ContactTabs';
import { GET_CONTACT } from '../graphql/Queries';

function ContactView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const { data: { contact } = {} } = useQuery(GET_CONTACT, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    },
  });

  if (!id) {
    return <Navigate to={ROUTES?.CONTACTS} replace />;
  }

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.CONTACTS} />
          {contact && (
            <span className="portal-header">
              {contact?.userInfo?.firstName} {contact?.userInfo?.lastName}
            </span>
          )}
        </div>
      </Portal>
      <Portal portalId="header-right-content">
        <Button
          className="common-button"
          icon={<img src={EditIcon} alt="save-icon" width={12} />}
          size="small"
          type="primary"
          onClick={() => navigate(`${ROUTES?.CONTACTS}/edit/${id}`)}
        >
          Edit
        </Button>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ContactDetail data={contact} />
        )}
      </CardWrapper>
    </Card>
  );
}
export default ContactView;
