import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PERMISSION_DATA_ASSIGNMENT, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './dataAssignment.less';
import AssignmentJobsList from './pages/AssignmentJobsList';
import BatchEntityList from './pages/BatchEntityList';
import BatchEntityRecordList from './pages/BatchEntityRecordList';

const AssignmentJobsWrapper = () => (
  <div className="assignment-jobs-wrapper">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_DATA_ASSIGNMENT}
            showNoAccess
          >
            <AssignmentJobsList />
          </AccessControl>
        }
      />
      <Route
        path={`/edit/:id${ROUTES?.ENTITY_FILTERS}`}
        element={
          <AccessControl
            allowedPermissions={[
              'FET_DATA_ASSIGNMENT_UPDATE',
              'FET_DATA_ASSIGNMENT_LIST',
              'FET_DATA_ASSIGNMENT_VIEW',
            ]}
            showNoAccess
          >
            <BatchEntityList />
          </AccessControl>
        }
      />
      <Route
        path={`/edit/:id${ROUTES?.ENTITY_FILTERS}/:batch-entity-id`}
        element={
          <AccessControl
            allowedPermissions={[
              'FET_DATA_ASSIGNMENT_UPDATE',
              'FET_DATA_ASSIGNMENT_LIST',
              'FET_DATA_ASSIGNMENT_VIEW',
            ]}
            showNoAccess
          >
            <BatchEntityRecordList />
          </AccessControl>
        }
      />
    </Routes>
  </div>
);

export default AssignmentJobsWrapper;
