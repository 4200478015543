import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import Portal from '../../../components/Portal';
import CampaignTable from '../components/CampaignTable';

const CampaignList = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);
  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <span className="portal-header">Campaigns</span>
      </Portal>
      {pageSize && <CampaignTable />}
    </Card>
  );
};

export default CampaignList;
