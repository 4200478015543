import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './contactModule.less';
import ContactCreate from './pages/ContactCreate';
import ContactEdit from './pages/ContactEdit';
import ContactList from './pages/ContactList';
import ContactView from './pages/ContactView';
import CustomerList from './pages/Customer';
import PropertyList from './pages/Property';

const ContactWrapper = () => (
  // commented because permission not needed now
  // const { getCurrentUserRole } = useContext(AppContext);
  // const userRoles = getCurrentUserRole();
  // const { TENANT_ADMIN } = ROLE_KEYS;
  // const isTenantAdmin = userRoles?.includes(TENANT_ADMIN);

  // NEED TO CHANGE ROUTES FOR CUSTOMERS
  <div className="contact-module">
    <Routes>
      <Route path={ROUTES?.MAIN} element={<ContactList />} />
      <Route path="/add" element={<ContactCreate />} />
      <Route path="/edit/:id" element={<ContactEdit />} />
      <Route path="/view/:id" element={<ContactView />} />
      <Route
        path={`/edit${ROUTES?.CUSTOMERS}/:id`}
        element={<CustomerList />}
      />
      <Route
        path={`/view${ROUTES?.CUSTOMERS}/:id`}
        element={<CustomerList />}
      />
      <Route
        path={`/edit${ROUTES?.PROPERTIES}/:id`}
        element={<PropertyList />}
      />
      <Route
        path={`/view${ROUTES?.PROPERTIES}/:id`}
        element={<PropertyList />}
      />
    </Routes>
  </div>
);
export default ContactWrapper;
