import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_MANUFACTURER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ManufacturerCreate from './pages/ManufacturerCreate';
import ManufacturerEdit from './pages/ManufacturerEdit';
import ManufacturerList from './pages/ManufacturerList';
import ManufacturerView from './pages/ManufacturerView';

const ManufacturerWrapper = () => (
  <div className="manufacturer-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_MANUFACTURER}
            showNoAccess
          >
            <ManufacturerList />
          </AccessControl>
        }
      />
      <Route
        path="/add"
        element={
          <AccessControl
            allowedPermissions={['FET_MANUFACTURER_CREATE']}
            showNoAccess
          >
            <ManufacturerCreate />
          </AccessControl>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <AccessControl
            allowedPermissions={[
              'FET_MANUFACTURER_UPDATE',
              'FET_MANUFACTURER_VIEW',
            ]}
            showNoAccess
          >
            <ManufacturerEdit />
          </AccessControl>
        }
      />
      <Route
        path="/view/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_MANUFACTURER_VIEW']}
            showNoAccess
          >
            <ManufacturerView />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default ManufacturerWrapper;
