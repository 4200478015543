import { EyeOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, Checkbox, Divider, Dropdown, Empty, message, Tag } from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import FilterIcon from '../../../assets/filter.svg';
import api from '../../../common/api';
import {
  DEFAULT_DATE_FORMAT,
  exportOptions,
  ROUTES,
  SKIP_RECORD,
} from '../../../common/constants';
import { checkPermissions, formatDate } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import RangePickerComponent from '../../../components/RangePickerComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import {
  GET_API_LOGS,
  GET_LOGS_FILTER,
  TENANT_FILTER,
} from '../graphql/Queries';

const initialPaginationValue = {
  total: 0,
  current: 1,
};

let scrollDebounce = null;

const ApiLogTable = () => {
  const {
    state: { pageSize, filterData, currentUser, permissions, globalDateFormat },
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const initialLogsFilter = useMemo(
    () => ({
      skip: 0,
      limit: pageSize,
      sortOn: 'createdAt',
      sortBy: 'DESC',
    }),
    [pageSize],
  );

  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [apiLogFilter, setApiLogFilter] = useState(initialLogsFilter);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [searchValue, setSearchValue] = useState(null);

  const handleViewLog = (id) => {
    const otherFilters = { apiLogFilter, paginationProp, filters };
    const stringifyObject = JSON?.stringify(otherFilters);

    navigate(`${ROUTES?.REST_API_LOGS}/view/${id}`, {
      state: {
        stringifyObject,
      },
    });
  };

  const [getApiLogs, { loading, data }] = useLazyQuery(GET_API_LOGS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.restApiLogs?.count,
      };
      setPaginationProp(pagination);
    },
  });

  const [tenantFilters] = useLazyQuery(TENANT_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.tenantFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.tenantFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.tenantFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
  });

  const [apiFilters] = useLazyQuery(GET_LOGS_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.restApiLogFilters?.data, (item) => {
          optionsCopy?.push(item?.[filterIndex]);
        });
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.restApiLogFilters?.data, (item) => {
          optionsCopy?.push(item?.[filterIndex]);
        });
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.restApiLogFilters?.data?.length < SKIP_RECORD);

      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, ['FET_REST_API_LOGS_LIST']);
    const whereFilter = location?.state;

    let parsedObject;

    if (!isEmpty(whereFilter)) {
      parsedObject = JSON?.parse(whereFilter?.stringifyObject);
    }

    if (permitted) {
      getApiLogs({
        variables: {
          filter: parsedObject?.apiLogFilter ?? apiLogFilter,
          ...(parsedObject && { where: parsedObject?.filters }),
        },
      });
      if (!isEmpty(parsedObject)) {
        let rangeObj;
        if (parsedObject?.filters?.createdAt) {
          rangeObj = {
            from: moment(parsedObject?.filters?.createdAt?.from),
            to: moment(parsedObject?.filters?.createdAt?.to),
          };
          setFilters({ ...parsedObject?.filters, createdAt: { ...rangeObj } });
          setFiltersCopyState({
            ...parsedObject?.filters,
            createdAt: { ...rangeObj },
          });
        } else {
          setFilters({ ...parsedObject?.filters });
          setFiltersCopyState({
            ...parsedObject?.filters,
          });
        }
        setSearchValue(parsedObject?.apiLogFilter?.search);
        const sorter = {
          order:
            parsedObject?.apiLogFilter?.sortBy === 'ASC' ? 'ascend' : 'descend',
          columnKey: parsedObject?.apiLogFilter?.sortOn,
        };
        setSortedInfo({ ...sorter });
        setApiLogFilter({ ...parsedObject?.apiLogFilter });
        setPaginationProp({ ...parsedObject?.paginationProp });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('beforeunload', () => {
      // eslint-disable-next-line no-undef
      if (window?.location?.pathname === ROUTES?.REST_API_LOGS)
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
    });
    return () => {
      // eslint-disable-next-line no-undef
      window?.removeEventListener('beforeunload', () => {
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
      });
    };
  }, []);

  const getFilterData = (confirm) => {
    getApiLogs({
      variables: {
        filter: { ...apiLogFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState }),
      },
    });
    setFilters(filtersCopyState);
    setApiLogFilter({
      ...apiLogFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'name':
          tenantFilters({
            variables: {
              filter: {
                sortOn: 'name',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                getDBField: ['name'],
                distinct: true,
              },
            },
          });
          break;

        default:
          apiFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: [filterIndex],
              },
            },
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'name':
            tenantFilters({
              variables: {
                filter: {
                  sortOn: 'name',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  getDBField: ['name'],
                  distinct: true,
                },
              },
            });
            break;

          default:
            apiFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: [filterIndex],
                },
              },
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const handleReset = (clearFilters, dataIndex) => {
    let filtersCopy = {
      ...filters,
    };
    if (dataIndex === 'createdAt') {
      delete filtersCopy?.createdAt;
    } else {
      filtersCopy = {
        ...filters,
        [dataIndex]: [],
      };
    }
    setFilters(filtersCopy);
    getApiLogs({
      variables: {
        filter: {
          ...apiLogFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC',
        },
        ...(filtersCopy && { where: filtersCopy }),
      },
    });
    setApiLogFilter({
      ...apiLogFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }

    setFiltersCopyState(filtersCopy);
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'name':
        tenantFilters({
          variables: {
            filter: {
              sortOn: 'name',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              getDBField: ['name'],
              distinct: true,
            },
          },
        });
        break;

      default:
        apiFilters({
          variables: {
            filter: {
              sortOn: filterIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: [filterIndex],
            },
          },
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };
  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: moment(values?.[0])?.startOf('day'),
      to: moment(values?.[1])?.endOf('day'),
    };

    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete filtersCopy?.[dataIndex];
    }
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown range-filter-dropdown">
            <RangePickerComponent
              format={DEFAULT_DATE_FORMAT}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                filtersCopyState?.[dataIndex]?.from
                  ? [
                      moment(filtersCopyState?.[dataIndex]?.from, 'DD/MM/YYYY'),
                      moment(filtersCopyState?.[dataIndex]?.to, 'DD/MM/YYYY'),
                    ]
                  : []
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="common-button discard-button filter-button"
                id="date-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button filter-button"
                id="date-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {dataIndex !== 'isActive' && dataIndex !== 'method' && (
              <SearchComponent
                className="list-search-box filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                getData={(value) => handleSearch(value, dataIndex)}
              />
            )}
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item) => (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span title={item?.label || item?.toString()}>
                      {item?.label || item?.toString()}
                    </span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => (
                  <div className="single-checkbox-div" key={item?.key || item}>
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item,
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      <span title={item?.label || item?.toString()}>
                        {item?.label || item?.toString()}
                      </span>
                    </Checkbox>
                  </div>
                ))
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="common-button discard-button filter-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button filter-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  const columns = [
    {
      title: 'REQUESTED URL',
      key: 'requestApi',
      dataIndex: 'requestApi',
      className: 'max-width-column',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'requestApi' && sortedInfo?.order,
      render: (requestApi) => {
        const queryStringIndex = requestApi?.search(/\?/);
        if (queryStringIndex > 0) {
          return requestApi?.substring(0, queryStringIndex);
        }
        return requestApi;
      },
    },
    {
      title: 'METHOD',
      key: 'method',
      dataIndex: 'method',
      className: 'max-width-column',
      width: 120,
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'method' && sortedInfo?.order,
      ...filterPopup('method'),
    },
    {
      title: 'STATUS CODE',
      key: 'statusCode',
      align: 'center',
      className: 'max-width-column',
      dataIndex: 'statusCode',
      width: 120,
    },
    {
      title: 'IP ADDRESS',
      key: 'publicIpAddress',
      dataIndex: 'publicIpAddress',
      className: 'max-width-column',
      width: 150,
      sorter: true,
      sortOrder:
        sortedInfo?.columnKey === 'publicIpAddress' && sortedInfo?.order,
    },
    {
      title: 'DATE & TIME',
      key: 'createdAt',
      dataIndex: 'createdAt',
      className: 'max-width-column',
      width: 160,
      sorter: true,
      ...filterPopup('createdAt'),
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (createdAt) => formatDate(createdAt, globalDateFormat),
    },
    checkPermissions(permissions, ['FET_REST_API_LOGS_VIEW']) && {
      dataIndex: 'id',
      align: 'right',
      width: 50,
      fixed: 'right',
      render: (id) => <EyeOutlined onClick={() => handleViewLog(id)} />,
    },
  ];

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    },
  };

  const handleExport = (e) => {
    const { key } = e;
    if (key === 'SELECTED' && !selectedKeys?.length) {
      message?.destroy();
      message?.error('Please select records!');
      return;
    }
    setExportLoading(true);
    api({
      method: 'POST',
      url: `${process.env.REACT_APP_SERVER_REST_URL}/export`,
      data: {
        module: 'REST_API_LOG',
        userId: currentUser?.id,
        filtersObj: {
          ...filters,
          ...(key === 'SELECTED' && { id: selectedKeys }),
        },
      },
    })
      .then((res) => {
        if (res?.data?.message) {
          message?.info(res.data.message);
        }
        setExportLoading(false);
      })
      .catch((error) => {
        message?.error(error?.response?.data?.error || 'got some problem');
        setExportLoading(false);
      });
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setApiLogFilter({ ...apiLogFilter, skip });
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setApiLogFilter({
        ...apiLogFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field === 'tenant' ? 'name' : sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      getApiLogs({
        variables: {
          filter: {
            ...apiLogFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field === 'tenant' ? 'name' : sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    } else {
      setApiLogFilter({
        ...apiLogFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      getApiLogs({
        variables: {
          filter: {
            ...apiLogFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const onSearchChange = (value) => {
    setApiLogFilter({
      ...apiLogFilter,
      skip: value ? 0 : apiLogFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    getApiLogs({
      variables: {
        filter: {
          ...apiLogFilter,
          skip: value ? 0 : apiLogFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
        ...(filters && { where: filters }),
      },
    });
  };

  return (
    <div className="api-log-wrapper">
      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <SearchComponent
            id="search-container-id"
            placeholder="logs or other detail.."
            name="apiLogs"
            getData={onSearchChange}
            value={searchValue}
            className="list-search-box"
            setSearchValue={setSearchValue}
          />
        </div>
        <div className="api-log-header">
          <Dropdown
            menu={{
              items: exportOptions,
              selectable: true,
              onClick: handleExport,
              className: 'export-btn-dropdown',
            }}
            placement="bottom"
          >
            <Button
              className="common-button export-button api-export-btn"
              id="user-table-export-btn"
              loading={exportLoading}
              type="primary"
            >
              Export
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="common-table">
        {pageSize && (
          <TableComponent
            loadingData={loading}
            columns={columns}
            data={data?.restApiLogs?.data || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
            rowSelection={rowSelection}
          />
        )}
      </div>
    </div>
  );
};
export default ApiLogTable;
