import { Card } from 'antd';
import React from 'react';
import Portal from '../../../components/Portal';
import PlanSettingForm from '../components/PlanSettingForm';

const PlanSettingPage = () => (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <span className="portal-header">Plans</span>
        </div>
      </Portal>
      <PlanSettingForm />
    </Card>
  );
export default PlanSettingPage;
