import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';

const PrivateRoute = ({ fromSignup = false, children }) => {
  const { getToken, isSignupAuthenticate } = useContext(AppContext);
  const idToken = getToken();
  const signupAuthenticate = isSignupAuthenticate();

  if (!idToken || (!signupAuthenticate && fromSignup)) {
    return (
      <Navigate to={fromSignup ? ROUTES?.SIGNUP : ROUTES?.LOGIN} replace />
    );
  }

  return children;
};

export default PrivateRoute;
