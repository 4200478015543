import { BellOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Badge, Layout } from 'antd';
import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../../AppContext';
import { HAS_UNREAD_NOTIFICATION } from './graphql/Query';

const { Header } = Layout;

const AppHeader = ({ children }) => {
  const {
    dispatch,
    state: { openDrawer },
  } = useContext(AppContext);

  const [hasUnReadNotification, { data }] = useLazyQuery(
    HAS_UNREAD_NOTIFICATION,
    {
      pollInterval: 30000,
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        dispatch({
          type: 'SET_HAS_UNREAD_NOTIFICATION',
          data: data?.hasUnreadNotifications,
        });
      },
      onError: () => {},
    },
  );

  useEffect(() => {
    hasUnReadNotification();
  }, []);

  useEffect(() => {
    if (!openDrawer) {
      hasUnReadNotification();
    }
  }, [openDrawer]);

  useEffect(() => {
    if (data?.hasUnreadNotifications !== undefined) {
      dispatch({
        type: 'SET_HAS_UNREAD_NOTIFICATION',
        data: data?.hasUnreadNotifications,
      });
    }
  }, [data]);

  return (
    <Header>
      <div className="header-portal-wrapper d-flex justify-between align-center fill-width">
        <div id="header-left-content" />
        <div id="header-right-content">
          <div className="notification-icon mr-16 d-flex align-center">
            <Badge dot={data?.hasUnreadNotifications}>
              <BellOutlined
                onClick={() => {
                  dispatch({ type: 'SET_DRAWER', data: true });
                }}
              />
            </Badge>
          </div>
        </div>
      </div>
      {children}
    </Header>
  );
};
export default AppHeader;
