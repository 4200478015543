import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Col, Form, Radio, Row, Switch } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { DYNAMIC_BOOLEAN_DEFAULT_CHECKED } from '../../../../../../../../common/constants';
import InputComponent from '../../../../../../../../components/InputComponent';

const SystemBooleanForm = ({ form }) => {
  const showTooltip = Form?.useWatch(
    ['widgetConfiguration', 'toolTipCheck'],
    form,
  );
  return (
    <div>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Required</span>
        <Form.Item
          name={['widgetConfiguration', 'required']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'label']}
        label="Label"
        rules={[
          {
            required: true,
            message: 'Please Enter Label',
          },
        ]}
      >
        <InputComponent name="label" />
      </Form.Item>
      <Row className="d-flex justify-between">
        <Col xs={12} sm={12} md={12} lg={12} xl={11} xxl={11}>
          <Form.Item
            name={['widgetConfiguration', 'trueValue']}
            label="True"
            rules={[{ required: true, message: 'Please Enter True Value' }]}
          >
            <InputComponent allowClear placeholder="Enter True Value" />
          </Form.Item>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={11} xxl={11}>
          <Form.Item
            name={['widgetConfiguration', 'falseValue']}
            label="False"
            rules={[{ required: true, message: 'Please Enter False Value' }]}
          >
            <InputComponent allowClear placeholder="Enter False Value" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={['widgetConfiguration', 'booleanDefaultValue']}
        className="fill-width"
      >
        <Radio.Group className="common-radio fill-width d-flex">
          <Row className="fill-width" justify="space-between">
            {map(DYNAMIC_BOOLEAN_DEFAULT_CHECKED, (item) => (
              <Col
                key={item?.key}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                xxl={12}
              >
                <Radio key={item?.key} value={item?.key}>
                  {item?.label}
                </Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Tooltip</span>
        <Form.Item
          name={['widgetConfiguration', 'toolTipCheck']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'toolTipText']}
        hidden={!showTooltip}
        rules={[
          {
            required: showTooltip,
            message: 'Please Enter Tooltip Value',
          },
        ]}
      >
        <InputComponent placeholder="Enter Tooltip Value" />
      </Form.Item>
    </div>
  );
};

export default SystemBooleanForm;
