import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import SponsorTenantTable from '../components/SponsorTenantTable';

const SponsorTenantList = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);
  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <span className="portal-header">{MODULES?.SPONSOR_TENANTS}</span>
      </Portal>
      {pageSize && <SponsorTenantTable />}
    </Card>
  );
};

export default SponsorTenantList;
