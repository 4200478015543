import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_RETAILER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './brandModule.less';
import BrandCreate from './pages/BrandCreate';
import BrandEdit from './pages/BrandEdit';
import BrandList from './pages/BrandList';
import BrandsView from './pages/BrandsView';

const BrandWrapper = () => (
  <div className="brand-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl allowedPermissions={PERMISSION_RETAILER} showNoAccess>
            <BrandList />
          </AccessControl>
        }
      />
      <Route
        path="/add"
        element={
          <AccessControl allowedPermissions={['FET_BRAND_CREATE']} showNoAccess>
            <BrandCreate />
          </AccessControl>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_BRAND_UPDATE', 'FET_BRAND_VIEW']}
            showNoAccess
          >
            <BrandEdit />
          </AccessControl>
        }
      />
      <Route
        path="/view/:id"
        element={
          <AccessControl allowedPermissions={['FET_BRAND_VIEW']} showNoAccess>
            <BrandsView />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default BrandWrapper;
