import Icon from '@ant-design/icons';
import React from 'react';

const regionIcon = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.73097 11.17L7.00016 11.8081L6.26935 11.17C3.65911 8.89074 2.3335 6.87382 2.3335 4.99967C2.3335 2.34728 4.40249 0.333008 7.00016 0.333008C9.59783 0.333008 11.6668 2.34728 11.6668 4.99967C11.6668 6.87382 10.3412 8.89074 7.73097 11.17ZM2.79053 8.86851C3.06426 9.24327 3.36917 9.62446 3.70515 10.0122C2.46366 10.2919 1.66683 10.6887 1.66683 10.9996C1.66683 11.5375 4.05292 12.3329 7.00016 12.3329C9.9474 12.3329 12.3335 11.5375 12.3335 10.9996C12.3335 10.6887 11.5367 10.2919 10.2952 10.0122C10.6312 9.62446 10.9361 9.24327 11.2098 8.86851C12.709 9.32344 13.6668 10.0532 13.6668 10.9996C13.6668 12.6707 10.6803 13.6662 7.00016 13.6662C3.31999 13.6662 0.333496 12.6707 0.333496 10.9996C0.333496 10.0532 1.29132 9.32344 2.79053 8.86851ZM7.00016 1.66634C8.86933 1.66634 10.3335 3.09178 10.3335 4.99967C10.3335 6.36432 9.24861 8.04361 7.00016 10.037C4.75171 8.04361 3.66683 6.36432 3.66683 4.99967C3.66683 3.09178 5.13099 1.66634 7.00016 1.66634ZM7.00016 2.99967C8.10473 2.99967 9.00016 3.8951 9.00016 4.99967C9.00016 6.10424 8.10473 6.99967 7.00016 6.99967C5.89559 6.99967 5.00016 6.10424 5.00016 4.99967C5.00016 3.8951 5.89559 2.99967 7.00016 2.99967ZM6.3335 4.99967C6.3335 4.63148 6.63197 4.33301 7.00016 4.33301C7.36835 4.33301 7.66683 4.63148 7.66683 4.99967C7.66683 5.36786 7.36835 5.66634 7.00016 5.66634C6.63197 5.66634 6.3335 5.36786 6.3335 4.99967Z"
        fill="currentColor"
      />
    </svg>
  );

const RegionIconComponent = (props) => (
  <Icon aria-label="Region Icon" component={regionIcon} {...props} />
);

export default RegionIconComponent;
