import Icon from '@ant-design/icons';
import React from 'react';

const deleteIcon = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 14"
      aria-label="Delete Icon"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.24984 0.583008H7.74984C8.39417 0.583008 8.9165 1.10534 8.9165 1.74967V2.33301H10.6665C11.3108 2.33301 11.8332 2.85534 11.8332 3.49967V4.66634C11.8332 5.31067 11.3108 5.83301 10.6665 5.83301H10.6198L10.0832 12.2497C10.0832 12.894 9.56084 13.4163 8.91651 13.4163H3.08318C2.43884 13.4163 1.91651 12.894 1.91852 12.2981L1.37976 5.83301H1.33317C0.688838 5.83301 0.166504 5.31067 0.166504 4.66634V3.49967C0.166504 2.85534 0.688838 2.33301 1.33317 2.33301H3.08317V1.74967C3.08317 1.10534 3.6055 0.583008 4.24984 0.583008ZM1.33317 3.49967H3.08317H8.9165H10.6665V4.66634H1.33317V3.49967ZM2.55029 5.83301H9.4492L8.91852 12.2012L8.9165 12.2497H3.08317L2.55029 5.83301ZM7.74984 1.74967V2.33301H4.24984V1.74967H7.74984Z"
        fill="currentColor"
      />
    </svg>
  );

const DeleteIconComponent = (props) => (
  <Icon
    component={deleteIcon}
    aria-label="Delete Icon"
    alt="Delete Icon"
    {...props}
  />
);

export default DeleteIconComponent;
