import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_LEAD, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import QuoteViewerLeadView from './pages/QuoteViewerLeadView';
import QuoteViewerList from './pages/QuoteViewerList';
import QuoteViewerView from './pages/QuoteViewerView';
import './quoteViewerModule.less';

const QuoteViewerWrapper = () => (
  <div className="quote-viewer-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl allowedPermissions={PERMISSION_LEAD} showNoAccess>
            <QuoteViewerList />
          </AccessControl>
        }
      />
      <Route
        path="/view/:id"
        element={
          <AccessControl allowedPermissions={['FET_LEAD_VIEW']} showNoAccess>
            <QuoteViewerView />
          </AccessControl>
        }
      />
      <Route
        path="/view/lead/:id"
        element={
          <AccessControl allowedPermissions={['FET_LEAD_VIEW']} showNoAccess>
            <QuoteViewerLeadView />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default QuoteViewerWrapper;
