import { Card } from 'antd';
import React from 'react';
import EditForm from '../components/EditForm';

const EditItem = () => (
    <Card className="full-height-card">
      <EditForm />
    </Card>
  );

export default EditItem;
