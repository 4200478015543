import { useLazyQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useEffect } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import ActivityLogDetails from '../component/ActivityLogDetails';
import { GET_ACTIVITY_LOG } from '../graphql/Queries';

const ActivityLogView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [activityLog, { loading, data }] = useLazyQuery(GET_ACTIVITY_LOG, {
    onError: () => {},
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    activityLog({
      variables: {
        where: {
          id,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unListen = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, { state: { ...location?.state } });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  if (!id) {
    return <Navigate to={ROUTES?.ACTIVITY_LOGS} replace />;
  }

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={`${ROUTES?.ACTIVITY_LOGS}`} />
          <span className="portal-header">
            {data && data?.activityLog?.refType}
          </span>
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <ActivityLogDetails logData={data?.activityLog} />
      )}
    </Card>
  );
};
export default ActivityLogView;
