import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_PRODUCT_ITEM, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ProductItemCreate from './pages/ProductItemCreate';
import ProductItemDocument from './pages/ProductItemDocument';
import ProductItemEdit from './pages/ProductItemEdit';
import ProductItemGallery from './pages/ProductItemGallery';
import ProductItemList from './pages/ProductItemList';
import ProductItemView from './pages/ProductItemView';
import './productItemModule.less';

const ProductItemWrapper = () => (
  <div className="product-item-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_PRODUCT_ITEM}
            showNoAccess
          >
            <ProductItemList />
          </AccessControl>
        }
      />
      <Route
        path="/add"
        element={
          <AccessControl
            allowedPermissions={['FET_PRODUCT_ITEM_CREATE']}
            showNoAccess
          >
            <ProductItemCreate />
          </AccessControl>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <AccessControl
            allowedPermissions={[
              'FET_PRODUCT_ITEM_UPDATE',
              'FET_PRODUCT_ITEM_VIEW',
            ]}
            showNoAccess
          >
            <ProductItemEdit />
          </AccessControl>
        }
      />
      <Route
        path="/view/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_PRODUCT_ITEM_VIEW']}
            showNoAccess
          >
            <ProductItemView />
          </AccessControl>
        }
      />
      <Route
        path={`/edit${ROUTES?.GALLERY}/:id`}
        element={
          <AccessControl
            allowedPermissions={[
              'FET_PRODUCT_ITEM_UPDATE',
              'FET_PRODUCT_ITEM_VIEW',
            ]}
            showNoAccess
          >
            <ProductItemGallery />
          </AccessControl>
        }
      />
      <Route
        path={`/edit${ROUTES?.DOCUMENTS}/:id`}
        element={
          <AccessControl
            allowedPermissions={[
              'FET_PRODUCT_ITEM_UPDATE',
              'FET_PRODUCT_ITEM_VIEW',
            ]}
            showNoAccess
          >
            <ProductItemDocument />
          </AccessControl>
        }
      />
      <Route
        path={`/view${ROUTES?.GALLERY}/:id`}
        element={
          <AccessControl
            allowedPermissions={['FET_PRODUCT_ITEM_VIEW']}
            showNoAccess
          >
            <ProductItemGallery />
          </AccessControl>
        }
      />
      <Route
        path={`/view${ROUTES?.DOCUMENTS}/:id`}
        element={
          <AccessControl
            allowedPermissions={['FET_PRODUCT_ITEM_VIEW']}
            showNoAccess
          >
            <ProductItemDocument />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default ProductItemWrapper;
