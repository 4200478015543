import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import { hasIn, isArray, isBoolean, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { uploadMultipleFiles } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import ProductItemForm from '../components/ProductItemForm';
import CardWrapper from '../components/ProductItemTabs';
import { CREATE_PRODUCT_ITEM } from '../graphql/Mutations';

function ProductItemCreate() {
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [showModal, setShowModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [itemFormData, setItemFormData] = useState({});
  const [createProductItem] = useMutation(CREATE_PRODUCT_ITEM, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const sendToDB = async (formValues) => {
    const dateTimeValues = [];
    const filesToSend = [];

    await Promise?.all(
      map(formValues?.dynamicFields, async (fieldType, key) => {
        if (fieldType?.upload) {
          let filesToUpload = [];
          const uploadedFiles = [];
          map(fieldType?.upload, (file) => {
            if (!file?.url) {
              filesToUpload?.push(file);
            } else {
              uploadedFiles?.push(file);
            }
          });
          if (filesToUpload?.length > 0) {
            await Promise?.all([
              await uploadMultipleFiles({
                files: filesToUpload,
                currentUser,
                uuid: formValues?.uuid,
                fieldKey: key,
                folder: 'product-item',
              }),
            ]).then((res) => {
              filesToUpload = [];
              if (!isEmpty(res?.[0])) {
                filesToSend?.push({ [key]: [...uploadedFiles, ...res?.[0]] });
              } else {
                filesToSend?.push({ [key]: [...uploadedFiles] });
              }
            });
          } else {
            filesToSend?.push({ [key]: [...uploadedFiles] });
          }
        }
        if (fieldType?.dateTime) {
          const format = Object.keys(fieldType?.dateTime);
          const value = fieldType?.dateTime?.[format];
          if (!value) {
            dateTimeValues?.push({
              [key]: null,
            });
          } else if (isArray(value)) {
            dateTimeValues?.push({
              [key]: value,
            });
          } else {
            dateTimeValues?.push({
              [key]: [value],
            });
          }
        }
      }),
    );

    const updateDateTime = dateTimeValues?.reduce(
      (prev, curr) => ({ ...prev, ...curr }),
      {},
    );

    const updateUpload = filesToSend?.reduce(
      (prev, curr) => ({ ...prev, ...curr }),
      {},
    );

    let finalDynamicFields = {
      ...updateUpload,
      ...updateDateTime,
    };

    if (formValues?.dynamicFields) {
      finalDynamicFields = {
        ...formValues?.dynamicFields,
        ...finalDynamicFields,
      };
    }

    map(finalDynamicFields, (value, key) => {
      if (hasIn(value, 'upload') && value?.upload === undefined) {
        finalDynamicFields[key] = [];
      } else if (isBoolean(value)) {
        finalDynamicFields[key] = value;
      } else {
        finalDynamicFields[key] = value || null;
      }
    });

    const newFormValues = {
      ...formValues,
      dynamicFields: finalDynamicFields || {},
      productCategoryId: formValues?.productCategories,
      productItemWarranty: parseFloat(formValues?.productItemWarranty),
      labourWarranty: parseFloat(formValues?.labourWarranty),
      score: parseFloat(formValues?.score),
      stock: Number(formValues?.stock),
      workOrderActivity: formValues?.workOrderActivity || false,
    };

    delete newFormValues?.productCategories;
    delete newFormValues?.createProduct;
    const variables = newFormValues;

    try {
      const response = await createProductItem({
        variables: { data: { ...variables } },
      });
      if (response?.data?.createProductItem) {
        const { id } = response?.data?.createProductItem?.data;
        navigate(`${ROUTES?.PRODUCTS}/edit${ROUTES?.GALLERY}/${id}`, {
          state: { ...location?.state },
        });
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const handleProductItemForm = (formValues) => {
    const uuid = uuidv4();
    setSubmitLoading(true);
    if (formValues?.createProduct) {
      setShowModal(true);
      setSubmitLoading(false);
      setItemFormData(formValues);
      return;
    }
    const tempFormValues = {
      uuid,
      ...formValues,
    };
    sendToDB(tempFormValues);
  };

  const handleCreateProductForm = (formValues) => {
    const uuid = uuidv4();
    const productObj = {
      title: itemFormData?.name,
      uuid,
      sku: itemFormData?.sku,
      sellingPrice: itemFormData?.defaultPrice,
      price: itemFormData?.defaultPrice,
      lineOfBusinessId: itemFormData?.lineOfBusinessId,
      subAreaId: itemFormData?.subAreaId,
      isActive: itemFormData?.isActive,
      brands: itemFormData?.brands,
      regions: itemFormData?.regions,
      productQuality: itemFormData?.productQuality,
      description: itemFormData?.description,
      ...formValues,
    };

    const finalObj = {
      ...itemFormData,
      uuid,
      product: productObj,
    };

    delete finalObj?.brands;
    delete finalObj?.regions;
    sendToDB(finalObj);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const initialValues = {
    description: '',
    quoteDescription: '',
    isSellable: false,
    manageInventory: false,
    options: false,
    allowPriceChange: false,
    asset: false,
    isActive: true,
    score: 50,
  };

  useEffect(() => {
    const unListen = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, { state: { ...location?.state } });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PRODUCTS} />
          <span className="portal-header">Add Product</span>
        </div>
      </Portal>
      <CardWrapper>
        <ProductItemForm
          productItemData={initialValues}
          isSubmit={submitLoading}
          showCreateProductModal={showModal}
          handleCreateProductCancel={handleCancel}
          handleProductItemForm={handleProductItemForm}
          handleCreateProductForm={handleCreateProductForm}
        />
      </CardWrapper>
    </Card>
  );
}

export default ProductItemCreate;
