import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import { checkPermissions } from '../common/utils';
import NoAccess from '../NoAccess';

const AccessControl = ({
  allowedPermissions = [],
  children,
  showNoAccess,
  renderNoAccess = () => showNoAccess ? <NoAccess /> : null,
}) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const permitted = checkPermissions(permissions, allowedPermissions);

  if (permitted) {
    return children;
  }
  return renderNoAccess();
};

export default AccessControl;
