import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import ProductItemDetails from '../components/ProductItemDetails';
import CardWrapper from '../components/ProductItemTabs';
import { GET_PRODUCT_ITEM } from '../graphql/Queries';

const ProductItemView = () => {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: { productItem } = {} } = useQuery(GET_PRODUCT_ITEM, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    const unListen = history?.listen((historyProps) => {
      if (historyProps?.action === 'POP') {
        navigate(historyProps?.location?.pathname, {
          state: { ...location?.state },
        });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PRODUCTS} />
          {productItem && (
            <span className="portal-header">{productItem?.name}</span>
          )}
        </div>
      </Portal>
      <AccessControl allowedPermissions={['FET_PRODUCT_ITEM_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={EditIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="manufacturer-table-save-btn"
            type="primary"
            onClick={() =>
              navigate(`${ROUTES?.PRODUCTS}/edit/${id}`, {
                state: { ...location?.state },
              })
            }
          >
            Edit
          </Button>
        </Portal>
      </AccessControl>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ProductItemDetails data={productItem} />
        )}
      </CardWrapper>
    </Card>
  );
};

export default ProductItemView;
