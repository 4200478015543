import { gql } from '@apollo/client';

export const GET_BRANDS = gql`
  query brands($filter: BrandFilter!, $where: BrandsWhereFilter) {
    brands(filter: $filter, where: $where) {
      count
      data {
        id
        name
        refData
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
        uuid
        secondaryColors
        tertiaryColors
        colorPallete
        description
        waiverText
        priceGuarantee
        offerText
        productDetails
        sponsorName
        isActive
        createdAt
      }
    }
  }
`;

export const GET_BRAND = gql`
  query brand($id: ID!) {
    brand(where: { id: $id }) {
      id
      name
      uuid
      refData
      primaryColor
      logo {
        url
        key
        name
        extension
        contentType
      }
      secondaryColors
      tertiaryColors
      colorPallete
      description
      waiverText
      priceGuarantee
      offerText
      productDetails
      isActive
      tenantId
      sponsorName
    }
  }
`;

export const BRAND_FILTER = gql`
  query brandFilters($filter: BrandFilter!) {
    brandFilters(filter: $filter) {
      count
      data {
        name
        refData
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
        secondaryColors
        tertiaryColors
        colorPallete
        description
        waiverText
        priceGuarantee
        offerText
        productDetails
        isActive
        tenantId
        createdAt
        sponsorName
      }
    }
  }
`;

export const GET_BRAND_LOB = gql`
  query brandLineOfBusinesses($id: ID!) {
    brandLineOfBusinesses(where: { id: $id }) {
      id
      brandId
      lineOfBusinessId
      subAreaIds
      tenantId
      createdAt
      updatedAt
    }
  }
`;

export const GET_LOB_LIST = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        lobCode
        description
        subAreas {
          id
          label
          key
          saCode
          description
          estimation
          isActive
          waiverText
        }
        createdAt
      }
    }
  }
`;

export const GET_SPONSOR_FILTER = gql`
  query sponsorFilters($filter: SponsorFilter!) {
    sponsorFilters(filter: $filter) {
      count
      data {
        firstName
        lastName
        businessName
        userId
      }
    }
  }
`;
