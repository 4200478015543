import { Card } from 'antd';
import React from 'react';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import DynamicFieldsForm from '../components/DynamicFieldsForm';

const DynamicFieldsCreate = ({ setSelectedDynamicKey, selectedDynamicKey }) => {
  const initialValues = {
    fieldType: selectedDynamicKey,
    textType: 'SINGLE_LINE',
    type: 'TEXT',
    dateTimeType: ['DATE'],
    dateType: 'EXACT',
    dateDefaultValue: 'TODAY',
    booleanDefaultValue: true,
    minLength: 1,
    maxLength: 255,
    decimal: 0,
    minNumber: 1,
    maxNumber: 10,
    true: 'Active',
    false: 'Inactive',
    fileType: 'IMAGE',
    allowPast: true,
    allowFuture: true,
    allowMultiple: false,
    limitFileSize: false,
    maxFile: 10,
    size: 50,
  };

  if (!selectedDynamicKey) {
    return <LoaderComponent />;
  }

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.DYNAMIC_FIELDS} />
          <span className="portal-header">Add Dynamic Field</span>
        </div>
      </Portal>
      <DynamicFieldsForm
        dynamicFieldsData={initialValues}
        setSelectedDynamicKey={setSelectedDynamicKey}
        selectedDynamicKey={selectedDynamicKey}
      />
    </Card>
  );
};

export default DynamicFieldsCreate;
