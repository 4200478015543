import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Tooltip } from 'antd';
import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import SelectComponent from '../../../../../../../../components/SelectComponent';
import TileGridComponent from '../../../../../previewComponents/globalComponents/TileGridComponent';

const { Option } = SelectComponent;

const CustomPickListPreview = ({ widgetConfig }) => {
  const [form] = Form?.useForm();
  const label = widgetConfig?.config?.label || ' ';
  const tooltipChecked = widgetConfig?.config?.tooltip || widgetConfig?.tooltip;
  const tooltipValue =
    widgetConfig?.config?.tooltipValue || widgetConfig?.tooltipValue;
  const hideLabel = widgetConfig?.config?.rules?.hideLabel;
  const required = widgetConfig?.config?.rules?.required;
  const displayStyle = widgetConfig?.config?.displayStyle;
  const images = widgetConfig?.config?.images;
  const imagePreview = widgetConfig?.config?.imagePreview;
  const renderDescriptions = widgetConfig?.config?.optionsDescription;
  const otherConfig = widgetConfig?.config?.listItems;
  const description = widgetConfig?.config?.description;
  const descriptionText = widgetConfig?.config?.descriptionValue;

  const [selectOptions, setSelectOptions] = useState([...otherConfig]);
  const [defaultOption, setDefaultOption] = useState(null);

  useEffect(() => {
    if (displayStyle === 'DROPDOWN') {
      setSelectOptions([...otherConfig]);
      const defaultSelectedOption = otherConfig?.find((item) => item?.default);
      setDefaultOption(defaultSelectedOption?.label);
      form?.setFieldsValue({
        pickListSelect: defaultSelectedOption?.label || null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherConfig, displayStyle]);

  return (
    <div>
      {displayStyle === 'DROPDOWN' ? (
        <Form form={form} layout="vertical">
          <Form.Item
            name="pickListSelect"
            label={hideLabel ? ' ' : label}
            tooltip={
              tooltipChecked && {
                title: tooltipValue,
                icon: <InfoCircleOutlined />,
              }
            }
            initialValue={{ defaultOption }}
            rules={[{ required, message: 'Please Select Value' }]}
            className={`${!required && 'hide-required-mark'} ${
              tooltipChecked && 'label-with-tooltip'
            } label-text-break`}
          >
            <SelectComponent
              className="fill-width pick-list-drop-down"
              filterOption={(input, option) =>
                option?.value?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
              optionLabelProp="label"
            >
              {map(selectOptions, (item, index) => (
                <Option
                  key={`${item?.uuid}-${index}`}
                  value={item?.label}
                  label={item?.label}
                >
                  <div
                    key={`${item?.uuid}-${index}`}
                    className="dropdown-option"
                  >
                    <span className="option-title">{item?.label}</span>
                    {renderDescriptions && (
                      <span className="option-description">
                        {item?.blockDescription || item?.description}
                      </span>
                    )}
                  </div>
                </Option>
              ))}
            </SelectComponent>
          </Form.Item>
        </Form>
      ) : (
        <>
          <div className="custom-form-item">
            {!hideLabel && <div className="item-label">{label}</div>}
            {required && <span className="required-mark">*</span>}
            {tooltipChecked && (
              <Tooltip title={tooltipValue}>
                <InfoCircleOutlined className="custom-tooltip" />
              </Tooltip>
            )}
          </div>
          <TileGridComponent
            displayStyle={displayStyle}
            renderImages={images}
            imageObjectName="image"
            imagePreview={imagePreview}
            renderDescriptions={renderDescriptions}
            haveLabel
            renderItems={map(otherConfig, (item, index) => ({
              key: `${index}-index`,
              ...item,
            }))}
          />
        </>
      )}
      {description && <div className="mt-37">{descriptionText}</div>}
    </div>
  );
};

export default CustomPickListPreview;
