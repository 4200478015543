import {
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { Collapse, Form, Popover, Switch } from 'antd';
import React from 'react';

const PostalWidgetForm = () => {
  const postalItems = [
    {
      forceRender: true,
      label: 'ZIP Code Widget',
      key: '1',
      children: (
        <>
          {/* Map */}
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">
              Map{' '}
              <Popover
                overlayClassName="pointer"
                content="This setting toggles the map on or off."
              >
                <QuestionCircleOutlined />
              </Popover>
            </span>
            <Form.Item
              name={['widgetConfiguration', 'zipCodeWidget', 'map']}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>

          {/* Geolocation */}
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">
              Geolocation{' '}
              <Popover
                overlayClassName="pointer"
                content="Allow customers to tap a location icon for automatic ZIP code prefill."
              >
                <QuestionCircleOutlined />
              </Popover>
            </span>
            <Form.Item
              name={['widgetConfiguration', 'zipCodeWidget', 'geolocation']}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>

          {/* Replace title with location */}
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">
              Replace title with location{' '}
              <Popover
                overlayClassName="pointer"
                content="Choose between displaying the customer's location or a custom title."
              >
                <QuestionCircleOutlined />
              </Popover>
            </span>
            <Form.Item
              name={[
                'widgetConfiguration',
                'zipCodeWidget',
                'replaceTitleWithLocation',
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
        </>
      ),
    },
    {
      forceRender: true,
      label: 'Customer Input Validation',
      key: '2',
      children: (
        <div className="d-flex justify-between align-center">
          <span className="switch-logo">
            US ZIP code format{' '}
            <Popover
              overlayClassName="pointer"
              content="Please note that only US ZIP codes are currently supported."
            >
              <QuestionCircleOutlined />
            </Popover>
          </span>
          <Form.Item
            name={[
              'widgetConfiguration',
              'customerInputValidation',
              'usZipCodeFormat',
            ]}
            className="mb-0"
            valuePropName="checked"
          >
            <Switch
              disabled
              className="common-switch"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </div>
      ),
    },
  ];
  return (
    <Collapse
      className="common-collapse questionnaire-collapse"
      expandIconPosition="end"
      expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 0 : 180} />}
      defaultActiveKey={['1', '2']}
      ghost
      items={postalItems}
    />
  );
};

export default PostalWidgetForm;
