import {
  DownloadOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Checkbox, Col, Form, Popover, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { v4 as uuidv4 } from 'uuid';
import { messageContext } from '../../../app/components/AppContextHolder';
import { AppContext } from '../../../AppContext';
import SaveIcon from '../../../assets/save.svg';
import { ALLOW_IMAGE_TYPES, REGEX } from '../../../common/constants';
import {
  checkPermissions,
  formValidatorRules,
  uploadFile,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DraggerUploadComponent from '../../../components/DraggerUploadComponent';
import InputComponent from '../../../components/InputComponent';
import ModalComponent from '../../../components/ModalComponent';
import SelectComponent from '../../../components/SelectComponent';
import useRouter from '../../../hooks/useRouter';
import {
  CREATE_DYNAMIC_LIST_ITEM,
  UPDATE_DYNAMIC_LIST_ITEM,
} from '../graphql/Mutations';

const { required, requiredWhiteSpaceAllowed } = formValidatorRules;

const { TextArea } = InputComponent;

const { Option } = SelectComponent;

const { confirm } = ModalComponent;

const PickListModal = ({
  pickListModalVisible = false,
  handlePickListModalVisible,
  setPickListModalVisible,
  isEdit = false,
  isFieldDisabled = false,
  pickListType = 'TEXT',
  pickListRecord = null,
  setPickListRecord,
  setRefetchData,
}) => {
  const { params: { id } = {} } = useRouter();
  const {
    state: { permissions, currentUser, showPrompt },
    dispatch,
  } = useContext(AppContext);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [form] = Form?.useForm();

  const [createDynamicListItem] = useMutation(CREATE_DYNAMIC_LIST_ITEM, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const [updateDynamicListItem] = useMutation(UPDATE_DYNAMIC_LIST_ITEM, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  useEffect(() => {
    if (pickListRecord?.image?.url) {
      setFileList([{ ...pickListRecord?.image }]);
    }
  }, [pickListRecord]);

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const onChangeUpload = (info) => {
    const {
      file: { name = '', url },
    } = info;
    if (url) {
      setFileList([]);
      form?.setFieldsValue({
        image: null,
      });
      return;
    }
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (ALLOW_IMAGE_TYPES?.includes(ext) && !url) {
      if (info?.file?.status === 'removed') {
        form?.setFieldsValue({
          image: null,
        });
      } else {
        form?.setFieldsValue({
          image: info?.file,
        });
      }
      setFileList([...info?.fileList]);
    } else {
      setFileList([]);
      form?.setFieldsValue({
        image: null,
      });
      messageContext?.destroy();
      messageContext?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    let image = null;

    const uuid = pickListRecord?.uuid || uuidv4();

    if (formValues?.image && !formValues?.image?.url) {
      image = await uploadFile({
        logoObject: formValues?.image,
        currentUser,
        uuid,
        folder: 'dynamicListItem',
      });
    }

    const newFormValues = {
      ...formValues,
      uuid,
      ...(!pickListRecord && {
        fieldId: id,
      }),
      description: formValues?.description || '',
      value:
        pickListType === 'TEXT'
          ? formValues?.value
          : formValues?.value?.toString()?.replace(/^0+/, ''),
      default: formValues?.default || false,
      image: formValues?.image?.url
        ? { ...pickListRecord?.image, __typename: undefined }
        : image,
    };
    if (pickListRecord) {
      try {
        const response = await updateDynamicListItem({
          variables: {
            data: { ...newFormValues },
            where: { id: pickListRecord?.id },
          },
        });
        if (response?.data?.updateDynamicListItem) {
          setSubmitLoading(false);
          setPickListModalVisible(false);
          setPickListRecord(null);
          setRefetchData(true);
        }
      } catch (error) {
        setSubmitLoading(false);
        return error;
      }
    } else {
      try {
        const response = await createDynamicListItem({
          variables: { data: { ...newFormValues } },
        });
        if (response?.data?.createDynamicListItem) {
          setSubmitLoading(false);
          setPickListModalVisible(false);
          setPickListRecord(null);
          setRefetchData(true);
        }
      } catch (error) {
        setSubmitLoading(false);
        return error;
      }
    }
  };

  return (
    <ModalComponent
      width={1200}
      title={pickListRecord ? 'Edit' : 'Add'}
      open={pickListModalVisible}
      onCancel={() => {
        if (showPrompt) {
          confirm({
            centered: true,
            wrapClassName: 'confirm-modal',
            icon: <ExclamationCircleOutlined />,
            okButtonProps: { className: 'common-button', type: 'primary' },
            cancelButtonProps: { className: 'common-button discard-button' },
            content:
              'There are unsaved changes. Are you sure want to close this modal ?',
            onOk() {
              handlePickListModalVisible(false);
            },
            onCancel() {},
          });
        } else {
          handlePickListModalVisible(false);
        }
      }}
      destroyOnClose
      wrapClassName="pick-list-modal"
      footer={
        <AccessControl allowedPermissions={['FET_DYNAMIC_FIELD_UPDATE']}>
          <div className="d-flex justify-end">
            <Button
              className="common-button"
              icon={<img src={SaveIcon} alt="save-icon" width={12} />}
              size="small"
              htmlType="submit"
              id="pick-list-form-save-btn"
              loading={submitLoading}
              type="primary"
              onClick={form?.submit}
            >
              Save
            </Button>
          </div>
        </AccessControl>
      }
    >
      <Form
        form={form}
        initialValues={{
          ...pickListRecord,
          ...(pickListRecord?.image?.url && {
            image: pickListRecord?.image,
          }),
        }}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit
                ? ['FET_DYNAMIC_FIELD_UPDATE']
                : ['FET_DYNAMIC_FIELD_CREATE'],
            )
          }
        >
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter Name' },
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        const validName = REGEX?.NO_SPECIAL_CHAR;
                        if (!value?.match(validName)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'should be a valid Field Name (No Special characters & Space)',
                          );
                        }
                        return Promise?.resolve();
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
                name="name"
                label="Name"
              >
                <InputComponent allowClear placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Label' }]}
                name="label"
                label="Label"
                className="label-with-tooltip"
                tooltip="This is a sample tooltip"
              >
                <InputComponent allowClear placeholder="Enter Label" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="value"
                label="Value"
                rules={[{ ...required, message: 'Please Enter Value' }]}
                className="label-with-tooltip"
                tooltip="This is a sample tooltip"
              >
                {pickListType === 'TEXT' ? (
                  <InputComponent
                    disabled={isFieldDisabled && pickListRecord}
                    allowClear
                    placeholder="Enter Value"
                  />
                ) : (
                  <NumericFormat
                    className="ant-input-affix-wrapper input-component price-component"
                    decimalScale={2}
                    allowClear
                    allowLeadingZeros={false}
                    placeholder="Enter Value"
                    showPrefix={false}
                    fixedDecimalScale
                  />
                )}
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="isActive"
                label="Status"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Status',
                  },
                ]}
              >
                <SelectComponent placeholder="Select Status" allowClear>
                  <Option key="active" value>
                    Active
                  </Option>
                  <Option key="inactive" value={false}>
                    InActive
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="image" label="Icon">
                <DraggerUploadComponent
                  showUploadList={{
                    showDownloadIcon: false,
                    showPreviewIcon: true,
                  }}
                  fileList={fileList}
                  onChange={onChangeUpload}
                >
                  <p className="icon-header">
                    <DownloadOutlined />
                  </p>
                  <span className="upload-title">
                    Drag image or browse your files
                  </span>{' '}
                  <br />
                  <span className="upload-description">
                    JPG, PNG, SVG, JPEG
                  </span>
                </DraggerUploadComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={18} xxl={18}>
              <Form.Item name="description" label="Description">
                <TextArea
                  className="common-textarea"
                  autoSize={{ minRows: 5, maxRows: 5 }}
                  placeholder="Enter Description"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="default"
                className="mb-0"
                valuePropName="checked"
              >
                <Checkbox className="common-checkbox">
                  Default Option
                  <Popover className="pointer" content="This is sample Tooltip">
                    <QuestionCircleOutlined className="ml-4" />
                  </Popover>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </ModalComponent>
  );
};

export default PickListModal;
