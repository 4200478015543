import { map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import Error404 from '../../../Error404';
import client from '../../../apollo';
import { QUESTIONNAIRE_TYPE } from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import { GET_PAGE_SEQUENCES } from '../graphql/Queries';
import '../myQuestionnaireModule.less';
import QuestionnairePageBuilderPage from './QuestionnairePageBuilder';

const DynamicRouteWrapper = ({ type = QUESTIONNAIRE_TYPE?.ROQ_BUILDER }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [pageSequencesData, setPageSequencesData] = useState([]);

  // const questionnaireRoute =
  //   type === QUESTIONNAIRE_TYPE?.ROQ_BUILDER
  //     ? ROUTES?.QUESTIONNAIRES_PRIMARY
  //     : ROUTES?.QUESTIONNAIRES_SECONDARY;

  const getPageSequences = async () => {
    try {
      const res = await client?.query({
        query: GET_PAGE_SEQUENCES,
        variables: {
          where: {
            id,
          },
        },
        fetchPolicy: 'network-only',
      });
      setPageSequencesData(res?.data?.pageSequences?.data);
      return res;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    async function getInitialCall() {
      setLoading(true);
      await getPageSequences();
      setLoading(false);
    }
    getInitialCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <div className="questionnaire-module">
      <Routes>
        <Route
          path="/create"
          element={
            <AccessControl
              allowedPermissions={[
                'FET_QUESTIONNAIRE_UPDATE',
                'FET_QUESTIONNAIRE_VIEW',
              ]}
              showNoAccess
            >
              <QuestionnairePageBuilderPage
                isNewPage
                pageSequencesData={pageSequencesData}
                refetchPageSequence={getPageSequences}
                type={type}
              />
            </AccessControl>
          }
        />
        {map(pageSequencesData, (_, index) => (
          <Route
            key={index}
            path="/:pageKey"
            element={
              <AccessControl
                allowedPermissions={[
                  'FET_QUESTIONNAIRE_UPDATE',
                  'FET_QUESTIONNAIRE_VIEW',
                ]}
                showNoAccess
              >
                <QuestionnairePageBuilderPage
                  pageSequencesData={pageSequencesData}
                  refetchPageSequence={getPageSequences}
                  type={type}
                />
              </AccessControl>
            }
          />
        ))}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
};

export default DynamicRouteWrapper;
