import Icon from '@ant-design/icons';
import React from 'react';

const catalogIcon = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1387 3.50077C13.8429 3.89201 13.8429 4.77205 13.1387 5.1633L12.2349 5.66536L13.1387 6.16743C13.8429 6.55867 13.8429 7.43872 13.1387 7.82996L12.2349 8.33203L13.1387 8.8341C13.8429 9.22534 13.8429 10.1054 13.1387 10.4966L7.77121 13.4785C7.32283 13.7276 6.67749 13.7276 6.22911 13.4785L0.861673 10.4966C0.157437 10.1054 0.157437 9.22534 0.861673 8.8341L1.7654 8.33203L0.861673 7.82996C0.157437 7.43872 0.157437 6.55867 0.861673 6.16743L1.76539 5.66536L0.861673 5.1633C0.157437 4.77205 0.157437 3.89201 0.861673 3.50077L6.22911 0.518855C6.67749 0.269757 7.32283 0.269757 7.77121 0.518855L13.1387 3.50077ZM7.77121 8.14521L10.8622 6.428L11.8894 6.99869L10.8622 7.56939L9.48943 8.33203L7.12369 9.64633C7.07801 9.67171 6.92232 9.67171 6.87664 9.64633L4.5109 8.33203L3.13815 7.56939L2.1109 6.99869L3.13815 6.428L6.22911 8.14521C6.67749 8.39431 7.32283 8.39431 7.77121 8.14521ZM3.13815 9.09467L2.1109 9.66536L6.87664 12.313C6.92232 12.3384 7.07801 12.3384 7.12369 12.313L11.8894 9.66536L10.8622 9.09467L7.77121 10.8119C7.32283 11.061 6.67749 11.061 6.22911 10.8119L3.13815 9.09467ZM6.87699 1.6831C6.92267 1.65772 7.07836 1.65772 7.12404 1.6831L11.8898 4.33073L7.12404 6.97836C7.07836 7.00374 6.92267 7.00374 6.87699 6.97836L2.11125 4.33073L6.87699 1.6831Z"
        fill="currentColor"
      />
    </svg>
  );

const CatalogIconComponent = (props) => (
  <Icon aria-label="Catalog Icon" component={catalogIcon} {...props} />
);

export default CatalogIconComponent;
