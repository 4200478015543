import { gql } from '@apollo/client';

export const GET_PRODUCT_CATEGORIES = gql`
  query productCategories(
    $filter: ProductCategoryFilter!
    $where: ProductCategoriesWhereFilter
  ) {
    productCategories(filter: $filter, where: $where) {
      count
      data {
        id
        title
        productCategoryCode
        description
        isActive
        sponsorName
      }
    }
  }
`;

export const GET_PRODUCT_CATEGORY = gql`
  query productCategory($id: ID!) {
    productCategory(where: { id: $id }) {
      id
      title
      productCategoryCode
      refData
      description
      isActive
      tenantId
      sponsorName
    }
  }
`;

export const PRODUCT_CATEGORY_FILTER = gql`
  query productCategoryFilters($filter: ProductCategoryFilter!) {
    productCategoryFilters(filter: $filter) {
      count
      data {
        title
        productCategoryCode
        refData
        description
        isActive
        tenantId
        sponsorName
      }
    }
  }
`;

export const GET_SPONSOR_FILTER = gql`
  query sponsorFilters($filter: SponsorFilter!) {
    sponsorFilters(filter: $filter) {
      count
      data {
        firstName
        lastName
        businessName
        userId
      }
    }
  }
`;
