import { QuestionCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Form, Popover, Row, Select } from 'antd';
import { debounce, forEach, map, startCase } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { REGEX, ROUTES, SKIP_RECORD } from '../../../common/constants';
import {
  formValidatorRules,
  formatPhoneNumberWithoutMask,
} from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import NumberComponent from '../../../components/NumberComponent';
import SelectComponent from '../../../components/SelectComponent';
import { CREATE_TENANT } from '../graphql/Mutation';
import { CHECK_SUBDOMAIN, FETCH_STATE_CITY } from '../graphql/Query';
import Signup from '../pages/Signup';

let searchDebounce = null;

const { Option } = Select;
const layout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

let stateScrollDebounce;
let scrollDebounceJob;
const initialFilter = {
  skip: 0,
  limit: 20,
  type: 'STATE',
  search: '',
  sortOn: 'name',
  sortBy: 'ASC',
};
const {
  requiredWithoutMessage,
  email,
  zipCode,
  subDomain,
} = formValidatorRules;

const BusinessInfo = () => {
  const [stateSkip, setStateSkip] = useState(0);
  const [stateCityFilter, setStateCityFilter] = useState(initialFilter);
  const [form] = Form?.useForm();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [citySkip, setCitySkip] = useState(0);
  const [fetchCity, setFetchCity] = useState(false);
  const [citySearchFlag, setCitySearchFlag] = useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [disable, setDisable] = useState(true);
  const [validateTrigger, setValidateTrigger] = useState('onSubmit');
  const [callAsync, setCallAsync] = useState(false);
  const [stateIsEnd, setStateIsEnd] = useState(false);
  const [cityIsEnd, setCityIsEnd] = useState(false);
  const [stateSearchFlag, setStateSearchFlag] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [isBusinessContactFocused, setIsBusinessContactFocused] = useState(
    false,
  );

  const navigate = useNavigate();

  const [fetchStateAndCity] = useLazyQuery(FETCH_STATE_CITY, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const moreData = response?.getLocationType?.data;
      if (fetchCity) {
        setCityIsEnd(moreData?.length < SKIP_RECORD);
        if (citySearchFlag) {
          setCities([...moreData]);
        } else {
          setCities([...cities, ...moreData]);
        }
      } else {
        setStateIsEnd(moreData?.length < SKIP_RECORD);
        if (stateSearchFlag) {
          setStates([...moreData]);
        } else {
          setStates([...states, ...moreData]);
        }
        setCallAsync(false);
      }
    },
    onError() {},
  });

  const [checkSubDomain] = useLazyQuery(CHECK_SUBDOMAIN, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      form?.setFieldsValue({ subDomain: res?.checkSubDomain });
    },
    onError() {},
  });

  useEffect(() => {
    fetchStateAndCity({
      variables: {
        filter: stateCityFilter,
      },
    });
    setStateSkip(20);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!callAsync && states?.length > 0) {
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: selectedState,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAsync]);

  const handleStateClear = () => {
    form?.setFieldsValue({
      state: null,
      city: null,
    });
    setFetchCity(false);
    setStateCityFilter(initialFilter);
    fetchStateAndCity({
      variables: {
        filter: stateCityFilter,
      },
    });
    setCities([]);
    setSelectedState('');
    setDisable(true);
  };
  const handleStateBlur = () => {
    setStateIsEnd(false);
  };

  const handleCityBlur = () => {
    setSearchValue('');
    setCityIsEnd(false);
  };
  const handleStateChange = (value) => {
    setFetchCity(false);
    setSearchValue(value);
    setStateSearchFlag(true);
    const state = form?.getFieldValue('state');
    if (value) {
      setCities([]);
      setDisable(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE',
            search: value,
          },
        },
      });
    } else {
      if (!state?.length) {
        setDisable(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter,
          },
        });
      }
      if (state?.length > 0) {
        setDisable(false);
        setCitySearchFlag(true);
        setCallAsync(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter,
          },
        });
      }
    }
  };

  const handleStateSelect = (value) => {
    if (value) {
      setStateCityFilter(initialFilter);
      setCities([]);
      form?.setFieldsValue({
        city: null,
      });
      setSelectedState(value);
      setDisable(false);
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: value,
          },
        },
      });
      setCitySkip(20);
    } else {
      setDisable(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE',
          },
        },
      });
      form?.setFieldsValue({
        city: null,
      });
    }
  };

  const handleCityChange = (value) => {
    setFetchCity(true);
    setCitySearchFlag(true);
    setSearchValue(value);
    if (value) {
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            search: value,
            state: form?.getFieldValue('state'),
          },
        },
      });
    } else {
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: form?.getFieldValue('state'),
          },
        },
      });
    }
  };

  const handleCityClear = () => {
    setFetchCity(true);
    fetchStateAndCity({
      variables: {
        filter: {
          ...stateCityFilter,
          type: 'CITY',
          state: selectedState,
        },
      },
    });
  };

  const onCityScroll = (event) => {
    setCitySearchFlag(false);
    setFetchCity(true);
    if (scrollDebounceJob) {
      scrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    scrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !cityIsEnd) {
        setCitySkip((prevSkip) => prevSkip + 20);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: citySkip,
              type: 'CITY',
              search: searchValue,
              state: selectedState,
            },
          },
        });
      }
    }, 500);

    scrollDebounceJob();
  };

  const onStateScroll = (event) => {
    setFetchCity(false);
    setStateSearchFlag(false);
    if (stateScrollDebounce) {
      stateScrollDebounce?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    stateScrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !stateIsEnd) {
        setStateSkip((prevSkip) => prevSkip + 20);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: stateSkip,
              search: searchValue,
              type: 'STATE',
            },
          },
        });
      }
    }, 500);

    stateScrollDebounce();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useCallback(
    debounce(handleCityChange, 500),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedStateHandler = useCallback(
    debounce(handleStateChange, 500),
    [],
  );

  const [addTenant] = useMutation(CREATE_TENANT);
  const onFinish = async (values) => {
    setValidateTrigger('onChange');
    setButtonLoading(true);
    const {
      email: mail,
      phoneNo,
      businessName,
      streetNo,
      city,
      state,
      zipCode: postalCode,
      subDomain: domainName,
    } = values;

    const tenantInput = {
      name: businessName,
      email: mail,
      phoneNo: formatPhoneNumberWithoutMask(phoneNo)?.slice(-10),
      subDomain: domainName,
    };
    const locationInput = {
      addressLine1: streetNo,
      city,
      state,
      zipCode: postalCode,
    };
    try {
      const response = await addTenant({
        variables: {
          data: {
            tenantInput,
            locationInput,
          },
        },
      });
      if (response) {
        setButtonLoading(false);
        navigate(`${ROUTES?.SIGNUP}/plan`);
      }
    } catch (error) {
      setButtonLoading(false);
    }
  };

  const onFinishFailed = (values) => {
    setValidateTrigger('onChange');
    const requiredFields = [];

    if (values?.errorFields) {
      forEach(values?.errorFields, (item) => {
        requiredFields?.push(startCase(item?.name?.[0]));
      });
      setErrorMessage(`Enter your valid ${requiredFields?.join(' , ')}`);
    } else {
      setErrorMessage('');
    }
  };

  const onFormChange = (changedValues, allValues) => {
    if (
      allValues?.businessName?.length > 0 &&
      allValues?.phoneNo?.length > 0 &&
      allValues?.email?.length > 0 &&
      allValues?.subDomain?.length > 0
    ) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }

    if (validateTrigger === 'onChange') {
      const newValues = {
        businessName: allValues?.businessName,
        email: allValues?.email,
        phoneNo: allValues?.phoneNo,
      };
      const requiredFields = [];
      forEach(newValues, (value, key) => {
        if (key === 'email') {
          if (value && !REGEX?.EMAIL?.test(value)) {
            requiredFields?.push(startCase(key));
          }
        }
        if (key === 'phoneNo') {
          if (value && !REGEX?.PHONE?.test(value)) {
            requiredFields?.push(startCase(key));
          }
        }
        if (!value) {
          requiredFields?.push(startCase(key));
        }
      });

      setErrorMessage(`Enter your valid ${requiredFields?.join(' , ')}`);

      if (!Object?.values(newValues)?.includes(undefined)) {
        setErrorMessage(null);
      }
    }
  };

  const checkSubDomainFunc = (value) => {
    checkSubDomain({
      variables: {
        subDomain: value,
      },
    });
  };

  const handleChange = ({ target: { value } }) => {
    if (searchDebounce) {
      searchDebounce?.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(checkSubDomainFunc, 500);
    searchDebounce(value);
  };

  const renderSubDomainInfo = () => (
    <div>
      <span>Guidelines: </span>
      <ol>
        <li>
          The subdomain can contain only small characters, numbers and
          hyphen(-).
        </li>
        <li>Space or Special Characters are not allowed.</li>
        <li>The subdomain name must be a unique one.</li>
      </ol>
    </div>
  );

  return (
    <Signup>
      <Form
        {...layout}
        form={form}
        name="tenant-add-form"
        className="add-tenant-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
        onValuesChange={onFormChange}
        autoComplete="off"
      >
        <Row gutter={16} className="business-form" align="bottom">
          <Col md={24} lg={24} xl={24}>
            <Form.Item
              label="Business Name"
              name="businessName"
              rules={[requiredWithoutMessage]}
            >
              <InputComponent
                autoFocus
                placeholder="Business Name *"
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={24} xl={24}>
            <Form.Item
              label="Business Email"
              name="email"
              rules={[email, requiredWithoutMessage]}
            >
              <InputComponent
                placeholder="Business Email *"
                id="business-email-input"
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={24} xl={24}>
            <Form.Item
              label="Business Contact Phone Number"
              name="phoneNo"
              rules={[
                requiredWithoutMessage,
                () => ({
                  validator(rule, value) {
                    if (value) {
                      // eslint-disable-next-line no-param-reassign
                      value = value?.split(' ')?.join('');
                      const numberPattern = REGEX?.PHONE;
                      if (!numberPattern?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject(
                          'should be a valid phone number',
                        );
                      }
                    }
                    return Promise?.resolve();
                  },
                }),
              ]}
            >
              <PhoneInput
                inputProps={{
                  name: 'Business Contact Phone Number',
                  required: true,
                  placeholder: isBusinessContactFocused
                    ? '(000)-000-000'
                    : 'Business Contact Phone Number *',
                }}
                disableCountryCode
                disableDropdown
                onlyCountries={['us']}
                country="us"
                buttonClass="custom-dropdown"
                onFocus={() => {
                  setIsBusinessContactFocused(true);
                }}
                onBlur={() => {
                  setIsBusinessContactFocused(false);
                }}
              />
            </Form.Item>
          </Col>
          <Col md={23} lg={23} xl={23}>
            <Form.Item
              label="Sub Domain"
              name="subDomain"
              className="subdomain-input"
              rules={[requiredWithoutMessage, subDomain]}
            >
              <InputComponent placeholder="Sub Domain *" />
            </Form.Item>
          </Col>
          <Col md={1} lg={1} xl={1} className="d-flex justify-end">
            <Popover
              title="This is the name at the end of your link that you will share with prospective clients. "
              overlayClassName="subdomain-info"
              content={renderSubDomainInfo()}
            >
              <QuestionCircleOutlined className="question-icon" />
            </Popover>
          </Col>
          <Col md={24} lg={24} xl={24}>
            <Form.Item label="Street Address" name="streetNo">
              <InputComponent
                placeholder="Street Address"
                id="onboard-streetno-input"
              />
            </Form.Item>
          </Col>
          <Col md={24} lg={24} xl={24}>
            <Form.Item name="state" label="State">
              <SelectComponent
                className="gx-text-center"
                placeholder="State"
                allowClear
                size="large"
                onSearch={debouncedStateHandler}
                onClear={handleStateClear}
                onSelect={handleStateSelect}
                onPopupScroll={onStateScroll}
                onBlur={handleStateBlur}
              >
                {map(states, (state) => (
                  <Option key={state?.id} value={state?.name}>
                    {state?.name}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col md={24} lg={24} xl={16}>
            <Form.Item name="city" label="City">
              <SelectComponent
                className="gx-text-center"
                placeholder="City"
                disabled={disable}
                onSearch={debouncedChangeHandler}
                id="onboard-city-input"
                onPopupScroll={onCityScroll}
                onClear={handleCityClear}
                onBlur={handleCityBlur}
                size="large"
              >
                {cities?.map((city) => (
                  <Option key={city?.id} value={city?.name}>
                    {city?.name}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col md={24} lg={24} xl={8}>
            <Form.Item name="zipCode" label="Postal Code" rules={[zipCode]}>
              <NumberComponent placeholder="Postal Code" />
            </Form.Item>
          </Col>
          <Col md={24} lg={24} xl={24}>
            <Form.Item>
              <Button
                type="primary"
                id="btn-business-info"
                htmlType="submit"
                className="fill-width onboarding-btn business-btn"
                loading={buttonLoading}
                disabled={disableBtn}
              >
                Continue to Plan Selection
              </Button>
            </Form.Item>
          </Col>
          {errorMessage && (
            <Col md={24} lg={24} xl={24}>
              <p className="error">{errorMessage}</p>
            </Col>
          )}
        </Row>
      </Form>
    </Signup>
  );
};

export default BusinessInfo;
