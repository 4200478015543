import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Checkbox, Col, Divider, Empty, Form, Radio, Row, Switch } from 'antd';
import { isEmpty, map, pull, startCase, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  CONDITIONS,
  CONDITIONS_KEYS,
  DYNAMIC_FIELDS_DATA_KEYS,
  NUMERIC_CONDITIONS,
} from '../../../common/constants';
import { fieldLevelDisabled } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import NumberComponent from '../../../components/NumberComponent';
import TreeComponent from '../../../components/TreeComponent';

const ProductTab = ({
  form,
  checkedFields = [],
  setCheckedFields,
  disabledFields,
  setIsFormValuesChanged,
  productStaticFieldDataClone,
  productStaticFieldOptionData,
  setProductStaticFieldOptionData,
  productStaticFieldSelectedOption,
  setProductStaticFieldSelectedOption,
  dynamicFieldsLoading = false,
  sponsorName,
  editableFields = [],
}) => {
  const [selectedList, setSelectedList] = useState(null);

  const booleanCondition = Form?.useWatch(
    ['dynamicFields', 'booleanCondition'],
    form,
  );
  const numberCondition = Form?.useWatch(
    ['dynamicFields', 'numberCondition'],
    form,
  );
  const listCondition = Form?.useWatch(
    ['dynamicFields', 'listCondition'],
    form,
  );

  useEffect(() => {
    if (!isEmpty(productStaticFieldSelectedOption?.[selectedList])) {
      if (
        listCondition?.[selectedList] === CONDITIONS_KEYS?.EQUAL_TO ||
        listCondition?.[selectedList] === CONDITIONS_KEYS?.NOT_EQUAL_TO
      ) {
        const newListOptions = map(
          productStaticFieldOptionData?.[selectedList],
          (item) => {
            if (
              item?.key !==
              productStaticFieldSelectedOption?.[selectedList]?.[0]
            ) {
              return { ...item, disabled: true };
            }
            return item;
          },
        );
        setProductStaticFieldOptionData({
          ...productStaticFieldOptionData,
          [selectedList]: newListOptions,
        });
      } else {
        const newListOptions = map(
          productStaticFieldOptionData?.[selectedList],
          (item) => {
            if (
              item?.key !==
              productStaticFieldSelectedOption?.[selectedList]?.[0]
            ) {
              return { ...item, disabled: false };
            }
            return item;
          },
        );
        setProductStaticFieldOptionData({
          ...productStaticFieldOptionData,
          [selectedList]: newListOptions,
        });
      }
    } else if (productStaticFieldOptionData?.[selectedList]) {
      const newListOptions = map(
        productStaticFieldOptionData?.[selectedList],
        (item) => ({ ...item, disabled: false }),
      );
      setProductStaticFieldOptionData({
        ...productStaticFieldOptionData,
        [selectedList]: newListOptions,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productStaticFieldSelectedOption, listCondition]);

  const renderTextCondition = (id) => (
    <div>
      <p className="mb-8 ml-8 mt-8" hidden={!checkedFields?.includes(id)}>
        Conditions
      </p>
      <div className="ml-12 mt-8">
        <Form.Item
          name={['dynamicFields', 'textCondition', id]}
          initialValue={CONDITIONS_KEYS?.EQUAL_TO}
          hidden={!checkedFields?.includes(id)}
        >
          <Radio.Group
            disabled={fieldLevelDisabled({
              isSponsorRecord: sponsorName?.length > 0,
              fieldName: 'dynamicFields',
              editableFields,
            })}
            className="common-radio fill-width d-flex flex-vertical"
          >
            <Row gutter={[5, 5]} className="fill-width">
              {map(CONDITIONS, (condition) => (
                <Col
                  xs={10}
                  sm={10}
                  md={10}
                  lg={5}
                  xl={4}
                  xxl={4}
                  key={condition?.value}
                >
                  <Radio value={condition?.value} key={condition?.value}>
                    {condition?.label}
                  </Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Add Filtering word or phrase"
          className="mt-8 ml-12"
          name={['dynamicFields', 'textCondition', 'value', id]}
          hidden={!checkedFields?.includes(id)}
        >
          <InputComponent
            disabled={fieldLevelDisabled({
              isSponsorRecord: sponsorName?.length > 0,
              fieldName: 'dynamicFields',
              editableFields,
            })}
            name="word or phrase"
          />
        </Form.Item>
      </div>
    </div>
  );

  const renderBooleanCondition = (data) => {
    const trueValue = data?.config?.BOOLEAN?.trueValue;
    const falseValue = data?.config?.BOOLEAN?.falseValue;

    return (
      <div>
        <p
          className="mb-8 ml-8 mt-8"
          hidden={!checkedFields?.includes(data?.id)}
        >
          Conditions
        </p>
        <div className="ml-12 mt-8">
          <Form.Item
            name={['dynamicFields', 'booleanCondition', data?.id]}
            initialValue="EQUAL_TO"
            hidden={!checkedFields?.includes(data?.id)}
          >
            <Radio
              disabled={fieldLevelDisabled({
                isSponsorRecord: sponsorName?.length > 0,
                fieldName: 'dynamicFields',
                editableFields,
              })}
              value="EQUAL_TO"
              key="EQUAL_TO"
              checked
            >
              Equal to
            </Radio>
          </Form.Item>
          <Form.Item
            name={['dynamicFields', 'booleanCondition', 'value', data?.id]}
            hidden={!checkedFields?.includes(data?.id)}
          >
            <Checkbox.Group
              disabled={fieldLevelDisabled({
                isSponsorRecord: sponsorName?.length > 0,
                fieldName: 'dynamicFields',
                editableFields,
              })}
              className="ml-12 d-flex"
            >
              <Checkbox
                className="common-checkbox"
                key={trueValue}
                value="true"
                disabled={
                  booleanCondition?.value?.[data?.id]?.length > 0 &&
                  booleanCondition?.value?.[data?.id]?.[0] !== 'true'
                }
              >
                {trueValue}
              </Checkbox>
              <Checkbox
                className="common-checkbox"
                key={falseValue}
                value="false"
                disabled={
                  booleanCondition?.value?.[data?.id]?.length > 0 &&
                  booleanCondition?.value?.[data?.id]?.[0] !== 'false'
                }
              >
                {falseValue}
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
        </div>
      </div>
    );
  };

  const renderNumberCondition = (id) => {
    const isLessThanEnabled = numberCondition?.check?.[id]?.lessThan;
    const isGreaterThanEnabled = numberCondition?.check?.[id]?.greaterThan;
    return (
      <div>
        <p className="mb-8 ml-8 mt-8" hidden={!checkedFields?.includes(id)}>
          Conditions
        </p>
        <div className="ml-12 mt-8">
          <Form.Item
            name={['dynamicFields', 'numberCondition', id]}
            initialValue={CONDITIONS_KEYS?.EQUAL_TO}
            hidden={!checkedFields?.includes(id)}
          >
            <Radio.Group
              disabled={fieldLevelDisabled({
                isSponsorRecord: sponsorName?.length > 0,
                fieldName: 'dynamicFields',
                editableFields,
              })}
              className="common-radio fill-width d-flex flex-vertical"
            >
              <Row gutter={[5, 5]} className="fill-width">
                {map(NUMERIC_CONDITIONS, (condition) => (
                  <Col
                    xs={10}
                    sm={10}
                    md={10}
                    lg={5}
                    xl={4}
                    xxl={4}
                    key={condition?.value}
                  >
                    <Radio
                      value={condition?.value}
                      key={condition?.value}
                      onChange={() => {
                        form?.setFieldsValue({
                          dynamicFields: {
                            numberCondition: {
                              value: {
                                [id]: null,
                              },
                              check: {
                                [id]: {
                                  lessThan: false,
                                  greaterThan: false,
                                },
                              },
                            },
                          },
                        });
                      }}
                    >
                      {condition?.label}
                    </Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
        </div>
        {numberCondition?.[id] === CONDITIONS_KEYS?.GREATER_LESS_RANGE && (
          <div className="d-flex mt-16 ml-12">
            <div>
              <div className="d-flex justify-between align-center">
                <span
                  className="switch-logo"
                  hidden={!checkedFields?.includes(id)}
                >
                  Less than
                </span>
                <Form.Item
                  className="mb-0"
                  valuePropName="checked"
                  name={[
                    'dynamicFields',
                    'numberCondition',
                    'check',
                    id,
                    'lessThan',
                  ]}
                  initialValue={false}
                  hidden={!checkedFields?.includes(id)}
                >
                  <Switch
                    disabled={fieldLevelDisabled({
                      isSponsorRecord: sponsorName?.length > 0,
                      fieldName: 'dynamicFields',
                      editableFields,
                    })}
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name={[
                  'dynamicFields',
                  'numberCondition',
                  'value',
                  id,
                  'lessThan',
                ]}
                initialValue="0"
                hidden={!checkedFields?.includes(id)}
                dependencies={[
                  ['dynamicFields', 'numberCondition', 'check', id, 'lessThan'],
                  [
                    'dynamicFields',
                    'numberCondition',
                    'value',
                    id,
                    'greaterThan',
                  ],
                ]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const lessThanNumber = getFieldValue([
                        'dynamicFields',
                        'numberCondition',
                        'check',
                        id,
                        'lessThan',
                      ]);
                      const greaterThanNumber = getFieldValue([
                        'dynamicFields',
                        'numberCondition',
                        'value',
                        id,
                        'greaterThan',
                      ]);
                      if (
                        lessThanNumber &&
                        greaterThanNumber &&
                        Number(value) <= Number(greaterThanNumber)
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Should be greater than ${greaterThanNumber}`,
                          ),
                        );
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
              >
                <NumberComponent
                  name="number"
                  disabled={
                    !isLessThanEnabled ||
                    fieldLevelDisabled({
                      isSponsorRecord: sponsorName?.length > 0,
                      fieldName: 'dynamicFields',
                      editableFields,
                    })
                  }
                />
              </Form.Item>
            </div>
            <div className="ml-12">
              <div className="d-flex justify-between align-center">
                <span
                  className="switch-logo"
                  hidden={!checkedFields?.includes(id)}
                >
                  Greater than
                </span>
                <Form.Item
                  className="mb-0"
                  valuePropName="checked"
                  name={[
                    'dynamicFields',
                    'numberCondition',
                    'check',
                    id,
                    'greaterThan',
                  ]}
                  initialValue={false}
                  hidden={!checkedFields?.includes(id)}
                >
                  <Switch
                    disabled={fieldLevelDisabled({
                      isSponsorRecord: sponsorName?.length > 0,
                      fieldName: 'dynamicFields',
                      editableFields,
                    })}
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name={[
                  'dynamicFields',
                  'numberCondition',
                  'value',
                  id,
                  'greaterThan',
                ]}
                initialValue="0"
                hidden={!checkedFields?.includes(id)}
                dependencies={[
                  ['dynamicFields', 'numberCondition', 'value', id, 'lessThan'],
                  [
                    'dynamicFields',
                    'numberCondition',
                    'check',
                    id,
                    'greaterThan',
                  ],
                ]}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const greaterThanNumber = getFieldValue([
                        'dynamicFields',
                        'numberCondition',
                        'check',
                        id,
                        'greaterThan',
                      ]);
                      const lessThanNumber = getFieldValue([
                        'dynamicFields',
                        'numberCondition',
                        'value',
                        id,
                        'lessThan',
                      ]);
                      if (
                        greaterThanNumber &&
                        lessThanNumber &&
                        Number(value) >= Number(lessThanNumber)
                      ) {
                        return Promise?.reject(
                          new Error(`Should be less than ${lessThanNumber}`),
                        );
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
              >
                <NumberComponent
                  name="number"
                  disabled={
                    !isGreaterThanEnabled ||
                    fieldLevelDisabled({
                      isSponsorRecord: sponsorName?.length > 0,
                      fieldName: 'dynamicFields',
                      editableFields,
                    })
                  }
                />
              </Form.Item>
            </div>
          </div>
        )}
        {(numberCondition?.[id] === CONDITIONS_KEYS?.EQUAL_TO ||
          numberCondition?.[id] === CONDITIONS_KEYS?.NOT_EQUAL_TO) && (
          <Form.Item
            label={
              numberCondition?.[id] === CONDITIONS_KEYS?.EQUAL_TO
                ? 'Equal to'
                : 'Not Equal to'
            }
            className="mt-8 ml-12 width-percent-30"
            name={['dynamicFields', 'numberCondition', 'value', id]}
            hidden={!checkedFields?.includes(id)}
          >
            <NumberComponent
              name="number"
              disabled={fieldLevelDisabled({
                isSponsorRecord: sponsorName?.length > 0,
                fieldName: 'dynamicFields',
                editableFields,
              })}
            />
          </Form.Item>
        )}
      </div>
    );
  };

  const handleOptionsSelectAll = (id, data) => {
    if (productStaticFieldSelectedOption?.[id]?.length > 0) {
      setProductStaticFieldSelectedOption({
        ...productStaticFieldSelectedOption,
        [id]: null,
      });
    } else {
      const optionsIds = map(data?.[id], (item) => item?.key);
      const updatedListOptions = {
        [id]: [...optionsIds],
      };
      setProductStaticFieldSelectedOption({
        ...productStaticFieldSelectedOption,
        ...updatedListOptions,
      });
    }
    setIsFormValuesChanged(true);
  };

  const renderListCondition = (data) => (
    <div>
      <p className="mb-8 ml-8 mt-8" hidden={!checkedFields?.includes(data?.id)}>
        Conditions
      </p>
      <div className="ml-12 mt-8">
        <Form.Item
          name={['dynamicFields', 'listCondition', data?.id]}
          initialValue={CONDITIONS_KEYS?.EQUAL_TO}
          hidden={!checkedFields?.includes(data?.id)}
        >
          <Radio.Group
            disabled={fieldLevelDisabled({
              isSponsorRecord: sponsorName?.length > 0,
              fieldName: 'dynamicFields',
              editableFields,
            })}
            className="common-radio fill-width d-flex flex-vertical"
          >
            <Row gutter={[5, 5]} className="fill-width">
              {map(CONDITIONS, (condition) => (
                <Col
                  xs={10}
                  sm={10}
                  md={10}
                  lg={5}
                  xl={4}
                  xxl={4}
                  key={condition?.value}
                >
                  <Radio
                    value={condition?.value}
                    key={condition?.value}
                    onChange={() => {
                      setProductStaticFieldSelectedOption({
                        ...productStaticFieldSelectedOption,
                        [data?.id]: null,
                      });
                      setSelectedList(data?.id);
                    }}
                  >
                    {condition?.label}
                  </Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
      </div>
      {(listCondition?.[data?.id] === CONDITIONS_KEYS?.CONTAINS ||
        listCondition?.[data?.id] === CONDITIONS_KEYS?.NOT_CONTAINS) &&
        productStaticFieldOptionData?.[data?.id]?.length > 0 && (
          <span
            className="select-all-text"
            onClick={() =>
              handleOptionsSelectAll(data?.id, productStaticFieldOptionData)
            }
            hidden={!checkedFields?.includes(data?.id)}
          >
            {productStaticFieldSelectedOption?.[data?.id]?.length > 0
              ? 'Deselect All'
              : 'Select All'}
          </span>
        )}
      {productStaticFieldOptionData?.[data?.id]?.length > 0 ? (
        <Form.Item
          name={['dynamicFields', 'listCondition', 'value', data?.id]}
          hidden={!checkedFields?.includes(data?.id)}
        >
          <TreeComponent
            disabled={fieldLevelDisabled({
              isSponsorRecord: sponsorName?.length > 0,
              fieldName: 'dynamicFields',
              editableFields,
            })}
            className="panel-tree mt-8"
            data={productStaticFieldOptionData?.[data?.id]}
            checkedKeys={productStaticFieldSelectedOption?.[data?.id]}
            blockNode
            titleRender={(item) => (
              <div
                className="d-flex justify-between"
                key={item?.key}
                title={item?.label}
              >
                <p>{item?.label}</p>
              </div>
            )}
            setCheckedKeys={setProductStaticFieldSelectedOption}
            onCheckParent={(value) => {
              const updatedListOptions = {
                [data?.id]: [...value],
              };
              setProductStaticFieldSelectedOption({
                ...productStaticFieldSelectedOption,
                ...updatedListOptions,
              });

              setIsFormValuesChanged(true);
              setSelectedList(data?.id);
            }}
          />
        </Form.Item>
      ) : (
        <Empty />
      )}
    </div>
  );

  const renderEnumListCondition = (data) => (
    <div>
      <p className="mb-8 ml-8 mt-8" hidden={!checkedFields?.includes(data?.id)}>
        Conditions
      </p>
      <div className="ml-12 mt-8">
        <Form.Item
          name={['dynamicFields', 'listCondition', data?.id]}
          initialValue={CONDITIONS_KEYS?.EQUAL_TO}
          hidden={!checkedFields?.includes(data?.id)}
        >
          <Radio.Group
            disabled={fieldLevelDisabled({
              isSponsorRecord: sponsorName?.length > 0,
              fieldName: 'dynamicFields',
              editableFields,
            })}
            className="common-radio fill-width d-flex flex-vertical"
          >
            <Row gutter={[5, 5]} className="fill-width">
              {map(CONDITIONS, (condition) => (
                <Col
                  xs={10}
                  sm={10}
                  md={10}
                  lg={5}
                  xl={4}
                  xxl={4}
                  key={condition?.value}
                >
                  <Radio
                    value={condition?.value}
                    key={condition?.value}
                    onChange={() => {
                      setProductStaticFieldSelectedOption({
                        ...productStaticFieldSelectedOption,
                        [data?.id]: null,
                      });
                      setSelectedList(data?.id);
                    }}
                  >
                    {condition?.label}
                  </Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>
      </div>
      {(listCondition?.[data?.id] === CONDITIONS_KEYS?.CONTAINS ||
        listCondition?.[data?.id] === CONDITIONS_KEYS?.NOT_CONTAINS) &&
        productStaticFieldOptionData?.[data?.id]?.length > 0 && (
          <span
            className="select-all-text"
            onClick={() =>
              handleOptionsSelectAll(data?.id, productStaticFieldOptionData)
            }
            hidden={!checkedFields?.includes(data?.id)}
          >
            {productStaticFieldSelectedOption?.[data?.id]?.length > 0
              ? 'Deselect All'
              : 'Select All'}
          </span>
        )}
      {productStaticFieldOptionData?.[data?.id]?.length > 0 ? (
        <Form.Item
          name={['dynamicFields', 'listCondition', 'value', data?.id]}
          hidden={!checkedFields?.includes(data?.id)}
        >
          <TreeComponent
            disabled={fieldLevelDisabled({
              isSponsorRecord: sponsorName?.length > 0,
              fieldName: 'dynamicFields',
              editableFields,
            })}
            className="panel-tree mt-8"
            data={productStaticFieldOptionData?.[data?.id]}
            checkedKeys={productStaticFieldSelectedOption?.[data?.id]}
            blockNode
            titleRender={(item) => (
              <div
                className="d-flex justify-between"
                key={item?.key}
                title={item?.label}
              >
                <p>{item?.label}</p>
              </div>
            )}
            setCheckedKeys={setProductStaticFieldSelectedOption}
            onCheckParent={(value) => {
              const updatedListOptions = {
                [data?.id]: [...value],
              };
              setProductStaticFieldSelectedOption({
                ...productStaticFieldSelectedOption,
                ...updatedListOptions,
              });

              setIsFormValuesChanged(true);
              setSelectedList(data?.id);
            }}
          />
        </Form.Item>
      ) : (
        <Empty />
      )}
    </div>
  );

  return (
    <div>
      {!dynamicFieldsLoading && isEmpty(productStaticFieldDataClone) && (
        <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
      )}
      {dynamicFieldsLoading && <LoaderComponent setHeight={10} />}
      {map(productStaticFieldDataClone, (item) => (
        <div key={item?.id}>
          <div className="d-flex align-center justify-between ml-8">
            <h5 className="mb-0">{startCase(item?.label)}</h5>
            <Switch
              className="common-switch"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={() => {
                if (checkedFields?.includes(item?.id)) {
                  pull(checkedFields, item?.id);
                  setCheckedFields([...checkedFields]);
                } else {
                  setCheckedFields(uniq([...checkedFields, item?.id]));
                }
                setIsFormValuesChanged(true);
              }}
              checked={checkedFields?.includes(item?.id)}
              disabled={
                disabledFields?.includes(item?.id) ||
                fieldLevelDisabled({
                  isSponsorRecord: sponsorName?.length > 0,
                  fieldName: 'dynamicFields',
                  editableFields,
                })
              }
            />
          </div>
          {item?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.TEXT &&
            renderTextCondition(item?.id)}
          {item?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.NUMBER &&
            renderNumberCondition(item?.id)}
          {item?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.BOOLEAN &&
            renderBooleanCondition(item)}
          {item?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.PICK_LIST &&
            renderListCondition(item)}
          {item?.fieldType === DYNAMIC_FIELDS_DATA_KEYS?.ENUM_PICK_LIST &&
            renderEnumListCondition(item)}
          <Divider className="global-divider" />
        </div>
      ))}
    </div>
  );
};

export default ProductTab;
