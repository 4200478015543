import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import {
  PERMISSION_QUESTIONNAIRE,
  QUESTIONNAIRE_TYPE,
  ROUTES,
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './myQuestionnaireModule.less';
import DynamicRouteWrapper from './pages/DynamicRouteWrapper';
import QuestionnaireList from './pages/QuestionnaireList';
import SecondaryQuestionnaireList from './pages/SecondaryQuestionnaireList';

const MyQuestionnaireWrapper = () => (
  <div className="questionnaire-module">
    <Routes>
      <Route
        path={ROUTES?.BUILDER}
        element={
          <AccessControl
            allowedPermissions={[...PERMISSION_QUESTIONNAIRE]}
            showNoAccess
          >
            <QuestionnaireList />
          </AccessControl>
        }
      />
      <Route
        path={ROUTES?.PATH}
        element={
          <AccessControl
            allowedPermissions={[...PERMISSION_QUESTIONNAIRE]}
            showNoAccess
          >
            <SecondaryQuestionnaireList />
          </AccessControl>
        }
      />
      <Route
        path={`${ROUTES?.BUILDER}/:id${ROUTES?.PAGE}/*`}
        element={
          <AccessControl
            allowedPermissions={[
              'FET_QUESTIONNAIRE_UPDATE',
              'FET_QUESTIONNAIRE_VIEW',
            ]}
            showNoAccess
          >
            <DynamicRouteWrapper type={QUESTIONNAIRE_TYPE?.ROQ_BUILDER} />
          </AccessControl>
        }
      />
      <Route
        path={`${ROUTES?.PATH}/:id${ROUTES?.PAGE}/*`}
        element={
          <AccessControl
            allowedPermissions={[
              'FET_QUESTIONNAIRE_UPDATE',
              'FET_QUESTIONNAIRE_VIEW',
            ]}
            showNoAccess
          >
            <DynamicRouteWrapper type={QUESTIONNAIRE_TYPE?.ROQ_PATH} />
          </AccessControl>
        }
      />
      <Route
        path={ROUTES?.MAIN}
        element={<Navigate to={ROUTES?.QUESTIONNAIRES_PRIMARY} replace />}
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default MyQuestionnaireWrapper;
