import Icon from '@ant-design/icons';
import React from 'react';

const copyIcon = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 12 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.83317 0.166504H10.6665C11.3387 0.166504 11.8332 0.661005 11.8332 1.33317V7.1665C11.8332 7.83867 11.3387 8.33317 10.6665 8.33317H8.33317V10.6665C8.33317 11.3387 7.83867 11.8332 7.1665 11.8332H1.33317C0.661005 11.8332 0.166504 11.3387 0.166504 10.6665V4.83317C0.166504 4.161 0.661005 3.6665 1.33317 3.6665H3.6665V1.33317C3.6665 0.661005 4.161 0.166504 4.83317 0.166504ZM3.6665 4.83317H1.33317V10.6665H7.1665V8.33317H4.83317C4.161 8.33317 3.6665 7.83867 3.6665 7.1665V4.83317ZM4.83317 1.33317V7.1665H10.6665V1.33317H4.83317Z"
        fill="currentColor"
      />
    </svg>
  );

const CopyIconComponent = (props) => <Icon component={copyIcon} {...props} />;

export default CopyIconComponent;
