import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_DASHBOARD, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './dashboard.less';
import DashboardPage from './pages/DashboardPage';

const DashboardWrapper = () => (
  <div className="dashboard-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl allowedPermissions={PERMISSION_DASHBOARD} showNoAccess>
            <DashboardPage />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default DashboardWrapper;
