import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { Button, Col, Form, Radio, Row, Switch } from 'antd';
import { some } from 'lodash';
import React, { useContext, useState } from 'react';

import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../../../../AppContext';
import { uploadImageNormalize } from '../../../../../../../../common/utils';
import DraggerUploadComponent from '../../../../../../../../components/DraggerUploadComponent';
import InputComponent from '../../../../../../../../components/InputComponent';

const Draggable = ({
  remove,
  onRadioChange,
  lastCheckedIndex,
  isSponsorQuestionnaire,
  ...item
}) => {
  const {
    state: { isSponsor },
  } = useContext(AppContext);
  const location = useLocation();
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: item?.name + 1,
    strategy: rectSortingStrategy,
  });

  const style = transform
    ? {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS?.Translate?.toString(transform),
        transition,
      }
    : undefined;

  const handlers = {
    ...attributes,
    ...listeners,
  };

  return (
    <Row
      className="mt-10 dragging-class"
      align="middle"
      justify="space-between"
      key={item?.id}
      ref={setNodeRef}
      style={style}
      gutter={4}
    >
      {!isSponsorQuestionnaire ||
        (((isSponsorQuestionnaire &&
          location?.pathname?.includes('/create') &&
          !isSponsor) ||
          (isSponsorQuestionnaire && isSponsor)) && (
          <Col span={1}>
            <MenuOutlined {...handlers} />
          </Col>
        ))}
      <Col span={8}>
        <Form.Item
          {...item}
          name={[item?.name, 'image']}
          className="mb-0"
          normalize={uploadImageNormalize}
          valuePropName="fileList"
        >
          <DraggerUploadComponent className="common-upload">
            <p className="icon-header">
              <DownloadOutlined />
            </p>
            <span className="upload-title">
              Drag image or browse your files
            </span>
          </DraggerUploadComponent>
        </Form.Item>
      </Col>
      <Col span={10}>
        <Form.Item
          {...item}
          name={[item?.name, 'default']}
          className="mb-0"
          valuePropName="checked"
        >
          <Radio
            onChange={() => onRadioChange(item?.name)}
            checked={item?.name === lastCheckedIndex}
            className="common-radio"
          >
            Default
          </Radio>
        </Form.Item>
        <Form.Item
          {...item}
          name={[item?.name, 'label']}
          className="mb-0"
          rules={[
            {
              required: true,
              message: 'Please Enter Label',
            },
          ]}
        >
          <InputComponent placeholder="Enter Label *" />
        </Form.Item>
        <Form.Item
          {...item}
          name={[item?.name, 'blockDescription']}
          className="mt-10 mb-0"
        >
          <InputComponent placeholder="Enter Description" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Button
          danger
          onClick={(e) => {
            e?.stopPropagation();
            remove(item?.name);
          }}
        >
          <DeleteOutlined />
        </Button>
      </Col>
    </Row>
  );
};

const CustomPickListForm = ({
  form = null,
  setFormValues,
  formValues = null,
  setDisableBtn,
  isSponsorQuestionnaire,
}) => {
  const [lastCheckedIndex, setLastCheckedIndex] = useState(-1);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

  const showTooltip = Form?.useWatch(
    ['widgetConfiguration', 'config', 'tooltip'],
    form,
  );

  const isReadonly = Form?.useWatch(
    ['widgetConfiguration', 'config', 'rules', 'readOnly'],
    form,
  );

  const radioArray = Form.useWatch(
    ['widgetConfiguration', 'config', 'listItems'],
    form,
  );

  const onRadioChange = (index) => {
    setLastCheckedIndex(index);
    const newOptionList = radioArray?.map((item, itemIndex) => {
      if (itemIndex === index) {
        return {
          ...item,
          default: true,
        };
      }
      return {
        ...item,
        default: false,
      };
    });

    form?.setFieldValue(
      ['widgetConfiguration', 'config', 'listItems'],
      newOptionList,
    );
    setFormValues({
      ...formValues,
      widgetConfiguration: {
        ...formValues?.widgetConfiguration,
        config: {
          ...formValues?.widgetConfiguration?.config,
          listItems: newOptionList,
        },
      },
    });
    setDisableBtn(false);
  };

  const checkListItems = (rule, value) => {
    if (!value || value?.length === 0) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise?.reject(new Error('At least one option is required'));
    }
    const hasDefaultSelect = some(value, (item) => item?.default);

    if (isReadonly && !hasDefaultSelect) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise?.reject(
        new Error(
          'One option should be default select as it is read only field',
        ),
      );
    }

    return Promise?.resolve();
  };

  const handleDragEnd = ({ over, active }) => {
    if (!over) return;

    const source = active?.id - 1;
    const destination = over?.id - 1;
    if (source !== destination) {
      const optionsCopy = [
        ...arrayMove(
          form?.getFieldValue(['widgetConfiguration', 'config', 'listItems']),
          source,
          destination,
        ),
      ];

      form?.setFieldValue(
        ['widgetConfiguration', 'config', 'listItems'],
        optionsCopy,
      );

      setFormValues({
        ...formValues,
        widgetConfiguration: {
          ...formValues?.widgetConfiguration,
          config: {
            ...formValues?.widgetConfiguration?.config,
            listItems: [...optionsCopy],
          },
        },
      });
      setDisableBtn(false);
    }
  };

  return (
    <>
      <div>
        <Form.Item
          name={['widgetConfiguration', 'config', 'label']}
          label="Label"
          rules={[
            {
              required: true,
              message: 'Please Enter Label',
            },
          ]}
        >
          <InputComponent name="label" />
        </Form.Item>
        <div className="d-flex justify-between align-center">
          <span className="switch-logo">Tooltip</span>
          <Form.Item
            name={['widgetConfiguration', 'config', 'tooltip']}
            className="mb-0"
            valuePropName="checked"
          >
            <Switch
              className="common-switch"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </div>
        <Form.Item
          name={['widgetConfiguration', 'config', 'tooltipValue']}
          hidden={!showTooltip}
          rules={[
            {
              required: showTooltip,
              message: 'Please Enter Tooltip Value',
            },
          ]}
        >
          <InputComponent placeholder="Enter Tooltip Value" />
        </Form.Item>
        <div className="d-flex justify-between align-center">
          <span className="switch-logo font-500">Description</span>
          <Form.Item
            name={['widgetConfiguration', 'config', 'optionsDescription']}
            className="mb-0"
            valuePropName="checked"
          >
            <Switch
              className="common-switch"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        </div>
      </div>
      <Form.List
        name={['widgetConfiguration', 'config', 'listItems']}
        rules={[{ validator: checkListItems }]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            <div className="listview form-table nested-table">
              <DndContext
                sensors={sensors}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
              >
                <SortableContext
                  strategy={verticalListSortingStrategy}
                  items={fields?.map(({ name }) => name + 1)}
                >
                  {fields?.map((field) => (
                    <div key={field?.name + 1}>
                      <Draggable
                        {...field}
                        lastCheckedIndex={lastCheckedIndex}
                        isSponsorQuestionnaire={isSponsorQuestionnaire}
                        onRadioChange={onRadioChange}
                        remove={remove}
                      />
                    </div>
                  ))}
                </SortableContext>
              </DndContext>
            </div>
            <Button
              type="primary"
              className="fill-width mt-10"
              onClick={() => add({ default: false })}
            >
              Add Option
            </Button>
            <Form.ErrorList errors={errors} />
          </>
        )}
      </Form.List>
    </>
  );
};

export default CustomPickListForm;
