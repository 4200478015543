import { QuestionCircleOutlined } from '@ant-design/icons';
import { Checkbox, Col, Divider, Form, Popover, Radio, Row } from 'antd';
import { map } from 'lodash';
import React from 'react';
import {
  DYNAMIC_UPLOAD_FILE_TYPES,
  DYNAMIC_UPLOAD_TYPE_AUDIO,
  DYNAMIC_UPLOAD_TYPE_DOCS,
  DYNAMIC_UPLOAD_TYPE_IMAGES,
  DYNAMIC_UPLOAD_TYPE_VIDEO,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import NumberComponent from '../../../components/NumberComponent';

const { number } = formValidatorRules;

const FileUploadFormData = ({
  position = 'top',
  isDisabled = false,
  checkedList,
  setCheckedList,
  validationTriggered = false,
  form,
}) => {
  const onChangeCheckbox = (list) => {
    setCheckedList(list);
  };

  const handleChangeFileType = () => {
    setCheckedList([]);
  };

  return (
    <div className="upload-form-data">
      {position === 'bottom' && (
        <>
          <span className="form-divider-text">FIELD SETTINGS</span>
          <Divider className="form-divider" />
          <Form.Item name="fileType">
            <Radio.Group
              onChange={handleChangeFileType}
              className="common-radio fill-width"
              disabled={isDisabled}
            >
              <Row>
                {map(DYNAMIC_UPLOAD_FILE_TYPES, (item) => (
                  <Col
                    key={item?.value}
                    xs={4}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={2}
                    xxl={2}
                  >
                    <Radio value={item?.value}>{item?.label}</Radio>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
          <Row gutter={16}>
            <Col xs={4} sm={4} md={4} lg={4} xl={2} xxl={2}>
              <Checkbox.Group
                className="common-checkbox"
                options={DYNAMIC_UPLOAD_TYPE_DOCS}
                value={checkedList}
                onChange={(list) => onChangeCheckbox(list)}
                disabled={
                  isDisabled || form?.getFieldValue('fileType') !== 'DOCS'
                }
              />
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={2} xxl={2}>
              <Checkbox.Group
                className="common-checkbox"
                options={DYNAMIC_UPLOAD_TYPE_IMAGES}
                value={checkedList}
                onChange={(list) => onChangeCheckbox(list)}
                disabled={
                  isDisabled || form?.getFieldValue('fileType') !== 'IMAGE'
                }
              />
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={2} xxl={2}>
              <Checkbox.Group
                className="common-checkbox"
                options={DYNAMIC_UPLOAD_TYPE_AUDIO}
                value={checkedList}
                onChange={(list) => onChangeCheckbox(list)}
                disabled={
                  isDisabled || form?.getFieldValue('fileType') !== 'AUDIO'
                }
              />
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} xl={2} xxl={2}>
              <Checkbox.Group
                className="common-checkbox"
                options={DYNAMIC_UPLOAD_TYPE_VIDEO}
                value={checkedList}
                onChange={(list) => onChangeCheckbox(list)}
                disabled={
                  isDisabled || form?.getFieldValue('fileType') !== 'VIDEO'
                }
              />
            </Col>
          </Row>
          {validationTriggered && !checkedList?.length && (
            <span className="upload-error">
              Please select at least one format
            </span>
          )}
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name="limitFileSize"
                className="mb-0"
                valuePropName="checked"
              >
                <Checkbox disabled={isDisabled} className="common-checkbox">
                  Limit File Size
                  <Popover
                    className="pointer"
                    content="Limit the file upload size, maximum file is 50MB."
                  >
                    <QuestionCircleOutlined className="ml-4" />
                  </Popover>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {form?.getFieldValue('limitFileSize') && (
            <>
              <Row gutter={16} align="middle">
                <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                  <Form.Item
                    rules={[
                      number,
                      {
                        required: true,
                        type: 'number',
                        min: 1,
                        message: 'Please Enter File Size',
                      },
                      {
                        max: 50,
                        type: 'number',
                        message: 'Please Enter Max 50MB File Size',
                      },
                    ]}
                    normalize={(value) => Number(value)}
                    name="size"
                    className="mb-0"
                  >
                    <NumberComponent
                      min={1}
                      max={50}
                      disabled={isDisabled}
                      allowClear={false}
                      placeholder="Enter File Size"
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <span className="primary-color font-500">MB</span>
                </Col>
              </Row>
            </>
          )}
          <span className="warning-text">
            <QuestionCircleOutlined /> Contact customer service if more than
            50MB file size is needed.
          </span>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="allowMultiple"
                className="mb-0"
                valuePropName="checked"
              >
                <Checkbox disabled={isDisabled} className="common-checkbox">
                  Allow Multi-Upload
                  <Popover
                    className="pointer"
                    content="When enabled, this field permits users to upload multiple files at once."
                  >
                    <QuestionCircleOutlined className="ml-4" />
                  </Popover>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {form?.getFieldValue('allowMultiple') && (
            <>
              <Row gutter={16}>
                <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
                  <Form.Item
                    label="Max. Number of Files Uploaded"
                    name="maxFile"
                    className="mb-0"
                    normalize={(value) => Number(value)}
                    rules={[
                      number,
                      {
                        required: true,
                        type: 'number',
                        min: 1,
                        message: 'Please Enter Max. Number of Files Uploaded',
                      },
                      {
                        max: 10,
                        type: 'number',
                        message: 'Please Enter Max 10 Number of Files Uploaded',
                      },
                    ]}
                  >
                    <NumberComponent
                      min={1}
                      max={10}
                      disabled={isDisabled}
                      allowClear={false}
                      placeholder="Enter Max. Number of Files Uploaded"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <span className="warning-text">
                <QuestionCircleOutlined /> Contact customer service if more than
                10 files are needed.
              </span>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default FileUploadFormData;
