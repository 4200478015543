import { gql } from '@apollo/client';

export const ADD_ADMINISTRATOR_INFO = gql`
  mutation addAdministratorInfo($data: UserInput!) {
    webRegistration(data: $data) {
      authToken
      user {
        id
        firstName
        lastName
        roles
        email
        phoneNo
        profileImage {
          url
          key
          name
          contentType
          extension
        }
        tenantId
        emailVerified
      }
    }
  }
`;

export const CREATE_TENANT = gql`
  mutation addTenant($data: TenantInputForCreation!) {
    createTenant(data: $data) {
      status
      data {
        id
        name
        userId
        email
        phoneNo
        isActive
        colourPallette
        createdAt
        updatedAt
      }
    }
  }
`;

export const CREATE_TENANT_SUBSCRIPTION = gql`
  mutation createTenantSubscription($id: ID!) {
    createTenantSubscription(data: { subscriptionPlanId: $id }) {
      status
      data {
        id
      }
      stripeId
    }
  }
`;

export const CREATE_SPONSOR_SUBSCRIPTION = gql`
  mutation createSponsorSubscription($id: ID!) {
    createSponsorSubscription(data: { subscriptionPlanId: $id }) {
      status
      data {
        id
      }
      stripeId
    }
  }
`;
