import { Form } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { DEFAULT_STAIRS_DEPTH_OPTIONS } from '../../../../../../common/constants';
import SelectComponent from '../../../../../../components/SelectComponent';
import InputComponent from '../../../previewComponents/globalComponents/InputComponent';

const { Option } = SelectComponent;

const ManualEntryForm = ({ manualEntry = null }) => (
    <>
      <Form.Item
        name="area"
        className={manualEntry?.areaToolTipCheck ? 'hasDescription' : ''}
      >
        <InputComponent
          className="cpq-common-input"
          readOnly
          placeholder={manualEntry?.areaText}
          suffix="sq/ft"
        />
      </Form.Item>
      {manualEntry?.areaToolTipCheck && (
        <div className="input-description">
          <span>{manualEntry?.areaToolTipText}</span>
        </div>
      )}
      <Form.Item
        name="depth"
        className={manualEntry?.depthToolTipCheck ? 'hasDescription' : ''}
      >
        <SelectComponent
          placeholder={manualEntry?.depthText}
          className="stairs-select"
          allowClear
          disabled
        >
          {map(DEFAULT_STAIRS_DEPTH_OPTIONS, (item) => (
              <Option key={item?.value} value={item?.value}>
                {item?.label}
              </Option>
            ))}
        </SelectComponent>
      </Form.Item>
      {manualEntry?.depthToolTipCheck && (
        <div className="input-description">
          <span>{manualEntry?.depthToolTipText}</span>
        </div>
      )}
      <Form.Item
        name="volume"
        className={manualEntry?.volumeToolTipCheck ? 'hasDescription' : ''}
      >
        <InputComponent
          className="cpq-common-input"
          readOnly
          placeholder={manualEntry?.volumeText}
          suffix="cu/yds"
        />
      </Form.Item>
      {manualEntry?.volumeToolTipCheck && (
        <div className="input-description">
          <span>{manualEntry?.volumeToolTipText}</span>
        </div>
      )}

      {manualEntry?.descriptionCheck && (
        <div className="input-description mt-10">
          <span>{manualEntry?.descriptionText}</span>
        </div>
      )}
    </>
  );

export default ManualEntryForm;
