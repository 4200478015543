import Icon from '@ant-design/icons';
import React from 'react';

const plusIcon = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 9H19C19.5523 9 20 9.44771 20 10C20 10.5523 19.5523 11 19 11H11V19C11 19.5523 10.5523 20 10 20C9.44771 20 9 19.5523 9 19V11H1C0.447715 11 0 10.5523 0 10C0 9.44771 0.447715 9 1 9H9V1C9 0.447715 9.44771 0 10 0C10.5523 0 11 0.447715 11 1V9Z"
        fill="currentColor"
      />
    </svg>
  );

const PlusIconComponent = (props) => <Icon component={plusIcon} {...props} />;

export default PlusIconComponent;
