import { Line } from '@ant-design/plots';
import { map } from 'lodash';
import React, { memo, useContext } from 'react';
import { AppContext } from '../AppContext';

const LineChartComponent = memo((props) => {
  const {
    state: { dashboardConfig },
  } = useContext(AppContext);
  const {
    configData = null,
    chartData = [],
    colors = [],
    xField = 'timePeriod',
    yField = 'count',
    seriesField = 'statsTitle',
    loading = false,
  } = props;

  const config = {
    data: map(chartData, (item) => ({
        ...item,
        statsTitle: `${item?.questionnaire}-${item?.statsTitle}`,
      })),
    xField,
    yField,
    seriesField,
    appendPadding: [20, 0, 0, 0],
    meta: {
      timePeriod: {
        type: 'timeCat',
        mask:
          dashboardConfig?.timePeriodValue === 'PREVYEAR'
            ? 'MMMM'
            : 'MM-DD-YYYY',
      },
    },
    legend: {
      position: 'top-left',
      layout: 'horizontal',
      itemWidth: 220,
      maxRow: 3,
    },
    rawFields: ['questionnaire', 'statsTitle'],
    theme: {
      colors10: colors,
      colors20: colors,
      styleSheet: {
        fontFamily: 'Poppins',
      },
    },
    smooth: true,
    point: {
      size: 5,
      shape: 'circle',
    },
    ...configData,
  };

  return <Line {...config} loading={loading} />;
});

export default LineChartComponent;
