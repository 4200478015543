import { Card } from 'antd';
import React from 'react';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import ProductRankingCollapseListing from '../components/ProductRankingCollapseListing';

const ProductRankingPage = () => (
  <Card className="full-height-card card-body-padding">
    <Portal portalId="header-left-content">
      <div className="d-flex align-center">
        <span className="portal-header">
          {MODULES?.QUESTIONNAIRE_RESULT_VALIDATION}
        </span>
      </div>
    </Portal>
    <ProductRankingCollapseListing />
  </Card>
);

export default ProductRankingPage;
