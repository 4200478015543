import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import {
  PERMISSION_HOURS_OF_OPERATION_SETTING,
  ROUTES,
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './hoursOfOperationModule.less';
import HoursOfOperationPage from './pages/HoursOfOperationPage';

const HoursOfOperationWrapper = () => (
  <div className="hours-of-operation-wrapper">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_HOURS_OF_OPERATION_SETTING}
            showNoAccess
          >
            <HoursOfOperationPage />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default HoursOfOperationWrapper;
