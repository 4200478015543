import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_PLAN_SETTING, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import PlanSettingPage from './pages/PlanSettingPage';
import './planSettingModule.less';

const PlanSettingWrapper = () => (
  <div className="plan-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_PLAN_SETTING}
            showNoAccess
          >
            <PlanSettingPage />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default PlanSettingWrapper;
