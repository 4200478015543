import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import PropertyContact from './pages/PropertyContact';
import PropertyCreate from './pages/PropertyCreate';
import PropertyCustomer from './pages/PropertyCustomer';
import PropertyEdit from './pages/PropertyEdit';
import PropertyList from './pages/PropertyList';
import PropertyView from './pages/PropertyView';
import './propertyModule.less';

const PropertyWrapper = () => (
  <div className="property-module">
    <Routes>
      <Route path={ROUTES?.MAIN} element={<PropertyList />} />
      <Route path="/add" element={<PropertyCreate />} />
      <Route path="/edit/:id" element={<PropertyEdit />} />
      <Route path="/view/:id" element={<PropertyView />} />
      <Route
        path={`/view${ROUTES?.CUSTOMERS}/:id`}
        element={<PropertyCustomer />}
      />
      <Route
        path={`/view${ROUTES?.CONTACTS}/:id`}
        element={<PropertyContact />}
      />
      <Route
        path={`/edit${ROUTES?.CUSTOMERS}/:id`}
        element={<PropertyCustomer />}
      />
      <Route
        path={`/edit${ROUTES?.CONTACTS}/:id`}
        element={<PropertyContact />}
      />
    </Routes>
  </div>
);

export default PropertyWrapper;
