import { Card } from 'antd';
import React from 'react';
import PricingModifierForm from '../components/PricingModifierForm';

function PricingModifierEdit() {
  return (
    <Card className="full-height-card card-body-padding">
      <PricingModifierForm isEdit />
    </Card>
  );
}

export default PricingModifierEdit;
