import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_INDUSTRY, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import LineOfBusinessEdit from '../lineOfBusinesses/pages/LineOfBusinessEdit';
import LineOfBusinessView from '../lineOfBusinesses/pages/LineOfBusinessView';
import SubAreaEdit from '../lineOfBusinesses/pages/SubAreaEdit';
import SubAreaView from '../lineOfBusinesses/pages/SubAreaView';
import './industry.less';
import IndustryEdit from './pages/IndustryEdit';
import IndustryList from './pages/IndustryList';
import IndustryView from './pages/IndustryView';

const IndustryWrapper = () => (
  <div className="industry-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl allowedPermissions={PERMISSION_INDUSTRY} showNoAccess>
            <IndustryList />
          </AccessControl>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_INDUSTRY_UPDATE']}
            showNoAccess
          >
            <IndustryEdit />
          </AccessControl>
        }
      />
      <Route
        path="/view/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_INDUSTRY_VIEW']}
            showNoAccess
          >
            <IndustryView />
          </AccessControl>
        }
      />
      <Route
        path="/edit/:id/edit/:lobId/edit/:subId"
        element={
          <AccessControl
            allowedPermissions={['FET_INDUSTRY_UPDATE']}
            showNoAccess
          >
            <SubAreaEdit />
          </AccessControl>
        }
      />
      <Route
        path="/view/:id/view/:lobId/view/:subId"
        element={
          <AccessControl
            allowedPermissions={['FET_INDUSTRY_VIEW']}
            showNoAccess
          >
            <SubAreaView />
          </AccessControl>
        }
      />
      <Route
        path="/edit/:id/edit/:lobId"
        element={
          <AccessControl
            allowedPermissions={['FET_INDUSTRY_UPDATE']}
            showNoAccess
          >
            <LineOfBusinessEdit />
          </AccessControl>
        }
      />
      <Route
        path="/view/:id/view/:lobId"
        element={
          <AccessControl
            allowedPermissions={['FET_INDUSTRY_VIEW']}
            showNoAccess
          >
            <LineOfBusinessView />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default IndustryWrapper;
