import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PERMISSION_CAMPAIGNS, ROUTES } from '../../common/constants';

import Error404 from '../../Error404';
import AccessControl from '../../components/AccessControl';
import CampaignCreate from './pages/CampaignCreate';
import CampaignEdit from './pages/CampaignEdit';
import CampaignList from './pages/CampaignList';

const CampaignViewerWrapper = () => (
  <div className="quote-viewer-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl allowedPermissions={PERMISSION_CAMPAIGNS} showNoAccess>
            <CampaignList />
          </AccessControl>
        }
      />
      <Route
        path="/add"
        element={
          <AccessControl
            allowedPermissions={['FET_CAMPAIGN_CREATE']}
            showNoAccess
          >
            <CampaignCreate />
          </AccessControl>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_CAMPAIGN_UPDATE', 'FET_CAMPAIGN_VIEW']}
            showNoAccess
          >
            <CampaignEdit />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default CampaignViewerWrapper;
