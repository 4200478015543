import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PERMISSION_QUOTE, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import Error404 from '../../Error404';
import QuoteListing from './pages/QuoteList';
import QuoteView from './pages/QuoteView';

const QuoteWrapper = () => (
  <div className="quote-viewer-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl allowedPermissions={PERMISSION_QUOTE} showNoAccess>
            <QuoteListing />
          </AccessControl>
        }
      />
      <Route
        path="/view/:id"
        element={
          <AccessControl allowedPermissions={['FET_QUOTE_VIEW']} showNoAccess>
            <QuoteView />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default QuoteWrapper;
