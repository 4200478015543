import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_ROLE, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import RoleCreate from './pages/RoleCreate';
import RoleEdit from './pages/RoleEdit';
import RoleList from './pages/RoleList';

const RoleWrapper = () => (
  <div className="role-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl allowedPermissions={PERMISSION_ROLE} showNoAccess>
            <RoleList />
          </AccessControl>
        }
      />
      <Route
        path="/add"
        element={
          <AccessControl allowedPermissions={['FET_ROLE_CREATE']} showNoAccess>
            <RoleCreate />
          </AccessControl>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_ROLE_UPDATE', 'FET_ROLE_VIEW']}
            showNoAccess
          >
            <RoleEdit />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default RoleWrapper;
