import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React from 'react';
import EditorComponent from '../../../../../../../../components/EditorComponent';
import InputComponent from '../../../../../../../../components/InputComponent';

const SystemTextPreviewForm = ({ systemFieldData }) => {
  const label = systemFieldData?.config?.label || systemFieldData?.label || ' ';
  const required =
    systemFieldData?.config?.required || systemFieldData?.required;
  const toolTipChecked =
    systemFieldData?.config?.toolTipCheck || systemFieldData?.toolTipCheck;
  const toolTipValue =
    systemFieldData?.config?.toolTipText || systemFieldData?.toolTipText;

  const textType =
    systemFieldData?.config?.fieldConfig?.TEXT?.textType ||
    systemFieldData?.fieldConfig?.TEXT?.textType;

  const renderText = () => {
    if (textType === 'MULTI_LINE') {
      return (
        <InputComponent.TextArea
          readOnly
          rows={3}
          className="common-textarea mb-16"
          placeholder={`Enter your ${label}`}
        />
      );
    }
    if (textType === 'RICH_TEXT') {
      return <EditorComponent readOnly placeholder={`Enter your ${label}`} />;
    }
    return (
      <InputComponent
        readOnly
        name="Value"
        placeholder={`Enter your ${label}`}
      />
    );
  };

  return (
    <div className="mt-37">
      <Form layout="vertical">
        <Form.Item
          name="label"
          label={label}
          tooltip={
            toolTipChecked && {
              title: toolTipValue,
              icon: <InfoCircleOutlined />,
            }
          }
          rules={[{ required, message: 'Please Enter Value' }]}
          className={`${!required && 'hide-required-mark'} ${
            toolTipChecked && 'label-with-tooltip'
          } label-text-break`}
        >
          {renderText()}
        </Form.Item>
      </Form>
    </div>
  );
};

export default SystemTextPreviewForm;
