import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import { flatMap, map, parseInt } from 'lodash';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import { formatPhoneNumberWithoutMask } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import ProspectForm from '../components/ProspectForm';
import { CREATE_PROSPECT } from '../graphql/Mutations';

function ProspectCreate() {
  const navigate = useNavigate();
  const location = useLocation();
  const [submitLoading, setSubmitLoading] = useState(false);
  const [createProspectMutate] = useMutation(CREATE_PROSPECT, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const handleSubmit = async (formValues, checkBoxArray) => {
    setSubmitLoading(true);
    let address;
    if (
      formValues?.addressLine1 ||
      formValues?.city ||
      formValues?.state ||
      formValues?.country ||
      formValues?.zipCode
    ) {
      address = {
        addressLine1: formValues?.addressLine1 || null,
        city: formValues?.city || null,
        state: formValues?.state || null,
        country: formValues?.country || null,
        zipCode: formValues?.zipCode || null,
      };
    }

    const newFormValues = {
      ...formValues,
      phoneNo: formatPhoneNumberWithoutMask(formValues?.phoneNo),
      address,
      locations: parseInt(formValues?.locations),
      noOfEmployee: parseInt(formValues?.noOfEmployee),
      industryIds: map(checkBoxArray, 'id'),
      lineOfBusinessIds: flatMap(checkBoxArray, 'checkedChildren'),
    };
    delete newFormValues?.addressLine1;
    delete newFormValues?.city;
    delete newFormValues?.state;
    delete newFormValues?.country;
    delete newFormValues?.zipCode;

    const variables = newFormValues;

    try {
      const response = await createProspectMutate({
        variables: { data: { ...variables } },
      });
      if (response?.data?.createProspect) {
        navigate(ROUTES?.PROSPECTS, { state: { ...location?.state } });
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    country: 'USA',
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PROSPECTS} />
          <span className="portal-header">Add Prospect</span>
        </div>
      </Portal>
      <ProspectForm
        prospectsData={initialValues}
        handleSubmit={handleSubmit}
        isSubmit={submitLoading}
      />
    </Card>
  );
}

export default ProspectCreate;
