import { DownloadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { messageContext } from '../../../app/components/AppContextHolder';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import SaveIcon from '../../../assets/save.svg';
import { ALLOW_IMAGE_TYPES, ROUTES } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DraggerUploadComponent from '../../../components/DraggerUploadComponent';
import EditorComponent from '../../../components/EditorComponent';
import InputComponent from '../../../components/InputComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';

const { required, requiredWhiteSpaceAllowed } = formValidatorRules;

const { Option } = SelectComponent;

const SubAreaForm = (props) => {
  const {
    state: { permissions },
    dispatch,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();

  const { lobId, id: industryId } = useParams();
  const { subAreaData, handleSubmit, isSubmit, isEdit = false } = props;
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [fileList, setFileList] = useState([]);

  const [form] = Form?.useForm();

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const onChangeUpload = (info) => {
    const {
      file: { name = '', url },
    } = info;
    if (url) {
      setFileList([]);
      form?.setFieldsValue({
        logo: null,
      });
      return;
    }
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (ALLOW_IMAGE_TYPES?.includes(ext) && !url) {
      if (info?.file?.status === 'removed') {
        form?.setFieldsValue({
          logo: null,
        });
      } else {
        form?.setFieldsValue({
          logo: info?.file,
        });
      }
      setFileList([...info?.fileList]);
    } else {
      setFileList([]);
      form?.setFieldsValue({
        logo: null,
      });
      messageContext?.destroy();
      messageContext?.error(`${info?.file?.name} file is not image file.`);
    }
  };

  useEffect(() => {
    if (subAreaData?.logo?.url && !fileList?.length) {
      setFileList([subAreaData?.logo]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unListen = history?.listen((historyProps) => {
      if (historyProps?.action === 'POP') {
        navigate(historyProps?.location?.pathname, {
          state: { ...location?.state },
        });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  return (
    <div className="content-section">
      <AccessControl
        allowedPermissions={['FET_INDUSTRY_UPDATE', 'FET_INDUSTRY_VIEW']}
      >
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="subArea-table-discard-btn"
            onClick={() =>
              navigate(`${ROUTES?.INDUSTRIES}/edit/${industryId}/edit/${lobId}`)
            }
          >
            Discard Changes
          </Button>
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="subarea-table-save-btn"
            loading={isSubmit}
            type="primary"
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={subAreaData}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values);
        }}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <fieldset
          disabled={
            !checkPermissions(
              permissions,
              isEdit ? ['FET_INDUSTRY_UPDATE'] : ['FET_INDUSTRY_CREATE'],
            )
          }
        >
          <Row gutter={20} className="mb-10">
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Label' }]}
                name="label"
                label="Service Type"
              >
                <InputComponent name="label" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="alias"
                label="Alias"
                className="tooltip-label"
                tooltip={{
                  title: (
                    <span className="fill-width">
                      This will be alias for label
                    </span>
                  ),
                  overlayClassName: 'alias-popover',
                  icon: <QuestionCircleOutlined />,
                }}
              >
                <InputComponent name="alias" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="isActive"
                label="Status"
                rules={[
                  {
                    ...requiredWhiteSpaceAllowed,
                    message: 'Please Select Status',
                  },
                ]}
              >
                <SelectComponent placeholder="Select Status" allowClear>
                  <Option key="active" value>
                    Active
                  </Option>
                  <Option key="inactive" value={false}>
                    InActive
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="referenceId"
                label="Reference ID"
                className="text-form-item"
              >
                <p>{subAreaData?.referenceId}</p>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="industry"
                label="Industry"
                className="text-form-item"
              >
                <p>{subAreaData?.industry?.label}</p>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="industry"
                label="Line of Business"
                className="text-form-item"
              >
                <p>{subAreaData?.lineOfBusiness?.label}</p>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="logo"
                label="Icon"
                rules={[
                  { required, message: 'Please Upload Icon', type: 'object' },
                ]}
              >
                <DraggerUploadComponent
                  showUploadList={{
                    showDownloadIcon: false,
                    showPreviewIcon: true,
                  }}
                  fileList={fileList}
                  onChange={onChangeUpload}
                >
                  <p className="icon-header">
                    <DownloadOutlined />
                  </p>
                  <span className="upload-title">
                    Drag image or browse your files
                  </span>{' '}
                  <br />
                  <span className="upload-description">
                    JPG, PNG, SVG, JPEG
                  </span>
                </DraggerUploadComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={24} lg={24} xl={18} xxl={18}>
              <Form.Item name="description" label="Description">
                <EditorComponent placeholder="Enter Description" />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default SubAreaForm;
