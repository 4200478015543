import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Empty,
  Form,
  List,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Switch,
  Tag,
} from 'antd';
import { isEmpty, map, startCase, unionBy } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { messageContext } from '../../../app/components/AppContextHolder';
import ShowMoreIconComponent from '../../../app/components/iconComponents/ShowMoreIconComponent';
import { AppContext } from '../../../AppContext';
import deleteIcon from '../../../assets/delete-red.svg';
import {
  CONDITIONS,
  CONDITIONS_KEYS,
  ENTITY_FIELDS_DATA_KEYS,
  ENTITY_FIELDS_WRAPPER,
  MODAL_WIDTH,
  NUMERIC_CONDITIONS,
} from '../../../common/constants';
import {
  checkPermissions,
  dateFormatWithoutTime,
  formValidatorRules,
  getEntityFieldName,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DatePickerComponent from '../../../components/DatePickerComponent';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import NumberComponent from '../../../components/NumberComponent';
import SelectComponent from '../../../components/SelectComponent';
import { CREATE_BATCH_ENTITY, UPDATE_BATCH_ENTITY } from '../graphql/Mutations';
import { GET_ENTITIES, GET_ENTITY_FIELDS } from '../graphql/Queries';

const { required } = formValidatorRules;

const { Option } = SelectComponent;

const entityFieldsFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
};

const BOOLEAN_VALUES = [
  {
    label: 'TRUE',
    value: true,
  },
  {
    label: 'FALSE',
    value: false,
  },
];

export const createEntityFilterMessage = (
  entityFilter,
  globalDateFormat = 'MM-DD-YY',
) => {
  const allConditions = unionBy(NUMERIC_CONDITIONS, CONDITIONS, 'value');

  const conditionValue = allConditions?.find(
    (condObj) => condObj?.value === entityFilter?.key,
  );

  let message = null;

  if (
    entityFilter?.key === CONDITIONS_KEYS?.EQUAL ||
    entityFilter?.key === CONDITIONS_KEYS?.NOT_EQUAL ||
    entityFilter?.key === CONDITIONS_KEYS?.EQUAL_TO ||
    entityFilter?.key === CONDITIONS_KEYS?.NOT_EQUAL_TO
  ) {
    if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.DATE_TIME) {
      message = (
        <div>
          <Tag className="single-item">{conditionValue?.label}</Tag>
          {moment(entityFilter?.values)?.format(globalDateFormat)}
        </div>
      );
    } else if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.PICK_LIST) {
      const labels = entityFilter?.values?.map(
        (item) => item?.name || item?.title || item?.label,
      );
      const slicedLabels = labels?.slice(0, 10);
      message = (
        <div>
          <Tag className="single-item">{conditionValue?.label}</Tag>
          {slicedLabels?.join(', ')}
          {labels?.length > 10 && (
            <Popover
              overlayClassName="pricing-popover"
              placement="rightBottom"
              content={<span>{labels?.join(', ')}</span>}
            >
              <Tag className="single-item pointer more-shadow ml-5">
                <span className="content-text">
                  {labels?.length - 1 > 0
                    ? `${labels?.length - 1} more`
                    : 'show more'}
                  <ShowMoreIconComponent className="show-more-icon" />
                </span>
              </Tag>
            </Popover>
          )}
        </div>
      );
    } else if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.BOOLEAN) {
      message = (
        <div>
          <Tag className="single-item">{conditionValue?.label}</Tag>{' '}
          {entityFilter?.values?.toString()}
        </div>
      );
    } else {
      message = (
        <div>
          <Tag className="single-item">{conditionValue?.label}</Tag>{' '}
          {entityFilter?.values}
        </div>
      );
    }
  }
  if (
    entityFilter?.key === CONDITIONS_KEYS?.CONTAINS ||
    entityFilter?.key === CONDITIONS_KEYS?.NOT_CONTAINS
  ) {
    if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.DATE_TIME) {
      message = (
        <div>
          <Tag className="single-item">{conditionValue?.label} $</Tag>
          {moment(entityFilter?.values).format(globalDateFormat)}
        </div>
      );
    } else if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.PICK_LIST) {
      const labels = entityFilter?.values?.map((item) => item?.name);
      message = (
        <div>
          <Tag className="single-item">{conditionValue?.label}</Tag>
          {labels?.join(', ')}
        </div>
      );
    } else {
      message = (
        <div>
          <Tag className="single-item">{conditionValue?.label}</Tag>
          {entityFilter?.values?.toString()}
        </div>
      );
    }
  }
  if (conditionValue?.value === CONDITIONS_KEYS?.GREATER_LESS_RANGE) {
    let convertedGreaterThanValue;
    let convertedLessThanValue;
    if (entityFilter?.fieldType === ENTITY_FIELDS_DATA_KEYS?.DATE_TIME) {
      convertedGreaterThanValue = moment(
        entityFilter?.values?.greaterThan?.max,
      )?.format(globalDateFormat);
      convertedLessThanValue = moment(
        entityFilter?.values?.lessThan?.min,
      )?.format(globalDateFormat);
    } else {
      convertedGreaterThanValue = entityFilter?.values?.greaterThan?.max?.toLocaleString(
        'en',
        { useGrouping: false, minimumFractionDigits: 2 },
      );
      convertedLessThanValue = entityFilter?.values?.lessThan?.min?.toLocaleString(
        'en',
        {
          useGrouping: false,
          minimumFractionDigits: 2,
        },
      );
    }

    if (
      entityFilter?.values?.greaterThan?.check &&
      entityFilter?.values?.lessThan?.check
    ) {
      message = (
        <div>
          <Tag className="single-item">in Between</Tag>{' '}
          {convertedGreaterThanValue} - {convertedLessThanValue}
        </div>
      );
    }

    if (
      entityFilter?.values?.greaterThan?.check &&
      !entityFilter?.values?.lessThan?.check
    ) {
      message = (
        <div>
          <Tag className="single-item">is Greater Than</Tag>{' '}
          {convertedGreaterThanValue}
        </div>
      );
    }
    if (
      !entityFilter?.values?.greaterThan?.check &&
      entityFilter?.values?.lessThan?.check
    ) {
      message = (
        <div>
          <Tag className="single-item">is Less Than</Tag>{' '}
          {convertedLessThanValue}
        </div>
      );
    }
  }
  return message;
};
const CreateEntityBatchModal = ({
  showModal,
  setShowModal,
  refetchEntityFieldsData,
  initialValues = null,
  setInitialValues,
  isEdit = false,
  setIsEdit,
  lobId = null,
}) => {
  const [form] = Form?.useForm();
  const { id } = useParams();

  const {
    state: { globalDateFormat, permissions },
  } = useContext(AppContext);

  const [entitiesLoading, setEntitiesLoading] = useState(true);
  const [entitiesData, setEntitiesData] = useState([]);

  const [selectedEntityField, setSelectedEntityField] = useState(null);
  const [entityFieldsLoading, setEntityFieldsLoading] = useState(false);
  const [entityFieldsData, setEntityFieldsData] = useState([]);
  const [entityFieldType, setEntityFieldType] = useState(null);
  const [selectedListOptions, setSelectedListOptions] = useState([]);
  const [optionsData, setOptionsData] = useState([]);

  const [entityFilters, setEntityFilters] = useState([]);
  const [isShowError, setIsShowError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [disableBtn, setDisableBtn] = useState(true);

  const conditionValue = Form?.useWatch('key', form);
  const isLessThanEnabled = Form?.useWatch('lessThanCheck', form);
  const isGreaterThanEnabled = Form?.useWatch('greaterThanCheck', form);

  const [getEntities] = useLazyQuery(GET_ENTITIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setEntitiesData([...res?.entities?.data]);
      setEntitiesLoading(false);
    },
    onError() {
      setEntitiesLoading(false);
    },
  });

  const [getEntityFields] = useLazyQuery(GET_ENTITY_FIELDS, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setEntityFieldsData([...res?.entityFields?.data]);
      setEntityFieldsLoading(false);
    },
    onError() {
      setEntityFieldsLoading(false);
    },
  });

  const [createBatchEntity] = useMutation(CREATE_BATCH_ENTITY, {
    onCompleted: () => {
      setLoading(false);
      setShowModal(false);
      refetchEntityFieldsData();
      setInitialValues(null);
    },
    onError() {
      setLoading(false);
      setInitialValues(null);
    },
  });

  const [updateBatchEntity] = useMutation(UPDATE_BATCH_ENTITY, {
    onCompleted: () => {
      setLoading(false);
      setShowModal(false);
      refetchEntityFieldsData();
      setInitialValues(null);
      setIsEdit(false);
    },
    onError() {
      setShowModal(false);
      setLoading(false);
      setInitialValues(null);
      setIsEdit(false);
    },
  });

  useEffect(() => {
    getEntities({
      variables: {
        filter: {
          sortOn: 'name',
          sortBy: 'ASC',
        },
      },
    });
  }, []);

  useEffect(() => {
    if (isEdit) {
      setEntityFilters([...initialValues?.conditions]);
      getEntityFields({
        variables: {
          filter: {
            ...entityFieldsFilter,
          },
          where: {
            name: [initialValues?.entityName],
            lineOfBusinessId: [lobId],
          },
        },
      });
    }
  }, [initialValues, isEdit]);

  const resetChildFields = (isCallFromEntityFieldSelect) => {
    if (!isCallFromEntityFieldSelect) {
      form?.setFieldValue('field', null);
      form?.setFieldValue('key', CONDITIONS_KEYS?.EQUAL_TO);
    }
    form?.setFieldValue('min', null);
    form?.setFieldValue('max', null);
    form?.setFieldValue('value', null);
    form?.setFieldValue('greaterThanCheck', false);
    form?.setFieldValue('less ThanCheck', null);
    setSelectedListOptions([]);
  };

  const onEntitySelect = (value) => {
    resetChildFields();
    setEntityFieldsData([]);
    setEntityFilters([]);
    setEntityFieldType(null);
    setEntityFieldsLoading(true);
    form?.setFieldsValue({
      entityFieldId: null,
    });
    getEntityFields({
      variables: {
        filter: {
          ...entityFieldsFilter,
        },
        where: { name: [value], lineOfBusinessId: [lobId] },
      },
    });
  };

  const onEntityFieldSelect = (value, ...rest) => {
    const selectedType = rest?.[0]?.['data-type'];
    setIsShowError(false);
    setSelectedEntityField(value);
    if (
      selectedType === ENTITY_FIELDS_DATA_KEYS?.PICK_LIST ||
      selectedType === ENTITY_FIELDS_DATA_KEYS?.ENUM_PICK_LIST
    ) {
      const selectedField = entityFieldsData?.find(
        (field) => field?.fieldName === value,
      );
      setOptionsData([]); // clearing old options
      setOptionsData([...selectedField?.data]);
    }
    setEntityFieldType(selectedType);
    resetChildFields(true);
  };

  const onEntityFieldChange = () => {
    form?.validateFields(['field']);
    setEntityFieldType(null);
    resetChildFields(true);
  };

  const onFinish = (values) => {
    setLoading(true);
    if (entityFilters?.length === 0) {
      messageContext?.destroy();
      messageContext?.error('Please add at least one condition.');
      setLoading(false);
      return;
    }
    if (!isEdit) {
      createBatchEntity({
        variables: {
          data: {
            entityName: values?.entityName,
            conditions: entityFilters,
            batchId: id,
          },
        },
      });
    } else {
      updateBatchEntity({
        variables: {
          data: {
            entityName: values?.entityName,
            conditions: entityFilters,
            batchId: id,
          },
          where: { id: initialValues?.id },
        },
      });
    }
  };

  const onCancel = () => {
    setIsEdit(false);
    setShowModal(false);
    setInitialValues(null);
  };

  const addCondition = () => {
    const key = form?.getFieldValue('key');
    const value = form?.getFieldValue('value');
    const min = form?.getFieldValue('min');
    const max = form?.getFieldValue('max');
    const greaterThanCheck = form?.getFieldValue('greaterThanCheck');
    const lessThanCheck = form?.getFieldValue('lessThanCheck');
    let values = value;

    if (key === CONDITIONS_KEYS?.GREATER_LESS_RANGE && (min || max)) {
      if (entityFieldType === ENTITY_FIELDS_DATA_KEYS?.NUMBER) {
        values = {
          greaterThan: { max: Number(max), check: greaterThanCheck },
          lessThan: { min: Number(min), check: lessThanCheck },
        };
      } else if (entityFieldType === ENTITY_FIELDS_DATA_KEYS?.DATE_TIME) {
        values = {
          greaterThan: { max, check: greaterThanCheck },
          lessThan: { min, check: lessThanCheck },
        };
      }
    }

    if (entityFieldType === ENTITY_FIELDS_DATA_KEYS?.PICK_LIST) {
      values = selectedListOptions?.map((option) => ({
        id: option.id,
        name: option.label || option.name || option?.title,
        ...(option.label && { label: option.label }),
        ...(option.name && { name: option.name }),
        ...(option.title && { title: option.title }),
      }));
    }

    if (
      !selectedEntityField ||
      values === null ||
      values === undefined ||
      (typeof values !== 'boolean' && isEmpty(values))
    ) {
      if (typeof value === 'boolean') {
        return;
      }
      setIsShowError(true);
      setValidationTriggered(true);
      form?.validateFields([
        'field',
        'value',
        'min',
        'max',
        'greaterThanCheck',
        'lessThanCheck',
      ]);
      return;
    }
    setIsShowError(false);

    const alreadyExistEntityFilter = entityFilters?.find(
      (entityFilter) => entityFilter?.field === selectedEntityField,
    );

    if (!alreadyExistEntityFilter) {
      const condition = {
        values,
        key,
        field: selectedEntityField,
        fieldType: entityFieldType,
      };
      setEntityFilters([...entityFilters, condition]);
    } else {
      const modifiedEntityFilters = entityFilters?.map((entityFilter) => {
        if (entityFilter?.field === selectedEntityField) {
          return {
            ...entityFilter,
            values,
            key,
          };
        }
        return entityFilter;
      });
      setEntityFilters([...modifiedEntityFilters]);
    }
    setEntityFieldType(null);
    resetChildFields();
  };

  const renderText = () => (
    <div>
      <p className="mb-8 ml-8 mt-8">Conditions</p>
      <div className="ml-12 mt-8">
        <Form.Item name="key" initialValue={CONDITIONS_KEYS?.EQUAL_TO}>
          <Radio.Group className="common-radio fill-width">
            {map(CONDITIONS, (condition) => (
              <Radio value={condition?.value} key={condition?.value}>
                {condition?.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Add Filtering word or phrase"
          className="mt-8 ml-12"
          name="value"
          rules={[
            () => ({
              validator(_, value) {
                if (isShowError && !value) {
                  return Promise?.reject(new Error(`Please Enter Value`));
                }
                return Promise?.resolve();
              },
            }),
          ]}
        >
          <InputComponent name="word or phrase" />
        </Form.Item>
      </div>
    </div>
  );

  const renderBoolean = () => (
    <div>
      <p className="mb-8 ml-8 mt-8">Conditions</p>
      <div className="ml-12 mt-8">
        <Form.Item
          name="key"
          initialValue="EQUAL_TO"
          valuePropName="checked"
          rules={[
            () => ({
              validator(_, value) {
                if (isShowError && !value) {
                  return Promise?.reject(new Error(`Please Enter Value`));
                }
                return Promise?.resolve();
              },
            }),
          ]}
        >
          <Radio value="EQUAL_TO" key="EQUAL_TO" className="common-radio">
            Equal to
          </Radio>
        </Form.Item>
        <Form.Item
          name="value"
          rules={[
            () => ({
              validator(_, value) {
                if (isShowError && value === undefined) {
                  return Promise?.reject(new Error(`Please Select Value`));
                }
                return Promise?.resolve();
              },
            }),
          ]}
        >
          <Radio.Group options={BOOLEAN_VALUES} />
        </Form.Item>
      </div>
    </div>
  );

  const renderNumber = () => (
    <div>
      <p className="mb-8 ml-8 mt-8">Conditions</p>
      <div className="ml-12 mt-8">
        <Form.Item name="key" initialValue={CONDITIONS_KEYS?.EQUAL_TO}>
          <Radio.Group className="common-radio fill-width">
            {map(NUMERIC_CONDITIONS, (condition) => (
              <Radio value={condition?.value} key={condition?.value}>
                {condition?.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>
      {conditionValue === CONDITIONS_KEYS?.GREATER_LESS_RANGE && (
        <>
          <div className="d-flex mt-8 ml-12">
            <div>
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Greater than</span>
                <Form.Item
                  className="mb-0"
                  valuePropName="checked"
                  name="greaterThanCheck"
                  initialValue={false}
                  rules={[
                    () => ({
                      validator(_, value) {
                        if (isShowError && !value) {
                          return Promise?.reject(
                            new Error(`Please select max value`),
                          );
                        }
                        return Promise?.resolve();
                      },
                    }),
                  ]}
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="max"
                initialValue="0"
                dependencies={['min', 'greaterThanCheck']}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        getFieldValue('greaterThanCheck') &&
                        getFieldValue('lessThanCheck') &&
                        Number(value) >= Number(getFieldValue('min'))
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Should be less than ${getFieldValue('min')}`,
                          ),
                        );
                      }
                      if (getFieldValue('greaterThanCheck') && !value) {
                        return Promise?.reject(
                          new Error(`Please enter max value`),
                        );
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
              >
                <NumberComponent
                  name="value"
                  disabled={!isGreaterThanEnabled}
                />
              </Form.Item>
            </div>
            <div className="ml-12">
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Less than</span>
                <Form.Item
                  className="mb-0"
                  valuePropName="checked"
                  name="lessThanCheck"
                  initialValue={false}
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="min"
                initialValue="0"
                dependencies={['max', 'lessThanCheck']}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        getFieldValue('lessThanCheck') &&
                        getFieldValue('greaterThankCheck') &&
                        Number(value) <= Number(getFieldValue('max'))
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Should be greater than ${getFieldValue('max')}`,
                          ),
                        );
                      }

                      if (getFieldValue('lessThanCheck') && !value) {
                        return Promise?.reject(
                          new Error(`Please enter min value`),
                        );
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
              >
                <NumberComponent name="value" disabled={!isLessThanEnabled} />
              </Form.Item>
            </div>
          </div>
          {isShowError && (!isLessThanEnabled || !isGreaterThanEnabled) && (
            <div className="error">
              <span>Please select number(s)</span>
            </div>
          )}
        </>
      )}
      {(conditionValue === CONDITIONS_KEYS?.EQUAL_TO ||
        conditionValue === CONDITIONS_KEYS?.NOT_EQUAL_TO) && (
        <Form.Item
          label={
            conditionValue === CONDITIONS_KEYS?.EQUAL_TO
              ? 'Equal to'
              : 'Not Equal to'
          }
          className="mt-8 ml-12 width-percent-30"
          name="value"
          rules={[
            {
              validator(_, value) {
                if (isShowError && !value) {
                  return Promise?.reject(new Error(`Please enter value`));
                }
                return Promise?.resolve();
              },
            },
          ]}
        >
          <NumberComponent name="value" />
        </Form.Item>
      )}
    </div>
  );

  const renderDate = () => (
    <div>
      <p className="mb-8 ml-8 mt-8">Conditions</p>
      <div className="ml-12 mt-8">
        <Form.Item name="key" initialValue={CONDITIONS_KEYS?.EQUAL_TO}>
          <Radio.Group className="common-radio fill-width">
            {map(NUMERIC_CONDITIONS, (condition) => (
              <Radio value={condition?.value} key={condition?.value}>
                {condition?.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>
      {conditionValue === CONDITIONS_KEYS?.GREATER_LESS_RANGE && (
        <>
          <div className="d-flex mt-8 ml-12">
            <div>
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Greater than</span>
                <Form.Item
                  className="mb-0"
                  valuePropName="checked"
                  name="greaterThanCheck"
                  initialValue={false}
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="max"
                dependencies={['min', 'greaterThanCheck']}
                normalize={(value) => {
                  if (value) {
                    return moment(value)?.startOf('day');
                  }
                }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        getFieldValue('greaterThanCheck') &&
                        getFieldValue('lessThanCheck') &&
                        value > getFieldValue('min')
                      ) {
                        return Promise?.reject(
                          new Error(
                            'Start date cannot be greater than expiry date!',
                          ),
                        );
                      }
                      if (getFieldValue('greaterThanCheck') && !value) {
                        return Promise?.reject(
                          new Error('Please select start date!'),
                        );
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
              >
                <DatePickerComponent
                  placeholder="Select start Date"
                  format={dateFormatWithoutTime(globalDateFormat)}
                  disabled={!isGreaterThanEnabled}
                />
              </Form.Item>
            </div>
            <div className="ml-12">
              <div className="d-flex justify-between align-center">
                <span className="switch-logo">Less than</span>
                <Form.Item
                  className="mb-0"
                  valuePropName="checked"
                  name="lessThanCheck"
                  initialValue={false}
                >
                  <Switch
                    className="common-switch"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="min"
                dependencies={['max', 'lessThanCheck']}
                normalize={(value) => {
                  if (value) {
                    return moment(value)?.endOf('day');
                  }
                  return null;
                }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        getFieldValue('lessThanCheck') &&
                        getFieldValue('greaterThankCheck') &&
                        value < getFieldValue('max')
                      ) {
                        return Promise?.reject(
                          new Error('End date cannot be less than start date!'),
                        );
                      }

                      if (getFieldValue('lessThanCheck') && !value) {
                        return Promise?.reject(
                          new Error('Please select end date!'),
                        );
                      }

                      return Promise?.resolve();
                    },
                  }),
                ]}
              >
                <DatePickerComponent
                  placeholder="Select End Date"
                  format={dateFormatWithoutTime(globalDateFormat)}
                  disabled={!isLessThanEnabled}
                />
              </Form.Item>
            </div>
          </div>
          {isShowError && !isLessThanEnabled && !isGreaterThanEnabled && (
            <div className="entity-batch-error">
              <span>Please select date('s)</span>
            </div>
          )}
        </>
      )}
      {(conditionValue === CONDITIONS_KEYS?.EQUAL_TO ||
        conditionValue === CONDITIONS_KEYS?.NOT_EQUAL_TO) && (
        <Form.Item
          label={
            conditionValue === CONDITIONS_KEYS?.EQUAL_TO
              ? 'Equal to'
              : 'Not Equal to'
          }
          className="mt-8 ml-12 width-percent-30"
          name="value"
          rules={[
            {
              validator(_, value) {
                if (isShowError && !value) {
                  return Promise?.reject(new Error(`Please select date`));
                }
                return Promise?.resolve();
              },
            },
          ]}
        >
          <DatePickerComponent
            placeholder="Select Date"
            format={dateFormatWithoutTime(globalDateFormat)}
          />
        </Form.Item>
      )}
    </div>
  );

  const pickListOptionChange = (e, option) => {
    if (e?.target?.checked) {
      if (
        conditionValue === CONDITIONS_KEYS?.EQUAL_TO ||
        conditionValue === CONDITIONS_KEYS?.NOT_EQUAL_TO
      ) {
        const filteredOptionList = optionsData?.map((item) => {
          if (item?.id !== option?.id) {
            return {
              ...item,
              disable: true,
            };
          }
          return item;
        });
        setOptionsData([...filteredOptionList]);
      }
      setSelectedListOptions([...selectedListOptions, option]);
    } else {
      setSelectedListOptions(
        selectedListOptions?.filter((selected) => selected !== option),
      );
    }
  };

  const renderList = () => (
    <div>
      <p className="mb-8 ml-8 mt-8">Conditions</p>
      <div className="ml-12 mt-8">
        <Form.Item name="key" initialValue={CONDITIONS_KEYS?.EQUAL_TO}>
          <Radio.Group className="common-radio fill-width">
            {map(CONDITIONS, (condition) => (
              <Radio
                value={condition?.value}
                key={condition?.value}
                onChange={() => {
                  setSelectedListOptions([]);
                  form?.setFieldValue('value', null);
                }}
              >
                {condition?.label}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </div>

      {optionsData?.length > 0 ? (
        <Form.Item
          name="value"
          rules={[
            () => ({
              validator(_, value) {
                if (isShowError && !value) {
                  return Promise?.reject(
                    new Error(`Please select value before add condition`),
                  );
                }
                return Promise?.resolve();
              },
            }),
          ]}
        >
          <Checkbox.Group
            className="fill-width"
            value={map(
              optionsData,
              (item) => item?.label || item?.name || item?.title,
            )}
          >
            {map(optionsData, (item) => {
              const isChecked = selectedListOptions?.find(
                (listItem) => listItem?.id === item?.id,
              );
              const conditionCheck =
                conditionValue === CONDITIONS_KEYS?.EQUAL_TO ||
                conditionValue === CONDITIONS_KEYS?.NOT_EQUAL_TO;

              return (
                <div key={item?.label || item?.name || item?.title}>
                  <Checkbox
                    className="common-checkbox subarea-section"
                    value={item?.label || item?.name || item?.title}
                    checked={isChecked}
                    onChange={(e) => pickListOptionChange(e, item)}
                    disabled={
                      selectedListOptions.length > 0 &&
                      !isChecked &&
                      conditionCheck
                    }
                  >
                    <span className="line-clamp">
                      {item?.label || item?.name || item?.title}
                    </span>
                  </Checkbox>
                </div>
              );
            })}
          </Checkbox.Group>
        </Form.Item>
      ) : (
        <Empty />
      )}
    </div>
  );

  const handleDeleteEntityFilter = (entityFilterToBeRemoved) => {
    const modifiedEntityFilters = entityFilters?.filter(
      (entity) => entity?.field !== entityFilterToBeRemoved?.field,
    );
    setEntityFilters(modifiedEntityFilters);
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  return (
    <ModalComponent
      title="FILTERS"
      width={MODAL_WIDTH}
      open={showModal}
      onCancel={onCancel}
      destroyOnClose
      wrapClassName="entity-batch-modal"
      footer={
        <div className="d-flex">
          <Button
            className="common-button"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <AccessControl allowedPermissions={['FET_DATA_ASSIGNMENT_UPDATE']}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={disableBtn}
              className="common-button ml-5"
              onClick={() => {
                form?.submit();
              }}
            >
              Apply
            </Button>
          </AccessControl>
        </div>
      }
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        name="settingForm"
        layout="vertical"
        initialValues={initialValues}
        disabled={!checkPermissions(permissions, 'FET_DATA_ASSIGNMENT_UPDATE')}
        onValuesChange={() => {
          setDisableBtn(false);
        }}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="entityName"
              label="Entity"
              rules={[
                {
                  required,
                  message: 'Please Select Entity',
                },
              ]}
            >
              <SelectComponent
                showSearch
                placeholder="Select Entity"
                allowClear
                notFoundContent={
                  entitiesLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onChange={onEntitySelect}
              >
                {map(entitiesData, (item) => (
                  <Option key={item?.name} value={item?.name}>
                    {getEntityFieldName(item?.name)}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="field"
              label="Select Field"
              rules={[
                () => ({
                  validator(_, value) {
                    if (isShowError && !value) {
                      return Promise?.reject(
                        new Error(`Please select field before add condition`),
                      );
                    }
                    return Promise?.resolve();
                  },
                }),
              ]}
            >
              <SelectComponent
                showSearch
                placeholder="Select Field"
                allowClear
                notFoundContent={
                  entityFieldsLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onSelect={onEntityFieldSelect}
                onChange={onEntityFieldChange}
              >
                {map(entityFieldsData, (item) => {
                  const name = Object?.keys(ENTITY_FIELDS_WRAPPER)?.find(
                    (entity) => entity === item?.label,
                  );
                  return (
                    <Option
                      key={item?.fieldName}
                      value={item?.fieldName}
                      data-type={item?.fieldType}
                    >
                      {name
                        ? ENTITY_FIELDS_WRAPPER?.[name]
                        : startCase(item?.label)}
                    </Option>
                  );
                })}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col span={24}>
            {entityFieldType === ENTITY_FIELDS_DATA_KEYS?.TEXT && renderText()}
            {entityFieldType === ENTITY_FIELDS_DATA_KEYS?.BOOLEAN &&
              renderBoolean()}
            {entityFieldType === ENTITY_FIELDS_DATA_KEYS?.NUMBER &&
              renderNumber()}
            {entityFieldType === ENTITY_FIELDS_DATA_KEYS?.DATE_TIME &&
              renderDate()}
            {entityFieldType === ENTITY_FIELDS_DATA_KEYS?.PICK_LIST &&
              renderList()}
            {entityFieldType === ENTITY_FIELDS_DATA_KEYS?.ENUM_PICK_LIST &&
              renderList()}
          </Col>
          <Col>
            <Button
              icon={<PlusOutlined />}
              type="primary"
              className="common-button"
              onClick={addCondition}
            >
              Add Condition
            </Button>
          </Col>
        </Row>
        {entityFilters?.length > 0 && (
          <List
            className="entity-filter-list mt-15"
            bordered
            dataSource={entityFilters}
            renderItem={(item) => {
              const name = Object?.keys(ENTITY_FIELDS_WRAPPER)?.find(
                (entity) => entity === item?.field,
              );
              return (
                <div className="single-row" key={item?.field}>
                  <div className="entity-filter-list-head-section">
                    <span className="entity-filter-list-title">
                      {name
                        ? ENTITY_FIELDS_WRAPPER?.[name]
                        : startCase(item?.field)}
                    </span>
                  </div>

                  <div className="entity-filter-list-middle-section">
                    <span key={item?.field} className="content-text">
                      {createEntityFilterMessage(item, globalDateFormat)}
                    </span>
                  </div>
                  <AccessControl
                    allowedPermissions={['FET_DATA_ASSIGNMENT_UPDATE']}
                  >
                    <div
                      id="entity-delete-condition-popover"
                      className="entity-filter-list-end-section"
                    >
                      <Popconfirm
                        title="Are you sure to delete?"
                        onConfirm={() => handleDeleteEntityFilter(item)}
                        okText="Yes"
                        cancelText="No"
                        placement="topLeft"
                      >
                        <img
                          src={deleteIcon}
                          alt="delete-icon"
                          className="entity-filter-list-delete"
                        />
                      </Popconfirm>
                    </div>
                  </AccessControl>
                </div>
              );
            }}
          />
        )}
      </Form>
    </ModalComponent>
  );
};

export default CreateEntityBatchModal;
