import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import LineOfBusinessTable from '../components/LineOfBusinessTable';

const LineOfBusinessList = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);
  return <div>{pageSize && <LineOfBusinessTable />}</div>;
};

export default LineOfBusinessList;
