import { CheckOutlined, CloseOutlined, UpOutlined } from '@ant-design/icons';
import { Collapse, Form, Switch } from 'antd';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../../AppContext';
import { strippedString } from '../../../../../../common/utils';
import EditorComponent from '../../../../../../components/EditorComponent';
import InputComponent from '../../../../../../components/InputComponent';

const ContentWidgetForm = ({ form, isSponsorQuestionnaire = false }) => {
  const {
    state: { isSponsor },
  } = useContext(AppContext);
  const location = useLocation();
  const enableHeader = Form?.useWatch(
    ['widgetConfiguration', 'headerBlock', 'enable'],
    form,
  );
  const enableFooter = Form?.useWatch(
    ['widgetConfiguration', 'footerBlock', 'enable'],
    form,
  );
  const titleCheckHeader = Form?.useWatch(
    ['widgetConfiguration', 'headerBlock', 'titleCheck'],
    form,
  );
  const subTitleCheckHeader = Form?.useWatch(
    ['widgetConfiguration', 'headerBlock', 'subTitleCheck'],
    form,
  );
  const descriptionCheckHeader = Form?.useWatch(
    ['widgetConfiguration', 'headerBlock', 'descriptionCheck'],
    form,
  );
  const titleCheckFooter = Form?.useWatch(
    ['widgetConfiguration', 'footerBlock', 'titleCheck'],
    form,
  );
  const subTitleCheckFooter = Form?.useWatch(
    ['widgetConfiguration', 'footerBlock', 'subTitleCheck'],
    form,
  );
  const descriptionCheckFooter = Form?.useWatch(
    ['widgetConfiguration', 'footerBlock', 'descriptionCheck'],
    form,
  );

  const contentCollapseItems = [
    {
      forceRender: true,
      label: 'Header Block',
      key: '1',
      children: (
        <>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Enable</span>
            <Form.Item
              name={['widgetConfiguration', 'headerBlock', 'enable']}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <div>
            {/* Title */}
            <div className="d-flex justify-between align-center">
              {enableHeader && <span className="switch-logo">Title</span>}
              <Form.Item
                name={['widgetConfiguration', 'headerBlock', 'titleCheck']}
                className="mb-0"
                valuePropName="checked"
                hidden={!enableHeader}
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <Form.Item
              hidden={!titleCheckHeader || !enableHeader}
              rules={[
                {
                  required: titleCheckHeader && enableHeader,
                  message: 'Please Enter Title',
                },
              ]}
              name={['widgetConfiguration', 'headerBlock', 'titleText']}
            >
              <InputComponent placeholder="Enter Title" />
            </Form.Item>

            {/* Sub Title */}
            <div className="d-flex justify-between align-center">
              {enableHeader && <span className="switch-logo">Sub Title</span>}
              <Form.Item
                name={['widgetConfiguration', 'headerBlock', 'subTitleCheck']}
                hidden={!enableHeader}
                className="mb-0"
                valuePropName="checked"
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <Form.Item
              name={['widgetConfiguration', 'headerBlock', 'subTitleText']}
              hidden={!subTitleCheckHeader || !enableHeader}
              rules={[
                {
                  required: subTitleCheckHeader && enableHeader,
                  message: 'Please Enter Sub Title',
                },
              ]}
            >
              <InputComponent placeholder="Enter Sub Title" />
            </Form.Item>

            {/* Description */}
            <div className="d-flex justify-between align-center">
              {enableHeader && <span className="switch-logo">Description</span>}
              <Form.Item
                name={[
                  'widgetConfiguration',
                  'headerBlock',
                  'descriptionCheck',
                ]}
                hidden={!enableHeader}
                className="mb-0"
                valuePropName="checked"
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <Form.Item
              hidden={!descriptionCheckHeader || !enableHeader}
              rules={[
                {
                  validator(_, value) {
                    if (
                      enableHeader &&
                      descriptionCheckHeader &&
                      (!value || !strippedString(value)?.length)
                    ) {
                      return Promise?.reject(
                        new Error('Please Enter Description'),
                      );
                    }
                    return Promise?.resolve();
                  },
                },
              ]}
              name={['widgetConfiguration', 'headerBlock', 'descriptionText']}
            >
              <EditorComponent
                disabled={
                  isSponsorQuestionnaire &&
                  !location?.pathname?.includes('/create') &&
                  !isSponsor
                }
                placeholder="Enter Description"
              />
            </Form.Item>
          </div>
        </>
      ),
    },
    {
      forceRender: true,
      label: 'footer Block',
      key: '2',
      children: (
        <>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Enable</span>
            <Form.Item
              name={['widgetConfiguration', 'footerBlock', 'enable']}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
          <div>
            {/* Title */}
            <div className="d-flex justify-between align-center">
              {enableFooter && <span className="switch-logo">Title</span>}
              <Form.Item
                name={['widgetConfiguration', 'footerBlock', 'titleCheck']}
                className="mb-0"
                valuePropName="checked"
                hidden={!enableFooter}
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <Form.Item
              name={['widgetConfiguration', 'footerBlock', 'titleText']}
              hidden={!titleCheckFooter || !enableFooter}
              rules={[
                {
                  required: titleCheckFooter && enableFooter,
                  message: 'Please Enter Title',
                },
              ]}
            >
              <InputComponent placeholder="Enter Title" />
            </Form.Item>

            {/* Sub Title */}
            <div className="d-flex justify-between align-center">
              {enableFooter && <span className="switch-logo">Sub Title</span>}
              <Form.Item
                name={['widgetConfiguration', 'footerBlock', 'subTitleCheck']}
                className="mb-0"
                valuePropName="checked"
                hidden={!enableFooter}
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <Form.Item
              name={['widgetConfiguration', 'footerBlock', 'subTitleText']}
              hidden={!subTitleCheckFooter || !enableFooter}
              rules={[
                {
                  required: subTitleCheckFooter && enableFooter,
                  message: 'Please Enter Sub Title',
                },
              ]}
            >
              <InputComponent placeholder="Enter Sub Title" />
            </Form.Item>

            {/* Description */}
            <div className="d-flex justify-between align-center">
              {enableFooter && <span className="switch-logo">Description</span>}
              <Form.Item
                name={[
                  'widgetConfiguration',
                  'footerBlock',
                  'descriptionCheck',
                ]}
                className="mb-0"
                valuePropName="checked"
                hidden={!enableFooter}
              >
                <Switch
                  className="common-switch"
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
            </div>
            <Form.Item
              name={['widgetConfiguration', 'footerBlock', 'descriptionText']}
              hidden={!descriptionCheckFooter || !enableFooter}
              rules={[
                {
                  validator(_, value) {
                    if (
                      enableFooter &&
                      descriptionCheckFooter &&
                      (!value || !strippedString(value)?.length)
                    ) {
                      return Promise?.reject(
                        new Error('Please Enter Description'),
                      );
                    }
                    return Promise?.resolve();
                  },
                },
              ]}
            >
              <EditorComponent placeholder="Enter Description" />
            </Form.Item>
          </div>
        </>
      ),
    },
  ];
  return (
    <Collapse
      className="common-collapse questionnaire-collapse"
      expandIconPosition="end"
      expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 0 : 180} />}
      defaultActiveKey={['1', '2']}
      ghost
      items={contentCollapseItems}
    />
  );
};

export default ContentWidgetForm;
