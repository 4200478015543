import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
} from 'react-router-dom';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import ProductDetails from '../components/ProductDetails';
import CardWrapper from '../components/ProductTabs';
import { GET_PRODUCT } from '../graphql/Queries';

function ProductView() {
  const [loading, setLoading] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const navigateType = useNavigationType();

  const { data: { product } = {} } = useQuery(GET_PRODUCT, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(
    () => {
      if (navigateType === 'POP') {
        navigate(location?.pathname, {
          state: location?.state,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, location],
  );

  if (!id) {
    return <Navigate to={ROUTES?.PACKAGES} replace />;
  }

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PACKAGES} />
          {product && <span className="portal-header">{product?.title}</span>}
        </div>
      </Portal>
      <AccessControl allowedPermissions={['FET_PRODUCT_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={EditIcon} alt="save-icon" width={12} />}
            size="small"
            type="primary"
            onClick={() =>
              navigate(`${ROUTES?.PACKAGES}/edit/${id}`, {
                state: { ...location?.state },
              })
            }
          >
            Edit
          </Button>
        </Portal>
      </AccessControl>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ProductDetails data={product} />
        )}
      </CardWrapper>
    </Card>
  );
}

export default ProductView;
