import { Form } from 'antd';
import React from 'react';
import { formValidatorRules } from '../../../../../../common/utils';
import InputComponent from '../../../../../../components/InputComponent';

const { required } = formValidatorRules;

const CartWidgetForm = () => (
    <div className="mt-16">
      <Form.Item
        rules={[
          { ...required, message: 'Please Enter Change Quote Button Label' },
        ]}
        name={['widgetConfiguration', 'changeQuoteButtonLabel']}
        label="Change Quote Button Label"
      >
        <InputComponent
          allowClear
          placeholder="Enter Change Quote Button Label"
        />
      </Form.Item>
      <Form.Item
        rules={[
          { ...required, message: 'Please Enter Add Project Button Label' },
        ]}
        name={['widgetConfiguration', 'addProjectButtonLabel']}
        label="Add Project Button Label"
      >
        <InputComponent
          allowClear
          placeholder="Enter Add Project Button Label"
        />
      </Form.Item>
    </div>
  );

export default CartWidgetForm;
