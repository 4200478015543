import { Card } from 'antd';
import React from 'react';
import LineOfBusinessList from '../../lineOfBusinesses/pages/LineOfBusinessList';
import IndustryDetails from '../components/IndustryDetails';

const IndustryView = () => (
    <div className="split-screen-section">
      <Card className="mb-24 semi-height-card card-body-padding">
        <IndustryDetails />
      </Card>
      <Card className="semi-height-card card-body-padding">
        <LineOfBusinessList />
      </Card>
    </div>
  );

export default IndustryView;
