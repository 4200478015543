import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';

function PublicRoute({ children }) {
  const { getToken, isSignupAuthenticate } = useContext(AppContext);
  const idToken = getToken();
  const authenticate = isSignupAuthenticate();

  if (idToken && !authenticate) {
    return <Navigate to={ROUTES?.MAIN} replace />;
  }

  return children;
}

export default PublicRoute;
