import { Form } from 'antd';
import React from 'react';
import EditorComponent from '../../../../../../components/EditorComponent';
import InputComponent from '../../../../../../components/InputComponent';

const MeasurementTipsForManualEntry = () => (
    <>
      <Form.Item
        name={[
          'widgetConfiguration',
          'common',
          'measurementTipsManualEntry',
          'titleText',
        ]}
        label="Title"
        rules={[
          {
            required: true,
            message: 'Please Enter Title',
          },
        ]}
      >
        <InputComponent placeholder="Enter Title" />
      </Form.Item>
      <Form.Item
        label="Description"
        name={[
          'widgetConfiguration',
          'common',
          'measurementTipsManualEntry',
          'descriptionText',
        ]}
      >
        <EditorComponent placeholder="Enter Description" />
      </Form.Item>
      {/* action button label */}
      <Form.Item
        label="Action Button Label"
        name={[
          'widgetConfiguration',
          'common',
          'measurementTipsManualEntry',
          'actionButtonLabel',
        ]}
        rules={[
          {
            required: true,
            message: 'Please Enter Action Button Label',
          },
        ]}
      >
        <InputComponent placeholder="Enter Action Button Label" />
      </Form.Item>
    </>
  );

export default MeasurementTipsForManualEntry;
