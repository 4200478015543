import { Tabs } from 'antd';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InfoIconComponent from '../../../app/components/iconComponents/InfoIconComponent';
import { ROUTES } from '../../../common/constants';

const primaryTab = (
  <div>
    <InfoIconComponent className="tab-icons" />
    <span className="card-title">ROQ Builder</span>
  </div>
);
const secondaryTab = (
  <div>
    <InfoIconComponent className="tab-icons" />
    <span className="card-title">ROQ Path</span>
  </div>
);

const QuestionnaireTabs = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelectTab = (key) => {
    switch (key) {
      case 'PRIMARY':
        navigate(ROUTES?.QUESTIONNAIRES_PRIMARY);
        break;
      case 'SECONDARY':
        navigate(ROUTES?.QUESTIONNAIRES_SECONDARY);
        break;
      default:
        break;
    }
  };

  const tabItems = [
    {
      label: primaryTab,
      key: 'PRIMARY',
      children,
    },
    {
      label: secondaryTab,
      key: 'SECONDARY',
      children,
    },
  ];

  const getActiveKey = () => {
    if (location?.pathname?.includes(ROUTES?.BUILDER)) {
      return 'PRIMARY';
    }
    return 'SECONDARY';
  };

  return (
    <div className="card-container">
      <Tabs
        type="card"
        activeKey={getActiveKey()}
        onChange={handleSelectTab}
        items={tabItems}
      />
    </div>
  );
};

export default QuestionnaireTabs;
