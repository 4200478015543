import {
  DeleteOutlined,
  FilterOutlined,
  SyncOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Collapse,
  Empty,
  Form,
  Modal,
  Popconfirm,
  Radio,
  Space,
} from 'antd';
import { capitalize, debounce, find, get, map, uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  DYNAMIC_FIELDS_DATA,
  DYNAMIC_SELECTED_COLUMN,
  MODULES,
  PRODUCT_CONFIG_SORT_BY_OPTION,
  PRODUCT_CONFIG_SORT_ON_OPTION,
  QUESTIONNAIRE_RESULT_VALIDATION,
} from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import SelectComponent from '../../../components/SelectComponent';
import {
  DEFAULT_CONFIG,
  GET_BRANDS,
  GET_INDUSTRIES,
  GET_LINE_OF_BUSINESSES,
  GET_PRODUCT_RANKING_REGIONS,
  GET_SPONSORS,
  GET_SUB_AREAS,
} from '../graphql/Queries';
import RenderCheckBox from '../rendering/RenderCheckBox';
import RenderDateTime from '../rendering/RenderDateTime';
import RenderNumber from '../rendering/RenderNumber';
import RenderRadio from '../rendering/RenderRadio';
import RenderSelect from '../rendering/RenderSelect';
import RenderText from '../rendering/RenderText';
import DynamicFilterModal from './DynamicFilterModal';
import './ProductRankingPanelComponent.less';

const { Option } = SelectComponent;

const LIMIT = 50;

let industryScrollDebounceJob;
let lineOfBusinessScrollDebounceJob;
let subAreaScrollDebounceJob;
let regionScrollDebounceJob;
let brandScrollDebounceJob;
let sponsorScrollDebounceJob;

const industryFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'label',
  sortBy: 'ASC',
  justShow: true,
};

const lineOfBusinessFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'label',
  sortBy: 'ASC',
  justShow: true,
};

const subAreaFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'label',
  sortBy: 'ASC',
  justShow: true,
};

const regionFilter = {
  skip: 0,
  limit: LIMIT,
};

const brandFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  skip: 0,
  limit: LIMIT,
  defaultBrand: true,
};

const sponsorFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'businessName',
  sortBy: 'ASC',
  distinct: true,
  getDBField: 'businessName',
};

const updateData = (data = null) => {
  // eslint-disable-next-line no-undef
  localStorage?.setItem(QUESTIONNAIRE_RESULT_VALIDATION, JSON?.stringify(data));
};

const getData = (key = '') => {
  const data =
    // eslint-disable-next-line no-undef
    JSON?.parse(localStorage?.getItem(QUESTIONNAIRE_RESULT_VALIDATION)) || null;
  if (key?.length > 0) {
    return get(data, key);
  }
  return data;
};

const ExtraLoaderComponent = ({ loading = false }) => (
  <LoaderComponent spinning={loading} size="small" setHeight={2} />
);
const { required } = formValidatorRules;
const { confirm } = Modal;
const ProductRankingPanelComponent = () => {
  const {
    dispatch,
    state: { productRankingConfig, showNotificationWarning, isSponsor },
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const dynamicFieldFormData = Form?.useWatch(['dynamicFields'], form);

  const [industriesLoading, setIndustriesLoading] = useState(true);
  const [industriesData, setIndustriesData] = useState([]);
  const [industriesIsEnd, setIndustriesIsEnd] = useState(false);
  const [industriesInitialCall, setIndustriesInitialCall] = useState(true);
  const [industriesDebounceCall, setIndustriesDebounceCall] = useState(0);

  const [lineOfBusinessesLoading, setLineOfBusinessesLoading] = useState(true);
  const [lineOfBusinessesData, setLineOfBusinessesData] = useState([]);
  const [lineOfBusinessesIsEnd, setLineOfBusinessesIsEnd] = useState(false);
  const [
    lineOfBusinessesInitialCall,
    setLineOfBusinessesInitialCall,
  ] = useState(true);
  const [
    lineOfBusinessesDebounceCall,
    setLineOfBusinessesDebounceCall,
  ] = useState(0);

  const [subAreasLoading, setSubAreasLoading] = useState(true);
  const [subAreasData, setSubAreasData] = useState([]);
  const [subAreasIsEnd, setSubAreasIsEnd] = useState(false);
  const [subAreasInitialCall, setSubAreasInitialCall] = useState(true);
  const [subAreasDebounceCall, setSubAreasDebounceCall] = useState(0);

  const [regionsLoading, setRegionsLoading] = useState(true);
  const [regionsData, setRegionsData] = useState([]);
  const [regionsIsEnd, setRegionsIsEnd] = useState(false);
  const [regionsInitialCall, setRegionsInitialCall] = useState(true);
  const [regionsDebounceCall, setRegionsDebounceCall] = useState(0);

  const [brandsLoading, setBrandsLoading] = useState(true);
  const [brandsData, setBrandsData] = useState([]);
  const [brandsIsEnd, setBrandsIsEnd] = useState(false);
  const [brandsInitialCall, setBrandsInitialCall] = useState(true);
  const [brandsDebounceCall, setBrandsDebounceCall] = useState(0);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [dynamicFieldData, setDynamicFieldData] = useState([]);
  const [dynamicFieldDataLoading, setDynamicFieldDataLoading] = useState(true);
  const [defaultPanelKey, setDefaultPanelKey] = useState([]);

  const [sponsorsLoading, setSponsorsLoading] = useState(true);
  const [sponsorsData, setSponsorsData] = useState([]);
  const [sponsorsIsEnd, setSponsorsIsEnd] = useState(false);
  const [sponsorsInitialCall, setSponsorsInitialCall] = useState(true);
  const [sponsorsDebounceCall, setSponsorsDebounceCall] = useState(0);

  const renderDynamicFields = (type, item) => {
    switch (type) {
      case 'TEXT':
        return (
          <RenderText textData={item} form={form} editData={dynamicFieldData} />
        );
      case 'PICK_LIST':
        return (
          <RenderSelect
            selectData={item}
            form={form}
            editData={dynamicFieldData}
            dynamicFieldListItems={item?.dynamicFieldListItems}
            FilterType
          />
        );
      case 'NUMBER':
        return (
          <RenderNumber
            numberData={item}
            form={form}
            editData={dynamicFieldData}
            dynamicFieldFormData={dynamicFieldFormData}
          />
        );
      case 'BOOLEAN':
        return (
          <RenderRadio
            radioData={item}
            form={form}
            editData={dynamicFieldData}
            FilterType
          />
        );
      case 'DATE_TIME':
        return (
          <RenderDateTime
            dateTimeData={item}
            form={form}
            editData={dynamicFieldData}
            FilterType
          />
        );
      case 'CHECKBOX':
        return <RenderCheckBox checkBoxData={item} form={form} />;
      default:
        break;
    }
  };

  const handleShowFilterModal = (value, cb) => {
    setShowFilterModal(value);
    setDynamicFieldData(
      // eslint-disable-next-line no-undef
      JSON?.parse(localStorage?.getItem(DYNAMIC_SELECTED_COLUMN)) || null,
    );
    setDynamicFieldDataLoading(false);
    cb();
  };

  const handleChangeFilter = (value, objKey, objData = []) => {
    let configCopy;
    const localStorageData = getData();
    switch (objKey) {
      case 'industry':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            id: value,
            label: find(objData, (item) => item?.id === value)?.label,
          },
        };
        delete configCopy?.lineOfBusiness;
        delete configCopy?.subArea;
        setLineOfBusinessesInitialCall(true);
        setSubAreasInitialCall(true);
        break;
      case 'lineOfBusiness':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            id: value,
            label: find(objData, (item) => item?.id === value)?.label,
          },
        };
        delete configCopy?.subArea;
        setSubAreasInitialCall(true);
        break;
      case 'subArea':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            id: value,
            label: find(objData, (item) => item?.id === value)?.label,
            lineOfBusinessId: find(objData, (item) => item?.id === value)
              ?.lineOfBusinessId,
          },
        };
        break;
      case 'region':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            id: value,
            name: find(regionsData, (item) => item?.id === value)?.name,
            ...(find(regionsData, (item) => item?.id === value)?.parentName && {
              parentName: find(regionsData, (item) => item?.id === value)
                ?.parentName,
            }),
          },
        };
        break;
      case 'brand':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            id: value,
            name: find(brandsData, (item) => item?.id === value)?.name,
          },
        };
        break;
      case 'sponsorId':
        configCopy = {
          ...localStorageData,
          [objKey]: {
            id: value,
            businessName: find(sponsorsData, (item) => item?.id === value)
              ?.businessName,
          },
        };
        break;
      default:
        break;
    }
    dispatch({
      type: 'SET_PRODUCT_RANKING_CONFIG',
      data: configCopy,
    });
    updateData(configCopy);
  };

  const [industries] = useLazyQuery(GET_INDUSTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIndustriesIsEnd(res?.industries?.data?.length < LIMIT);
      if (industriesInitialCall) {
        const industryData = getData('industry');
        if (industryData) {
          setIndustriesData(
            uniqBy([industryData, ...res?.industries?.data], 'id'),
          );
        } else {
          const uniqueData = uniqBy(res?.industries?.data, 'id');
          setIndustriesData(uniqueData);
        }
        setIndustriesInitialCall(false);
      } else {
        setIndustriesData(
          uniqBy([...industriesData, ...res?.industries?.data], 'id'),
        );
      }
      setIndustriesLoading(false);
    },
    onError() {
      setIndustriesLoading(false);
    },
  });

  const [lineOfBusinesses] = useLazyQuery(GET_LINE_OF_BUSINESSES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setLineOfBusinessesIsEnd(res?.lineOfBusinesses?.data?.length < LIMIT);
      if (lineOfBusinessesInitialCall) {
        const lineOfBusinessData = getData('lineOfBusiness');
        if (lineOfBusinessData) {
          setLineOfBusinessesData(
            uniqBy([lineOfBusinessData, ...res?.lineOfBusinesses?.data], 'id'),
          );
        } else {
          const uniqueData = uniqBy(res?.lineOfBusinesses?.data, 'id');
          if (!productRankingConfig?.lineOfBusiness?.id) {
            handleChangeFilter(
              uniqueData?.[0]?.id,
              'lineOfBusiness',
              uniqueData,
            );
          }
          setLineOfBusinessesData(uniqueData);
        }
        setLineOfBusinessesInitialCall(false);
      } else {
        setLineOfBusinessesData(
          uniqBy(
            [...lineOfBusinessesData, ...res?.lineOfBusinesses?.data],
            'id',
          ),
        );
      }
      setLineOfBusinessesLoading(false);
    },
    onError() {
      setLineOfBusinessesLoading(false);
    },
  });

  const [subAreas] = useLazyQuery(GET_SUB_AREAS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setSubAreasIsEnd(res?.subAreas?.data?.length < LIMIT);
      if (subAreasInitialCall) {
        const subAreaData = getData('subArea');
        if (subAreaData) {
          setSubAreasData(uniqBy([subAreaData, ...res?.subAreas?.data], 'id'));
        } else {
          const uniqueData = uniqBy(res?.subAreas?.data, 'id');
          if (!productRankingConfig?.subArea?.id) {
            handleChangeFilter(uniqueData?.[0]?.id, 'subArea', uniqueData);
          }
          setSubAreasData(uniqueData);
        }
        setSubAreasInitialCall(false);
      } else {
        setSubAreasData(
          uniqBy([...subAreasData, ...res?.subAreas?.data], 'id'),
        );
      }
      setSubAreasLoading(false);
    },
    onError() {
      setSubAreasLoading(false);
    },
  });

  const [regionsProductRankingConfigData] = useLazyQuery(
    GET_PRODUCT_RANKING_REGIONS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setRegionsIsEnd(
          res?.regionsProductRankingConfigData?.data?.length < LIMIT,
        );
        if (regionsInitialCall) {
          const regionData = getData('region');
          if (regionData) {
            setRegionsData(
              uniqBy(
                [regionData, ...res?.regionsProductRankingConfigData?.data],
                'id',
              ),
            );
          } else {
            setRegionsData(
              uniqBy(res?.regionsProductRankingConfigData?.data, 'id'),
            );
          }
          setRegionsInitialCall(false);
        } else {
          setRegionsData(
            uniqBy(
              [...regionsData, ...res?.regionsProductRankingConfigData?.data],
              'id',
            ),
          );
        }
        setRegionsLoading(false);
      },
      onError() {
        setRegionsLoading(false);
      },
    },
  );

  const [brands] = useLazyQuery(GET_BRANDS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setBrandsIsEnd(res?.brands?.data?.length < LIMIT);
      if (brandsInitialCall) {
        const brandData = getData('brand');
        if (brandData) {
          setBrandsData(uniqBy([brandData, ...res?.brands?.data], 'id'));
        } else {
          setBrandsData(uniqBy(res?.brands?.data, 'id'));
        }
        setBrandsInitialCall(false);
      } else {
        setBrandsData(uniqBy([...brandsData, ...res?.brands?.data], 'id'));
      }
      setBrandsLoading(false);
    },
    onError() {
      setBrandsLoading(false);
    },
  });

  const [sponsors] = useLazyQuery(GET_SPONSORS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setSponsorsIsEnd(res?.sponsors?.data?.length < LIMIT);
      if (sponsorsInitialCall) {
        const sponsorData = getData('sponsorId');

        if (sponsorData && Object?.keys(sponsorData)?.length > 0) {
          setSponsorsData(uniqBy([sponsorData, ...res?.sponsors?.data], 'id'));
        } else {
          const uniqueData = uniqBy(res?.sponsors?.data, 'id');
          setSponsorsData(uniqueData);
        }
        setSponsorsInitialCall(false);
      } else {
        setSponsorsData(
          uniqBy([...sponsorsData, ...res?.sponsors?.data], 'id'),
        );
      }
      setSponsorsLoading(false);
    },
    onError() {
      setSponsorsLoading(false);
    },
  });
  const initialQueryCall = () => {
    const localStorageData = getData();
    industries({
      variables: {
        filter: industryFilter,
        where: { isActive: true },
      },
    });
    lineOfBusinesses({
      variables: {
        filter: {
          ...lineOfBusinessFilter,
          industryId: localStorageData?.industry?.id,
        },
        where: { isActive: true },
      },
    });
    subAreas({
      variables: {
        filter: {
          ...subAreaFilter,
          industryId: localStorageData?.industry?.id,
          lobId: localStorageData?.lineOfBusiness?.id,
        },
        where: { isActive: true },
      },
    });
    regionsProductRankingConfigData({
      variables: {
        filter: regionFilter,
      },
    });
    brands({
      variables: {
        filter: brandFilter,
        where: { isActive: true },
      },
    });
  };

  const [defaultConfig, { loading }] = useLazyQuery(DEFAULT_CONFIG, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const configCopy = {
        industry: {
          id: res?.defaultConfig?.industry?.[0]?.id,
          label: res?.defaultConfig?.industry?.[0]?.label,
        },
        lineOfBusiness: {
          id: res?.defaultConfig?.industry?.[0]?.lineOfBusinesses?.[0]?.id,
          label:
            res?.defaultConfig?.industry?.[0]?.lineOfBusinesses?.[0]?.label,
        },
        subArea: {
          id:
            res?.defaultConfig?.industry?.[0]?.lineOfBusinesses?.[0]
              ?.subAreas?.[0]?.id,
          label:
            res?.defaultConfig?.industry?.[0]?.lineOfBusinesses?.[0]
              ?.subAreas?.[0]?.label,
          lineOfBusinessId:
            res?.defaultConfig?.industry?.[0]?.lineOfBusinesses?.[0]?.id,
        },
        brand: {
          id: res?.defaultConfig?.brand?.[0]?.id,
          name: res?.defaultConfig?.brand?.[0]?.name,
        },
        region: {
          id: res?.defaultConfig?.region?.[0]?.id,
          name: res?.defaultConfig?.region?.[0]?.parentName
            ? `${res?.defaultConfig?.region?.[0]?.parentName} / ${res?.defaultConfig?.region?.[0]?.name}`
            : res?.defaultConfig?.region?.[0]?.name,
        },
        orderFilters: {
          sortBy: 'DESC',
          sortOn: 'sellingPrice',
        },
        sponsorId: {
          id: res?.defaultConfig?.sponsor?.[0]?.id,
          businessName: res?.defaultConfig?.sponsor?.[0]?.businessName,
        },
      };
      dispatch({
        type: 'SET_PRODUCT_RANKING_CONFIG',
        data: configCopy,
      });
      updateData(configCopy);
      initialQueryCall();
    },
    onError() {},
  });

  useEffect(() => {
    const localStorageData = getData();

    setDynamicFieldData(
      // eslint-disable-next-line no-undef
      JSON?.parse(localStorage?.getItem(DYNAMIC_SELECTED_COLUMN)) || null,
    );
    setDynamicFieldDataLoading(false);
    if (!localStorageData && !showNotificationWarning) {
      defaultConfig();
    } else {
      dispatch({
        type: 'SET_PRODUCT_RANKING_CONFIG',
        data: { ...localStorageData, submit: true },
      });
      initialQueryCall();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isSponsor) {
      sponsors({
        variables: {
          filter: sponsorFilter,
        },
      });
    }
  }, [isSponsor]);

  useEffect(() => {
    if (
      productRankingConfig?.industry?.id &&
      !productRankingConfig?.lineOfBusiness?.id
    ) {
      setLineOfBusinessesLoading(true);
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            limit: LIMIT,
            industryId: productRankingConfig?.industry?.id,
          },
          where: { isActive: true },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRankingConfig?.industry?.id]);

  useEffect(() => {
    if (
      productRankingConfig?.lineOfBusiness?.id &&
      !productRankingConfig?.subArea?.id
    ) {
      setSubAreasLoading(true);
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            limit: LIMIT,
            industryId: productRankingConfig?.industry?.id,
            lobId: productRankingConfig?.lineOfBusiness?.id,
          },
          where: { isActive: true },
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRankingConfig?.lineOfBusiness?.id]);

  useEffect(() => {
    form?.setFieldsValue({
      industry: productRankingConfig?.industry?.id,
      lineOfBusiness: productRankingConfig?.lineOfBusiness?.id,
      subArea: productRankingConfig?.subArea?.id,
      brand: productRankingConfig?.brand?.id,
      region: productRankingConfig?.region?.id,
      sponsorId: productRankingConfig?.sponsorId?.id,
      orderFilters: { ...productRankingConfig?.orderFilters },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productRankingConfig]);

  useEffect(() => {
    if (dynamicFieldFormData) {
      setDynamicFieldData(
        dynamicFieldData?.map((item) => ({
          ...item,
          value:
            item?.fieldType === 'CHECKBOX' || item?.fieldType === 'BOOLEAN'
              ? dynamicFieldFormData?.[item?.key]
              : dynamicFieldFormData?.[item?.key]?.value,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamicFieldFormData]);

  const onIndustryScroll = (event) => {
    if (industryScrollDebounceJob) {
      industryScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !industriesIsEnd) {
        setIndustriesLoading(true);
        setIndustriesDebounceCall((prevState) => prevState + 1);
        industries({
          variables: {
            filter: {
              ...industryFilter,
              skip: (industriesDebounceCall + 1) * LIMIT,
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);

    industryScrollDebounceJob();
  };

  const onLineOfBusinessScroll = (event) => {
    if (lineOfBusinessScrollDebounceJob) {
      lineOfBusinessScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    lineOfBusinessScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !lineOfBusinessesIsEnd) {
        setLineOfBusinessesLoading(true);
        setLineOfBusinessesDebounceCall((prevState) => prevState + 1);
        lineOfBusinesses({
          variables: {
            filter: {
              ...lineOfBusinessFilter,
              skip: (lineOfBusinessesDebounceCall + 1) * LIMIT,
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);

    lineOfBusinessScrollDebounceJob();
  };

  const onSubAreaScroll = (event) => {
    if (subAreaScrollDebounceJob) {
      subAreaScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    subAreaScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !subAreasIsEnd) {
        setSubAreasLoading(true);
        setSubAreasDebounceCall((prevState) => prevState + 1);
        subAreas({
          variables: {
            filter: {
              ...subAreaFilter,
              skip: (subAreasDebounceCall + 1) * LIMIT,
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);

    subAreaScrollDebounceJob();
  };

  const onSponsorScroll = (event) => {
    if (sponsorScrollDebounceJob) {
      sponsorScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    sponsorScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !sponsorsIsEnd) {
        setSponsorsLoading(true);
        setSponsorsDebounceCall((prevState) => prevState + 1);
        sponsors({
          variables: {
            filter: {
              ...sponsorFilter,
              skip: (sponsorsDebounceCall + 1) * LIMIT,
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);

    sponsorScrollDebounceJob();
  };
  const onRegionScroll = (event) => {
    if (regionScrollDebounceJob) {
      regionScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    regionScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !regionsIsEnd) {
        setRegionsLoading(true);
        setRegionsDebounceCall((prevState) => prevState + 1);
        regionsProductRankingConfigData({
          variables: {
            filter: {
              ...regionFilter,
              skip: (regionsDebounceCall + 1) * LIMIT,
            },
          },
        });
      }
    }, 500);

    regionScrollDebounceJob();
  };

  const onBrandScroll = (event) => {
    if (brandScrollDebounceJob) {
      brandScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    brandScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !brandsIsEnd) {
        setBrandsLoading(true);
        setBrandsDebounceCall((prevState) => prevState + 1);
        brands({
          variables: {
            filter: {
              ...brandFilter,
              skip: (brandsDebounceCall + 1) * LIMIT,
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);

    brandScrollDebounceJob();
  };

  const panelHeader = (title = '', description = '') => (
    <div className="d-flex flex-vertical">
      <span>{title}</span>
      <span className="panel-description">{description}</span>
    </div>
  );

  const getDynamicFields = (arr, values) => {
    const val = values?.dynamicFields;
    return arr?.reduce((newObject, item) => {
      const temp = newObject;
      if (item?.field_content_type === 'static') {
        if (item?.key && val?.[item?.key]) {
          if (item?.fieldType === 'CHECKBOX' || item?.fieldType === 'BOOLEAN') {
            temp[item?.key] = val?.[item?.key];
          }
          if (
            (item?.fieldType === 'NUMBER' && val?.[item?.key]?.value !== 0) ||
            val?.[item?.key]?.value
          ) {
            temp[item?.key] = {
              value:
                item?.fieldType === 'PICK_LIST'
                  ? find(arr, (items) => items?.value === item?.value)?.value
                  : val?.[item?.key]?.value,
              checkType: [
                'is_greater_than',
                'is_lower_than',
                'range',
              ]?.includes(val?.[item?.key]?.filter)
                ? 'GREATER_LESS_RANGE'
                : val?.[item?.key]?.filter,
              ...(['is_greater_than', 'is_lower_than', 'range']?.includes(
                val?.[item?.key]?.filter,
              ) && {
                max:
                  val?.[item?.key]?.filter === 'is_lower_than'
                    ? val?.[item?.key]?.value
                    : val?.[item?.key]?.max || null,
                min:
                  val?.[item?.key]?.filter === 'is_greater_than'
                    ? val?.[item?.key]?.value
                    : val?.[item?.key]?.min || null,
              }),
            };
          }
        } else if (
          item?.fieldType === 'CHECKBOX' ||
          item?.fieldType === 'BOOLEAN'
        ) {
          temp[item?.key] = !!val?.[item?.key];
        }
      }
      return temp;
    }, {});
  };

  const getCustomDynamicFields = (arr, values, type) => {
    const val = values?.dynamicFields;
    return arr
      ?.filter(
        (item) =>
          item?.fieldType === type &&
          ((type === 'NUMBER' && val?.[item?.key]?.filter === 'range') ||
            type === 'BOOLEAN' ||
            val?.[item?.key]?.value) &&
          item?.field_content_type !== 'static',
      )
      ?.map(
        (item) =>
          ((type === 'NUMBER' && val?.[item?.key]?.filter === 'range') ||
            type === 'BOOLEAN' ||
            val?.[item?.key]?.value) && {
            id: item?.id,
            value:
              type === 'BOOLEAN'
                ? val?.[item?.key]
                : val?.[item?.key]?.value || 0,
            ...(type !== 'BOOLEAN' && {
              checkType: [
                'is_greater_than',
                'is_lower_than',
                'range',
              ]?.includes(val?.[item?.key]?.filter)
                ? 'GREATER_LESS_RANGE'
                : val?.[item?.key]?.filter,
            }),
            ...(['is_greater_than', 'is_lower_than', 'range'].includes(
              val?.[item?.key]?.filter,
            ) && {
              max:
                val?.[item?.key]?.filter === 'is_lower_than'
                  ? val?.[item?.key]?.value
                  : val?.[item?.key]?.max || null,
              min:
                val?.[item?.key]?.filter === 'is_greater_than'
                  ? val?.[item?.key]?.value
                  : val?.[item?.key]?.min || null,
            }),
          },
      );
  };

  if (loading) return <LoaderComponent />;

  const onFinish = async (values) => {
    const localStorageData = getData();
    dispatch({
      type: 'SET_PRODUCT_RANKING_CONFIG',
      data: {
        ...localStorageData,
        submit: true,
        customFilters: {
          numberFilters: getCustomDynamicFields(
            dynamicFieldData,
            values,
            'NUMBER',
          ),
          textFilters: getCustomDynamicFields(dynamicFieldData, values, 'TEXT'),
          pickListFilters: getCustomDynamicFields(
            dynamicFieldData,
            values,
            'PICK_LIST',
          ),
          booleanFilters:
            getCustomDynamicFields(dynamicFieldData, values, 'BOOLEAN') ||
            dynamicFieldData
              ?.filter(
                (item) =>
                  item?.fieldType === 'BOOLEAN' &&
                  values?.dynamicFields?.[item?.key] &&
                  item?.field_content_type !== 'static',
              )
              ?.map((item) => ({
                id: item?.id,
                value: values?.dynamicFields?.[item?.key] || '',
              })),
        },
        staticFilters: {
          ...getDynamicFields(dynamicFieldData, values),
        },
      },
    });
  };

  const panelCollapseItems = [
    !isSponsor && {
      forceRender: true,
      label: panelHeader(
        'Sponsor',
        productRankingConfig?.sponsorId?.businessName,
      ),
      key: 'sponsorId',
      collapsible: sponsorsLoading && 'disabled',
      extra: <ExtraLoaderComponent loading={sponsorsLoading} />,
      children: (
        <>
          {sponsorsData?.length > 0 ? (
            <div className="panel-section" onScroll={onSponsorScroll}>
              <LoaderComponent spinning={sponsorsLoading}>
                <Form.Item name="sponsorId">
                  <Radio.Group
                    className="common-radio"
                    onChange={(event) => {
                      if (dynamicFieldData?.length > 0) {
                        if (
                          productRankingConfig?.sponsorId?.id !==
                          event?.target?.value
                        ) {
                          confirm({
                            centered: true,
                            wrapClassName: 'confirm-modal',
                            okButtonProps: {
                              className: 'common-button',
                              type: 'primary',
                            },
                            cancelButtonProps: {
                              className: 'common-button discard-button',
                            },
                            content:
                              'Changing the sponsor result in the removal of custom filters. Are you sure you want to continue?',
                            onOk: () => {
                              handleChangeFilter(
                                event?.target?.value,
                                'sponsorId',
                                sponsorsData,
                              );
                              setDynamicFieldData([]);
                              //  eslint-disable-next-line no-undef
                              localStorage?.removeItem(DYNAMIC_SELECTED_COLUMN);
                            },
                            onCancel: () => {
                              form?.setFieldsValue({
                                sponsor: productRankingConfig?.sponsor?.id,
                              });
                            },
                          });
                        }
                      } else {
                        handleChangeFilter(
                          event?.target?.value,
                          'sponsorId',
                          sponsorsData,
                        );
                      }
                    }}
                  >
                    <Space direction="vertical">
                      {map(sponsorsData, (item) => (
                        <Radio key={item?.id} value={item?.id}>
                          {item?.businessName}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </>
      ),
    },
    {
      forceRender: true,
      label: panelHeader('Industry', productRankingConfig?.industry?.label),
      key: 'industry',
      collapsible: industriesLoading && 'disabled',
      extra: <ExtraLoaderComponent loading={industriesLoading} />,
      children: (
        <>
          {industriesData?.length > 0 ? (
            <div className="panel-section" onScroll={onIndustryScroll}>
              <LoaderComponent spinning={industriesLoading}>
                <Form.Item
                  name="industry"
                  rules={[
                    {
                      ...required,
                      message: 'Please Select Industry To Continue',
                    },
                  ]}
                >
                  <Radio.Group
                    className="common-radio"
                    onChange={(event) => {
                      if (dynamicFieldData?.length > 0) {
                        if (
                          productRankingConfig?.industry?.id !==
                          event?.target?.value
                        ) {
                          confirm({
                            centered: true,
                            wrapClassName: 'confirm-modal',
                            okButtonProps: {
                              className: 'common-button',
                              type: 'primary',
                            },
                            cancelButtonProps: {
                              className: 'common-button discard-button',
                            },
                            content:
                              'Changing the Industry/Line of Business will result in the removal of custom filters. Are you sure you want to continue?',
                            onOk: () => {
                              handleChangeFilter(
                                event?.target?.value,
                                'industry',
                                industriesData,
                              );
                              setDynamicFieldData([]);
                              //  eslint-disable-next-line no-undef
                              localStorage?.removeItem(DYNAMIC_SELECTED_COLUMN);
                            },
                            onCancel: () => {
                              form?.setFieldsValue({
                                industry: productRankingConfig?.industry?.id,
                              });
                            },
                          });
                        }
                      } else {
                        handleChangeFilter(
                          event?.target?.value,
                          'industry',
                          industriesData,
                        );
                      }
                    }}
                  >
                    <Space direction="vertical">
                      {map(industriesData, (item) => (
                        <Radio key={item?.id} value={item?.id}>
                          {item?.label}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </>
      ),
    },
    {
      forceRender: true,
      label: panelHeader(
        'Line of Business',
        productRankingConfig?.lineOfBusiness?.label,
      ),
      key: 'lineOfBusiness',
      collapsible: lineOfBusinessesLoading && 'disabled',
      extra: <ExtraLoaderComponent loading={lineOfBusinessesLoading} />,
      children: (
        <>
          {lineOfBusinessesData?.length > 0 ? (
            <div className="panel-section" onScroll={onLineOfBusinessScroll}>
              <LoaderComponent spinning={lineOfBusinessesLoading}>
                <Form.Item
                  name="lineOfBusiness"
                  rules={[
                    {
                      ...required,
                      message: 'Please Select Line Of Business To Continue',
                    },
                  ]}
                >
                  <Radio.Group
                    className="common-radio"
                    onChange={(event) => {
                      if (dynamicFieldData?.length > 0) {
                        if (
                          productRankingConfig?.lineOfBusiness?.id !==
                          event?.target?.value
                        ) {
                          confirm({
                            centered: true,
                            wrapClassName: 'confirm-modal',
                            okButtonProps: {
                              className: 'common-button',
                              type: 'primary',
                            },
                            cancelButtonProps: {
                              className: 'common-button discard-button',
                            },
                            content:
                              'Changing the Industry/Line of Business will result in the removal of custom filters. Are you sure you want to continue?',
                            onOk: () => {
                              handleChangeFilter(
                                event?.target?.value,
                                'lineOfBusiness',
                                lineOfBusinessesData,
                              );
                              // eslint-disable-next-line no-undef
                              localStorage?.removeItem(DYNAMIC_SELECTED_COLUMN);
                              setDynamicFieldData([]);
                            },
                            onCancel: () => {
                              form?.setFieldsValue({
                                lineOfBusiness:
                                  productRankingConfig?.lineOfBusiness?.id,
                              });
                            },
                          });
                        }
                      } else {
                        handleChangeFilter(
                          event?.target?.value,
                          'lineOfBusiness',
                          lineOfBusinessesData,
                        );
                      }
                    }}
                  >
                    <Space direction="vertical">
                      {map(lineOfBusinessesData, (item) => (
                        <Radio key={item?.id} value={item?.id}>
                          {item?.label}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </>
      ),
    },
    {
      forceRender: true,
      label: panelHeader('Service Type', productRankingConfig?.subArea?.label),
      key: 'subArea',
      collapsible: subAreasLoading && 'disabled',
      extra: <ExtraLoaderComponent loading={subAreasLoading} />,
      children: (
        <>
          {subAreasData?.length > 0 ? (
            <div className="panel-section" onScroll={onSubAreaScroll}>
              <LoaderComponent spinning={subAreasLoading}>
                <Form.Item
                  name="subArea"
                  rules={[
                    {
                      ...required,
                      message: 'Please Select Service Type To Continue',
                    },
                  ]}
                >
                  <Radio.Group
                    className="common-radio"
                    onChange={(event) =>
                      handleChangeFilter(
                        event?.target?.value,
                        'subArea',
                        subAreasData,
                      )
                    }
                  >
                    <Space direction="vertical">
                      {map(subAreasData, (item) => (
                        <Radio key={item?.id} value={item?.id}>
                          {item?.label}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </>
      ),
    },
    {
      label: panelHeader(
        'Region',
        productRankingConfig?.region?.parentName
          ? `${productRankingConfig?.region?.parentName} / ${productRankingConfig?.region?.name}`
          : productRankingConfig?.region?.name,
      ),
      forceRender: true,
      key: 'region',
      collapsible: regionsLoading && 'disabled',
      extra: <ExtraLoaderComponent loading={regionsLoading} />,
      children: (
        <>
          {regionsData?.length > 0 ? (
            <div className="panel-section" onScroll={onRegionScroll}>
              <LoaderComponent spinning={regionsLoading}>
                <Form.Item
                  name="region"
                  rules={[
                    {
                      ...required,
                      message: 'Please Select Region To Continue',
                    },
                  ]}
                >
                  <Radio.Group
                    className="common-radio"
                    onChange={(event) =>
                      handleChangeFilter(event?.target?.value, 'region')
                    }
                  >
                    <Space direction="vertical">
                      {map(regionsData, (item) => (
                        <Radio key={item?.id} value={item?.id}>
                          {item?.parentName
                            ? `${item?.parentName} / ${item?.name}`
                            : item?.name}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </>
      ),
    },
    {
      label: panelHeader(
        `${MODULES?.RETAILERS}`,
        productRankingConfig?.brand?.name,
      ),
      forceRender: true,
      key: 'BRAND',
      collapsible: brandsLoading && 'disabled',
      extra: <ExtraLoaderComponent loading={brandsLoading} />,
      children: (
        <>
          {brandsData?.length > 0 ? (
            <div className="panel-section" onScroll={onBrandScroll}>
              <LoaderComponent spinning={brandsLoading}>
                <Form.Item
                  name="brand"
                  rules={[
                    {
                      ...required,
                      message: 'Please Select Retailer To Continue',
                    },
                  ]}
                >
                  <Radio.Group
                    className="common-radio"
                    onChange={(event) =>
                      handleChangeFilter(event?.target?.value, 'brand')
                    }
                  >
                    <Space direction="vertical">
                      <>
                        {map(brandsData, (item) => (
                          <Radio key={item?.id} value={item?.id}>
                            {item?.name}
                          </Radio>
                        ))}
                      </>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </>
      ),
    },
    ...(dynamicFieldData?.length > 0
      ? map(dynamicFieldData, (item) => {
          const getDynamicFieldsHeader = () => {
            if (item?.fieldType === 'PICK_LIST') {
              return find(
                item?.dynamicFieldListItems,
                (items) => items?.value === item?.value,
              )?.label;
            }
            if (item?.fieldType === 'BOOLEAN') {
              return item?.value
                ? item?.config?.BOOLEAN?.trueValue
                : item?.config?.BOOLEAN?.falseValue;
            }
            if (item?.fieldType === 'CHECKBOX') {
              return item?.value ? 'true' : 'false';
            }

            return (
              item?.value ||
              find(DYNAMIC_FIELDS_DATA, ['key', item?.fieldType])?.label
            );
          };

          return {
            key: item?.id,
            label: panelHeader(
              capitalize(item?.label),
              getDynamicFieldsHeader(),
            ),
            extra: !!item?.id && (
              <div
                onClick={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                }}
              >
                <Popconfirm
                  title={
                    <p className="m-0 p-0">
                      <span>
                        Your custom filter will be removed from here
                        <br />
                        Are you sure you want to delete?
                      </span>
                    </p>
                  }
                  onConfirm={() => {
                    const updatedDynamicList = JSON?.parse(
                      // eslint-disable-next-line no-undef
                      localStorage?.getItem(DYNAMIC_SELECTED_COLUMN),
                    )?.filter((items) => items?.id !== item?.id);
                    setDynamicFieldData(updatedDynamicList);
                    //  eslint-disable-next-line no-undef
                    localStorage?.setItem(
                      DYNAMIC_SELECTED_COLUMN,
                      JSON?.stringify(updatedDynamicList),
                    );
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <div className="delete-dynamic-field-filter">
                    <DeleteOutlined />
                  </div>
                </Popconfirm>
              </div>
            ),
            forceRender: true,
            collapsible: dynamicFieldDataLoading && 'disabled',
            children: (
              <>
                <div className="dynamic-field-form">
                  {renderDynamicFields(item?.fieldType, item)}
                </div>
              </>
            ),
          };
        })
      : []),
  ];

  return (
    <>
      <DynamicFilterModal
        industryId={productRankingConfig?.industry?.id}
        lineOfBusiness={productRankingConfig?.lineOfBusiness?.id}
        showFilterModal={showFilterModal}
        handleShowFilterModal={handleShowFilterModal}
      />
      <div className="product-ranking-panel-component">
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={(errorValues) => {
            setDefaultPanelKey(
              errorValues?.errorFields?.map((item) => item?.name?.[0]),
            );
          }}
        >
          <div className="qrv-filter-button">
            <Button
              size="large"
              className="common-button import-button discard-button"
              icon={<SyncOutlined />}
              type="secondary"
              onClick={() => {
                dispatch({
                  type: 'SET_PRODUCT_RANKING_CONFIG',
                  data: null,
                });
                form?.resetFields();
              }}
            >
              Reset Filter
            </Button>
            <Button
              size="large"
              className="common-button import-button common-button"
              icon={<FilterOutlined />}
              type="primary"
              onClick={() => handleShowFilterModal(true, () => {})}
            >
              Custom Filter
            </Button>
          </div>
          <Collapse
            bordered={false}
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
            activeKey={defaultPanelKey}
            onChange={(key) => {
              setDefaultPanelKey(key);
            }}
            items={[...panelCollapseItems?.filter((item) => item !== false)]}
          />

          <div className="footer-btn-section">
            <Button
              htmlType="submit"
              className="common-button"
              size="small"
              id="apply-btn"
              type="primary"
            >
              <span>Apply</span>
            </Button>
          </div>
          <div className="pannel-footer">
            <span className="label">Sort On :</span>
            <Form.Item name={['orderFilters', 'sortOn']}>
              <SelectComponent
                placeholder="Select Order"
                onSelect={(value) => {
                  const localStorageData = getData();
                  const configCopy = {
                    ...localStorageData,
                    orderFilters: {
                      ...localStorageData?.orderFilters,
                      sortOn: value,
                    },
                  };
                  dispatch({
                    type: 'SET_PRODUCT_RANKING_CONFIG',
                    data: configCopy,
                  });
                  updateData(configCopy);
                  form?.submit();
                }}
                allowClear
              >
                {map(PRODUCT_CONFIG_SORT_ON_OPTION, (item) => (
                  <Option key={item?.value} value={item?.value}>
                    {item?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
            <Form.Item name={['orderFilters', 'sortBy']}>
              <Radio.Group
                onChange={(event) => {
                  const localStorageData = getData();
                  const configCopy = {
                    ...localStorageData,
                    orderFilters: {
                      ...localStorageData?.orderFilters,
                      sortBy: event?.target?.value,
                    },
                  };
                  dispatch({
                    type: 'SET_PRODUCT_RANKING_CONFIG',
                    data: configCopy,
                  });
                  updateData(configCopy);
                  form?.submit();
                }}
              >
                {map(PRODUCT_CONFIG_SORT_BY_OPTION, (item) => (
                  <Radio className="common-radio" value={item?.value}>
                    {item?.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ProductRankingPanelComponent;
