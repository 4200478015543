import { useLazyQuery, useQuery } from '@apollo/client';
import { Button, Checkbox, Col, Divider, Empty, Form, Row } from 'antd';
import { debounce, every, filter, find, findIndex, forEach, map } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { messageContext } from '../../../app/components/AppContextHolder';
import DeleteIconComponent from '../../../app/components/iconComponents/DeleteIconComponent';
import SaveIcon from '../../../assets/save.svg';
import { REGEX, ROUTES, SKIP_RECORD } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import NumberComponent from '../../../components/NumberComponent';
import Portal from '../../../components/Portal';
import PriceComponent from '../../../components/PriceComponent';
import SelectComponent from '../../../components/SelectComponent';
import history from '../../../historyData';
import { FETCH_STATE_CITY } from '../../signup/graphql/Query';
import { GET_INDUSTRIES } from '../graphql/Queries';

let stateScrollDebounce;
let cityScrollDebounceJob;
// commented as currently it is text input
// let campaignScrollDebounceJob;

const {
  required,
  email,
  zipCode,
  firstName,
  lastName,
  address,
  number,
  url: urlCheck,
} = formValidatorRules;

const stateCityFilter = {
  skip: 0,
  limit: 20,
  type: 'STATE',
  search: '',
  sortOn: 'name',
  sortBy: 'ASC',
};

// commented as currently it is text input
// const campaignFilter = {
//   sortOn: 'name',
//   sortBy: 'ASC',
//   skip: 0,
//   limit: 20,
//   search: '',
// };

const { Option } = SelectComponent;
const CheckboxGroup = Checkbox.Group;

const ProspectForm = (props) => {
  const {
    state: { permissions },
    dispatch,
  } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const { prospectsData = null, handleSubmit, isSubmit } = props;
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [fetchCity, setFetchCity] = useState(false);
  const [citySearchFlag, setCitySearchFlag] = useState(false);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [stateSearchFlag, setStateSearchFlag] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [disableCity, setDisableCity] = useState(true);
  const [disableState, setDisableState] = useState(false);
  const [callAsync, setCallAsync] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [stateIsEnd, setStateIsEnd] = useState(false);
  const [cityIsEnd, setCityIsEnd] = useState(false);

  // commented as currently it is text input
  // const [campaignLoading, setCampaignLoading] = useState(false);
  // const [campaignSearchFlag, setCampaignSearchFlag] = useState(false);
  // const [campaignsData, setCampaignsData] = useState([]);
  // const [isEnd, setIsEnd] = useState(false);
  // const [debounceCall, setDebounceCall] = useState(0);

  // industry, lob and service type
  const [loading, setLoading] = useState(true);
  const [checkBoxArray, setCheckBoxArray] = useState([]);

  const [form] = Form?.useForm();
  const [fetchStateAndCity] = useLazyQuery(FETCH_STATE_CITY, {
    fetchPolicy: 'network-only',
    onCompleted(response) {
      const moreData = response?.getLocationType?.data;
      if (fetchCity) {
        setCityIsEnd(moreData?.length < SKIP_RECORD);
        if (citySearchFlag) {
          setCities([...moreData]);
        } else {
          setCities([...cities, ...moreData]);
        }
        setCityLoading(false);
      } else {
        setStateIsEnd(moreData?.length < SKIP_RECORD);
        if (stateSearchFlag) {
          setStates([...moreData]);
        } else {
          setStates([...states, ...moreData]);
        }
        setStateLoading(false);
        setCallAsync(false);
      }
    },
    onError() {
      setStateLoading(false);
      setCityLoading(false);
    },
  });

  // commented as currently it is text input
  // const [campaigns] = useLazyQuery(GET_CAMPAIGNS, {
  //   fetchPolicy: 'network-only',
  //   onCompleted: (res) => {
  //     setIsEnd(res?.campaigns?.data?.length < SKIP_RECORD);
  //     if (campaignSearchFlag) {
  //       setCampaignsData([...res?.campaigns?.data]);
  //     } else {
  //       setCampaignsData([...campaignsData, ...res?.campaigns?.data]);
  //     }
  //     setCampaignLoading(false);
  //   },
  //   onError() {
  //     setCampaignLoading(false);
  //   },
  // });

  const { data } = useQuery(GET_INDUSTRIES, {
    variables: {
      filter: {
        justShow: true,
      },
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const checkboxArray = [];
      forEach(res?.industries?.data, (industry) => {
        const activeData = filter(
          industry?.lobSaData,
          (item) => item?.isActive,
        );
        checkboxArray?.push({
          indeterminate:
            activeData?.length > 0
              ? !!(industry?.lobSaData?.length !== activeData?.length)
              : false,
          checkedAll: !!(industry?.lobSaData?.length === activeData?.length),
          checkedChildren: map(industry?.lobSaData, (lob) => {
            if (lob?.isActive === true) {
              return lob?.id;
            }
          }),
          id: industry?.id,
          key: industry?.key,
        });
      });
      setCheckBoxArray(checkboxArray);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (!callAsync && states?.length > 0) {
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: prospectsData?.state,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAsync]);

  useEffect(() => {
    const unListen = history?.listen((historyProps) => {
      if (historyProps?.action === 'POP') {
        navigate(historyProps?.location?.pathname, {
          state: { ...location?.state },
        });
      }
    });
    if (!prospectsData?.state) {
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter,
        },
      });
    }
    if (prospectsData?.country) {
      setDisableState(false);
    }
    // commented as currently it is text input
    // campaigns({
    //   variables: {
    //     filter: campaignFilter,
    //   },
    // });
    if (prospectsData?.state) {
      setDisableCity(false);
      setCitySearchFlag(true);
      setCallAsync(true);
      setSelectedState(prospectsData?.state);
      fetchStateAndCity({
        variables: {
          filter: stateCityFilter,
        },
      });
    }
    return () => {
      if (unListen) {
        unListen();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // commented as currently it is text input
  // const onCampaignScroll = (event) => {
  //   setCampaignSearchFlag(false);
  //   if (campaignScrollDebounceJob) {
  //     campaignScrollDebounceJob?.cancel();
  //   }
  //   const { target } = event;
  //   const { scrollTop, scrollHeight, offsetHeight } = target || {};

  //   campaignScrollDebounceJob = debounce(() => {
  //     const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
  //     if (scrolledToBottom && !isEnd) {
  //       setCampaignLoading(true);
  //       setDebounceCall((prevState) => prevState + 1);
  //       campaigns({
  //         variables: {
  //           filter: {
  //             ...campaignFilter,
  //             skip: (debounceCall + 1) * SKIP_RECORD,
  //             search: searchValue,
  //           },
  //           where: { isActive: true },
  //         },
  //       });
  //     }
  //   }, 500);

  //   campaignScrollDebounceJob();
  // };

  // const handleCampaignChange = (value) => {
  //   setCampaignSearchFlag(true);
  //   setSearchValue(value);
  //   if (value) {
  //     setCampaignLoading(true);
  //     campaigns({
  //       variables: {
  //         filter: {
  //           ...campaignFilter,
  //           search: value,
  //         },
  //         where: { isActive: true },
  //       },
  //     });
  //   } else {
  //     setCampaignLoading(true);
  //     campaigns({
  //       variables: {
  //         filter: {
  //           ...campaignFilter,
  //           search: value,
  //         },
  //         where: { isActive: true },
  //       },
  //     });
  //   }
  // };

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debouncedCampaignHandler = useCallback(
  //   debounce(handleCampaignChange, 500),
  //   [],
  // );

  // const handleCampaignClear = () => {
  //   form?.setFieldsValue({
  //     brands: [],
  //   });
  //   setCampaignsData([]);
  //   campaigns({
  //     variables: { filter: campaignFilter, where: { isActive: true } },
  //   });
  // };

  // const handleCampaignBlur = () => {
  //   setSearchValue('');
  //   setDebounceCall(0);
  //   setIsEnd(false);
  //   setCampaignSearchFlag(true);
  //   campaigns({
  //     variables: {
  //       filter: campaignFilter,
  //       where: { isActive: true },
  //     },
  //   });
  // };

  const handleCityBlur = () => {
    setSearchValue('');
    setCityIsEnd(false);
  };

  const handleStateBlur = () => {
    setStateIsEnd(false);
  };

  const handleCityClear = () => {
    setFetchCity(true);
    fetchStateAndCity({
      variables: {
        filter: {
          ...stateCityFilter,
          type: 'CITY',
          state: selectedState,
        },
      },
    });
  };

  const onStateScroll = (event) => {
    setFetchCity(false);
    setStateSearchFlag(false);
    if (stateScrollDebounce) {
      stateScrollDebounce?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    stateScrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !stateIsEnd) {
        setStateLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: states?.length,
              search: searchValue,
              type: 'STATE',
            },
          },
        });
      }
    }, 500);

    stateScrollDebounce();
  };

  const onCityScroll = (event) => {
    setCitySearchFlag(false);
    setFetchCity(true);
    if (cityScrollDebounceJob) {
      cityScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    cityScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !cityIsEnd) {
        setCityLoading(true);
        fetchStateAndCity({
          variables: {
            filter: {
              ...stateCityFilter,
              skip: cities?.length,
              type: 'CITY',
              search: searchValue,
              state: selectedState,
            },
          },
        });
      }
    }, 500);

    cityScrollDebounceJob();
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const handleStateChange = (value) => {
    setFetchCity(false);
    setSearchValue(value);
    setStateSearchFlag(true);
    const state = form?.getFieldValue('state');
    if (value) {
      setStateLoading(true);
      setCities([]);
      setDisableCity(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE',
            search: value,
          },
        },
      });
    } else {
      if (!state?.length) {
        setStateLoading(true);
        setDisableCity(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter,
          },
        });
      }
      if (state?.length > 0) {
        setDisableCity(false);
        setCitySearchFlag(true);
        setCallAsync(true);
        fetchStateAndCity({
          variables: {
            filter: stateCityFilter,
          },
        });
      }
    }
  };

  const handleStateSelect = (value) => {
    if (value) {
      form?.setFieldsValue({
        city: null,
      });
      setSelectedState(value);
      setDisableCity(false);
      setCitySearchFlag(true);
      setFetchCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: value,
          },
        },
      });
      setStateLoading(false);
    } else {
      setCitySearchFlag(true);
      setStateLoading(false);
      setDisableCity(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'STATE',
          },
        },
      });
    }
  };

  const handleSelectCountry = (value) => {
    setDisableState(!value);
  };

  const handleCityChange = (value) => {
    setFetchCity(true);
    setCitySearchFlag(true);
    setSearchValue(value);
    if (value) {
      setCityLoading(true);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            search: value,
            state: form?.getFieldValue('state'),
          },
        },
      });
    } else {
      setCityLoading(false);
      fetchStateAndCity({
        variables: {
          filter: {
            ...stateCityFilter,
            type: 'CITY',
            state: form?.getFieldValue('state'),
          },
        },
      });
    }
  };

  const handleCountryClear = () => {
    form?.setFieldsValue({
      country: null,
      state: null,
      city: null,
    });
    setDisableState(true);
    setDisableCity(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedStateHandler = useCallback(
    debounce(handleStateChange, 500),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedCityHandler = useCallback(debounce(handleCityChange, 500), []);

  const handleStateClear = () => {
    form?.setFieldsValue({
      state: null,
      city: null,
    });
    setFetchCity(false);
    fetchStateAndCity({
      variables: {
        filter: stateCityFilter,
      },
    });
    setSelectedState('');
    setCities([]);
    setDisableCity(true);
  };

  const onCheckAllChange = (e, industry, index) => {
    const checkboxArrayCopy = [...checkBoxArray];
    const checked = [];
    if (e?.target?.checked) {
      forEach(industry?.lobSaData, (area) => checked?.push(area?.id));
    }
    checkboxArrayCopy[index].indeterminate = false;
    checkboxArrayCopy[index].checkedAll = e?.target?.checked;
    checkboxArrayCopy[index].checkedChildren = checked;
    setCheckBoxArray(checkboxArrayCopy);
  };

  const onChange = (list, industry, index) => {
    const checkboxArrayCopy = [...checkBoxArray];
    if (list?.length > 0) {
      checkboxArrayCopy[index].checkedChildren = list;
    } else {
      checkboxArrayCopy[index].checkedChildren = [];
    }
    if (list?.length === industry?.lobSaData?.length) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = true;
    } else if (list?.length === 0) {
      checkboxArrayCopy[index].indeterminate = false;
      checkboxArrayCopy[index].checkedAll = false;
    } else {
      checkboxArrayCopy[index].indeterminate = true;
      checkboxArrayCopy[index].checkedAll = false;
    }
    setCheckBoxArray(checkboxArrayCopy);
  };

  return (
    <div className="content-section">
      <AccessControl allowedPermissions={['FET_PROSPECT_CREATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button discard-button"
            icon={<DeleteIconComponent />}
            size="small"
            id="prospect-table-discard-btn"
            onClick={() =>
              navigate(`${ROUTES?.PROSPECTS}`, {
                state: { ...location?.state },
              })
            }
          >
            Cancel
          </Button>
          <Button
            className="common-button"
            icon={<img src={SaveIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="prospect-table-save-btn"
            loading={isSubmit}
            type="primary"
            onClick={form?.submit}
          >
            Save
          </Button>
        </Portal>
      </AccessControl>
      <Form
        form={form}
        initialValues={prospectsData}
        layout="vertical"
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        onFinish={(values) => {
          if (
            every(checkBoxArray, (item) => item?.checkedChildren?.length < 1)
          ) {
            messageContext?.error(
              'Please select at least one Line Of Business',
            );
            return;
          }
          dispatch({ type: 'SET_SHOW_PROMPT', data: false });
          handleSubmit(values, checkBoxArray);
        }}
        onValuesChange={() => dispatch({ type: 'SET_SHOW_PROMPT', data: true })}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
      >
        <fieldset
          disabled={!checkPermissions(permissions, ['FET_PROSPECT_CREATE'])}
        >
          <span className="form-divider-text">MANDATORY</span>
          <Divider className="form-divider" />
          <Row gutter={16} className="required-row">
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter First Name' },
                  firstName,
                ]}
                name="firstName"
                label="First Name"
              >
                <InputComponent allowClear placeholder="Enter First name" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter Last Name' },
                  lastName,
                ]}
                name="lastName"
                label="Last Name"
              >
                <InputComponent allowClear placeholder="Enter Last Name" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="businessName"
                label="Business Name"
                rules={[{ ...required, message: 'Please Enter Business Name' }]}
              >
                <InputComponent allowClear placeholder="Enter Business Name" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                label="Contact"
                name="phoneNo"
                rules={[
                  { ...required, message: 'Please Enter Contact' },
                  () => ({
                    validator(rule, value) {
                      if (value) {
                        // eslint-disable-next-line no-param-reassign
                        value = value?.split(' ')?.join('');
                        const numberPattern = REGEX?.PHONE;
                        if (!numberPattern?.test(value)) {
                          // eslint-disable-next-line prefer-promise-reject-errors
                          return Promise?.reject(
                            'should be a valid phone number',
                          );
                        }
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
              >
                <PatternFormat
                  placeholder="(___) ___-____"
                  format="(###) ###-####"
                  mask="_"
                  customInput={InputComponent}
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[
                  { ...required, message: 'Please Enter Contact Name' },
                  {
                    validator(_, value) {
                      if (!value) {
                        return Promise?.resolve();
                      }
                      if (!REGEX?.NAME?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject(
                          'Contact Name should not contain the special characters.',
                        );
                      }
                      return Promise?.resolve();
                    },
                  },
                ]}
                name="contactName"
                label="Contact Name"
              >
                <InputComponent
                  allowClear
                  placeholder="This is the primary contact for all communications"
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[{ ...required, message: 'Please Enter Email' }, email]}
                name="email"
                label="Email"
              >
                <InputComponent allowClear placeholder="Enter Email" />
              </Form.Item>
            </Col>
          </Row>
          <span className="form-divider-text">INDUSTRY - LINE OF BUSINESS</span>
          <Divider className="form-divider" />
          {loading ? (
            <LoaderComponent setHeight={10} />
          ) : (
            data?.industries?.data?.length > 0 && (
              <div className="d-flex flex-wrap block-parent">
                {map(data?.industries?.data, (industry) => {
                  if (industry?.isActive) {
                    return (
                      <div className="industry-block" key={industry?.key}>
                        <Checkbox
                          className="common-checkbox"
                          id={industry?.key}
                          indeterminate={
                            find(
                              checkBoxArray,
                              (item) => item?.key === industry?.key,
                            )?.indeterminate
                          }
                          onChange={(e) =>
                            onCheckAllChange(
                              e,
                              industry,
                              findIndex(
                                checkBoxArray,
                                (item) => item?.key === industry?.key,
                              ),
                            )
                          }
                          checked={
                            find(
                              checkBoxArray,
                              (item) => item?.key === industry?.key,
                            )?.checkedAll
                          }
                        >
                          <span className="bold-label">{industry?.label}</span>
                        </Checkbox>
                        <Divider />
                        <div className="d-flex">
                          <CheckboxGroup
                            options={map(industry?.lobSaData, (area) => {
                              if (area?.isActive) {
                                return {
                                  label: area?.label,
                                  value: area?.id,
                                };
                              }
                            })}
                            value={
                              find(
                                checkBoxArray,
                                (item) => item?.key === industry?.key,
                              )?.checkedChildren
                            }
                            className="common-checkbox subarea-group"
                            onChange={(list) =>
                              onChange(
                                list,
                                industry,
                                findIndex(
                                  checkBoxArray,
                                  (item) => item?.key === industry?.key,
                                ),
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )
          )}
          <Row gutter={16}>
            <span className="form-divider-text optional-divider">OPTIONAL</span>
            <Divider className="form-divider optional-divider" />
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="addressLine1" label="Address" rules={[address]}>
                <InputComponent allowClear placeholder="Enter Address" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="country" label="Country">
                <SelectComponent
                  placeholder="Select Country"
                  onSelect={handleSelectCountry}
                  onClear={handleCountryClear}
                  allowClear
                >
                  <Option key="USA" value="USA">
                    USA
                  </Option>
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="state" label="State">
                <SelectComponent
                  placeholder="Select State"
                  disabled={disableState}
                  allowClear
                  notFoundContent={
                    stateLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onSearch={debouncedStateHandler}
                  onClear={handleStateClear}
                  onSelect={handleStateSelect}
                  onPopupScroll={onStateScroll}
                  onBlur={handleStateBlur}
                >
                  {map(states, (state) => (
                    <Option key={state?.id} value={state?.name}>
                      {state?.name}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="city" label="City">
                <SelectComponent
                  placeholder="Select City"
                  disabled={disableCity}
                  notFoundContent={
                    cityLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onSearch={debouncedCityHandler}
                  onPopupScroll={onCityScroll}
                  onClear={handleCityClear}
                  onBlur={handleCityBlur}
                >
                  {cities?.map((city) => (
                    <Option key={city?.id} value={city?.name}>
                      {city?.name}
                    </Option>
                  ))}
                </SelectComponent>
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="zipCode" rules={[zipCode]} label="Postal Code">
                <NumberComponent placeholder="Enter Postal code" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                className="ml-6"
                label="Website"
                name="website"
                rules={[urlCheck]}
              >
                <InputComponent placeholder="URL" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="notes" label="Profile Notes">
                <InputComponent
                  allowClear
                  placeholder="Enter any additional information or notes here"
                />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="noOfEmployee"
                label="Number of Employees"
                rules={[number]}
              >
                <NumberComponent placeholder="Enter Number of Employees" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="revenue" label="Annual Revenue">
                <PriceComponent isPrice placeholder="Enter Annual Revenue" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="locations" label="Locations" rules={[number]}>
                <NumberComponent placeholder="Total number of offices or branches" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="region" label="Regions">
                <InputComponent allowClear placeholder="Enter Regions" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="marketFocus" label="Market Focus">
                <InputComponent allowClear placeholder="Enter Market Focus" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item name="currentPartners" label="Current Partners">
                <InputComponent
                  allowClear
                  placeholder="Enter Current Partners"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              {/* commented as currently it is text input */}
              {/* <Form.Item name="campaignId" label="Campaign">
                <SelectComponent
                  placeholder="Select Campaign"
                  allowClear
                  notFoundContent={
                    campaignLoading ? (
                      <LoaderComponent size="small" setHeight={10} />
                    ) : (
                      <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onBlur={handleCampaignBlur}
                  onSearch={debouncedCampaignHandler}
                  onClear={handleCampaignClear}
                  onPopupScroll={onCampaignScroll}
                >
                  <>
                    {map(campaignsData, (item) => (
                      <Option key={item?.id} value={item?.id}>
                        {item?.name}
                      </Option>
                    ))}
                  </>
                </SelectComponent>
              </Form.Item> */}
              <Form.Item name="campaignId" label="Campaign ID">
                <InputComponent allowClear placeholder="Enter Campaign ID" />
              </Form.Item>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                name="sourceOfInformation"
                label="Source of Information"
              >
                <InputComponent
                  allowClear
                  placeholder="From where did you come to know about this tenant?"
                />
              </Form.Item>
            </Col>
          </Row>
        </fieldset>
      </Form>
    </div>
  );
};

export default ProspectForm;
