import { Button, Card } from 'antd';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import ImportTable from '../components/ImportTable';

const ImportList = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);

  const navigate = useNavigate();

  const handleUpload = () => {
    navigate(`${ROUTES?.IMPORTS}/upload`);
  };
  return (
    <Card className="full-height-card card-body-p-20 import-list">
      <Portal portalId="header-left-content">
        <span className="portal-header">Imports</span>
      </Portal>
      <Portal portalId="header-right-content">
        <Button type="primary" onClick={handleUpload}>
          New Import
        </Button>
      </Portal>
      {pageSize && <ImportTable />}
    </Card>
  );
};

export default ImportList;
