import React, { useContext, useMemo } from 'react';
import { AppContext } from '../../../AppContext';
import checkCircleLight from '../../../assets/check-circle-light.svg';
import { planDetails } from '../../../common/utils';

const PlanSettingForm = () => {
  const {
    state: { isSponsor, onBoardingData },
  } = useContext(AppContext);

  const isCreatedBySponsor = useMemo(
    () =>
      onBoardingData?.data?.tenantInput?.createSourceType === 'SPONSOR_PORTAL',
    [],
  );

  const planDetailsSettings = useMemo(
    () =>
      planDetails(
        isCreatedBySponsor && !isSponsor,
        onBoardingData?.data?.tenantInput?.sponsorBusinessName,
      ),
    [],
  );

  return (
    <div className="content-section content-header">
      <div className="d-flex justify-center align-center flex-horizontal plan-card-wrapper">
        <div className="plan-card">
          <span className="plan-title">{planDetailsSettings?.title}</span>
          <div>
            {isCreatedBySponsor && !isSponsor && (
              <span className="bullet-points head-title">
                Sponsors your Path.Pro account
              </span>
            )}
          </div>
          <span className="plan-description">
            {planDetailsSettings?.description}
          </span>
          <div className="bullet-points">
            <div>
              <img src={checkCircleLight} alt="check-circle" />
              <span>{planDetailsSettings?.firstBullet}</span>
            </div>
            <div className="mt-24">
              <img src={checkCircleLight} alt="check-circle" />
              <span>{planDetailsSettings?.secondBullet}</span>
            </div>
            <div className="mt-24">
              <img src={checkCircleLight} alt="check-circle" />
              <span>{planDetailsSettings?.thirdBullet}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlanSettingForm;
