import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Popconfirm,
  Popover,
  Tag,
} from 'antd';
import { debounce, filter, find, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import ImportIcon from '../../../assets/import.svg';
import { ROUTES, SKIP_RECORD, exportOptions } from '../../../common/constants';
import {
  checkPermissions,
  formatPhoneNumber,
  handleExportCommon,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import ShowMoreComponent from '../../../components/ShowMoreComponent';
import TableComponent from '../../../components/TableComponent';
import ImportModal from '../../imports/components/ImportModal';
import { DELETE_USER, UPDATE_USER } from '../graphql/Mutations';
import { GET_USERS, ROLE_FILTER, USER_FILTER } from '../graphql/Queries';

let scrollDebounce = null;

const initialPaginationValue = {
  total: 0,
  current: 1,
};

const UserTable = () => {
  const {
    state: { pageSize, filterData, permissions },
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const initialUserFilter = useMemo(
    () => ({
      skip: 0,
      limit: pageSize,
      sortOn: 'createdAt',
      sortBy: 'DESC',
    }),
    [pageSize],
  );

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [showImportModal, setShowImportModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [userFilter, setUserFilter] = useState(initialUserFilter);
  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [searchValue, setSearchValue] = useState(null);

  const [updateUserMutate, { loading: updateUserLoading }] = useMutation(
    UPDATE_USER,
    {
      onError() {},
    },
  );

  const [deleteUser, { loading: deleteUserLoading }] = useMutation(
    DELETE_USER,
    {
      onError() {},
    },
  );

  const [fetchUserData, { loading, data }] = useLazyQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.users?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  const [userFilters] = useLazyQuery(USER_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.userFilters?.data, (item) => {
          if (filterIndex === 'name') {
            optionsCopy?.push(`${item?.firstName} ${item?.lastName}`);
          } else {
            optionsCopy?.push(item?.[filterIndex]);
          }
        });
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.userFilters?.data, (item) => {
          if (filterIndex === 'name') {
            optionsCopy?.push(`${item?.firstName} ${item?.lastName}`);
          } else {
            optionsCopy?.push(item?.[filterIndex]);
          }
        });
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.userFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [roleFilter] = useLazyQuery(ROLE_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.roleFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.roleFilters?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.roleFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, ['FET_USER_LIST']);
    const whereFilter = location?.state;
    let parsedObject;

    if (!isEmpty(whereFilter)) {
      parsedObject = JSON?.parse(whereFilter?.stringifyObject);
    }

    if (permitted) {
      fetchUserData({
        variables: {
          filter: parsedObject?.userFilter ?? userFilter,
          ...(parsedObject && { where: parsedObject?.filters }),
        },
      });
      if (!isEmpty(parsedObject)) {
        setFilters({ ...parsedObject?.filters });
        setFiltersCopyState({
          ...parsedObject?.filters,
        });

        setSearchValue(parsedObject?.userFilter?.search);
        const sorter = {
          order:
            parsedObject?.userFilter?.sortBy === 'ASC' ? 'ascend' : 'descend',
          columnKey: parsedObject?.userFilter?.sortOn,
        };
        setSortedInfo({ ...sorter });
        setUserFilter({ ...parsedObject?.userFilter });
        setPaginationProp({ ...parsedObject?.paginationProp });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('beforeunload', () => {
      // eslint-disable-next-line no-undef
      if (window?.location?.pathname === ROUTES?.USERS)
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
    });
    return () => {
      // eslint-disable-next-line no-undef
      window?.removeEventListener('beforeunload', () => {
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
      });
    };
  }, []);

  const getFilterData = (confirm) => {
    fetchUserData({
      variables: {
        filter: { ...userFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState }),
      },
    });
    setFilters(filtersCopyState);
    setUserFilter({
      ...userFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'roles':
          roleFilter({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: ['label', 'key'],
              },
            },
          });
          break;

        default:
          userFilters({
            variables: {
              filter: {
                sortOn: filterIndex === 'name' ? 'firstName' : filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField:
                  filterIndex === 'name'
                    ? ['firstName', 'lastName']
                    : filterIndex,
                showCurrentUser: false,
              },
            },
          });
          break;
      }
    }
    if (!filterVisible) {
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);

        switch (dataIndex) {
          case 'roles':
            roleFilter({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: ['label', 'key'],
                },
              },
            });
            break;

          default:
            userFilters({
              variables: {
                filter: {
                  sortOn: filterIndex === 'name' ? 'firstName' : filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField:
                    filterIndex === 'name'
                      ? ['firstName', 'lastName']
                      : filterIndex,
                },
              },
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id,
      });
    },
  };

  const handleResetRole = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: [],
    };
    setFilters(filtersCopy);
    setFiltersCopyState(filtersCopy);
    fetchUserData({
      variables: {
        filter: { ...userFilter, skip: 0, sortOn: 'createdAt', sortBy: 'DESC' },
        ...(filtersCopy && { where: filtersCopy }),
      },
    });
    setUserFilter({
      ...userFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const handleAddEditUser = (record) => {
    const otherFilters = { userFilter, paginationProp, filters };
    const stringifyObject = JSON?.stringify(otherFilters);

    if (record?.id) {
      navigate(`${ROUTES?.USERS}/edit/${record?.id}`, {
        state: { stringifyObject },
      });
    } else {
      navigate(`${ROUTES?.USERS}/add`, { state: { stringifyObject } });
    }
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setUserFilter(initialUserFilter);
    setSortedInfo({});
    fetchUserData({
      variables: { filter: initialUserFilter },
    });
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      fetchUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    } else {
      setUserFilter({
        ...userFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      fetchUserData({
        variables: {
          filter: {
            ...userFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'roles':
        roleFilter({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: ['label', 'key'],
            },
          },
        });
        break;

      default:
        userFilters({
          variables: {
            filter: {
              sortOn: dataIndex === 'name' ? 'firstName' : dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField:
                dataIndex === 'name' ? ['firstName', 'lastName'] : dataIndex,
            },
          },
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex, isContactNo = false) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          {dataIndex !== 'isActive' && (
            <SearchComponent
              className="list-search-box filter-search"
              id="search-container-id-roles"
              placeholder="Search..."
              name={dataIndex}
              getData={(value) => handleSearch(value, dataIndex)}
            />
          )}
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => (
                <Tag
                  key={item?.toString()}
                  closable
                  onClose={() => handleDeleteFilter(item, dataIndex)}
                  className="filter-tag"
                >
                  {dataIndex === 'isActive' ? (
                    <span title={item === true ? 'Active' : 'Inactive'}>
                      {item === true ? 'Active' : 'Inactive'}
                    </span>
                  ) : (
                    <>
                      {dataIndex === 'roles' ? (
                        <>
                          <span
                            title={
                              find(filterList, ['key', item])?.label ||
                              item?.toString()
                            }
                          >
                            {find(filterList, ['key', item])?.label ||
                              item?.toString()}
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            title={
                              isContactNo
                                ? formatPhoneNumber(
                                    item?.label || item?.toString(),
                                  )
                                : item?.label || item?.toString()
                            }
                          >
                            {isContactNo
                              ? formatPhoneNumber(
                                  item?.label || item?.toString(),
                                )
                              : item?.label || item?.toString()}
                          </span>
                        </>
                      )}
                    </>
                  )}
                </Tag>
              ))}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => (
                <div
                  className="filter-checkbox-section"
                  key={item?.key || item}
                >
                  <Checkbox
                    value={item?.key || item}
                    checked={filtersCopyState?.[dataIndex]?.includes(
                      item?.key || item,
                    )}
                    key={item?.key || item}
                    onChange={(e) => changeFilter(e, dataIndex)}
                    className="common-checkbox"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <>
                        <span
                          title={
                            isContactNo
                              ? formatPhoneNumber(
                                  item?.label || item?.toString(),
                                )
                              : item?.label || item?.toString()
                          }
                        >
                          {isContactNo
                            ? formatPhoneNumber(item?.label || item?.toString())
                            : item?.label || item?.toString()}
                        </span>
                      </>
                    )}
                  </Checkbox>
                </div>
              ))
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="common-button discard-button filter-button"
            id="roles-filter-reset"
            onClick={() => handleResetRole(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button filter-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  const handleDeleteUser = async (editUserData) => {
    const response = await deleteUser({
      variables: { where: { id: editUserData?.id } },
    });
    if (response?.data?.deleteUser) {
      fetchUserData({
        variables: { filter: userFilter, ...(filters && { where: filters }) },
      });
    }
  };

  const handleUserStatus = async (editUserData) => {
    const editData = {
      isActive: !editUserData?.isActive,
      email: editUserData?.email,
      roles: editUserData?.roles,
    };
    const response = await updateUserMutate({
      variables: { data: { ...editData }, where: { id: editUserData?.id } },
    });
    if (response?.data?.updateUser) {
      fetchUserData({
        variables: { filter: userFilter, ...(filters && { where: filters }) },
      });
    }
  };

  const renderActionButtons = (editUserData) => (
    <div className="d-flex flex-vertical">
      <AccessControl allowedPermissions={['FET_USER_UPDATE', 'FET_USER_VIEW']}>
        <Button
          id="user-table-edit-btn"
          className="b-0"
          onClick={() => {
            handleAddEditUser(editUserData);
          }}
        >
          Edit
        </Button>
      </AccessControl>
      <AccessControl allowedPermissions={['FET_USER_DELETE']}>
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={() => handleDeleteUser(editUserData)}
          okText="Yes"
          cancelText="No"
        >
          <Button id="user-table-status-btn" className="b-0">
            Remove
          </Button>
        </Popconfirm>
      </AccessControl>
      <AccessControl allowedPermissions={['FET_USER_UPDATE']}>
        <Popconfirm
          title={`Are you sure to ${
            editUserData?.isActive ? 'Mark Inactive' : 'Mark Active'
          }?`}
          onConfirm={() => handleUserStatus(editUserData)}
          okText="Yes"
          cancelText="No"
        >
          <Button id="user-table-status-btn" className="b-0">
            {editUserData?.isActive ? 'Mark Inactive' : 'Mark Active'}
          </Button>
        </Popconfirm>
      </AccessControl>
    </div>
  );
  const columns = [
    {
      title: 'NAME',
      ellipsis: true,
      width: 130,
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
      render: (text, record) => `${record?.firstName} ${record?.lastName}`,
    },
    {
      title: 'EMAIL',
      ellipsis: true,
      dataIndex: 'email',
      className: 'max-width-column',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      key: 'email',
    },
    {
      title: 'CONTACT',
      width: 180,
      ellipsis: true,
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order,
      render: (phone) => formatPhoneNumber(phone) || '-',
    },
    {
      title: 'ROLES',
      width: 250,
      ellipsis: true,
      dataIndex: 'roles',
      key: 'roles',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'roles' && sortedInfo?.order,
      ...filterPopup('roles'),
      render: (roles, record) => (
        <ShowMoreComponent show={1} data={record?.roleValues} />
      ),
    },
    {
      title: 'STATUS',
      width: 120,
      dataIndex: 'isActive',
      key: 'isActive',
      ...filterPopup('isActive'),
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      render: (isActive) => {
        if (isActive) {
          return <span>Active</span>;
        }

        return <span>Inactive</span>;
      },
    },
    checkPermissions(permissions, [
      'FET_USER_UPDATE',
      'FET_USER_DELETE',
      'FET_USER_VIEW',
    ]) && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => (
        <Popover
          placement="bottom"
          overlayClassName="action-button"
          content={renderActionButtons(record)}
        >
          <MoreOutlined />
        </Popover>
      ),
    },
  ];

  const onSearchChange = (value) => {
    setUserFilter({
      ...userFilter,
      skip: value ? 0 : userFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    fetchUserData({
      variables: {
        filter: {
          ...userFilter,
          skip: value ? 0 : userFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
        ...(filters && { where: filters }),
      },
    });
  };

  const handleExport = async (e) => {
    const { key } = e;
    setExportLoading(true);
    await handleExportCommon({
      filters,
      key,
      selectedKeys,
      module: 'USER',
    });
    setExportLoading(false);
  };

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="users"
        folder="USER"
      />
      <AccessControl allowedPermissions={['FET_USER_CREATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<PlusOutlined />}
            size="small"
            id="user-table-add-btn"
            type="primary"
            onClick={handleAddEditUser}
          >
            Add User
          </Button>
        </Portal>
      </AccessControl>

      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_USER_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="User name or other detail.."
              name="Users"
              getData={onSearchChange}
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </AccessControl>
        </div>
        <div className="header-buttons">
          <AccessControl allowedPermissions={['FET_USER_IMPORT']}>
            <Button
              size="small"
              className="common-button import-button"
              icon={<img src={ImportIcon} alt="import-icon" width={11} />}
              id="user-table-import-btn"
              type="primary"
              onClick={() => handleShowImportModal(true)}
            >
              Import
            </Button>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_USER_EXPORT']}>
            <Dropdown
              overlayClassName="export-btn-dropdown"
              menu={{ items: exportOptions, onClick: handleExport }}
              placement="bottom"
            >
              <Button
                className="common-button export-button"
                size="small"
                icon={<img src={ExportIcon} alt="export-icon" width={11} />}
                id="user-table-export-btn"
                loading={exportLoading}
                type="primary"
              >
                Export
              </Button>
            </Dropdown>
          </AccessControl>
        </div>
      </div>
      <AccessControl allowedPermissions={['FET_USER_LIST']} showNoAccess>
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={loading || updateUserLoading || deleteUserLoading}
              columns={[...columns?.filter((item) => item !== false)]}
              data={data?.users?.data || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowSelection={rowSelection}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default UserTable;
