import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Form } from 'antd';
import React from 'react';
import BooleanChecked from '../../../../../../../../app/components/iconComponents/BooleanChecked';
import BooleanUnchecked from '../../../../../../../../app/components/iconComponents/BooleanUnchecked';

const CustomBooleanPreview = ({ widgetConfig }) => {
  const label = widgetConfig?.config?.label || '';
  const hideLabel = widgetConfig?.config?.rules?.hideLabel;
  const required = widgetConfig?.config?.rules?.required;
  const toolTipChecked = widgetConfig?.config?.tooltip || widgetConfig?.tooltip;
  const toolTipValue =
    widgetConfig?.config?.tooltipValue || widgetConfig?.tooltipValue;
  const trueValue = widgetConfig?.config?.trueValue;
  const falseValue = widgetConfig?.config?.falseValue;
  const booleanDefaultValue = widgetConfig?.config?.booleanDefaultValue;
  const descriptionCheck = widgetConfig?.config?.description;
  const descriptionValue = widgetConfig?.config?.descriptionValue;

  return (
    <div className="mt-37 tile-grid">
      <Form layout="vertical">
        <Form.Item
          name="boolean"
          label={hideLabel ? ' ' : label}
          tooltip={
            toolTipChecked && {
              title: toolTipValue,
              icon: <InfoCircleOutlined />,
            }
          }
          rules={[{ required, message: 'Please Enter Value' }]}
          className={`${!required && 'hide-required-mark'} ${
            toolTipChecked && 'label-with-tooltip'
          } label-text-break custom-form-item-label`}
        >
          <Card>
            <Card.Grid
              className={`grid-card tile-view ${
                !booleanDefaultValue ? 'card-selected' : ''
              }`}
              hoverable
            >
              <div className="d-flex flex-vertical align-center">
                <BooleanUnchecked selected={!booleanDefaultValue} />
                <span className="card-title" title={falseValue}>
                  {falseValue}
                </span>
              </div>
            </Card.Grid>
            <Card.Grid
              className={`grid-card tile-view ${
                booleanDefaultValue ? 'card-selected' : ''
              }`}
              hoverable
            >
              <div className="d-flex flex-vertical align-center">
                <BooleanChecked selected={booleanDefaultValue} />
                <span className="card-title break-word" title={trueValue}>
                  {trueValue}
                </span>
              </div>
            </Card.Grid>
          </Card>
        </Form.Item>
      </Form>
      {descriptionCheck && descriptionValue && (
        <span className="thin-label">{descriptionValue}</span>
      )}
    </div>
  );
};

export default CustomBooleanPreview;
