import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../../AppContext';
import { DIMENSION_LOB_COMMON } from '../../../../../../common/constants';
import LoaderComponent from '../../../previewComponents/globalComponents/LoaderComponent';
import HighlightYourAreaModal from './HighlightYourAreaModal';
import MeasurementTipsForMapModal from './MeasurementTipsForMapModal';
import MeasurementTipsModal from './MeasurementTipsModal';
import OtherConfigsForm from './OtherConfigsForm';
import StairsForm from './StairsForm';
import './dimensionWidget.less';

const DimensionWidgetComponent = ({
  widgetConfig = null,
  selectedDimensionTab = null,
  selectedDimensionProject = null,
  currentPageSequence = null,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [
    showHightLightYourAreaModal,
    setShowHightLightYourAreaModal,
  ] = useState(false);
  const [
    showMeasurementTipForMapEntry,
    setShowMeasurementTipForMapEntry,
  ] = useState(false);
  const {
    state: { dimensionFormLoading },
  } = useContext(AppContext);

  useEffect(() => {
    setShowModal(selectedDimensionTab === 'MEASUREMENT_TIP_FOR_MANUAL_ENTRY');
    setShowHightLightYourAreaModal(
      selectedDimensionTab === 'HIGHLIGHT_YOUR_AREA',
    );
    setShowMeasurementTipForMapEntry(
      selectedDimensionTab === 'MEASUREMENT_TIP_FOR_MAP_ENTRY',
    );
  }, [selectedDimensionTab]);

  return (
    <div className="dimension-wrapper" id="dimensionWrapper">
      <LoaderComponent spinning={dimensionFormLoading} setHeight={10}>
        {selectedDimensionProject === DIMENSION_LOB_COMMON ? (
          <>
            <OtherConfigsForm
              widgetConfig={widgetConfig?.common}
              selectedDimensionTab={selectedDimensionTab}
            />
            <div className="measurement-tips-link">Measurement Tips</div>
            {showModal && (
              <MeasurementTipsModal
                showModal={showModal}
                setShowModal={setShowModal}
                selectedDimensionTab={selectedDimensionTab}
                widgetConfig={widgetConfig?.common?.measurementTipsManualEntry}
              />
            )}
            {showHightLightYourAreaModal && (
              <HighlightYourAreaModal
                showHightLightYourAreaModal={showHightLightYourAreaModal}
                setShowHightLightYourAreaModal={setShowHightLightYourAreaModal}
                selectedDimensionTab={selectedDimensionTab}
                widgetConfig={widgetConfig?.common?.highlightYourArea}
                tenantLogo={currentPageSequence?.tenantLogo?.url}
              />
            )}
            {showMeasurementTipForMapEntry && (
              <MeasurementTipsForMapModal
                showMeasurementTipForMapEntry={showMeasurementTipForMapEntry}
                setShowMeasurementTipForMapEntry={
                  setShowMeasurementTipForMapEntry
                }
                widgetConfig={widgetConfig?.common?.measurementTipsMapEntry}
              />
            )}
          </>
        ) : (
          <StairsForm
            widgetConfig={widgetConfig?.stairs}
            selectedDimensionTab={selectedDimensionTab}
          />
        )}
      </LoaderComponent>
    </div>
  );
};

export default DimensionWidgetComponent;
