import { gql } from '@apollo/client';

export const GET_PROSPECTS = gql`
  query prospects($filter: ProspectsFilterInput, $where: ProspectsWhereInput) {
    prospects(filter: $filter, where: $where) {
      count
      data {
        id
        businessName
        contactName
        phoneNo
        email
        industries {
          label
          lineOfBusinesses {
            label
          }
        }
        address {
          addressLine1
          addressLine2
          city
          state
          country
          zipCode
        }
        region
        status
      }
    }
  }
`;

export const GET_PROSPECT = gql`
  query prospect($id: ID!) {
    prospect(where: { id: $id }) {
      id
      firstName
      lastName
      businessName
      contactName
      phoneNo
      email
      industries {
        id
        label
        lineOfBusinesses {
          id
          label
        }
      }
      address {
        addressLine1
        addressLine2
        city
        state
        country
        zipCode
      }
      region
      status
      website
      notes
      noOfEmployee
      revenue
      locations
      marketFocus
      currentPartners
      campaignId
      sourceOfInformation
    }
  }
`;

export const PROSPECT_FILTER = gql`
  query prospectFilters($filter: ProspectsFilterInput!) {
    prospectFilters(filter: $filter) {
      count
      data {
        status
        region
      }
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        isActive
        lobSaData {
          id
          label
          key
          isActive
        }
      }
    }
  }
`;

export const INDUSTRY_FILTER = gql`
  query industryFilters($filter: IndustryFilter!) {
    industryFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const LINE_OF_BUSINESS_FILTER = gql`
  query lineOfBusinessFilters($filter: LineOfBusinessFilter!) {
    lineOfBusinessFilters(filter: $filter) {
      count
      data {
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const FETCH_STATE_CITY = gql`
  query fetchStatesAndCity($filter: USLocationTypeFilter) {
    getLocationType(filter: $filter) {
      data {
        id
        name
        state
      }
    }
  }
`;

export const GET_CAMPAIGNS = gql`
  query campaigns($filter: CampaignFilter!) {
    campaigns(filter: $filter) {
      data {
        id
        name
      }
    }
  }
`;
