import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import './customerModule.less';
import CustomerContact from './pages/CustomerContact';
import CustomerCreate from './pages/CustomerCreate';
import CustomerEdit from './pages/CustomerEdit';
import CustomerList from './pages/CustomerList';
import CustomerProperty from './pages/CustomerProperty';
import CustomerView from './pages/CustomerView';

const CustomerWrapper = () => (
  // commented because permission not needed now
  // const { getCurrentUserRole } = useContext(AppContext);
  // const userRoles = getCurrentUserRole();
  // const { TENANT_ADMIN } = ROLE_KEYS;
  // const isTenantAdmin = userRoles?.includes(TENANT_ADMIN);

  <div className="customer-module">
    <Routes>
      <Route path={ROUTES?.MAIN} element={<CustomerList />} />
      <Route path="/add" element={<CustomerCreate />} />
      <Route path="/edit/:id" element={<CustomerEdit />} />
      <Route path="/view/:id" element={<CustomerView />} />
      <Route
        path={`/view${ROUTES?.CONTACTS}/:id`}
        element={<CustomerContact />}
      />
      <Route
        path={`/view${ROUTES?.PROPERTIES}/:id`}
        element={<CustomerProperty />}
      />
      <Route
        path={`/edit${ROUTES?.CONTACTS}/:id`}
        element={<CustomerContact />}
      />
      <Route
        path={`/edit${ROUTES?.PROPERTIES}/:id`}
        element={<CustomerProperty />}
      />
    </Routes>
  </div>
);
export default CustomerWrapper;
