import { CheckOutlined, CloseOutlined, UpOutlined } from '@ant-design/icons';
import { Checkbox, Collapse, Form, Switch } from 'antd';
import React from 'react';
import { formValidatorRules } from '../../../../../../common/utils';
import InputComponent from '../../../../../../components/InputComponent';

const { required } = formValidatorRules;

const ContactWidgetForm = ({ form }) => {
  const urgencyCheckboxLabelCheck = Form?.useWatch(
    ['widgetConfiguration', 'contactFromSettings', 'urgencyCheckboxLabelCheck'],
    form,
  );
  const sendConfirmationEmailCheckboxCheck = Form?.useWatch(
    [
      'widgetConfiguration',
      'contactFromSettings',
      'sendConfirmationEmailCheckboxCheck',
    ],
    form,
  );

  const nameCollapseItems = [
    {
      forceRender: true,
      label: 'Name',
      key: '1',
      children: (
        <>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">First Name Label</span>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'contactFromSettings',
              'firstName',
              'label',
            ]}
            rules={[
              {
                required: true,
                message: 'Please Enter First Name Label',
              },
            ]}
            className="mb-0"
          >
            <InputComponent placeholder="Enter First Name Label" />
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'contactFromSettings',
              'firstName',
              'required',
            ]}
            className="mb-0"
            valuePropName="checked"
          >
            <Checkbox disabled className="common-checkbox">
              Required
            </Checkbox>
          </Form.Item>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Last Name Label</span>
          </div>
          <Form.Item
            name={[
              'widgetConfiguration',
              'contactFromSettings',
              'lastName',
              'label',
            ]}
            rules={[
              {
                required: true,
                message: 'Please Enter Last Name Label',
              },
            ]}
            className="mb-0"
          >
            <InputComponent placeholder="Enter Last Name Label" />
          </Form.Item>
          <Form.Item
            name={[
              'widgetConfiguration',
              'contactFromSettings',
              'lastName',
              'required',
            ]}
            className="mb-0"
            valuePropName="checked"
          >
            <Checkbox disabled className="common-checkbox">
              Required
            </Checkbox>
          </Form.Item>
        </>
      ),
    },
    {
      forceRender: true,
      label: 'Email',
      key: '2',
      children: (
        <Form.Item
          name={[
            'widgetConfiguration',
            'contactFromSettings',
            'email',
            'labelText',
          ]}
          rules={[
            {
              required: true,
              message: 'Please Enter Label',
            },
          ]}
          className="mb-0"
        >
          <InputComponent placeholder="Enter Label" />
        </Form.Item>
      ),
    },
    {
      forceRender: true,
      label: 'Phone',
      key: '3',
      children: (
        <Form.Item
          name={[
            'widgetConfiguration',
            'contactFromSettings',
            'phone',
            'labelText',
          ]}
          rules={[
            {
              required: true,
              message: 'Please Enter Label',
            },
          ]}
          className="mb-0"
        >
          <InputComponent placeholder="Enter Label" />
        </Form.Item>
      ),
    },
  ];
  return (
    <div>
      <div className="section-header mt-16">
        <span className="section-title">Order Settings</span>
      </div>

      {/* Card Summary */}
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Cart Summary</span>
        <Form.Item
          name={['widgetConfiguration', 'orderSettings', 'cartSummary']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>

      <div className="section-header mt-16">
        <span className="section-title">Contact form Settings</span>
      </div>
      {/* Urgency Checkbox Label */}
      <Form.Item
        name={[
          'widgetConfiguration',
          'contactFromSettings',
          'optionalComments',
          'labelText',
        ]}
        rules={[
          {
            ...required,
            message: 'Please Enter Optional Comments Text',
          },
        ]}
        label="Optional Comments"
        className="mb-0"
      >
        <InputComponent placeholder="Enter Optional Comments Text" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Urgency Checkbox Label</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'contactFromSettings',
            'urgencyCheckboxLabelCheck',
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'contactFromSettings',
          'urgencyCheckboxLabelText',
        ]}
        hidden={!urgencyCheckboxLabelCheck}
        rules={[
          {
            required: urgencyCheckboxLabelCheck,
            message: 'Please Enter Urgency Checkbox Label',
          },
        ]}
        className="mb-0"
      >
        <InputComponent placeholder="Enter Urgency Checkbox Label" />
      </Form.Item>
      <Form.Item
        name={[
          'widgetConfiguration',
          'contactFromSettings',
          'urgencyCheckboxLabelSelected',
        ]}
        hidden={!urgencyCheckboxLabelCheck}
        className="mb-0"
        valuePropName="checked"
      >
        <Checkbox className="common-checkbox">Selected</Checkbox>
      </Form.Item>

      {/* Send Confirmation Email Checkbox */}
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Send Confirmation Email Checkbox</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'contactFromSettings',
            'sendConfirmationEmailCheckboxCheck',
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={[
          'widgetConfiguration',
          'contactFromSettings',
          'sendConfirmationEmailCheckboxText',
        ]}
        hidden={!sendConfirmationEmailCheckboxCheck}
        rules={[
          {
            required: sendConfirmationEmailCheckboxCheck,
            message: 'Please Enter Send Confirmation Email Checkbox',
          },
        ]}
        className="mb-0"
      >
        <InputComponent placeholder="Enter Send Confirmation Email Checkbox" />
      </Form.Item>
      <Form.Item
        name={[
          'widgetConfiguration',
          'contactFromSettings',
          'sendConfirmationEmailCheckboxSelected',
        ]}
        hidden={!sendConfirmationEmailCheckboxCheck}
        className="mb-0"
        valuePropName="checked"
      >
        <Checkbox className="common-checkbox">Selected</Checkbox>
      </Form.Item>

      <Collapse
        className="common-collapse questionnaire-collapse"
        expandIconPosition="end"
        expandIcon={({ isActive }) => (
          <UpOutlined rotate={isActive ? 0 : 180} />
        )}
        defaultActiveKey={['1', '2', '3']}
        ghost
        items={nameCollapseItems}
      />
    </div>
  );
};

export default ContactWidgetForm;
