import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import EntityRecordTable from '../components/EntityRecordTable';

const BatchEntityRecordList = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);

  return (
    <Card className="full-height-card card-body-padding">
      {pageSize && <EntityRecordTable />}
    </Card>
  );
};

export default BatchEntityRecordList;
