import { gql } from '@apollo/client';

export const GET_SPONSOR_TENANTS = gql`
  query sponsorAssociatedTenants(
    $filter: TenantFilter!
    $where: TenantsWhereInput
  ) {
    sponsorAssociatedTenants(filter: $filter, where: $where) {
      count
      data {
        id
        name
        phoneNo
        email
        industries {
          id
          label
          lineOfBusinesses {
            id
            label
          }
        }
        location {
          addressLine1
          addressLine2
          city
          state
          country
          zipCode
        }
        pickListStatus {
          label
        }
      }
    }
  }
`;

export const GET_SPONSOR_TENANT = gql`
  query sponsorAssociatedTenant($id: ID!) {
    sponsorAssociatedTenant(where: { id: $id }) {
      id
      name
      phoneNo
      email
      industries {
        id
        label
        lineOfBusinesses {
          id
          label
        }
      }
      userInfo {
        firstName
        lastName
      }
      location {
        addressLine1
        addressLine2
        city
        state
        country
        zipCode
      }
      pickListStatus {
        label
      }
    }
  }
`;

export const INDUSTRY_FILTER = gql`
  query industryFilters($filter: IndustryFilter!) {
    industryFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const LINE_OF_BUSINESS_FILTER = gql`
  query lineOfBusinessFilters($filter: LineOfBusinessFilter!) {
    lineOfBusinessFilters(filter: $filter) {
      count
      data {
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const FETCH_STATE_CITY = gql`
  query fetchStatesAndCity($filter: USLocationTypeFilter) {
    getLocationType(filter: $filter) {
      data {
        id
        name
        state
      }
    }
  }
`;

export const PICK_LIST_FILTERS = gql`
  query pickListFilters($filter: PickListFilters!) {
    pickListFilters(filter: $filter) {
      data {
        label
        key
      }
    }
  }
`;
