import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Form, Switch } from 'antd';
import React from 'react';
import EditorComponent from '../../../../../../components/EditorComponent';
import InputComponent from '../../../../../../components/InputComponent';

const MeasurementTipsForMap = ({ form = null }) => {
  const showDescription = Form?.useWatch(
    [
      'widgetConfiguration',
      'common',
      'measurementTipsMapEntry',
      'descriptionCheck',
    ],
    form,
  );

  return (
    <>
      <Form.Item
        label="Title"
        name={[
          'widgetConfiguration',
          'common',
          'measurementTipsMapEntry',
          'titleText',
        ]}
        rules={[
          {
            required: true,
            message: 'Please Enter Title',
          },
        ]}
      >
        <InputComponent placeholder="Enter Title" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Description</span>
        <Form.Item
          name={[
            'widgetConfiguration',
            'common',
            'measurementTipsMapEntry',
            'descriptionCheck',
          ]}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      {showDescription && (
        <Form.Item
          name={[
            'widgetConfiguration',
            'common',
            'measurementTipsMapEntry',
            'descriptionText',
          ]}
        >
          <EditorComponent placeholder="Enter Description" />
        </Form.Item>
      )}
      <Form.Item
        label="Action Button Label"
        name={[
          'widgetConfiguration',
          'common',
          'measurementTipsMapEntry',
          'actionButtonLabel',
        ]}
        rules={[
          {
            required: true,
            message: 'Please Enter Action Button Label',
          },
        ]}
      >
        <InputComponent placeholder="Enter Action Button Label" />
      </Form.Item>
    </>
  );
};

export default MeasurementTipsForMap;
