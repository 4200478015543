import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import history from '../historyData';
import ModalComponent from './ModalComponent';

const RouterPrompt = ({
  when,
  title = 'Leave this page',
  description = 'There are unsaved changes. Are you sure want to leave this page ?',
  okText = 'Confirm',
  cancelText = 'Cancel',
}) => {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [blockedLocation, setBlockedLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = useCallback(
    (nextLocation) => {
      if (
        !confirmedNavigation &&
        when &&
        nextLocation?.pathname !== location?.pathname
      ) {
        setBlockedLocation(nextLocation);
        setShowPrompt(true);
        return true; // Return true to indicate we're blocking
      }
      return false; // Return false to indicate we're not blocking
    },
    [confirmedNavigation, when, location],
  );

  const handleConfirmNavigation = useCallback(() => {
    setShowPrompt(false);
    dispatch({ type: 'SET_SHOW_PROMPT', data: false });
    setConfirmedNavigation(true);
  }, []);

  const handleCancelNavigation = useCallback(() => {
    setShowPrompt(false);
    setBlockedLocation(null);
  }, []);

  useEffect(() => {
    if (confirmedNavigation && blockedLocation) {
      navigate(blockedLocation?.pathname + blockedLocation?.search, {
        state: blockedLocation?.state,
      });
    }
  }, [confirmedNavigation, blockedLocation, navigate]);

  useEffect(() => {
    if (when) {
      // eslint-disable-next-line no-undef
      window.onbeforeunload = (event) => {
        // eslint-disable-next-line no-undef
        const e = event || window?.event;
        e.preventDefault();
        if (e) {
          e.returnValue = description;
        }
        return description;
      };
    } else {
      // eslint-disable-next-line no-undef
      window.onbeforeunload = null;
    }

    return () => {
      // eslint-disable-next-line no-undef
      window.onbeforeunload = null;
    };
  }, [when, description]);

  useEffect(() => {
    // Reset confirmation when location changes
    setConfirmedNavigation(false);
  }, [location]);

  useEffect(() => {
    if (!when) return;

    const unblock = history.block((tx) => {
      // If we should block navigation
      if (handleBlockedNavigation(tx.location)) {
        return; // Block the navigation
      }

      // Otherwise, allow the navigation
      tx.retry();
    });

    return () => {
      unblock();
    };
  }, [handleBlockedNavigation, when]);

  return (
    <>
      {showPrompt && (
        <ModalComponent
          title={title}
          className="router-prompt"
          open={showPrompt}
          onOk={handleConfirmNavigation}
          okText={okText}
          onCancel={handleCancelNavigation}
          cancelText={cancelText}
          okButtonProps={{ className: 'common-button' }}
          cancelButtonProps={{ className: 'common-button discard-button' }}
          closable
        >
          {description}
        </ModalComponent>
      )}
    </>
  );
};

export default RouterPrompt;
