import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import { hasIn, isArray, isBoolean, isEmpty, map } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { uploadMultipleFiles } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import ProductItemForm from '../components/ProductItemForm';
import CardWrapper from '../components/ProductItemTabs';
import { UPDATE_PRODUCT_ITEM } from '../graphql/Mutations';
import { GET_PRODUCT_ITEM } from '../graphql/Queries';

function ProductItemEdit() {
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [productItemData, setProductItemData] = useState({});
  const { data: { productItem } = {} } = useQuery(GET_PRODUCT_ITEM, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    },
  });

  useEffect(() => {
    const unListen = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, { state: { ...location?.state } });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  useEffect(() => {
    setProductItemData({
      ...productItem,
      quoteDescription: productItem?.quoteDescription || '',
      productCategories: productItem?.productCategoryId,
      description: productItem?.description || '',
      crmLastRefresh: productItem?.crmLastRefresh
        ? moment(productItem?.crmLastRefresh)
        : null,
    });
  }, [productItem]);

  const [updateProductItem] = useMutation(UPDATE_PRODUCT_ITEM, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const uuid = uuidv4();
    const dateTimeValues = [];
    const filesToSend = [];

    await Promise?.all(
      map(formValues?.dynamicFields, async (fieldType, key) => {
        if (fieldType?.upload) {
          let filesToUpload = [];
          const uploadedFiles = [];
          map(fieldType?.upload, (file) => {
            if (!file?.url) {
              filesToUpload?.push(file);
            } else {
              uploadedFiles?.push(file);
            }
          });
          if (filesToUpload?.length > 0) {
            await Promise?.all([
              await uploadMultipleFiles({
                files: filesToUpload,
                currentUser,
                uuid: formValues?.uuid || uuid,
                fieldKey: key,
                folder: 'product-item',
              }),
            ]).then((res) => {
              filesToUpload = [];
              if (!isEmpty(res?.[0])) {
                filesToSend?.push({ [key]: [...uploadedFiles, ...res?.[0]] });
              } else {
                filesToSend?.push({ [key]: [...uploadedFiles] });
              }
            });
          } else {
            filesToSend?.push({ [key]: [...uploadedFiles] });
          }
        }
        if (fieldType?.dateTime) {
          const format = Object.keys(fieldType?.dateTime);
          const value = fieldType?.dateTime?.[format];
          if (!value) {
            dateTimeValues?.push({
              [key]: [],
            });
          } else if (isArray(value)) {
            dateTimeValues?.push({
              [key]: value,
            });
          } else {
            dateTimeValues?.push({
              [key]: [value],
            });
          }
        }
      }),
    );

    const updatedDateTime = dateTimeValues?.reduce(
      (prev, curr) => ({ ...prev, ...curr }),
      {},
    );

    const updateFileUpload = filesToSend?.reduce(
      (prev, curr) => ({ ...prev, ...curr }),
      {},
    );

    let finalDynamicFields = {
      ...updateFileUpload,
      ...updatedDateTime,
    };

    if (formValues?.dynamicFields) {
      finalDynamicFields = {
        ...formValues?.dynamicFields,
        ...finalDynamicFields,
      };
    }

    map(finalDynamicFields, (value, key) => {
      if (hasIn(value, 'upload') && value?.upload === undefined) {
        finalDynamicFields[key] = [];
      } else if (isBoolean(value)) {
        finalDynamicFields[key] = value;
      } else {
        finalDynamicFields[key] = value || null;
      }
    });

    const newFormValues = {
      ...formValues,
      dynamicFields: finalDynamicFields || {},
      uuid: productItem?.uuid,
      productCategoryId: formValues?.productCategories || null,
      productItemWarranty: parseFloat(formValues?.productItemWarranty),
      labourWarranty: parseFloat(formValues?.labourWarranty),
      score: parseFloat(formValues?.score),
      conversionRate: parseFloat(formValues?.conversionRate),
      stock: Number(formValues?.stock),
      workOrderActivity: formValues?.workOrderActivity || false,
    };

    delete newFormValues?.productCategories;
    delete newFormValues?.sponsorName;

    const variables = { data: newFormValues, where: { id: productItem?.id } };

    try {
      const response = await updateProductItem({
        variables: { ...variables },
      });
      if (response?.data?.updateProductItem) {
        setSubmitLoading(false);
        navigate(ROUTES?.PRODUCTS, {
          state: { ...location?.state },
        });
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Navigate to={ROUTES?.PRODUCTS} replace />;
  }

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PRODUCTS} />
          {productItem && (
            <span className="portal-header">{productItem?.name}</span>
          )}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <ProductItemForm
            isEdit
            productItemData={productItemData || {}}
            handleProductItemForm={handleSubmit}
            isSubmit={submitLoading}
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default ProductItemEdit;
