import { Card } from 'antd';
import React from 'react';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import DashboardCharts from '../components/DashboardCharts';

const DashboardPage = () => (
  <Card className="full-height-card card-body-padding">
    <Portal portalId="header-left-content">
      <div className="d-flex align-center">
        <span className="portal-header">{MODULES?.DASHBOARD}</span>
      </div>
    </Portal>
    <div className="dashboard-module">
      <DashboardCharts />
    </div>
  </Card>
);

export default DashboardPage;
