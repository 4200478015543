import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import AddLogo from './components/AddLogo';
import ColorPalette from './components/ColorPalette';
import Industry from './components/Industries';
import InviteTeam from './components/InviteTeam';
import Region from './components/Region';
import SuccessPage from './components/SuccessPage';
import './onboarding.less';

const OnBoardingWrapper = () => (
  <div className="steps">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={<Navigate to={`${ROUTES?.ONBOARDING}/add-logo`} replace />}
      />
      <Route path="/add-logo" element={<AddLogo />} />
      <Route path="/color-palette" element={<ColorPalette />} />
      <Route path="/industry" element={<Industry />} />
      <Route path="/region" element={<Region />} />
      {/*
      Currently not showing package screen in onboarding flow, but code is kept for future use.
      Check https://app.clickup.com/t/606124/DRF-7106
      */}
      {/* <Route
        path={`/packages`}
        element={<Products />}
      /> */}
      <Route path="/invite" element={<InviteTeam />} />
      <Route path="/success" element={<SuccessPage />} />
    </Routes>
  </div>
);

export default OnBoardingWrapper;
