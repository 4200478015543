import { Card, Col, Divider, Row } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { formatPhoneNumber, formatPrice } from '../../../common/utils';

const ProspectDetails = ({ prospectsData }) => (
  <div className="prospect-details">
    <div className="content-section">
      <span className="form-divider-text">MANDATORY</span>
      <Divider className="form-divider" />
      <Row gutter={20}>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">First Name :</span>
            <span className="field-value">
              {prospectsData?.firstName || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Last Name :</span>
            <span className="field-value">
              {prospectsData?.lastName || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Business Name :</span>
            <span className="field-value">
              {prospectsData?.businessName || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Contact :</span>
            <span className="field-value">
              {formatPhoneNumber(prospectsData?.phoneNo) || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Contact Name :</span>
            <span className="field-value">
              {prospectsData?.contactName || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Email :</span>
            <span className="field-value">{prospectsData?.email || '-'}</span>
          </div>
        </Col>
      </Row>
      <span className="form-divider-text">INDUSTRY - LINE OF BUSINESS</span>
      <Divider className="form-divider" />
      <Row className="mx-16">
        {map(prospectsData?.industries, (industry) => (
          <Card
            bordered={false}
            className="industry-view"
            key={industry?.id}
            title={industry?.label}
          >
            {map(industry?.lineOfBusinesses, (lob) => (
              <div key={lob?.id} className="lob-view">
                {lob?.label}
              </div>
            ))}
          </Card>
        ))}
      </Row>
      <Row gutter={16}>
        <span className="form-divider-text optional-divider">OPTIONAL</span>
        <Divider className="form-divider optional-divider" />
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">AddressLine 1 :</span>
            <span className="field-value">
              {prospectsData?.address?.addressLine1 || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Country :</span>
            <span className="field-value">
              {prospectsData?.address?.country || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">State :</span>
            <span className="field-value">
              {prospectsData?.address?.state || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">City :</span>
            <span className="field-value">
              {prospectsData?.address?.city || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Postal Code :</span>
            <span className="field-value">
              {prospectsData?.address?.zipCode || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Website :</span>
            <span className="field-value">{prospectsData?.website || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Profile Notes :</span>
            <span className="field-value">{prospectsData?.notes || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Number of Employees :</span>
            <span className="field-value">
              {prospectsData?.noOfEmployee || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Annual Revenue :</span>
            <span className="field-value">
              {prospectsData?.revenue
                ? formatPrice(prospectsData?.revenue)
                : '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Locations :</span>
            <span className="field-value">
              {prospectsData?.locations || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Regions :</span>
            <span className="field-value">{prospectsData?.region || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Market Focus :</span>
            <span className="field-value">
              {prospectsData?.marketFocus || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Current Partners :</span>
            <span className="field-value">
              {prospectsData?.currentPartners || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Campaign ID :</span>
            <span className="field-value">
              {prospectsData?.campaignId || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Source of Information :</span>
            <span className="field-value">
              {prospectsData?.sourceOfInformation || '-'}
            </span>
          </div>
        </Col>
      </Row>
    </div>
  </div>
);
export default ProspectDetails;
