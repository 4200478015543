import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_PRICING_MODIFIER, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import PricingModifierCreate from './pages/PricingModifierCreate';
import PricingModifierEdit from './pages/PricingModifierEdit';
import PricingModifierList from './pages/PricingModifierList';
import './pricingModifierModule.less';

const PricingModifierWrapper = () => (
  <div className="pricing-modifier-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_PRICING_MODIFIER}
            showNoAccess
          >
            <PricingModifierList />
          </AccessControl>
        }
      />
      <Route
        path="/add"
        element={
          <AccessControl
            allowedPermissions={['FET_PRICING_MODIFIER_CREATE']}
            showNoAccess
          >
            <PricingModifierCreate />
          </AccessControl>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <AccessControl
            allowedPermissions={[
              'FET_PRICING_MODIFIER_UPDATE',
              'FET_PRICING_MODIFIER_VIEW',
            ]}
            showNoAccess
          >
            <PricingModifierEdit />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default PricingModifierWrapper;
