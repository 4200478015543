import Icon, {
  FileSyncOutlined,
  HistoryOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import React from 'react';
import { ReactComponent as DynamicFieldsIcon } from '../../../assets/dynamic-fields.svg';
import {
  MODULES,
  PERMISSION_ACTIVITY_LOGS,
  PERMISSION_API_SETTING,
  PERMISSION_CAMPAIGNS,
  PERMISSION_DASHBOARD,
  PERMISSION_DATA_ASSIGNMENT,
  PERMISSION_DYNAMIC_FIELDS,
  PERMISSION_HOURS_OF_OPERATION_SETTING,
  PERMISSION_IMPORT_EXPORT_QUEUE,
  PERMISSION_INDUSTRY,
  PERMISSION_LEAD,
  PERMISSION_MANUFACTURER,
  PERMISSION_MY_ACCOUNT_SETTING,
  PERMISSION_PLAN_SETTING,
  PERMISSION_PRICING_MODIFIER,
  PERMISSION_PRODUCT,
  PERMISSION_PRODUCT_CATEGORY,
  PERMISSION_PRODUCT_ITEM,
  PERMISSION_PRODUCT_PRICING,
  PERMISSION_PRODUCT_RANKING,
  PERMISSION_PROSPECT,
  PERMISSION_QUESTIONNAIRE,
  PERMISSION_QUOTE,
  PERMISSION_REGION,
  PERMISSION_REST_API_LOGS,
  PERMISSION_RETAILER,
  PERMISSION_RETAILER_SETTING,
  PERMISSION_ROLE,
  PERMISSION_TENANT_SETTING,
  PERMISSION_TENANT_SPONSOR_ASSOCIATION,
  PERMISSION_USER,
  PERMISSION_USER_PERMISSION,
  ROUTES,
} from '../../../common/constants';
import BrandIconComponent from '../iconComponents/BrandIconComponent';
import CatalogIconComponent from '../iconComponents/CatalogIconComponent';
import LobIconComponent from '../iconComponents/LobIconComponent';
import QuestionnaireIconComponent from '../iconComponents/QuestionnaireIconComponent';
import RegionIconComponent from '../iconComponents/RegionIconComponent';
import SettingIconComponent from '../iconComponents/SettingIconComponent';
import UserIconComponent from '../iconComponents/UserIconComponent';

const sideMenuConfig = [
  {
    label: MODULES?.MY_QUESTIONNAIRES,
    title: MODULES?.MY_QUESTIONNAIRES,
    key: MODULES?.MY_QUESTIONNAIRES,
    icon: <QuestionnaireIconComponent />,
    permissions: [...PERMISSION_DASHBOARD, ...PERMISSION_QUESTIONNAIRE],
    children: [
      {
        label: MODULES?.DASHBOARD,
        title: MODULES?.DASHBOARD,
        key: ROUTES?.DASHBOARD,
        permissions: [...PERMISSION_DASHBOARD],
      },
      {
        label: MODULES?.QUESTIONNAIRES,
        title: MODULES?.QUESTIONNAIRES,
        key: ROUTES?.QUESTIONNAIRES,
        permissions: [...PERMISSION_QUESTIONNAIRE],
      },
    ],
  },
  {
    label: MODULES?.SALES,
    title: MODULES?.SALES,
    key: MODULES?.SALES,
    icon: <UserIconComponent />,
    permissions: [
      ...PERMISSION_LEAD,
      ...PERMISSION_QUOTE,
      ...PERMISSION_CAMPAIGNS,
    ],
    children: [
      {
        label: MODULES?.LEADS,
        title: MODULES?.LEADS,
        key: ROUTES?.LEADS,
        permissions: [...PERMISSION_LEAD],
      },
      {
        label: MODULES?.QUOTES,
        title: MODULES?.QUOTES,
        key: ROUTES?.QUOTES,
        permissions: [...PERMISSION_QUOTE],
      },
      {
        label: MODULES?.CAMPAIGNS,
        title: MODULES?.CAMPAIGNS,
        key: ROUTES?.CAMPAIGNS,
        permissions: [...PERMISSION_CAMPAIGNS],
      },
    ],
  },
  {
    label: MODULES?.CATALOG,
    title: MODULES?.CATALOG,
    key: MODULES?.CATALOG,
    icon: <CatalogIconComponent />,
    permissions: [
      ...PERMISSION_PRODUCT_ITEM,
      ...PERMISSION_PRODUCT,
      ...PERMISSION_PRODUCT_RANKING,
      ...PERMISSION_PRICING_MODIFIER,
      ...PERMISSION_PRODUCT_CATEGORY,
      ...PERMISSION_MANUFACTURER,
    ],
    children: [
      {
        label: MODULES?.PRODUCTS,
        title: MODULES?.PRODUCTS,
        key: ROUTES?.PRODUCTS,
        permissions: [...PERMISSION_PRODUCT_ITEM],
      },
      {
        label: MODULES?.PACKAGES,
        title: MODULES?.PACKAGES,
        key: ROUTES?.PACKAGES,
        permissions: [...PERMISSION_PRODUCT],
      },
      {
        label: MODULES?.PRODUCT_PRICING,
        title: MODULES?.PRODUCT_PRICING,
        key: ROUTES?.PRODUCT_PRICING,
        permissions: [...PERMISSION_PRODUCT_PRICING],
      },
      {
        label: MODULES?.QRV,
        title: MODULES?.QRV,
        key: ROUTES?.QUESTIONNAIRE_RESULT_VALIDATION,
        permissions: [...PERMISSION_PRODUCT_RANKING],
      },
      {
        label: MODULES?.PRICING_MODIFIERS,
        title: MODULES?.PRICING_MODIFIERS,
        key: ROUTES?.PRICING_MODIFIERS,
        permissions: [...PERMISSION_PRICING_MODIFIER],
      },
      {
        label: MODULES?.PRODUCT_CATEGORIES,
        title: MODULES?.PRODUCT_CATEGORIES,
        key: ROUTES?.PRODUCT_CATEGORIES,
        permissions: [...PERMISSION_PRODUCT_CATEGORY],
      },
      {
        label: MODULES?.MANUFACTURERS,
        title: MODULES?.MANUFACTURERS,
        key: ROUTES?.MANUFACTURERS,
        permissions: [...PERMISSION_MANUFACTURER],
      },
    ],
  },
  {
    label: MODULES?.REGIONS,
    title: MODULES?.REGIONS,
    key: ROUTES?.REGIONS,
    icon: <RegionIconComponent />,
    permissions: [...PERMISSION_REGION],
  },
  {
    label: MODULES?.RETAILERS,
    title: MODULES?.RETAILERS,
    key: ROUTES?.RETAILERS,
    icon: <BrandIconComponent />,
    permissions: [...PERMISSION_RETAILER],
  },
  {
    label: MODULES?.USERS_MANAGEMENT,
    title: MODULES?.USERS_MANAGEMENT,
    key: MODULES?.USERS_MANAGEMENT,
    icon: <UserIconComponent />,
    permissions: [
      ...PERMISSION_USER,
      ...PERMISSION_ROLE,
      ...PERMISSION_USER_PERMISSION,
    ],
    children: [
      {
        label: MODULES?.USERS,
        title: MODULES?.USERS,
        key: ROUTES?.USERS,
        permissions: [...PERMISSION_USER],
      },
      {
        label: MODULES?.ROLES,
        title: MODULES?.ROLES,
        key: ROUTES?.ROLES,
        permissions: [...PERMISSION_ROLE],
      },
      {
        label: MODULES?.PERMISSIONS,
        title: MODULES?.PERMISSIONS,
        key: ROUTES?.PERMISSIONS,
        permissions: [...PERMISSION_USER_PERMISSION],
      },
    ],
  },
  {
    label: MODULES?.INDUSTRIES,
    title: MODULES?.INDUSTRIES,
    key: ROUTES?.INDUSTRIES,
    icon: <LobIconComponent />,
    permissions: [...PERMISSION_INDUSTRY],
  },
  {
    label: MODULES?.DYNAMIC_FIELDS,
    title: MODULES?.DYNAMIC_FIELDS,
    key: ROUTES?.DYNAMIC_FIELDS,
    icon: <Icon aria-label="DynamicField Icon" component={DynamicFieldsIcon} />,
    permissions: [...PERMISSION_DYNAMIC_FIELDS],
  },
  {
    label: MODULES?.SETTING,
    title: MODULES?.SETTING,
    key: MODULES?.SETTING,
    icon: <SettingIconComponent />,
    permissions: [
      ...PERMISSION_TENANT_SETTING,
      ...PERMISSION_API_SETTING,
      ...PERMISSION_HOURS_OF_OPERATION_SETTING,
      ...PERMISSION_RETAILER_SETTING,
      ...PERMISSION_PLAN_SETTING,
      ...PERMISSION_MY_ACCOUNT_SETTING,
    ],
    children: [
      {
        label: MODULES?.TENANT_SETTING,
        title: MODULES?.TENANT_SETTING,
        key: ROUTES?.TENANT_SETTING,
        permissions: [...PERMISSION_TENANT_SETTING],
      },
      {
        label: MODULES?.API_SETTING,
        title: MODULES?.API_SETTING,
        key: ROUTES?.API_SETTING,
        permissions: [...PERMISSION_API_SETTING],
      },
      {
        label: MODULES?.HOURS_OF_OPERATION,
        title: MODULES?.HOURS_OF_OPERATION,
        key: ROUTES?.HOURS_OF_OPERATION,
        permissions: [...PERMISSION_HOURS_OF_OPERATION_SETTING],
      },
      {
        label: MODULES?.BUSINESS_INFORMATION,
        title: MODULES?.BUSINESS_INFORMATION,
        key: ROUTES?.BUSINESS_INFORMATION,
        permissions: [...PERMISSION_RETAILER_SETTING],
      },
      {
        label: MODULES?.PLAN_SETTING,
        title: MODULES?.PLAN_SETTING,
        key: ROUTES?.PLAN_SETTING,
        permissions: [...PERMISSION_PLAN_SETTING],
      },
      {
        label: MODULES?.MY_ACCOUNT_SETTING,
        title: MODULES?.MY_ACCOUNT_SETTING,
        key: ROUTES?.MY_ACCOUNT_SETTING,
        permissions: [...PERMISSION_MY_ACCOUNT_SETTING],
      },
    ],
  },
  // commented for future use

  {
    label: MODULES?.IMPORT_EXPORT_QUEUE,
    title: MODULES?.IMPORT_EXPORT_QUEUE,
    key: ROUTES?.IMPORT_EXPORT_QUEUE,
    icon: <FileSyncOutlined />,
    permissions: [...PERMISSION_IMPORT_EXPORT_QUEUE],
  },
  {
    label: MODULES?.TENANT_MANAGEMENT,
    title: MODULES?.TENANT_MANAGEMENT,
    key: MODULES?.TENANT_MANAGEMENT,
    icon: <UsergroupAddOutlined />,
    permissions: [
      ...PERMISSION_PROSPECT,
      ...PERMISSION_TENANT_SPONSOR_ASSOCIATION,
      ...PERMISSION_DATA_ASSIGNMENT,
    ],
    forSponsor: true,
    children: [
      {
        label: MODULES?.PROSPECTS,
        title: MODULES?.PROSPECTS,
        key: ROUTES?.PROSPECTS,
        forSponsor: true,
        permissions: [...PERMISSION_PROSPECT],
      },
      {
        label: MODULES?.SPONSOR_TENANTS,
        title: MODULES?.SPONSOR_TENANTS,
        key: ROUTES?.SPONSOR_TENANTS,
        forSponsor: true,
        permissions: [...PERMISSION_TENANT_SPONSOR_ASSOCIATION],
      },
      {
        label: MODULES?.ASSIGNMENT_JOBS,
        title: MODULES?.ASSIGNMENT_JOBS,
        key: ROUTES?.ASSIGNMENT_JOBS,
        forSponsor: true,
        permissions: [...PERMISSION_DATA_ASSIGNMENT],
      },
    ],
  },
  {
    label: MODULES?.LOGS,
    title: MODULES?.LOGS,
    key: MODULES?.LOGS,
    icon: <HistoryOutlined />,
    permissions: [...PERMISSION_REST_API_LOGS],
    children: [
      {
        label: MODULES?.REST_API_LOGS,
        title: MODULES?.REST_API_LOGS,
        key: ROUTES?.REST_API_LOGS,
        permissions: [...PERMISSION_REST_API_LOGS],
      },
      {
        label: MODULES?.ACTIVITY_LOGS,
        title: MODULES?.ACTIVITY_LOGS,
        key: ROUTES?.ACTIVITY_LOGS,
        permissions: [...PERMISSION_ACTIVITY_LOGS],
      },
    ],
  },
];

export default sideMenuConfig;
