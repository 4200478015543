import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Col, Form, Row, Switch } from 'antd';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../../../../AppContext';
import { uploadImageNormalize } from '../../../../../../../../common/utils';
import DraggerUploadComponent from '../../../../../../../../components/DraggerUploadComponent';
import InputComponent from '../../../../../../../../components/InputComponent';

const Draggable = ({
  onRadioChange,
  lastCheckedIndex,
  otherConfig,
  isSponsorQuestionnaire = false,
  ...item
}) => {
  const {
    state: { isSponsor },
  } = useContext(AppContext);
  const location = useLocation();
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: item?.name + 1,
    strategy: rectSortingStrategy,
  });

  const style = transform
    ? {
        opacity: isDragging ? 0.4 : undefined,
        transform: CSS?.Translate?.toString(transform),
        transition,
      }
    : undefined;

  const handlers = {
    ...attributes,
    ...listeners,
  };

  return (
    <>
      <div style={style} className="d-flex justify-between align-center">
        <span className="switch-logo font-500">
          {!isSponsorQuestionnaire ||
            (isSponsorQuestionnaire &&
              location?.pathname?.includes('/create') &&
              !isSponsor) ||
            (isSponsorQuestionnaire && isSponsor && (
              <>
                <MenuOutlined {...handlers} /> &nbsp;
              </>
            ))}
          {otherConfig?.[item?.name]?.blockTitle}
        </span>

        <Form.Item
          {...item}
          name={[item?.name, 'blockCheck']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Row
        className="mt-10 dragging-class"
        align="middle"
        justify="space-between"
        key={item?.id}
        ref={setNodeRef}
        style={style}
        gutter={12}
      >
        <Col span={8}>
          <Form.Item
            {...item}
            name={[item?.name, 'image']}
            className="mb-0"
            normalize={uploadImageNormalize}
            valuePropName="fileList"
          >
            <DraggerUploadComponent className="common-upload">
              <p className="icon-header">
                <DownloadOutlined />
              </p>
              <span className="upload-title">
                Drag image or browse your files
              </span>
            </DraggerUploadComponent>
          </Form.Item>
        </Col>
        <Col span={14}>
          <Form.Item
            {...item}
            name={[item?.name, 'label']}
            className="mb-0"
            rules={[
              {
                required: true,
                message: 'Please Enter Label',
              },
            ]}
          >
            <InputComponent placeholder="Enter Label *" />
          </Form.Item>
          <Form.Item
            {...item}
            name={[item?.name, 'blockDescription']}
            className="mt-10 mb-0"
          >
            <InputComponent placeholder="Enter Description" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

const SystemPickListForm = ({
  form,
  setDisableBtn,
  systemFieldData,
  setSystemFieldData,
  isSponsorQuestionnaire = false,
}) => {
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const sensors = useSensors(mouseSensor, touchSensor);

  const otherConfig = Form?.useWatch(
    ['widgetConfiguration', 'otherConfig'],
    form,
  );

  const handleDragEnd = ({ over, active }) => {
    if (!over) return;

    const source = active?.id - 1;
    const destination = over?.id - 1;
    if (source !== destination) {
      const optionsCopy = [
        ...arrayMove(
          form?.getFieldValue(['widgetConfiguration', 'otherConfig']),
          source,
          destination,
        ),
      ];

      form?.setFieldValue(['widgetConfiguration', 'otherConfig'], optionsCopy);
      setSystemFieldData({
        ...systemFieldData,
        config: {
          ...systemFieldData?.config,
          otherConfig: [...optionsCopy],
        },
      });
      setDisableBtn(false);
    }
  };

  return (
    <Form.List name={['widgetConfiguration', 'otherConfig']}>
      {(fields, { errors }) => (
        <>
          <div className="listview form-table nested-table">
            <DndContext
              sensors={sensors}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext
                strategy={verticalListSortingStrategy}
                items={fields?.map(({ name }) => name + 1)}
              >
                {fields?.map((field) => (
                  <div key={field?.name + 1}>
                    <Draggable
                      {...field}
                      otherConfig={otherConfig}
                      isSponsorQuestionnaire={isSponsorQuestionnaire}
                    />
                  </div>
                ))}
              </SortableContext>
            </DndContext>
          </div>
          <Form.ErrorList errors={errors} />
        </>
      )}
    </Form.List>
  );
};

export default SystemPickListForm;
