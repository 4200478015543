import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_USER_PERMISSION, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import PermissionList from './pages/PermissionList';
import './permissionModule.less';

const PermissionWrapper = () => (
  <div className="permission-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_USER_PERMISSION}
            showNoAccess
          >
            <PermissionList />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default PermissionWrapper;
