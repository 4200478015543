import React from 'react';
import PanelContent from '../../../../modules/productPricing/components/ProductPricingPanelComponent';
import '../productPricingPanel.less';
import PanelBtn from './PanelBtn';

const ProductPricingPanel = () => (
    <>
      <PanelBtn />
      <div className="side-panel-wrapper product-pricing-panel">
        <PanelContent />
      </div>
    </>
  );

export default ProductPricingPanel;
