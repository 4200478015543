import { Form, Radio } from 'antd';
import React, { useState } from 'react';
import PriceComponent from '../../../components/PriceComponent';
import './RenderItems.less';

const RenderNumber = ({
  numberData = null,
  dynamicFieldFormData = null,
  form = null,
}) => {
  const { key = '' } = numberData;
  const [isRange, setIsRange] = useState(false);

  return (
    <>
      <Form.Item name={['dynamicFields', key, 'filter']} initialValue="EQUAL">
        <Radio.Group
          onChange={(e) => {
            setIsRange(e?.target?.value === 'range');
          }}
          name={['dynamicFields', key, 'filter']}
          className="common-radio fill-width d-flex flex-vertical align-start radio-filter-options "
        >
          <Radio value="EQUAL">Equals</Radio>
          <Radio value="NOT_EQUAL">Not Equals</Radio>
          <Radio value="is_greater_than">Is Greater than</Radio>
          <Radio value="is_lower_than">Is Lower than</Radio>
          <Radio value="range">Range</Radio>
        </Radio.Group>
      </Form.Item>

      {isRange && (
        <div className="range-container">
          <Form.Item
            label="Min"
            name={['dynamicFields', key, 'min']}
            initialValue={0}
            className="label-with-tooltip-panel"
            onChange={() => {
              form?.validateFields();
            }}
            rules={[
              {
                async validator(_, value) {
                  if (value < 0) {
                    throw new Error(
                      'Minimum Values Should Be Greater Than Zero',
                    );
                  }
                  if (dynamicFieldFormData?.[key]?.max < value) {
                    throw new Error(
                      'Minimum Values Should Be Less than Maximum Value',
                    );
                  }
                },
              },
            ]}
          >
            <PriceComponent
              className="dynamic-price-component"
              prefix=""
              decimalValue={0}
              min={0}
            />
          </Form.Item>
          <Form.Item
            label="Max"
            name={['dynamicFields', key, 'max']}
            initialValue={0}
            className="label-with-tooltip-panel"
            onChange={() => {
              form?.validateFields();
            }}
            rules={[
              {
                async validator(_, value) {
                  if (value < 0) {
                    throw new Error(
                      'Maximum Values Should Be Greater Than Zero',
                    );
                  }
                  if (dynamicFieldFormData?.[key]?.min > value) {
                    throw new Error(
                      'Minimum Values Should Be Less than Maximum Value',
                    );
                  }
                },
              },
            ]}
          >
            <PriceComponent prefix="" decimalValue={0} min={0} />
          </Form.Item>
        </div>
      )}

      {!isRange && (
        <Form.Item name={['dynamicFields', key, 'value']} initialValue={0}>
          <PriceComponent prefix="" />
        </Form.Item>
      )}
    </>
  );
};

export default RenderNumber;
