import { Card, Col, Divider, Form, Radio, Row } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { fieldLevelDisabled, formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';
import SelectComponent from '../../../components/SelectComponent';

const { required } = formValidatorRules;
const { Option } = SelectComponent;

const SecondaryGeneralForm = ({
  industriesData,
  industryLobSaData,
  setIndustryLobSaData,
  setIsFormValuesChanged,
  setCheckedFields,
  id,
  sponsorName,
  editableFields = [],
}) => (
  <Card>
    <div className="setting-content-heading">
      <h5>GENERAL</h5>
      <span className="panel-description">
        General settings of this questionnaire
      </span>
    </div>
    <Row className="setting-content" gutter={10}>
      <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
        <Form.Item
          name="title"
          rules={[{ ...required, message: 'Please Enter Questionnaire Name' }]}
          label="Questionnaire Name"
        >
          <InputComponent
            disabled={fieldLevelDisabled({
              isSponsorRecord: sponsorName?.length > 0,
              fieldName: 'title',
              editableFields,
            })}
            allowClear
            placeholder="Add Questionnaire Name *"
          />
        </Form.Item>
      </Col>
      <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
        <Form.Item name="isActive" label="Status">
          <SelectComponent
            disabled={fieldLevelDisabled({
              isSponsorRecord: sponsorName?.length > 0,
              fieldName: 'isActive',
              editableFields,
            })}
            placeholder="Select Status"
            allowClear
          >
            <Option key="active" value>
              Active
            </Option>
            <Option key="inactive" value={false}>
              InActive
            </Option>
          </SelectComponent>
        </Form.Item>
      </Col>
    </Row>
    <Row className="setting-content" gutter={10}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Form.Item name="description" label="Description">
          <InputComponent
            disabled={fieldLevelDisabled({
              isSponsorRecord: sponsorName?.length > 0,
              fieldName: 'description',
              editableFields,
            })}
            allowClear
            placeholder="Add Description"
          />
        </Form.Item>
      </Col>
    </Row>
    <Divider />
    <div className="setting-content-heading">
      <h5>INDUSTRY</h5>
      <span className="panel-description">
        Select the industry for this questionnaire
      </span>
    </div>
    <Row className="setting-content">
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <Radio.Group
          className="fill-width"
          value={industryLobSaData?.industryId}
          onChange={() => {
            setIsFormValuesChanged(true);
            setCheckedFields([]);
          }}
          disabled={id}
        >
          <div className="d-flex flex-wrap block-parent">
            {map(industriesData, (item) => (
              <div className="lob-block" key={item?.id}>
                <Radio
                  className="common-radio lob-section"
                  value={item?.id}
                  onChange={(e) => {
                    setIndustryLobSaData({
                      industryId: e?.target?.value,
                      lineOfBusinessId: null,
                      subAreaId: null,
                    });
                  }}
                >
                  <span className="bold-label">{item?.label}</span>
                </Radio>
                <Divider />
                <Radio.Group
                  className="fill-width"
                  disabled={industryLobSaData?.industryId !== item?.id || id}
                  value={industryLobSaData?.lineOfBusinessId}
                  onChange={() => {
                    setIsFormValuesChanged(true);
                  }}
                >
                  <div className="d-flex flex-vertical">
                    {map(item?.lobSaData, (lob) => (
                      <div key={lob?.id}>
                        <Radio
                          className="common-radio lob-section"
                          value={lob?.id}
                          onChange={(e) => {
                            setIndustryLobSaData({
                              industryId: item?.id,
                              lineOfBusinessId: e?.target?.value,
                              subAreaId: lob?.subAreas?.[0]?.id,
                            });
                          }}
                        >
                          <span className="bold-label">{lob?.label}</span>
                          {industryLobSaData?.lineOfBusinessId !== lob?.id && (
                            <div className="subarea-section">
                              {map(lob?.subAreas, (subArea, subAreaIndex) => (
                                <div key={subArea?.id}>
                                  <span
                                    className={`subarea-label ${
                                      subAreaIndex !== 0 ? 'ml-4' : ''
                                    }`}
                                  >
                                    {subArea?.label}
                                    {lob?.subAreas?.length > 0 &&
                                      subAreaIndex !==
                                        lob?.subAreas?.length - 1 &&
                                      ', '}
                                  </span>
                                </div>
                              ))}
                            </div>
                          )}
                          {industryLobSaData?.lineOfBusinessId === lob?.id && (
                            <Radio.Group
                              className="fill-width"
                              value={industryLobSaData?.subAreaId}
                              onChange={() => {
                                setIsFormValuesChanged(true);
                              }}
                              disabled={id}
                            >
                              <div className="d-flex flex-vertical">
                                {map(lob?.subAreas, (subArea) => (
                                  <div key={subArea?.id}>
                                    <Radio
                                      className="common-radio subarea-radio-section"
                                      value={subArea?.id}
                                      onChange={(e) => {
                                        setIndustryLobSaData({
                                          industryId: item?.id,
                                          lineOfBusinessId: lob?.id,
                                          subAreaId: e?.target?.value,
                                        });
                                      }}
                                    >
                                      <span className="bold-label">
                                        {subArea?.label}
                                      </span>
                                    </Radio>
                                  </div>
                                ))}
                              </div>
                            </Radio.Group>
                          )}
                        </Radio>
                      </div>
                    ))}
                  </div>
                </Radio.Group>
              </div>
            ))}
          </div>
        </Radio.Group>
      </Col>
    </Row>
  </Card>
);

export default SecondaryGeneralForm;
