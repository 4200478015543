import { Tabs } from 'antd';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import InfoIconComponent from '../../../app/components/iconComponents/InfoIconComponent';
import gallerySvg from '../../../assets/gallery.svg';
import { ROUTES } from '../../../common/constants';

const infoTitle = (
  <div>
    <InfoIconComponent className="tab-icons" />
    <span className="card-title">Information</span>
  </div>
);
const infoTitleCustomer = (
  <div>
    <InfoIconComponent className="tab-icons" />
    <span className="card-title">Customer</span>
  </div>
);

const infoTitleContacts = (
  <div>
    <img src={gallerySvg} alt="info-icon" />
    <span className="card-title">Contacts</span>
  </div>
);

const PropertyTabs = ({ children }) => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleSelectTab = (key) => {
    switch (key) {
      case 'INFORMATION':
        if (location?.pathname?.includes('/edit')) {
          navigate(`${ROUTES?.PROPERTIES}/edit/${id}`);
        } else {
          navigate(`${ROUTES?.PROPERTIES}/view/${id}`);
        }
        break;

      case 'CUSTOMER':
        if (location?.pathname?.includes('/edit')) {
          navigate(`${ROUTES?.PROPERTIES}/edit${ROUTES?.CUSTOMERS}/${id}`);
        } else {
          navigate(`${ROUTES?.PROPERTIES}/view${ROUTES?.CUSTOMERS}/${id}`);
        }
        break;

      case 'CONTACTS':
        if (location?.pathname?.includes('/edit')) {
          navigate(`${ROUTES?.PROPERTIES}/edit${ROUTES?.CONTACTS}/${id}`);
        } else {
          navigate(`${ROUTES?.PROPERTIES}/view${ROUTES?.CONTACTS}/${id}`);
        }
        break;

      default:
        break;
    }
  };

  const tabItems = [
    {
      label: infoTitle,
      key: 'INFORMATION',
      children,
    },
    !location?.pathname?.includes('/add') && {
      label: infoTitleCustomer,
      key: 'CUSTOMERS',
      children,
    },
    !location?.pathname?.includes('/add') && {
      label: infoTitleContacts,
      key: 'CONTACTS',
      children,
    },
  ];

  const getActiveKey = () => {
    if (location?.pathname?.includes(ROUTES?.CUSTOMERS)) {
      return 'CUSTOMERS';
    }
    if (location?.pathname?.includes(ROUTES?.CONTACTS)) {
      return 'CONTACTS';
    }
    return 'INFORMATION';
  };

  return (
    <div className="card-container">
      <Tabs
        type="card"
        items={tabItems}
        activeKey={getActiveKey()}
        onChange={handleSelectTab}
      />
    </div>
  );
};

export default PropertyTabs;
