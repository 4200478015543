import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Error404 from '../../Error404';
import NotificationList from './pages/NotificationList';

const NotificationWrapper = () => (
  <Routes>
    <Route path={ROUTES?.MAIN} element={<NotificationList />} />
    <Route path="*" element={<Error404 />} />
  </Routes>
);

export default NotificationWrapper;
