import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PERMISSION_REST_API_LOGS, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import Error404 from '../../Error404';
import './apiLogsModule.less';
import ApiLogList from './pages/ApiLogList';
import ApiLogView from './pages/ApiLogView';

const ApiLogsWrapper = () => (
  <div className="api-log-wrapper">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_REST_API_LOGS}
            showNoAccess
          >
            <ApiLogList />
          </AccessControl>
        }
      />

      <Route
        path="/view/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_REST_API_LOGS_VIEW']}
            showNoAccess
          >
            <ApiLogView />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default ApiLogsWrapper;
