import { Checkbox, Col, DatePicker, Form, Row, TimePicker } from 'antd';
import { React, useState } from 'react';
import { formValidatorRules } from '../../../common/utils';
import InputComponent from '../../../components/InputComponent';

const { requiredWhiteSpaceAllowed } = formValidatorRules;

const CommonHolidayPanel = ({ holidayObj }) => {
  const [allDayChecked, setAllDayChecked] = useState(holidayObj?.allDayChecked);
  const [cutOfTimeChecked, setCutOfTimeChecked] = useState(
    holidayObj?.cutOfTimeChecked,
  );

  const handleAllDayCheckChange = (e) => {
    const {
      target: { checked },
    } = e;
    setAllDayChecked(checked);
  };

  const handleCutOfTimeCheckChange = (e) => {
    const {
      target: { checked },
    } = e;
    setCutOfTimeChecked(checked);
  };

  return (
    <>
      <Row>
        <Col xl={2}>
          <Form.Item
            name={[holidayObj?.id, 'date']}
            label="Date"
            initialValue={holidayObj?.date}
            rules={[
              {
                ...requiredWhiteSpaceAllowed,
                message: 'Please Enter Date',
              },
            ]}
          >
            <DatePicker
              format="MMM DD"
              allowClear
              className="date-picker-component-global"
              placeholder="Add Date"
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name={[holidayObj?.id, 'allDayChecked']}
        initialValue={holidayObj?.allDayChecked}
        valuePropName="checked"
      >
        <Checkbox
          onChange={handleAllDayCheckChange}
          className="hours-of-operation-checkbox"
        >
          All Day
        </Checkbox>
      </Form.Item>
      {!allDayChecked && (
        <Row gutter={8}>
          <Col span={3}>
            <Form.Item
              name={[holidayObj?.id, 'open']}
              label="Open"
              initialValue={holidayObj?.open}
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Select Open Time',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise?.resolve();
                    }
                    if (
                      getFieldValue([holidayObj?.id, 'close']) &&
                      value > getFieldValue([holidayObj?.id, 'close'])
                    ) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject(
                        'Open time cannot be greater than close time!',
                      );
                    }

                    return Promise?.resolve();
                  },
                }),
              ]}
            >
              <TimePicker
                format="h:mm a"
                className="common-time-picker"
                placeholder="Add Time"
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item
              name={[holidayObj?.id, 'close']}
              label="Close"
              initialValue={holidayObj?.close}
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Select Close Time',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || !getFieldValue([holidayObj?.id, 'open'])) {
                      return Promise?.resolve();
                    }
                    if (
                      getFieldValue([holidayObj?.id, 'open']) &&
                      value < getFieldValue([holidayObj?.id, 'open'])
                    ) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject(
                        'Close time cannot be less than open time!',
                      );
                    }
                    return Promise?.resolve();
                  },
                }),
              ]}
            >
              <TimePicker
                format="h:mm a"
                className="common-time-picker"
                placeholder="Add Time"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Form.Item
        name={[holidayObj?.id, 'cutOfTimeChecked']}
        valuePropName="checked"
        initialValue={holidayObj?.cutOfTimeChecked}
      >
        <Checkbox
          className="hours-of-operation-checkbox"
          onChange={handleCutOfTimeCheckChange}
        >
          Cut-off time
        </Checkbox>
      </Form.Item>
      {cutOfTimeChecked && (
        <Row>
          <Col xl={8}>
            <Form.Item
              name={[holidayObj?.id, 'cutOfTime']}
              initialValue={holidayObj?.cutOfTime}
              rules={[
                {
                  ...requiredWhiteSpaceAllowed,
                  message: 'Please Select Cut-off time',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise?.resolve();
                    }

                    if (!getFieldValue([holidayObj?.id, 'allDayChecked'])) {
                      if (
                        getFieldValue([holidayObj?.id, 'close']) &&
                        value > getFieldValue([holidayObj?.id, 'close'])
                      ) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject('Please Enter Valid Time!');
                      }
                      if (
                        getFieldValue([holidayObj?.id, 'open']) &&
                        value < getFieldValue([holidayObj?.id, 'open'])
                      ) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject('Please Enter Valid Time!');
                      }
                    }

                    return Promise?.resolve();
                  },
                }),
              ]}
            >
              <TimePicker
                format="h:mm a"
                className="common-time-picker"
                placeholder="Add Time"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Form.Item
        label="Holiday Message"
        initialValue={holidayObj?.holidayMessage}
        name={[holidayObj?.id, 'holidayMessage']}
      >
        <InputComponent placeholder="Add Holiday Message" />
      </Form.Item>
    </>
  );
};
export default CommonHolidayPanel;
