import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PrivateRoute from '../../PrivateRoute';
import PublicRoute from '../../PublicRoute';
import Administrator from './component/Administrator';
import BusinessInfo from './component/BusinessInfo';
import Plan from './component/Plan';
import Success from './component/Success';
import './signup.less';

const SignupWrapper = () => (
  <Routes>
    <Route path="/" element={<Navigate to="administrator" replace />} />
    <Route
      path="administrator"
      element={
        <PublicRoute>
          <Administrator />
        </PublicRoute>
      }
    />
    <Route
      path="business-info"
      element={
        <PrivateRoute fromSignup>
          <BusinessInfo />
        </PrivateRoute>
      }
    />
    <Route
      path="plan"
      element={
        <PrivateRoute fromSignup>
          <Plan />
        </PrivateRoute>
      }
    />
    <Route
      path="success"
      element={
        <PrivateRoute fromSignup>
          <Success />
        </PrivateRoute>
      }
    />
  </Routes>
);

export default SignupWrapper;
