import { QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Divider, Form, Radio, Row } from 'antd';
import { map } from 'lodash';
import React, { useCallback } from 'react';
import { DYNAMIC_TEXT_TYPE, REGEX } from '../../../common/constants';
import InputComponent from '../../../components/InputComponent';
import NumberComponent from '../../../components/NumberComponent';

const TextFormData = ({
  position = 'top',
  isDisabled = false,
  handleChangeTextType,
  form,
}) => {
  const updateMax = useCallback(() => {
    if (form?.getFieldValue('textType') === 'SINGLE_LINE') {
      return 255;
    }
    if (form?.getFieldValue('textType') === 'MULTI_LINE') {
      return 1000;
    }
    return 5000;
  }, [form]);

  const max = updateMax();

  return (
    <div className="text-form-data">
      {position === 'top' && (
        <>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <Form.Item name="textType">
                <Radio.Group
                  className="common-radio"
                  disabled={isDisabled}
                  onChange={handleChangeTextType}
                >
                  {map(DYNAMIC_TEXT_TYPE, (item) => (
                    <Radio key={item?.key} value={item?.key}>
                      {item?.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={6} sm={6} md={6} lg={6} xl={3} xxl={3}>
              <Form.Item
                name="minLength"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!REGEX?.NUMBER?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject('Should be a valid Number');
                      }
                      if (value && Number(value) < 1) {
                        return Promise?.reject(
                          new Error('Minimum character length should be 1'),
                        );
                      }
                      if (value && Number(value) > max) {
                        return Promise?.reject(
                          new Error(`Maximum character length should be ${max}`),
                        );
                      }
                      if (
                        getFieldValue('maxLength') &&
                        value &&
                        Number(value) > getFieldValue('maxLength')
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Please Enter value less than ${getFieldValue(
                              'maxLength',
                            )}`,
                          ),
                        );
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
                label="Min. Char. Length"
              >
                <NumberComponent
                  min={1}
                  max={updateMax()}
                  disabled={isDisabled}
                  allowClear
                  placeholder="Enter Min. Char. Length"
                />
              </Form.Item>
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} xl={3} xxl={3}>
              <Form.Item
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!REGEX?.NUMBER?.test(value)) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return Promise?.reject('Should be a valid Number');
                      }
                      if (value && Number(value) < 1) {
                        return Promise?.reject(
                          new Error('Minimum character length should be 1'),
                        );
                      }
                      if (value && Number(value) > max) {
                        return Promise?.reject(
                          new Error(`Maximum character length should be ${max}`),
                        );
                      }
                      if (
                        getFieldValue('minLength') &&
                        value &&
                        Number(value) < getFieldValue('minLength')
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Please Enter value greater than ${getFieldValue(
                              'minLength',
                            )}`,
                          ),
                        );
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
                name="maxLength"
                label="Max. Char. Length"
              >
                <NumberComponent
                  min={1}
                  max={updateMax()}
                  disabled={isDisabled}
                  allowClear
                  placeholder="Enter Max. Char. Length"
                />
              </Form.Item>
            </Col>
          </Row>
          <span className="warning-text">
            <QuestionCircleOutlined />{' '}
            {`You are allowed to add maximum ${updateMax()} characters.`}
          </span>
        </>
      )}
      {position === 'bottom' && (
        <>
          <span className="form-divider-text">FIELD SETTINGS</span>
          <Divider className="form-divider" />
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
              <Form.Item
                rules={[
                  ({ getFieldValue }) => ({
                    required:
                      getFieldValue('required') &&
                      (getFieldValue('readOnly') || getFieldValue('hidden')),
                    message: 'Please Enter value',
                  }),
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const maxLength =
                        getFieldValue('maxLength') || updateMax();
                      if (
                        getFieldValue('minLength') &&
                        maxLength &&
                        value?.length < getFieldValue('minLength') &&
                        value?.length > maxLength
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Please Enter value between ${getFieldValue(
                              'minLength',
                            )} & ${maxLength}`,
                          ),
                        );
                      }
                      if (
                        getFieldValue('required') &&
                        getFieldValue('minLength') &&
                        value?.length < getFieldValue('minLength')
                      ) {
                        return Promise?.reject(
                          new Error(
                            `Minimum length should be ${getFieldValue(
                              'minLength',
                            )}`,
                          ),
                        );
                      }
                      if (maxLength && value?.length > maxLength) {
                        return Promise?.reject(
                          new Error(`Maximum length should be ${maxLength}`),
                        );
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
                name="default"
                label="Default"
                className="label-with-tooltip"
                tooltip="Set the initial value here."
              >
                <InputComponent allowClear placeholder="Enter Default" />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default TextFormData;
