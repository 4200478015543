import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation webLogin($data: loginInput!) {
    webLogin(data: $data) {
      ... on LoginResponse {
        authToken
        user {
          id
          firstName
          lastName
          roles
          email
          phoneNo
          tenantId
          profileImage {
            url
            key
            name
            contentType
            extension
          }
          tenantId
          emailVerified
        }
      }
      ... on CommonMessageResponse {
        status
        message
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation webForgotPassword($data: webForgotPasswordInput!) {
    webForgotPassword(data: $data) {
      status
      message
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($data: resetPasswordInput!) {
    resetPassword(data: $data) {
      status
      message
    }
  }
`;

export const TOKEN_VALIDATION = gql`
  mutation verifyToken($data: verifyTokenInput!) {
    verifyToken(data: $data) {
      message
      status
    }
  }
`;

export const EMAIL_VALIDATION = gql`
  mutation verifyUserEmail($resetToken: String) {
    verifyUserEmail(where: { reset_token: $resetToken }) {
      message
      status
    }
  }
`;

export const VERIFY_OTP = gql`
  mutation VerifyOtp($data: VerifyOtpInput!) {
    verifyOtp(data: $data) {
      authToken
      message
      user {
        id
        firstName
        lastName
        email
        phoneNo
        roles
        uuid
        isActive
        emailVerified
        tenantId
        sponsorId
      }
    }
  }
`;

export const RESEND_OTP = gql`
  mutation ResendOtp($email: EmailAddress!) {
    resendOtp(email: $email) {
      message
      status
    }
  }
`;

export const PROSPECT_INVITATION_STATUS = gql`
  mutation changeProspectInvitationStatus(
    $data: ChangeProspectInvitationStatusInput!
  ) {
    changeProspectInvitationStatus(data: $data) {
      message
      status
      resetPasswordUrl
    }
  }
`;
