import { LoadingOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import { Spin } from 'antd';
import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppContext } from './AppContext';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import { ROUTES } from './common/constants';
import { fetchStep } from './common/utils';
import HistoryRouter from './components/HistoryRouter';
import keysDown from './components/HotKeys/HotKeys';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import history from './historyData';
import ChangePassword from './modules/auth/ChangePassword';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import OtpVerification from './modules/auth/OtpVerification';
import ProspectInvite from './modules/auth/ProspectInvite';
import ResetPassword from './modules/auth/ResetPassword';
import VerifyEmail from './modules/auth/VerifyEmail';
import SignupWrapper from './modules/signup';

const RoutesWrapper = () => {
  const {
    initializeAuth,
    defaultPageSize,
    getToken,
    getCurrentUser,
    dispatch,
    state: { loading, isSponsor },
  } = useContext(AppContext);

  const idToken = getToken();

  useEffect(() => {
    defaultPageSize();
    if (idToken) {
      fetchStep({
        dispatch,
        setLoading: true,
        changeRoute: true,
        getCurrentUser,
        initializeAuth,
        getToken,
        // eslint-disable-next-line no-undef
        queryToken: window?.location?.search?.split('?token=')?.[1],
      });
    } else {
      dispatch({
        type: 'SET_LOADING',
        data: false,
      });
    }

    // eslint-disable-next-line no-undef
    document?.addEventListener('keydown', keysDown);
    return () => {
      // eslint-disable-next-line no-undef
      document?.removeEventListener('keydown', keysDown);
    };
  }, [idToken]);

  const updateWidth = () => {
    // eslint-disable-next-line no-undef
    dispatch({ type: 'SET_WIDTH', data: window?.innerWidth });
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('resize', updateWidth);
    // eslint-disable-next-line no-undef
    return () => window?.removeEventListener('resize', updateWidth);
  }, []);

  const MyFallbackComponent = ({ error, componentStack }) => (
    <div>
      <p>
        <strong>Oops! An error occured!</strong>
      </p>
      <p>Below is the details…</p>
      <p>
        <strong>Error:</strong> {error?.toString()}
      </p>
      <p>
        <strong>Stacktrace:</strong> {componentStack}
      </p>
    </div>
  );

  Spin?.setDefaultIndicator(<LoadingOutlined className="font-size-32" spin />);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (maintenance === 'true') {
    return <MaintenancePage />;
  }

  if (loading) {
    return <LoaderComponent />;
  }

  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <HistoryRouter history={history}>
        <Routes>
          <Route
            path={`${ROUTES?.SIGNUP}/*`}
            element={
              <PublicRoute>
                <SignupWrapper />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES?.RESET}
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES?.OTP_VERIFICATION}
            element={
              <PublicRoute>
                <OtpVerification />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES?.LOGIN}
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path={ROUTES?.VERIFY}
            element={
              <PublicRoute>
                <ChangePassword />
              </PublicRoute>
            }
          />
          <Route path={ROUTES?.VERIFY_EMAIL} element={<VerifyEmail />} />
          {!isSponsor && (
            <Route
              path={ROUTES?.PROSPECT_INVITE}
              element={<ProspectInvite />}
            />
          )}
          <Route
            path={ROUTES?.LOGOUT}
            element={
              <PrivateRoute>
                <Logout />
              </PrivateRoute>
            }
          />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <App />
              </PrivateRoute>
            }
          />
        </Routes>
      </HistoryRouter>
    </Sentry.ErrorBoundary>
  );
};

export default RoutesWrapper;
