import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Popover, Upload } from 'antd';
import { get, lowerCase } from 'lodash';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { messageContext } from '../app/components/AppContextHolder';
import CsvIcon from '../assets/csv.svg';
import api from '../common/api';
import { ALLOW_IMPORT_TYPES, ROUTES, WRITE } from '../common/constants';
import { downloadFromURL, handleCsvDownload } from '../common/utils';
import LoaderComponent from './LoaderComponent';
import { GET_SIGN_URL } from './graphql/Mutation';
import './styles/CommonGoogleFileUpload.less';

const { Dragger } = Upload;
const CommonGoogleFileUpload = (props) => {
  const {
    state: { currentUser, isSponsor },
  } = useContext(AppContext);
  const [form] = Form?.useForm();
  const navigate = useNavigate();
  const {
    folder = 'unwanted',
    url = '',
    fromImport = false,
    setShowImportModal,
    callback,
  } = props;
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');
  const [fileKey, setFileKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [sampleLoading, setSampleLoading] = useState(false);

  const [uploadFile] = useMutation(GET_SIGN_URL, {
    onCompleted: async (res) => {
      if (res?.generateSignedUrl) {
        const { signedRequest, url: URL } = res?.generateSignedUrl;
        // above url is send to the backend
        try {
          const response = await api(signedRequest, {
            method: 'PUT',
            data: file,
            headers: {
              'Content-Type': 'text/csv',
            },
          });
          if (response) {
            try {
              await api({
                method: 'POST',
                url: `${process.env.REACT_APP_SERVER_REST_URL}/import`,
                data: {
                  importType: folder,
                  fileObj: {
                    url: URL,
                    name: fileName,
                    key: fileKey,
                  },
                },
              });
              messageContext?.info(
                'Your data import job has begun. We will notify you via email as soon as it is completed. ',
                5,
              );
              if (setShowImportModal) {
                setShowImportModal(false);
              }
              if (callback) {
                callback();
              }
              if (fromImport) {
                navigate(ROUTES?.IMPORTS);
              }
              setLoading(false);

              // commented this code as now it will go to queue

              // const responseMsg =
              //   get(finalResponse, 'headers.x-message') || '';
              // if (finalResponse?.data) {
              //   const errorFileName =
              //     get(finalResponse, 'headers.x-error') || '';

              //   handleCsvDownload(finalResponse?.data, errorFileName);
              //   messageContext?.error(responseMsg);
              // } else {
              //   messageContext?.success(responseMsg);
              // }
              // if (setShowImportModal) {
              //   setShowImportModal(false);
              // }
              // if (callback) {
              //   callback();
              // }
              // if (fromImport) {
              //    navigate(ROUTES?.IMPORTS);
              // }
              // setLoading(false);
            } catch (error) {
              const responseMsg =
                get(error?.response, 'headers.x-message') || '';
              messageContext?.error(responseMsg);
              setLoading(false);
            }
          }
        } catch (error) {
          const responseMsg = get(error?.response, 'headers.x-message') || '';
          messageContext?.error(responseMsg);
          setLoading(false);
        }
      }
    },
    onError: () => {
      setLoading(false);
    },
  });

  const handleUploadFile = () => {
    setLoading(true);
    const timestamp = Date?.now();
    const ext = fileName.substring(fileName.lastIndexOf('.') + 1);
    const newFilename = `${timestamp}_${fileName}`;
    const key = `organization/${
      isSponsor ? currentUser?.sponsorId : currentUser?.tenantId
    }/imports/${folder}/${newFilename}`;
    setFileKey(key);
    // for Upload file on Bucket
    uploadFile({
      variables: {
        action: WRITE,
        extension: `.${ext}`,
        contentType: fileType,
        key,
      },
    });
  };

  const onChange = (info) => {
    const {
      file: { name, type },
    } = info;
    const ext = name?.substring(name?.lastIndexOf('.') + 1);
    if (ALLOW_IMPORT_TYPES.includes(ext)) {
      setFileList([...info?.fileList]);
      setFile(info?.file);
      setFileName(name);
      setFileType(type);
    } else {
      messageContext?.destroy();
      messageContext?.error(`${info?.file?.name} file is not .CSV file.`);
      form?.resetFields();
    }
  };

  const handleDownloadSample = async () => {
    switch (folder) {
      case 'PRODUCT_ITEM':
        // runtime api call here
        try {
          setSampleLoading(true);
          const res = await api({
            method: 'POST',
            url: `${process.env.REACT_APP_SERVER_REST_URL}/sample-file`,
            data: {
              module: folder,
            },
          });
          handleCsvDownload(
            res?.data,
            `${[lowerCase(folder)?.replace(/[_\s]+/g, '-')]}-sample.csv`,
          );
          setSampleLoading(false);
        } catch (error) {
          messageContext?.error(
            error?.response?.data?.error || 'got some problem',
          );
          setSampleLoading(false);
        }
        break;

      default:
        downloadFromURL(
          url,
          `${lowerCase(folder)?.replace(/[_ ]+/g, '-')}-sample.csv`,
        );
        break;
    }
  };

  return (
    <div className="google-upload">
      <LoaderComponent spinning={loading} setHeight={15}>
        <Form form={form}>
          <div
            className={`upload-logo ${
              fileList?.length > 0 ? 'uploaded-file' : ''
            }`}
          >
            <Dragger
              showUploadList={{ showPreviewIcon: false }}
              listType="picture-card"
              beforeUpload={
                () => false // For stopping the default upload action of antd
              }
              fileList={fileList}
              maxCount={1}
              onChange={onChange}
            >
              <p className="ant-upload-drag-icon">
                <PlusOutlined />
              </p>
              <p className="ant-upload-text">
                Choose a CSV file or drag it here.
              </p>
            </Dragger>
          </div>
        </Form>
      </LoaderComponent>
      <div className="d-flex justify-between">
        <LoaderComponent size="small" spinning={sampleLoading} setHeight={3}>
          <span className="d-flex align-center">
            <img src={CsvIcon} alt="csv-icon" />
            <span className="link-btn" onClick={handleDownloadSample}>
              Sample.csv
            </span>
            <Popover
              trigger="hover"
              content="This feature enables you to export data in CSV format with pre-labeled fields, that simplifies importing the data accurately."
              overlayClassName="common-tooltip"
            >
              <InfoCircleOutlined className="info-icon" />
            </Popover>
          </span>
        </LoaderComponent>
        <Button
          type="primary"
          disabled={!fileList?.length}
          loading={loading}
          className="import-btn"
          onClick={handleUploadFile}
        >
          Import
        </Button>
      </div>
    </div>
  );
};

export default CommonGoogleFileUpload;
