import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Upload } from 'antd';
import { filter, forEach } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { messageContext } from '../app/components/AppContextHolder';
import { AppContext } from '../AppContext';
import api from '../common/api';
import { ALLOW_IMAGE_TYPES, ROUTES, WRITE } from '../common/constants';
import { getBase64 } from '../common/utils';
import {
  CREATE_ATTACHMENT,
  DELETE_ATTACHMENT,
  GET_SIGN_URL,
} from './graphql/Mutation';
import PreviewModal from './PreviewModal';
import './styles/GalleryComponent.less';

let tempFileArray = [];

const GalleryComponent = (props) => {
  const {
    state: { isSponsor },
  } = useContext(AppContext);
  const {
    recordId = null,
    uuid = null,
    tenantSponsorId = null,
    module = 'product',
    moduleKey = 'PRODUCT',
    fileListProps = [],
    refetch,
    isEdit = true,
  } = props;

  const [fileList, setFileList] = useState([]);
  const [newFileList, setNewFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [, /* uploading */ setUploading] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');

  useEffect(() => {
    if (fileListProps) {
      setFileList(fileListProps);
      tempFileArray = [];
    }
  }, [fileListProps]);

  const [createAttachment] = useMutation(CREATE_ATTACHMENT, {
    onError: () => {},
  });

  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT, {
    onError: () => {},
  });

  const [uploadFile] = useMutation(GET_SIGN_URL, {
    onError: () => {},
  });

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewTitle(
      fileObj?.name ||
        fileObj?.label ||
        fileObj?.url?.substring(fileObj?.url?.lastIndexOf('/') + 1),
    );
    setPreviewImage(fileObj?.url || preview);
  };

  const handleRemove = async (file) => {
    const response = await deleteAttachment({
      variables: {
        where: {
          id: file?.id,
        },
      },
    });
    if (response) {
      setFileList(filter(fileList, (item) => item?.id !== file?.id));
    }
  };

  const uploadToGoogleStorage = (uploadProps) =>
    new Promise((res, rej) => {
      const { onSuccess, onError, file, onProgress } = uploadProps;
      const { name, type } = file;
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      if (ALLOW_IMAGE_TYPES?.includes(ext)) {
        const filename = name?.split('.')?.slice(0, -1)?.join('.');
        const timestamp = Date?.now();
        const newFilename = `${timestamp}_${filename}.${ext}`;
        const fileKey = `organization/${tenantSponsorId}/${module}/${uuid}${ROUTES?.GALLERY}/${newFilename}`;
        uploadFile({
          variables: {
            action: WRITE,
            extension: `.${ext}`,
            contentType: type,
            key: fileKey,
          },
        }).then((uploadFileData) => {
          if (uploadFileData?.errors) {
            setFileList(filter(fileList, (item) => item?.uid !== file?.uid));
          }
          if (uploadFileData?.data?.generateSignedUrl) {
            const {
              signedRequest,
              url: URL,
            } = uploadFileData?.data?.generateSignedUrl;
            // above url is send to the backend
            try {
              api(signedRequest, {
                method: 'PUT',
                data: file,
                headers: {
                  'Content-Type': file?.type,
                },
                onUploadProgress: (event) => {
                  onProgress({ percent: (event?.loaded / event?.total) * 100 });
                },
              })
                .then((response) => {
                  if (response) {
                    // For sending url to backend
                    createAttachment({
                      variables: {
                        data: {
                          key: fileKey,
                          label: filename,
                          type: 'IMAGE',
                          isActive: true,
                          url: URL,
                          referenceId: recordId,
                          referenceKey: moduleKey,
                        },
                      },
                    })
                      .then((finalResponse) => {
                        if (finalResponse) {
                          onSuccess(null, file);
                          res({
                            id: finalResponse?.data?.createAttachment?.data?.id,
                            url:
                              finalResponse?.data?.createAttachment?.data?.url,
                          });
                        }
                      })
                      .catch((error) => {
                        setUploading(false);
                        setFileList(
                          filter(fileList, (item) => item?.uid !== file?.uid),
                        );
                        onError(error?.responseText, error?.response, file);
                      });
                  }
                })
                .catch((error) => {
                  setUploading(false);
                  setFileList(
                    filter(fileList, (item) => item?.uid !== file?.uid),
                  );
                  onError(error?.responseText, error?.response, file);
                });
            } catch (error) {
              setUploading(false);
              setFileList(filter(fileList, (item) => item?.uid !== file?.uid));
              onError(error?.responseText, error?.response, file);
              rej(error);
            }
          }
        });
      } else {
        messageContext?.destroy();
        messageContext?.error(`${name} file is not image file.`);
      }
    });

  const handleUpload = async (uploadProps) => {
    setUploading(true);
    const data = await uploadToGoogleStorage(uploadProps);
    tempFileArray?.push(data);
    if (tempFileArray?.length === newFileList?.length) {
      refetch();
      setUploading(false);
    }
  };

  const handleChange = ({ file, fileList: files }) => {
    if (file?.status === 'removed') {
      return;
    }
    const imageFiles = [];
    const newFiles = [];
    forEach(files, (item) => {
      const ext = item?.name?.substring(item?.name?.lastIndexOf('.') + 1);
      if (ALLOW_IMAGE_TYPES?.includes(ext) || item?.url) {
        imageFiles?.push(item);
      }
      if (ALLOW_IMAGE_TYPES?.includes(ext) && !item?.url) {
        newFiles?.push(item);
      }
    });
    setFileList(imageFiles);
    setNewFileList(newFiles);
  };

  return (
    <div className="gallery-component">
      {/* commented as not working properly */}
      {/* <RouterPrompt when={uploading} /> */}
      <PreviewModal
        previewImage={previewImage}
        previewTitle={previewTitle}
        previewVisible={previewVisible}
        setPreviewVisible={setPreviewVisible}
      />
      <Upload
        multiple
        listType="picture-card"
        onPreview={handlePreview}
        itemRender={(originNode, file) => (
          <div>
            {originNode}
            <div
              title={
                file?.name?.split('.')?.slice(0, -1)?.join('.') || file?.label
              }
              className="gallery-label"
            >
              {file?.name?.split('.')?.slice(0, -1)?.join('.') || file?.label}
            </div>
          </div>
        )}
        fileList={fileList}
        showUploadList={{
          showDownloadIcon: false,
          showPreviewIcon: true,
          showRemoveIcon: (file) => {
            if (isEdit) {
              if (!isSponsor && file?.createdBy === 'SPONSOR') {
                return false;
              }
              return true;
            }
            return false;
          },
        }}
        customRequest={handleUpload}
        onChange={handleChange}
        onRemove={handleRemove}
      >
        {isEdit && (
          <div className="upload-content">
            <span className="icon-header">
              <PlusOutlined />
            </span>
            <span className="upload-title">Upload</span>
            <span className="upload-description">
              ...one or multiple media files
            </span>
          </div>
        )}
      </Upload>
    </div>
  );
};

export default GalleryComponent;
