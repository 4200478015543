import { capitalize, find } from 'lodash';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import placeholderImage from '../../../assets/images/place-holder-image-master.svg';
import { DYNAMIC_FIELDS_DATA } from '../../../common/constants';
import {
  formatDate,
  formatPhoneNumber,
  formatPrice,
  formatUnits,
  strippedString,
} from '../../../common/utils';

const qualityObjCopy = {
  GOOD: 'Good',
  BETTER: 'Better',
  BEST: 'Best',
};

// eslint-disable-next-line import/prefer-default-export
export const Columns = (module = '') => {
  const {
    state: { globalDateFormat },
  } = useContext(AppContext);

  let columns;
  switch (module) {
    case 'Brand':
      columns = [
        {
          title: 'NAME',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
          className: 'max-width-column',
          render: (name, record) => (
            <>
              <img
                src={record?.logo?.url || placeholderImage}
                alt="brand-img"
                onError={(e) => {
                  e.target.src = placeholderImage;
                }}
                className="list-img image-contain"
                width={24}
                height={24}
              />
              <span title={name}>{name}</span>
            </>
          ),
        },
        {
          title: 'ADDED ON',
          dataIndex: 'createdAt',
          key: 'createdAt',
          ellipsis: true,
          width: 140,
          render: (createdAt) => (
            <span>{formatDate(createdAt, globalDateFormat)}</span>
          ),
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
          width: 120,
          render: (status) => capitalize(status),
        },
      ];
      break;
    case 'Product':
      columns = [
        {
          title: 'SKU',
          dataIndex: 'sku',
          key: 'sku',
          className: 'max-width-column',
          ellipsis: true,
          width: 120,
        },
        {
          title: 'NAME',
          dataIndex: 'title',
          key: 'title',
          className: 'max-width-column',
          ellipsis: true,
        },
        {
          title: 'QUALITY',
          dataIndex: 'productQuality',
          key: 'productQuality',
          ellipsis: true,
          width: 130,
          render: (productQuality) => (
            <span>{qualityObjCopy[productQuality]}</span>
          ),
        },
        {
          title: 'TYPE',
          dataIndex: 'productType',
          key: 'productType',
          ellipsis: true,
          width: 100,
        },
        {
          title: 'PRICE',
          dataIndex: 'price',
          key: 'price',
          align: 'right',
          ellipsis: true,
          width: 150,
          render: (price = null) => (
            <span title={formatPrice(price)}>{formatPrice(price)}</span>
          ),
        },
        {
          title: 'PACKAGE PRICE',
          dataIndex: 'sellingPrice',
          key: 'sellingPrice',
          align: 'right',
          ellipsis: true,
          width: 180,
          render: (sellingPrice = null) => (
            <span title={formatPrice(sellingPrice)}>
              {formatPrice(sellingPrice)}
            </span>
          ),
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
          width: 120,
          render: (status) => capitalize(status),
        },
      ];
      break;

    case 'ProductItem':
      columns = [
        {
          title: 'SKU',
          dataIndex: 'sku',
          key: 'sku',
          ellipsis: true,
          className: 'max-width-column',
          width: 120,
        },
        {
          title: 'NAME',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
          className: 'max-width-column',
        },
        {
          title: 'COST',
          dataIndex: 'costPrice',
          key: 'costPrice',
          ellipsis: true,
          align: 'right',
          width: 130,
          className: 'max-width-column',
          render: (costPrice) => (
            <span title={formatPrice(costPrice)}>{formatPrice(costPrice)}</span>
          ),
        },
        {
          title: 'DEF.PRICE',
          dataIndex: 'defaultPrice',
          key: 'defaultPrice',
          ellipsis: true,
          align: 'right',
          width: 140,
          className: 'max-width-column',
          render: (defaultPrice) => (
            <span title={formatPrice(defaultPrice)}>
              {formatPrice(defaultPrice)}
            </span>
          ),
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
          width: 120,
          render: (status) => capitalize(status),
        },
      ];
      break;

    case 'ProductCategory':
      columns = [
        {
          title: 'TITLE',
          dataIndex: 'title',
          key: 'title',
          ellipsis: true,
          width: 120,
          className: 'max-width-column',
        },
        {
          title: 'DESCRIPTION',
          dataIndex: 'description',
          key: 'description',
          ellipsis: true,
          className: 'max-width-column',
          render: (description = '') => strippedString(description) || '-',
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
          width: 120,
          render: (status) => capitalize(status),
        },
      ];
      break;

    case 'Manufacturer':
      columns = [
        {
          title: 'TITLE',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
          width: 200,
          className: 'max-width-column',
          render: (name, record) => (
            <>
              <img
                src={record?.logo?.url || placeholderImage}
                alt="brand-img"
                onError={(e) => {
                  e.target.src = placeholderImage;
                }}
                className="list-img image-contain"
                width={24}
                height={24}
              />
              <span title={name}>{name}</span>
            </>
          ),
        },
        {
          title: 'CONTACT NUMBER',
          dataIndex: 'contactNumber',
          key: 'contactNumber',
          ellipsis: true,
          width: 180,
          render: (contactNumber) => formatPhoneNumber(contactNumber) || '-',
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
          width: 120,
          render: (status) => capitalize(status),
        },
      ];
      break;

    case 'DynamicField':
      columns = [
        {
          title: 'LABEL',
          ellipsis: true,
          width: 130,
          dataIndex: 'label',
          key: 'label',
          className: 'max-width-column',

          render: (label) => <span>{label || '-'}</span>,
        },
        {
          title: 'FIELD KEY',
          ellipsis: true,
          dataIndex: 'key',
          className: 'max-width-column',
          key: 'key',
          render: (key) => <span>{key || '-'}</span>,
        },
        {
          title: 'FIELD TYPE',
          width: 150,
          ellipsis: true,
          dataIndex: 'fieldType',
          key: 'fieldType',
          className: 'max-width-column',
          render: (fieldType) => (
            <span>
              {find(DYNAMIC_FIELDS_DATA, ['key', fieldType])?.label || '-'}
            </span>
          ),
        },
        {
          title: 'ORDER',
          width: 100,
          ellipsis: true,
          dataIndex: 'sortOrder',
          key: 'sortOrder',
          render: (sortOrder) => <span>{sortOrder ?? '-'}</span>,
        },
        {
          title: 'STATUS',
          width: 120,
          dataIndex: 'status',
          key: 'status',
          render: (status) => capitalize(status),
        },
      ];
      break;

    case 'Campaign':
      columns = [
        {
          title: 'NAME',
          dataIndex: 'name',
          key: 'name',
          ellipsis: true,
          width: 120,
          className: 'max-width-column',
        },
        {
          title: 'CODE',
          dataIndex: 'code',
          key: 'code',
          ellipsis: true,
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 'status',
          render: (status) => capitalize(status),
        },
      ];
      break;

    case 'PricingModifier':
      columns = [
        {
          title: 'TITLE',
          dataIndex: 'title',
          key: 'title',
          ellipsis: true,
          width: 200,
          className: 'max-width-column',
        },
        {
          title: 'MODIFIER ON',
          dataIndex: 'modifierOn',
          key: 'modifierOn',
          ellipsis: true,
          width: 160,
          render: (modifierOn) => {
            if (!modifierOn) {
              return <span>-</span>;
            }
            return <span>{`${modifierOn} Value `}</span>;
          },
        },
        {
          title: 'GOOD',
          dataIndex: 'pricingValue',
          key: 'pricingValue',
          ellipsis: true,
          onHeaderCell: () => ({
            style: {
              textAlign: 'right',
            },
          }),
          align: 'right',
          width: 130,
          render: (amount, record) => {
            if (record?.pricingValueType === '$') {
              return (
                <span>
                  {record?.pricingOperation === 'ADD' ? '' : '-'}
                  {record?.pricingValueType}
                  {formatUnits(amount)}
                </span>
              );
            }
            return (
              <span>
                {record?.pricingOperation === 'ADD' ? '' : '-'}
                {formatUnits(amount)}
                {record?.pricingValueType}
              </span>
            );
          },
        },
        {
          title: 'BETTER',
          dataIndex: 'pricingValueBetter',
          key: 'pricingValueBetter',
          ellipsis: true,
          onHeaderCell: () => ({
            style: {
              textAlign: 'right',
            },
          }),
          align: 'right',
          width: 130,
          render: (amount, record) => {
            if (record?.pricingValueType === '$') {
              return (
                <span>
                  {record?.pricingOperation === 'ADD' ? '' : '-'}
                  {record?.pricingValueType}
                  {formatUnits(amount)}
                </span>
              );
            }
            return (
              <span>
                {record?.pricingOperation === 'ADD' ? '' : '-'}
                {formatUnits(amount)}
                {record?.pricingValueType}
              </span>
            );
          },
        },
        {
          title: 'BEST',
          dataIndex: 'pricingValueBest',
          key: 'pricingValueBest',
          ellipsis: true,
          onHeaderCell: () => ({
            style: {
              textAlign: 'right',
            },
          }),
          align: 'right',
          width: 130,
          render: (amount, record) => {
            if (record?.pricingValueType === '$') {
              return (
                <span>
                  {record?.pricingOperation === 'ADD' ? '' : '-'}
                  {record?.pricingValueType}
                  {formatUnits(record?.pricingValueBest)}
                </span>
              );
            }
            return (
              <span>
                {record?.pricingOperation === 'ADD' ? '' : '-'}
                {formatUnits(amount)}
                {record?.pricingValueType}
              </span>
            );
          },
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 'status',
          ellipsis: true,
          width: 120,
          render: (status) => capitalize(status),
        },
      ];
      break;

    case 'Questionnaire':
      columns = [
        {
          title: 'TITLE',
          dataIndex: 'title',
          key: 'title',
          width: 200,
          ellipsis: true,
          fixed: 'left',
          className: 'max-width-column',
        },
        {
          title: 'CLOSE RATE',
          dataIndex: 'closeRate',
          key: 'closeRate',
          align: 'right',
          ellipsis: true,
          width: 150,
          render: (closeRate) => <span>{`${formatUnits(closeRate)}%`}</span>,
        },
        {
          title: 'DATE',
          dataIndex: 'startDate',
          key: 'startDate',
          ellipsis: true,
          width: 300,
          render: (startDate, record) => {
            if (startDate && record?.endDate && record?.isScheduleEnabled) {
              return (
                <span>{`${formatDate(
                  startDate,
                  globalDateFormat,
                )} - ${formatDate(record?.endDate, globalDateFormat)}`}</span>
              );
            }
            return '-';
          },
        },
        {
          title: 'STATUS',
          dataIndex: 'status',
          key: 'status',
          render: (status) => capitalize(status),
        },
      ];
      break;

    default:
      break;
  }
  return columns;
};
