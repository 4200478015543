import { useLazyQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import BatchEntityTable from '../components/BatchEntityTable';
import { GET_BATCH } from '../graphql/Queries';

const BatchEntityList = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);
  const { id } = useParams();

  const [fetchBatchEntityData, { loading, data }] = useLazyQuery(GET_BATCH, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    fetchBatchEntityData({
      variables: {
        where: {
          id,
        },
      },
    });
  }, []);

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.ASSIGNMENT_JOBS} />
          {data && <span className="portal-header">{data?.batch?.name}</span>}
        </div>
      </Portal>
      {pageSize && !loading && (
        <BatchEntityTable lobId={data?.batch?.lineOfBusinessId} />
      )}
    </Card>
  );
};

export default BatchEntityList;
