import { useLazyQuery } from '@apollo/client';
import { Tabs } from 'antd';
import { capitalize, filter, find, map, some } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DYNAMIC_FIELDS_DATA } from '../../../common/constants';
import ModalComponent from '../../../components/ModalComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import { GET_DYNAMICS_FIELDS, GET_STATIC_FIELDS } from '../graphql/Queries';

const PAGE_LIMIT = 20;
function DynamicFilterModal({
  showFilterModal = false,
  handleShowFilterModal,
  industryId = null,
  lineOfBusiness = null,
}) {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [dynamicFieldData, setDynamicFieldData] = useState([]);
  const [staticField, setStaticFieldData] = useState([]);
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [hasMore, setHasMore] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [ref, setRef] = useState();
  const [tab, setTab] = useState(1);
  const [filteredTableStaticField, setFilteredTableStaticField] = useState([]);
  const [staticSearch, setStaticSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');

  const [dynamicFields, { loading = false }] = useLazyQuery(
    GET_DYNAMICS_FIELDS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        setDynamicFieldData(
          page !== 1
            ? [
                ...dynamicFieldData,
                ...res?.dynamicFieldsWithSelectedValuesAndListItems?.data,
              ]
            : res?.dynamicFieldsWithSelectedValuesAndListItems?.data,
        );

        setHasMore(
          res?.dynamicFieldsWithSelectedValuesAndListItems?.count >
            PAGE_LIMIT * page,
        );
      },
      onError() {},
    },
  );

  const [staticFields, { loading: staticLoading = false }] = useLazyQuery(
    GET_STATIC_FIELDS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        if (res && res.systemFieldMappingsWithConfigurations) {
          setStaticFieldData(
            res.systemFieldMappingsWithConfigurations?.map((item) => ({
              ...item,
              field_content_type: 'static',
            })),
          );
          setFilteredTableStaticField(
            res.systemFieldMappingsWithConfigurations?.map((item) => ({
              ...item,
              field_content_type: 'static',
            })),
          );
        }
      },
      onError() {},
    },
  );

  const columns = [
    {
      title: 'LABEL',
      dataIndex: 'label',
      key: 'label',
      ellipsis: true,
      width: 150,
      render: (record) => capitalize(record),
    },
    {
      title: 'FIELD TYPE',
      dataIndex: 'fieldType',
      key: 'fieldType',
      ellipsis: true,
      width: 150,
      render: (fieldType) => (
        <span>
          {find(DYNAMIC_FIELDS_DATA, ['key', fieldType])?.label || '-'}
        </span>
      ),
    },
    tab === 1 && {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
    },
    tab === 1 && {
      title: 'INDUSTRY',
      dataIndex: 'industry',
      key: 'industry',
      ellipsis: true,
      width: 150,
      render: (record) => record?.label,
    },
    tab === 1 && {
      title: 'LINE OF BUSINESS',
      dataIndex: 'lineOfBusinesses',
      key: 'lineOfBusinesses',
      ellipsis: true,
      width: 150,
      render: (record) => record?.[0]?.label,
    },
  ];

  useEffect(() => {
    setFilteredTableStaticField(
      staticField?.filter(
        (item) =>
          item?.label
            ?.toLowerCase()
            ?.includes(staticSearch?.trim()?.toLowerCase()) ||
          item?.fieldType
            ?.toLowerCase()
            ?.includes(staticSearch?.trim()?.toLowerCase()) ||
          item?.status?.toLowerCase()?.includes(staticSearch?.toLowerCase()),
      ),
    );
  }, [staticField, staticSearch]);

  useEffect(() => {
    if (showFilterModal) {
      if (tab === 1) {
        dynamicFields({
          variables: {
            filter: {
              skip: (page - 1) * PAGE_LIMIT,
              limit: PAGE_LIMIT,
              search: searchFilter?.trim(),
            },
            where: {
              industry: industryId,
              lineOfBusiness,
              selectedIds:
                JSON?.parse(
                  // eslint-disable-next-line no-undef
                  localStorage?.getItem('DYNAMIC_SELECTED_COLUMN'),
                )
                  ?.filter((item) => item?.field_content_type !== 'static')
                  ?.map((item) => item?.id) || [],
            },
          },
        });
      } else if (tab === 2) {
        staticFields({
          variables: {
            where: {
              entityName: 'ProductItem',
            },
          },
        });
      }
      // eslint-disable-next-line no-undef
      if (JSON?.parse(localStorage?.getItem('DYNAMIC_SELECTED_COLUMN'))) {
        setSelectedKeys(
          // eslint-disable-next-line no-undef
          JSON?.parse(localStorage?.getItem('DYNAMIC_SELECTED_COLUMN')) || null,
        );
      } else {
        setSelectedKeys([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilterModal, searchFilter, page, tab]);

  const rowSelection = {
    fixed: 'left',
    columnWidth: tab === 1 ? 90 : 50,
    selectedRowKeys: selectedKeys?.map((item) => item?.id),
    onSelect: (record) => {
      const isAlready = some(selectedKeys, (item) => item?.id === record?.id);
      if (isAlready) {
        const rowsCopy = filter(
          selectedKeys,
          (item) => item?.id !== record?.id,
        );
        setSelectedKeys(rowsCopy);
      } else {
        const rowsCopy = [...selectedKeys];
        rowsCopy?.push(record);
        setSelectedKeys(rowsCopy);
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        const rowsCopy = [...selectedKeys, ...map(changeRows, (item) => item)];
        setSelectedKeys(rowsCopy);
      } else {
        const remainingObject = selectedKeys?.filter(
          (elem) =>
            !changeRows?.find(({ id: changeId }) => elem?.id === changeId),
        );
        setSelectedKeys(remainingObject);
      }
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id,
      });
    },
  };

  const handleSearch = (t) => {
    setSearchFilter(t);
    setPage(1);
  };

  // commented for now. it is issue with ref.
  // useEffect(() => {
  // const tbody = ref?.querySelector('.ant-table-body');
  // const handleScroll = () => {
  //   const { scrollTop, scrollHeight, offsetHeight } = tbody || {};
  //   const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight;
  //   if (scrolledToBottom) {
  //     if (hasMore) {
  //       setPage((prev) => prev + 1);
  //     }
  //   }
  // };
  // if (tbody) {
  //   tbody?.addEventListener('scroll', handleScroll);
  // }
  // return () => {
  //   if (tbody) {
  //     tbody?.removeEventListener('scroll', handleScroll);
  //   }
  // };
  // }, [ref, hasMore]);

  const handleModalClose = () => {
    handleShowFilterModal(false, () => {
      setSelectedKeys([]);
      setPage(1);
      setSearchFilter('');
      setDynamicFieldData([]);
      setStaticFieldData([]);
    });
  };
  const handleOk = () => {
    // eslint-disable-next-line no-undef
    localStorage?.setItem(
      'DYNAMIC_SELECTED_COLUMN',
      JSON?.stringify(selectedKeys),
    );
    handleShowFilterModal(false, () => {
      setPage(1);
      setSearchFilter('');
      setDynamicFieldData([]);
      setStaticFieldData([]);
    });
  };

  const handleTabClick = (key) => {
    setTab(key);
    if (key === 2) {
      setSelectedKeys([]);
      setPage(1);
      setSearchFilter('');
      setDynamicFieldData([]);
    } else {
      setStaticFieldData([]);
    }
  };

  return (
    <ModalComponent
      open={showFilterModal}
      onCancel={handleModalClose}
      width={1000}
      onOk={handleOk}
      okButtonProps={{ className: 'common-button' }}
      cancelButtonProps={{ className: 'common-button discard-button' }}
      cancelText={selectedKeys?.length > 0 ? 'Discard' : 'Cancel'}
      okText="Apply"
    >
      <div>
        <div>Filters</div>
        <Tabs
          defaultActiveKey={1}
          onTabClick={(key) => {
            handleTabClick(key);
          }}
          items={[
            {
              key: 1,
              label: `Dynamic`,
              children: (
                <>
                  <SearchComponent
                    className="list-search-box filter-search mb-10"
                    id="search-container-id-roles"
                    placeholder="Search..."
                    name="Search Filter"
                    getData={(value) => handleSearch(value)}
                  />
                  <TableComponent
                    // ref={setRef} // commented for now will resolve this issue
                    isSearch={false}
                    rowSelection={rowSelection}
                    fullHeight={false}
                    setHeight="800px"
                    scroll={{ y: 240 }}
                    loadingData={loading}
                    columns={[...columns?.filter((item) => item !== true)]}
                    data={dynamicFieldData || []}
                    rowKey="id"
                  />
                </>
              ),
            },
            {
              key: 2,
              label: `Static`,
              children: (
                <>
                  <SearchComponent
                    className="list-search-box filter-search mb-10"
                    id="search-container-id-roles"
                    placeholder="Search..."
                    name="Search Filter"
                    getData={(value) => {
                      setStaticSearch(value);
                    }}
                  />
                  {filteredTableStaticField && (
                    <TableComponent
                      isSearch={false}
                      rowSelection={rowSelection}
                      fullHeight={false}
                      setHeight="800px"
                      scroll={{ y: 240 }}
                      loadingData={staticLoading}
                      columns={columns}
                      data={filteredTableStaticField || []}
                      rowKey="id"
                    />
                  )}
                </>
              ),
            },
          ]}
        />
      </div>
    </ModalComponent>
  );
}

export default DynamicFilterModal;
