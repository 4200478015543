import { Checkbox, DatePicker, Space } from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { TABLET_BREAKPOINT } from '../../../common/constants';
import { formatPrice } from '../../../common/utils';
import SelectComponent from '../../../components/SelectComponent';

const { Option } = SelectComponent;

const { RangePicker } = DatePicker;

const FunnelSection = ({
  handleChangeTimePeriod,
  timePeriodData = [],
  timePeriodValue = 'LAST7DAYS',
  selectedDate = [],
  handleChangeDate,
  funnelInfoValue = [],
  funnelInfoData = [],
  setFunnelInfoDataFunc,
}) => {
  const {
    state: { windowWidth },
  } = useContext(AppContext);
  return (
    <div className="funnel-section">
      <span className="header-title">Funnel</span>
      <div className="scroll-section">
        <div className="time-period-section">
          <span className="time-period-label">Time Period</span>
          <SelectComponent
            allowClear={false}
            className="fill-width"
            aria-label="Select Time Period"
            placeholder="Select Time Period"
            value={timePeriodValue}
            onChange={handleChangeTimePeriod}
          >
            {map(timePeriodData, (item) => (
              <Option key={item?.key} value={item?.key}>
                {item?.label}
              </Option>
            ))}
          </SelectComponent>
          {timePeriodValue === 'CUSTOM' && (
            <div className="date-range-picker">
              <RangePicker
                className="common-range-picker fill-width"
                popupClassName="common-range-picker"
                value={selectedDate}
                onChange={handleChangeDate}
                allowClear={false}
                disabledDate={(current) => current > moment()?.endOf('day')}
              />
            </div>
          )}
        </div>
        <div className="checkbox-section">
          <Checkbox.Group
            onChange={setFunnelInfoDataFunc}
            value={funnelInfoValue}
          >
            <Space
              size={windowWidth < TABLET_BREAKPOINT ? 8 : 0}
              wrap
              direction={
                windowWidth < TABLET_BREAKPOINT ? 'horizontal' : 'vertical'
              }
            >
              {map(funnelInfoData, (item) => (
                  <div key={item?.key} className="single-section">
                    <Checkbox
                      className="common-checkbox d-flex align-center"
                      value={item?.key}
                      key={item?.key}
                    >
                      <div className="d-flex flex-vertical justify-start flex-wrap">
                        <span className="title">{item?.title}</span>
                        <div className="d-flex align-center">
                          <span className="count">{item?.count}</span>
                          <span className="price">
                            {item?.price > 0 && formatPrice(item?.price)}
                          </span>
                        </div>
                        <span className="percentage">{`${
                          item?.percent ? `${item?.percent}%` : ''
                        } prospects`}</span>
                      </div>
                    </Checkbox>
                  </div>
                ))}
            </Space>
          </Checkbox.Group>
        </div>
      </div>
    </div>
  );
};

export default FunnelSection;
