import { gql } from '@apollo/client';

export const GET_QUOTES = gql`
  query quotes($filter: QuoteFilter!, $where: LeadWhere) {
    quotes(filter: $filter, where: $where) {
      count
      data {
        id
        createdAt
        customerName
        status
        amount
        exportStatus
        industry {
          id
          label
        }
        lineOfBusiness {
          id
          label
        }
        region {
          id
          name
        }
        questionnaire {
          id
          title
        }
        brand {
          id
          name
        }
        campaign {
          name
          code
        }
        email
        leadNumber
        contactNumber
        sponsorName
        roqPathName
        leadNumber
        opportunityName
      }
    }
  }
`;

export const GET_QUOTE = gql`
  query quote($where: UniqueWhereInput!) {
    quote(where: $where) {
      id
      title
      sku
      manufacturer
      quantity
      quality
      date
      expiryDate
      amount
      suggestedQuotes
      addOnProducts {
        id
        title
        quantity
        amount
      }
      systemFields
      customFields
      quoteItems {
        id
        title
        quantity
        amount
      }
      brand
      createdAt
      customerName
      email
      contactNumber
      sponsorName
      roqPathName
      leadNumber
      status
    }
  }
`;
export const INDUSTRY_FILTER = gql`
  query industryFilters($filter: IndustryFilter!) {
    industryFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const LINE_OF_BUSINESS_FILTER = gql`
  query lineOfBusinessFilters($filter: LineOfBusinessFilter!) {
    lineOfBusinessFilters(filter: $filter) {
      count
      data {
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const SUB_AREA_FILTER = gql`
  query subAreaFilters($filter: SubAreaFilter!) {
    subAreaFilters(filter: $filter) {
      count
      data {
        label
        saCode
        key
        description
        estimation
        isActive
        waiverText
        priceGuaranteeText
        offerText
        lineOfBusinessId
        referenceId
      }
    }
  }
`;

export const BRAND_FILTER = gql`
  query brandFilters($filter: BrandFilter!) {
    brandFilters(filter: $filter) {
      count
      data {
        name
        refData
        primaryColor
        logo {
          url
          key
          name
          extension
          contentType
        }
        secondaryColors
        tertiaryColors
        colorPallete
        description
        waiverText
        priceGuarantee
        offerText
        productDetails
        isActive
        tenantId
        createdAt
      }
    }
  }
`;

export const GET_SPONSOR_FILTER = gql`
  query sponsorFilters($filter: SponsorFilter!) {
    sponsorFilters(filter: $filter) {
      count
      data {
        firstName
        lastName
        businessName
        userId
      }
    }
  }
`;
