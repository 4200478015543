import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, Space } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import InputComponent from '../../components/InputComponent';
import { FORGOT_PASSWORD } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const ResetPassword = () => {
  const navigate = useNavigate();
  const [forgotPasswordMutate, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      navigate(ROUTES?.LOGIN);
    },
    onError() {},
  });
  const onFinish = async (values) => {
    await forgotPasswordMutate({
      variables: { data: { ...values, portal: process.env.REACT_APP_PORTAL } },
    });
  };
  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-mb-4">
          <h2>Forgot Your Password ?</h2>
          <p>
            Don't worry. Recovering the password is easy.Just tell us the email.
          </p>
        </div>
        <Form
          layout="vertical"
          onFinish={onFinish}
          className="gx-login-form gx-form-row0"
        >
          <Form.Item name="email" rules={[required, email]}>
            <InputComponent prefix={<UserOutlined />} placeholder="Email" />
          </Form.Item>
          <div className="d-flex">
            <Space>
              <Form.Item>
                <Button
                  type="primary"
                  loading={loading}
                  className="common-button login-button font-size-14"
                  htmlType="submit"
                >
                  Reset Password
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  className="common-button login-button discard-button font-size-14"
                  onClick={() => {
                    navigate(ROUTES?.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Space>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
