import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PERMISSION_ACTIVITY_LOGS, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import Error404 from '../../Error404';
import './activityLogsModule.less';
import ActivityLogList from './pages/ActivityLogList';
import ActivityLogView from './pages/ActivityLogView';

const ActivityLogsWrapper = () => (
  <div className="activity-log-wrapper">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_ACTIVITY_LOGS}
            showNoAccess
          >
            <ActivityLogList />
          </AccessControl>
        }
      />

      <Route
        path="/view/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_ACTIVITY_LOGS_VIEW']}
            showNoAccess
          >
            <ActivityLogView />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default ActivityLogsWrapper;
