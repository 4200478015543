import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { MODULES } from '../../../common/constants';
import Portal from '../../../components/Portal';
import ProductItemTable from '../components/ProductItemTable';

const ProductItemList = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <span className="portal-header">{MODULES?.PRODUCTS}</span>
      </Portal>
      {pageSize && <ProductItemTable />}
    </Card>
  );
};
export default ProductItemList;
