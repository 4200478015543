import { Col, Row } from 'antd';
import { capitalize, startCase } from 'lodash';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { formatDate } from '../../../common/utils';

const ActivityLogDetails = ({ logData }) => {
  const {
    state: { globalDateFormat },
  } = useContext(AppContext);
  return (
    <div className="content-section api-log-details">
      <Row gutter={20}>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Entity :</span>
            <span className="field-value">
              {capitalize(startCase(logData?.refType)) || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">User :</span>
            <span className="field-value">
              {logData?.user?.firstName?.length > 0
                ? `${logData?.user?.firstName} ${logData?.user?.lastName}`
                : '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Method :</span>
            <span className="field-value">{logData?.httpMethod || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Response Status :</span>
            <span className="field-value">
              {logData?.responseStatus || '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Activity Type :</span>
            <span className="field-value">{logData?.activityType || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Device :</span>
            <span className="field-value">{logData?.deviceType || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">OS :</span>
            <span className="field-value">
              {logData?.userAgent?.os
                ? `${logData?.userAgent?.os?.name} (${logData?.userAgent?.os?.version})`
                : '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Browser :</span>
            <span className="field-value">
              {logData?.userAgent?.browser
                ? `${logData?.userAgent?.browser?.name} (${logData?.userAgent?.browser?.version})`
                : '-'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">IP Address :</span>
            <span className="field-value">{logData?.ip || '-'}</span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Date & Time :</span>
            <span className="field-value">
              {formatDate(logData?.createdAt, globalDateFormat) || '-'}
            </span>
          </div>
        </Col>
        {logData?.type === 'ERROR' && (
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Error :</span>
              <span className="field-value">
                {logData?.errorMessage || '-'}
              </span>
            </div>
          </Col>
        )}
      </Row>
      {logData?.differValues && (
        <Row gutter={16}>
          <Col span={24}>
            <div className="field-detail">
              <span className="field-label">Difference :</span>
              <pre className="params-info">
                {JSON?.stringify(logData?.differValues, undefined, 2) || '-'}
              </pre>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ActivityLogDetails;
