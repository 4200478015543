import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import Portal from '../../../components/Portal';
import PermissionTable from '../components/PermissionTable';

const PermissionList = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);
  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <span className="portal-header">Permissions</span>
      </Portal>
      {pageSize && <PermissionTable />}
    </Card>
  );
};
export default PermissionList;
