/* eslint-disable react/no-danger */
import { Checkbox, Col, Divider, Row } from 'antd';
import React from 'react';
import {
  formatDate,
  formatPhoneNumber,
  formatPrice,
} from '../../../common/utils';

const PropertyDetails = ({ data = {} }) => {
  const {
    name = '',
    propertyTypeData = null,
    builtYear = '',
    isActive = true,
    location = null,
    propertyValue = '',
    floor = '',
    internalSqFt = '',
    lotSqFt = '',
    lotsFrontWidth = '',
    lotsRightWidth = '',
    lotsLeftWidth = '',
    lotsBackWidth = '',
    pinNumber = '',
    heatSourceData = null,
    waterHookUpData = null,
    noBaths = '',
    noHalfBaths = '',
    propertyFinishedBasementData = null,
    propertyFinishedCeilingData = null,
    propertyLastConfirmed = null,
    lockBoxCode = '',
    propertyManagementCompany = '',
    propertyManagerPhone = '',
    thirdPartyProvider = '',
    historyDistrict = false,
    elevator = false,
    centralAir = false,
    centralHeat = false,
    cornerLot = false,
    parking = false,
    taxParcel = false,
  } = data;

  return (
    <div>
      <Row>
        <span className="form-divider-text">MANDATORY</span>
        <Divider className="form-divider" />
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Name *</span>
            <span className="field-value">{name}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Type *</span>
            <span className="field-value">{propertyTypeData?.label}</span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Built Year *</span>
            <span className="field-value">{builtYear}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Status *</span>
            <span className="field-value">
              {isActive ? 'Active' : 'InActive'}
            </span>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <div className="field-detail">
            <span className="field-label">Address *</span>
            <span className="field-value">{`${location?.addressLine1} ${location?.city} ${location?.state} ${location?.country} ${location?.zipCode}`}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <span className="form-divider-text">OPTIONAL</span>
        <Divider className="form-divider" />
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Property Value</span>
            <span className="field-value">
              {formatPrice(propertyValue) || '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Floor</span>
            <span className="field-value">{floor || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Internal Sq. Ft</span>
            <span className="field-value">{internalSqFt || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Lot sq. ft</span>
            <span className="field-value">{lotSqFt || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Lots Front Width</span>
            <span className="field-value">{lotsFrontWidth || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Lots Right Width</span>
            <span className="field-value">{lotsRightWidth || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Lots Left Width</span>
            <span className="field-value">{lotsLeftWidth || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Lots Back Width</span>
            <span className="field-value">{lotsBackWidth || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Pin Number</span>
            <span className="field-value">{pinNumber || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Heat Source</span>
            <span className="field-value">{heatSourceData?.label || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Water Hook Up</span>
            <span className="field-value">{waterHookUpData?.label || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">No. of Baths</span>
            <span className="field-value">{noBaths || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">No. of 1/2 Baths</span>
            <span className="field-value">{noHalfBaths || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Finished Basement</span>
            <span className="field-value">
              {propertyFinishedBasementData?.label || '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Finished Basement Ceiling</span>
            <span className="field-value">
              {propertyFinishedCeilingData?.label || '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">
              Property Information last confirmed on
            </span>
            <span className="field-value">
              {propertyLastConfirmed ? formatDate(propertyLastConfirmed) : '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Lock Box Code</span>
            <span className="field-value">{lockBoxCode || '-'}</span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Project Management Company</span>
            <span className="field-value">
              {propertyManagementCompany || '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Property Manager Phone</span>
            <span className="field-value">
              {formatPhoneNumber(propertyManagerPhone) || '-'}
            </span>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <div className="field-detail">
            <span className="field-label">Third Party Provider</span>
            <span className="field-value">{thirdPartyProvider || '-'}</span>
          </div>
        </Col>
      </Row>
      <Row gutter={[0, 16]}>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            checked={historyDistrict}
            disabled
            className="common-checkbox property-checkbox"
          >
            History District
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            checked={elevator}
            disabled
            className="common-checkbox property-checkbox"
          >
            Elevator
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            checked={centralAir}
            disabled
            className="common-checkbox property-checkbox"
          >
            Central Air
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            checked={centralHeat}
            disabled
            className="common-checkbox property-checkbox"
          >
            Central Heat
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            checked={cornerLot}
            disabled
            className="common-checkbox property-checkbox"
          >
            Corner Lot
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            checked={parking}
            disabled
            className="common-checkbox property-checkbox"
          >
            Parking
          </Checkbox>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
          <Checkbox
            checked={taxParcel}
            disabled
            className="common-checkbox property-checkbox"
          >
            Tax Parcel
          </Checkbox>
        </Col>
      </Row>
    </div>
  );
};

export default PropertyDetails;
