import { gql } from '@apollo/client';

export const GET_TENANT = gql`
  query tenant($where: TenantWhereInput!) {
    tenant(where: $where) {
      name
      email
      helpPage {
        key
        checked
      }
      liveChat {
        key
        checked
      }
      subDomain
      phoneNo
      location {
        addressLine1
        city
        state
        country
        zipCode
      }
      timeFormat
      tagLine
      socialMediaLinks {
        title
        url
        checked
      }
    }
  }
`;

export const GET_SPONSOR = gql`
  query sponsor($where: SponsorWhereInput!) {
    sponsor(where: $where) {
      subDomain
      email
      businessName
      helpPage {
        key
        checked
      }
      liveChat {
        key
        checked
      }
      phoneNo
      location {
        addressLine1
        city
        state
        country
        zipCode
      }
      timeFormat
      tagLine
      socialMediaLinks {
        title
        url
        checked
      }
    }
  }
`;

export const GET_DATE_FORMATS = gql`
  query timeFormats($filter: TenantFilter!) {
    timeFormats(filter: $filter) {
      count
      data
    }
  }
`;
