import { useMutation } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { uploadFile } from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import BrandForm from '../components/BrandForm';
import { CREATE_BRAND } from '../graphql/Mutations';

function BrandCreate() {
  const [submitLoading, setSubmitLoading] = useState(false);

  const {
    state: { currentUser },
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [createBrand] = useMutation(CREATE_BRAND, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  useEffect(() => {
    const unListen = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, { state: { ...location?.state } });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    const uuid = uuidv4();
    const logo = await uploadFile({
      logoObject: formValues?.logo,
      currentUser,
      uuid,
      folder: 'brand',
    });

    const newFormValues = {
      ...formValues,
      logo,
      uuid,
    };

    const variables = newFormValues;

    try {
      const response = await createBrand({
        variables: { data: { ...variables } },
      });
      if (response?.data?.createBrand) {
        navigate(ROUTES?.RETAILERS, { state: { ...location?.state } });
      }
    } catch (error) {
      setSubmitLoading(false);
      return error;
    }
  };

  const initialValues = {
    description: '',
    waiverText: '',
    priceGuarantee: '',
    offerText: '',
    productDetails: '',
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.RETAILERS} />
          <span className="portal-header">Add Retailer</span>
        </div>
      </Portal>
      <BrandForm
        brandData={initialValues}
        handleSubmit={handleSubmit}
        isSubmit={submitLoading}
      />
    </Card>
  );
}

export default BrandCreate;
