import { useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { MODULES, ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import useRouter from '../../../hooks/useRouter';
import QuoteViewDetail from '../components/QuoteViewDetail';
import { GET_QUOTE } from '../graphql/Queries';

const QuoteView = () => {
  const { params: { id } = {} } = useRouter();
  const [loading, setLoading] = useState(true);

  const { data: { quote } = {} } = useQuery(GET_QUOTE, {
    variables: { where: { id } },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    },
  });

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.QUOTES} />
          <span className="portal-header">{MODULES?.QUOTES}</span>
        </div>
      </Portal>

      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <QuoteViewDetail quote={quote} />
      )}
    </Card>
  );
};

export default QuoteView;
