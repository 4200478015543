import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import JobDetail from './pages/JobDetail';

const JobWrapper = () => (
  <Routes>
    <Route path={ROUTES?.MAIN} element={<JobDetail />} />
    <Route path="/create" element={<JobDetail />} />
    <Route path="/:id/edit" element={<JobDetail />} />
    <Route path="/:id" element={<JobDetail />} />
  </Routes>
);

export default JobWrapper;
