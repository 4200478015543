import { DownloadOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Tag } from 'antd';
import { capitalize } from 'lodash';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { messageContext } from '../../../app/components/AppContextHolder';
import { AppContext } from '../../../AppContext';
import api from '../../../common/api';
import { IMPORT_STATUS, READ, ROUTES } from '../../../common/constants';
import { formatDate, handleCsvDownload } from '../../../common/utils';
import AvatarComponent from '../../../components/AvatarComponent';
import { GET_SIGN_URL } from '../../../components/graphql/Mutation';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import { IMPORT_FILES } from '../graphql/Queries';

const ImportTable = () => {
  const navigate = useNavigate();
  const {
    state: { pageSize, globalDateFormat },
  } = useContext(AppContext);

  const [paginationProp, setPaginationProp] = useState({
    total: 0,
    current: 1,
  });
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);

  const [uploadFile] = useMutation(GET_SIGN_URL, {
    onError: () => {
      setLoading(false);
    },
  });

  const handleVerify = (record) => {
    navigate(`${ROUTES?.IMPORTS}/${record?.id}`);
  };

  const handleDownloadFile = async (fileObj) => {
    setLoading(true);
    const res = await uploadFile({
      variables: {
        action: READ,
        extension: `.csv`,
        contentType: 'text/csv',
        key: fileObj.key,
      },
    });
    if (res?.data?.generateSignedUrl) {
      const { signedRequest } = res?.data?.generateSignedUrl;
      try {
        const response = await api(signedRequest, {
          method: 'GET',
        });
        if (response) {
          setLoading(false);
          handleCsvDownload(response.data, fileObj.name);
        }
      } catch {
        setLoading(false);
        messageContext?.error('Error while downloading file');
      }
    }
  };

  const { data, refetch } = useQuery(IMPORT_FILES, {
    variables: { limit: pageSize, skip: 0, query: '', sortBy: 'DESC' },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const paginationProps = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.importFiles?.count,
      };
      setPaginationProp(paginationProps);
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const handleTableChange = async (pagination) => {
    setLoading(true);
    const { current = '', pageSize: paginationPageSize } = pagination;
    setPaginationProp({ ...paginationProp, ...pagination });
    try {
      await refetch({
        limit: paginationPageSize,
        skip: (current - 1) * pageSize,
        query: searchValue,
        sortBy: 'DESC',
      });
      setLoading(false);
    } catch {
      setLoading(false);
      messageContext?.error('Error while fetching data');
    }
  };

  const onSearchChange = async (value) => {
    setLoading(true);
    setSearchValue(value);
    try {
      const response = await refetch({
        limit: pageSize,
        skip: 0,
        query: value,
        sortBy: 'DESC',
      });
      setPaginationProp((prevState) => {
        const newState = {
          ...prevState,
          total: response?.data?.importFiles?.count,
        };
        return newState;
      });
      setLoading(false);
    } catch {
      setLoading(false);
      messageContext?.error('Error while fetching data');
    }
  };

  const columns = [
    {
      title: 'Requested From',
      width: 100,
      ellipsis: true,
      key: 'importType',
      dataIndex: 'importType',
      render: (importType) => <span>{capitalize(importType)}</span>,
    },
    {
      title: 'Imported By',
      width: 100,
      ellipsis: true,
      key: 'user',
      dataIndex: 'user',
      render: (user) => {
        const { firstName = '', lastName = '' } = user;
        if (user) {
          return <AvatarComponent firstName={firstName} lastName={lastName} />;
        }
      },
    },
    {
      title: 'Imported On',
      width: 100,
      ellipsis: true,
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (createdAt) => formatDate(createdAt, globalDateFormat),
    },
    {
      title: 'Completed On',
      width: 100,
      ellipsis: true,
      key: 'updatedAt',
      dataIndex: 'updatedAt',
      render: (updatedAt) => formatDate(updatedAt, globalDateFormat),
    },
    {
      title: 'Imported File',
      width: 100,
      ellipsis: true,
      render: (record) => {
        const {
          fileObj: { name = '' },
        } = record;
        if (record?.fileObj) {
          return (
            <span
              className="link-btn"
              onClick={() => handleDownloadFile(record?.fileObj)}
            >
              <DownloadOutlined />
              {name}
            </span>
          );
        }
      },
    },
    {
      title: 'Status',
      width: 100,
      dataIndex: 'importFileStatus',
      ellipsis: true,
      key: 'importFileStatus',
      render: (importFileStatus) => {
        if (importFileStatus === 'INPROGRESS') {
          return <Tag color="warning">{IMPORT_STATUS.INPROGRESS}</Tag>;
        }
        if (importFileStatus === 'FAILED') {
          return <Tag color="error">{IMPORT_STATUS.FAILED}</Tag>;
        }
        if (importFileStatus === 'VERIFIED') {
          return <Tag color="success">{IMPORT_STATUS.VERIFIED}</Tag>;
        }
        return null;
      },
    },
    {
      title: 'Action',
      width: 100,
      dataIndex: 'actions',
      key: 'actions',
      render: (actions, record) => {
        if (record?.importFileStatus !== 'VERIFIED' && !record?.errorFileObj) {
          return (
            <Button
              type="primary"
              className="table-button"
              onClick={() => handleVerify(record)}
            >
              Verify
            </Button>
          );
        }
        if (record?.errorFileObj) {
          const {
            errorFileObj: { name = '' },
          } = record;
          return (
            <span
              type="link"
              className="error-file"
              onClick={() => handleDownloadFile(record?.errorFileObj)}
            >
              <DownloadOutlined />
              {name}
            </span>
          );
        }
      },
    },
  ];

  return (
    <>
      <div className="mb-10 width-percent-20">
        <SearchComponent
          disabled={!data?.importFiles?.importFiles || loading}
          getData={onSearchChange}
          id="search-container-id"
        />
      </div>
      <TableComponent
        columns={[...columns?.filter((item) => item !== false)]}
        data={data?.importFiles?.importFiles}
        loadingData={loading}
        rowKey={(record) => record?.id}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
      />
    </>
  );
};

export default ImportTable;
