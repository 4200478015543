import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Col, Collapse, Divider, Form, Radio, Row, Switch } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { uploadImageNormalize } from '../../../../../../common/utils';
import DraggerUploadComponent from '../../../../../../components/DraggerUploadComponent';
import InputComponent from '../../../../../../components/InputComponent';
import SelectComponent from '../../../../../../components/SelectComponent';
import { MY_PROJECT_ROQ_PATH_LIST } from '../../../../graphql/Queries';
import PanelHeader from '../../../previewComponents/globalComponents/PanelHeader';

const { Option } = SelectComponent;

const ServiceTypeNavigate = ({
  restSubField,
  otherConfig,
  keyProp,
  subKey,
  subFieldName,
}) => {
  const { loading, data } = useQuery(MY_PROJECT_ROQ_PATH_LIST, {
    variables: {
      where: {
        subAreaId: otherConfig?.[keyProp]?.subAreas?.[subKey]?.blockId,
      },
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  return (
    <Col className="ml-8" span={10}>
      <Form.Item
        {...restSubField}
        label="Navigate to"
        hidden={!otherConfig?.[keyProp]?.subAreas?.[subKey]?.blockCheck}
        name={[subFieldName, 'navigateTo']}
      >
        <SelectComponent
          loading={loading}
          allowClear
          placeholder="Select Navigate To"
        >
          {data?.myProjectROQPathList?.data?.map((option) => (
            <Option key={option?.id} value={option?.id}>
              {option?.title}
            </Option>
          ))}
        </SelectComponent>
      </Form.Item>
    </Col>
  );
};

const ProjectWidgetForm = ({ form, setSelectedLob, selectedLob = null }) => {
  const descriptionCheck = Form?.useWatch(
    ['widgetConfiguration', 'description'],
    form,
  );
  const otherConfig = Form?.useWatch(
    ['widgetConfiguration', 'otherConfig'],
    form,
  );

  const showDescription = Form?.useWatch([
    'widgetConfiguration',
    'optionDescriptions',
  ]);

  const createItems = ({ key = null, name = null }) => ({
    forceRender: true,
    label: (
      <PanelHeader
        title="Service Types"
        formSelectedKey={otherConfig?.[key]}
        previewSelectedKey={selectedLob}
        setPreviewSelectedKey={setSelectedLob}
      />
    ),
    key: otherConfig?.[key]?.blockKey,
    children: (
      <>
        <Form.List name={[name, 'subAreas']}>
          {(subFields) => (
            <>
              {subFields?.map(
                ({ key: subKey, name: subFieldName, ...restSubField }) => (
                  <div key={subKey}>
                    <div className="d-flex justify-between align-center">
                      <span className="switch-logo font-500">
                        {otherConfig?.[key]?.subAreas?.[subKey]?.blockTitle}
                      </span>
                      <Form.Item
                        name={[subFieldName, 'blockCheck']}
                        className="mb-0"
                        valuePropName="checked"
                      >
                        <Switch
                          className="common-switch"
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      </Form.Item>
                    </div>
                    <Row>
                      <Col span={10}>
                        <Form.Item
                          {...restSubField}
                          hidden={
                            !otherConfig?.[key]?.subAreas?.[subKey]?.blockCheck
                          }
                          rules={[
                            {
                              required:
                                otherConfig?.[key]?.subAreas?.[subKey]
                                  ?.blockCheck,
                              message: 'Please Enter Label',
                            },
                          ]}
                          label="Label"
                          name={[subFieldName, 'label']}
                        >
                          <InputComponent placeholder="Enter Label" />
                        </Form.Item>
                      </Col>
                      <ServiceTypeNavigate
                        restSubField={restSubField}
                        otherConfig={otherConfig}
                        keyProp={key}
                        subKey={subKey}
                        subFieldName={subFieldName}
                      />
                    </Row>
                    <Divider className="mx-16" />
                  </div>
                ),
              )}
            </>
          )}
        </Form.List>
      </>
    ),
  });

  return (
    <>
      <div className="section-header mt-16">
        <span className="section-title">My Projects Widget</span>
      </div>

      {/* displayStyle */}
      <Form.Item
        className="mb-0"
        name={['widgetConfiguration', 'displayStyle']}
      >
        <Radio.Group className="common-radio fill-width d-flex">
          <Radio value="TILES">Tiles</Radio>
          <Radio value="LIST">List</Radio>
        </Radio.Group>
      </Form.Item>

      {/* Images */}
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Images</span>
        <Form.Item
          name={['widgetConfiguration', 'images']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>

      {/* Imag Preview */}
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Image Preview</span>
        <Form.Item
          name={['widgetConfiguration', 'imagePreview']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>

      {/* Description */}
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Description</span>
        <Form.Item
          name={['widgetConfiguration', 'description']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        hidden={!descriptionCheck}
        rules={[
          {
            required: descriptionCheck,
            message: 'Please Enter Description',
          },
        ]}
        name={['widgetConfiguration', 'descriptionText']}
      >
        <InputComponent placeholder="Enter Description" />
      </Form.Item>
      <Divider className="mx-24" />

      {/* Option Descriptions */}
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Option Descriptions</span>
        <Form.Item
          name={['widgetConfiguration', 'optionDescriptions']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>

      {/* LOB Section */}
      <Form.List name={['widgetConfiguration', 'otherConfig']}>
        {(fields) => (
          <>
            {fields?.map(({ key, name, ...restField }) => {
              const defaultActiveKey = map(
                otherConfig,
                (item) => item?.blockKey,
              );
              return (
                <div key={key}>
                  <div className="d-flex justify-between align-center">
                    <span className="switch-logo font-500">
                      {otherConfig?.[key]?.blockTitle}
                    </span>
                    <Form.Item
                      {...restField}
                      name={[name, 'blockCheck']}
                      className="mb-0"
                      valuePropName="checked"
                    >
                      <Switch
                        className="common-switch"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </Form.Item>
                  </div>
                  <Row
                    className={
                      !otherConfig?.[key]?.blockCheck ? 'form-hide' : ''
                    }
                  >
                    <Col span={8}>
                      <Form.Item
                        {...restField}
                        name={[name, 'logo']}
                        className="mb-0"
                        normalize={uploadImageNormalize}
                        valuePropName="fileList"
                      >
                        <DraggerUploadComponent className="common-upload">
                          {' '}
                          <p className="icon-header">
                            <DownloadOutlined />
                          </p>
                          <span className="upload-title">
                            Drag image or browse your files
                          </span>{' '}
                          <br />
                          <span className="upload-description">
                            JPG, PNG, SVG, JPEG
                          </span>
                        </DraggerUploadComponent>
                      </Form.Item>
                    </Col>
                    <Col className="ml-8" span={14}>
                      <Form.Item
                        {...restField}
                        label="Label"
                        name={[name, 'label']}
                        className="mb-0"
                      >
                        <InputComponent placeholder="Enter Label" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="Description"
                        rules={[
                          {
                            required:
                              otherConfig?.[key]?.blockCheck && showDescription,
                            message: 'Please Enter Description',
                          },
                        ]}
                        name={[name, 'blockDescription']}
                        className="mb-0"
                      >
                        <InputComponent placeholder="Enter Description" />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      {defaultActiveKey?.length > 0 && (
                        <Collapse
                          className="common-collapse questionnaire-collapse"
                          expandIconPosition="end"
                          expandIcon={({ isActive }) => (
                            <UpOutlined rotate={isActive ? 0 : 180} />
                          )}
                          defaultActiveKey={defaultActiveKey}
                          ghost
                          items={[createItems({ key, name })]}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              );
            })}
          </>
        )}
      </Form.List>
    </>
  );
};

export default ProjectWidgetForm;
