import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  PERMISSION_DASHBOARD,
  PERMISSION_PRODUCT_PRICING,
  PERMISSION_PRODUCT_RANKING,
  PERMISSION_QUESTIONNAIRE,
  ROUTES,
  WHOM_TO_SHOW_SIDE_PANEL,
} from '../../../common/constants';
import { getElementFromDocumentId } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DashboardPanel from './components/DashboardPanel';
import JobsPanel from './components/JobsPanel';
import ProductPricingPanel from './components/ProductPricingPanel';
import ProductRankingPanel from './components/ProductRankingPanel';
import './sidepanel.less';

const SidePanel = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const attachSidePanel = (pathname, pathsToWhichHideSidePanel) => {
    if (!pathname) {
      return false;
    }

    for (
      let pathIndex = 0;
      pathIndex < pathsToWhichHideSidePanel?.length;
      // eslint-disable-next-line no-plusplus
      pathIndex++
    ) {
      const path = pathsToWhichHideSidePanel[pathIndex];

      if (pathname?.indexOf(path) !== -1) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location?.search);
    const spParam = urlSearchParams?.get('sp');

    if (spParam === 'false') {
      getElementFromDocumentId('sidePanelToggleStatus')?.classList?.remove(
        'side-panel-open',
      );
    } else {
      getElementFromDocumentId('sidePanelToggleStatus')?.classList?.add(
        'side-panel-open',
      );
    }
  }, [location?.pathname, location?.search]);

  const hideSidePanel = (e) => {
    e?.preventDefault();
    const urlSearchObj = new URLSearchParams(location?.search);
    const currentSp = urlSearchObj?.get('sp');

    if (currentSp !== 'false') {
      urlSearchObj?.set('sp', 'false');
      getElementFromDocumentId('sidePanelToggleStatus')?.classList?.remove(
        'side-panel-open',
      );
    } else {
      urlSearchObj?.delete('sp');
      getElementFromDocumentId('sidePanelToggleStatus')?.classList?.add(
        'side-panel-open',
      );
    }

    navigate(`${location?.pathname}?${urlSearchObj?.toString()}`, {
      replace: true,
      state: {
        ...location?.state,
      },
    });
  };

  if (!attachSidePanel(location?.pathname, WHOM_TO_SHOW_SIDE_PANEL)) {
    return null;
  }

  return (
    <>
      <div className="side-panel-container">
        <Routes>
          <Route
            path={ROUTES?.DASHBOARD}
            element={
              <AccessControl allowedPermissions={PERMISSION_DASHBOARD}>
                <>
                  <DashboardPanel />
                  <div
                    className="side-panel-backdrop"
                    onClick={hideSidePanel}
                  />
                </>
              </AccessControl>
            }
          />
          <Route
            path={`${ROUTES?.QUESTIONNAIRES_PRIMARY}/:id${ROUTES?.PAGE}/:pageId?`}
            element={
              <AccessControl allowedPermissions={PERMISSION_QUESTIONNAIRE}>
                <>
                  <div id="page-sequence-content" />
                  <div
                    className="side-panel-backdrop"
                    onClick={hideSidePanel}
                  />
                </>
              </AccessControl>
            }
          />
          <Route
            path={`${ROUTES?.QUESTIONNAIRES_SECONDARY}/:id${ROUTES?.PAGE}/:pageId?`}
            element={
              <AccessControl allowedPermissions={PERMISSION_QUESTIONNAIRE}>
                <>
                  <div id="page-sequence-content" />
                  <div
                    className="side-panel-backdrop"
                    onClick={hideSidePanel}
                  />
                </>
              </AccessControl>
            }
          />
          <Route
            path={ROUTES?.PRODUCT_PRICING}
            element={
              <AccessControl allowedPermissions={PERMISSION_PRODUCT_PRICING}>
                <>
                  <ProductPricingPanel />
                  <div
                    className="side-panel-backdrop"
                    onClick={hideSidePanel}
                  />
                </>
              </AccessControl>
            }
          />
          <Route
            path={ROUTES?.QUESTIONNAIRE_RESULT_VALIDATION}
            element={
              <AccessControl allowedPermissions={PERMISSION_PRODUCT_RANKING}>
                <>
                  <ProductRankingPanel />
                  <div
                    className="side-panel-backdrop"
                    onClick={hideSidePanel}
                  />
                </>
              </AccessControl>
            }
          />
          <Route path={`${ROUTES?.JOBS}/:id?`} element={<JobsPanel />} />
        </Routes>
      </div>
    </>
  );
};

export default SidePanel;
