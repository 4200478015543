import { useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import {
  formatPhoneNumberWithoutMask,
  getLogo,
  objectWithoutKey,
  uploadFile,
} from '../../../common/utils';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import ManufacturerForm from '../components/ManufacturerForm';
import { UPDATE_MANUFACTURER } from '../graphql/Mutations';
import { GET_MANUFACTURER } from '../graphql/Queries';

function ManufacturerEdit() {
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);

  const { data: { manufacturer } = {} } = useQuery(GET_MANUFACTURER, {
    variables: { id },
    onCompleted: (res) => {
      if (!res?.manufacturer?.logo?.url) {
        setLoading(false);
      }
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    },
  });

  const [updateManufacturerMutate] = useMutation(UPDATE_MANUFACTURER, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  useEffect(() => {
    if (manufacturer?.logo?.url) {
      getLogo({
        logoObject: manufacturer?.logo,
        setDataState: setLogoUrl,
        setLoadingState: setLoading,
      });
    }
  }, [manufacturer]);

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    let locationObj;
    let logo = null;
    if (
      formValues?.addressLine1 ||
      formValues?.city ||
      formValues?.state ||
      formValues?.country ||
      formValues?.zipCode
    ) {
      locationObj = {
        addressLine1: formValues?.addressLine1 || null,
        city: formValues?.city || null,
        state: formValues?.state || null,
        country: formValues?.country || null,
        zipCode: formValues?.zipCode || null,
      };
    }
    if (formValues?.logo && !formValues?.logo?.url) {
      logo = await uploadFile({
        logoObject: formValues?.logo,
        currentUser,
        uuid: manufacturer?.uuid,
        folder: 'manufacturer',
      });
    }

    const newFormValues = {
      ...formValues,
      ...(formValues?.logo?.url
        ? {
            logo: {
              ...manufacturer?.logo,
              __typename: undefined,
            },
          }
        : {
            ...(logo !== null && { logo }),
          }),
      contactNumber: formatPhoneNumberWithoutMask(formValues?.contactNumber),
      fax: formatPhoneNumberWithoutMask(formValues?.fax),
      uuid: manufacturer?.uuid,
      location: locationObj,
    };
    delete newFormValues?.addressLine1;
    delete newFormValues?.city;
    delete newFormValues?.state;
    delete newFormValues?.country;
    delete newFormValues?.zipCode;
    delete newFormValues?.manufacturerCode;
    delete newFormValues?.sponsorName;
    const variables = { data: newFormValues, where: { id: manufacturer?.id } };

    try {
      const response = await updateManufacturerMutate({
        variables: { ...variables },
      });
      if (response?.data?.updateManufacturer) {
        setSubmitLoading(false);
        navigate(ROUTES?.MANUFACTURERS, { state: { ...location?.state } });
      }
    } catch (error) {
      return error;
    }
  };

  if (!id) {
    return <Navigate to={ROUTES?.MANUFACTURERS} replace />;
  }

  const initialValues = {
    ...(manufacturer && { ...objectWithoutKey(manufacturer, 'logo') }),
    ...manufacturer?.location,
    ...(manufacturer?.logo?.url && {
      logo: { ...manufacturer?.logo, ...logoUrl },
    }),
    isActive: manufacturer?.isActive,
    certification: manufacturer?.certification || '',
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.MANUFACTURERS} />
          {manufacturer && (
            <span className="portal-header">{manufacturer?.name}</span>
          )}
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <ManufacturerForm
          isEdit
          manufacturerData={initialValues}
          handleSubmit={handleSubmit}
          isSubmit={submitLoading}
        />
      )}
    </Card>
  );
}

export default ManufacturerEdit;
