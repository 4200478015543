import { useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import ProspectDetails from '../components/ProspectDetails';
import { GET_PROSPECT } from '../graphql/Queries';

function ProspectView() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const { data: { prospect } = {} } = useQuery(GET_PROSPECT, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    },
  });

  if (!id) {
    return <Navigate to={ROUTES?.PROSPECTS} replace />;
  }

  const prospectsData = {
    ...prospect,
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PROSPECTS} />
          {prospect && (
            <span className="portal-header">{prospect?.businessName}</span>
          )}
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <ProspectDetails prospectsData={prospectsData} />
      )}
    </Card>
  );
}

export default ProspectView;
