import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Radio } from 'antd';
import { hasIn } from 'lodash';
import React, { useEffect } from 'react';

const RenderRadio = ({
  radioData = null,
  form,
  editData = null,
  isDisabled = false,
}) => {
  const { config, label = '', tooltip = '', key = '' } = radioData;
  const {
    BOOLEAN: {
      booleanDefaultValue = false,
      trueValue = '',
      falseValue = '',
      rules: {
        hidden,
        hideLabel,
        readOnly: fieldReadOnly,
        required: fieldRequired,
      } = null,
    } = {},
  } = config || null;

  useEffect(() => {
    if (hasIn(editData, key)) {
      form?.setFieldsValue({
        dynamicFields: {
          [key]: editData?.[key],
        },
      });
    } else {
      form?.setFieldsValue({
        dynamicFields: {
          [key]: booleanDefaultValue,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData]);

  return (
    <Form.Item
      label={hideLabel ? ' ' : label}
      name={['dynamicFields', key]}
      rules={[{ required: fieldRequired, message: `Please Select ${label}` }]}
      hidden={hidden}
      tooltip={
        tooltip && {
          title: tooltip,
          icon: <InfoCircleOutlined />,
        }
      }
      className={`${tooltip && 'label-with-tooltip'}${hidden ? 'd-none' : ''}`}
    >
      <Radio.Group
        disabled={fieldReadOnly || isDisabled}
        className="common-radio fill-width d-flex"
      >
        <Radio value>{trueValue}</Radio>
        <Radio value={false}>{falseValue}</Radio>
      </Radio.Group>
    </Form.Item>
  );
};

export default RenderRadio;
