import { Card } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import Portal from '../../../components/Portal';
import CustomerTable from '../components/CustomerTable';

const CustomerListPage = () => {
  const {
    state: { pageSize },
  } = useContext(AppContext);
  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <span className="portal-header">Customers</span>
      </Portal>
      {pageSize && <CustomerTable />}
    </Card>
  );
};

export default CustomerListPage;
