import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Checkbox, Divider, Empty, Tag } from 'antd';
import { debounce, filter, find, forEach, isEmpty, map, some } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import FilterIcon from '../../../assets/filter.svg';
import { MODAL_WIDTH, SKIP_RECORD } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import RangePickerComponent from '../../../components/RangePickerComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import useRouter from '../../../hooks/useRouter';
import {
  GET_PROPERTIES,
  PROPERTY_FILTER,
} from '../../properties/graphql/Queries';
import { CREATE_SALES_JUNCTION } from '../graphql/Mutations';
import { PROPERTY_TYPES_FILTER } from '../graphql/Queries';

let scrollDebounce = null;

const CustomerPropertyModal = ({ showModal = false, setShowModal }) => {
  const { params: { id } = {} } = useRouter();
  const {
    state: { pageSize },
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };

  const initialPropertyFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [propertyFilter, setPropertyFilter] = useState(initialPropertyFilter);
  const [filters, setFilters] = useState(null);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [filtersCopyState, setFiltersCopyState] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);

  const [createSalesJunction, { loading: salesJunctionLoading }] = useMutation(
    CREATE_SALES_JUNCTION,
    {
      onError() {},
    },
  );

  const [fetchPropertyData, { loading, data }] = useLazyQuery(GET_PROPERTIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.properties?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  const [propertyFilters] = useLazyQuery(PROPERTY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.propertyFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.propertyFilters?.data, (item) =>
          optionsCopy?.push(item?.[filterIndex]),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.propertyFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [propertyTypes] = useLazyQuery(PROPERTY_TYPES_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.propertyTypes?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.propertyTypes?.data, (item) =>
          optionsCopy?.push({ label: item?.label, key: item?.key }),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.propertyTypes?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  useEffect(() => {
    fetchPropertyData({
      variables: {
        filter: propertyFilter,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFilterData = (confirm) => {
    fetchPropertyData({
      variables: {
        filter: { ...propertyFilter, skip: 0 },
        ...(filtersCopyState && { where: filtersCopyState }),
      },
    });
    setFilters(filtersCopyState);
    setPropertyFilter({
      ...propertyFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'propertyType':
          propertyTypes({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: ['label', 'key'],
              },
            },
          });
          break;
        default:
          propertyFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex,
              },
            },
          });
          break;
      }
    }
    if (!filterVisible) {
      setFiltersCopyState(filters);
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);
        switch (filterIndex) {
          case 'propertyType':
            propertyTypes({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: ['label', 'key'],
                },
              },
            });
            break;
          default:
            propertyFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex,
                },
              },
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 38,
    selectedRowKeys: selectedKeys,
    onSelect: (record) => {
      const isAlready = some(selectedKeys, (item) => item === record?.id);
      if (isAlready) {
        const rowsCopy = filter(selectedKeys, (item) => item !== record?.id);
        setSelectedKeys(rowsCopy);
      } else {
        const rowsCopy = [...selectedKeys];
        rowsCopy?.push(record?.id);
        setSelectedKeys(rowsCopy);
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        const rowsCopy = [
          ...selectedKeys,
          ...map(changeRows, (item) => item?.id),
        ];
        setSelectedKeys(rowsCopy);
      } else {
        const remainingObject = selectedKeys?.filter(
          (elem) => !changeRows?.find(({ id: changeId }) => elem === changeId),
        );
        setSelectedKeys(remainingObject);
      }
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id,
      });
    },
  };

  const handleReset = (clearFilters, dataIndex) => {
    const filtersCopy = {
      ...filters,
      [dataIndex]: [],
    };
    if (dataIndex === 'builtYear') {
      delete filtersCopy?.[dataIndex];
    }
    setFilters(filtersCopy);
    fetchPropertyData({
      variables: {
        filter: {
          ...propertyFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC',
        },
        ...(filtersCopy && { where: filtersCopy }),
      },
    });
    setPropertyFilter({
      ...propertyFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setPropertyFilter({
        ...propertyFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      fetchPropertyData({
        variables: {
          filter: {
            ...propertyFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    } else {
      setPropertyFilter({
        ...propertyFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      fetchPropertyData({
        variables: {
          filter: {
            ...propertyFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
          ...(filters && { where: filters }),
        },
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'propertyType':
        propertyTypes({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: ['label', 'key'],
            },
          },
        });
        break;
      default:
        propertyFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex,
            },
          },
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };
  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: moment(values?.[0])?.format('YYYY'),
      to: moment(values?.[1])?.format('YYYY'),
    };
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete filtersCopy?.[dataIndex];
    }
    setFiltersCopyState(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'builtYear') {
        return (
          <div className="custom-filter-dropdown range-filter-dropdown">
            <RangePickerComponent
              picker="year"
              format="YYYY"
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                filtersCopyState?.[dataIndex]
                  ? [
                      moment(filtersCopyState?.[dataIndex]?.from, 'YYYY'),
                      moment(filtersCopyState?.[dataIndex]?.to, 'YYYY'),
                    ]
                  : []
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="common-button discard-button filter-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button filter-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }
      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {dataIndex !== 'isActive' && (
              <SearchComponent
                className="list-search-box filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                getData={(value) => handleSearch(value, dataIndex)}
              />
            )}
            {filtersCopyState?.[dataIndex]?.length > 0 && (
              <div className="filter-section">
                {map(filtersCopyState?.[dataIndex], (item) => (
                  <Tag
                    key={item?.toString()}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    {dataIndex === 'isActive' ? (
                      <span title={item === true ? 'Active' : 'Inactive'}>
                        {item === true ? 'Active' : 'Inactive'}
                      </span>
                    ) : (
                      <span
                        title={
                          find(filterList, ['key', item])?.label ||
                          item?.toString()
                        }
                      >
                        {find(filterList, ['key', item])?.label ||
                          item?.toString()}
                      </span>
                    )}
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onScroll(e, dataIndex)}
            >
              {filterList?.length > 0 ? (
                map(filterList, (item) => (
                  <div
                    className="filter-checkbox-section"
                    key={item?.key || item}
                  >
                    <Checkbox
                      value={item?.key || item}
                      checked={filtersCopyState?.[dataIndex]?.includes(
                        item?.key || item,
                      )}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="common-checkbox"
                    >
                      {dataIndex === 'isActive' ? (
                        <span title={item === true ? 'Active' : 'Inactive'}>
                          {item === true ? 'Active' : 'Inactive'}
                        </span>
                      ) : (
                        <span title={item?.label || item?.toString()}>
                          {item?.label || item?.toString()}
                        </span>
                      )}
                    </Checkbox>
                  </div>
                ))
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="common-button discard-button filter-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button filter-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 || !isEmpty(filters?.[dataIndex]) ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      width: 300,
    },
    {
      title: 'TYPE',
      dataIndex: 'propertyType',
      key: 'propertyType',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'propertyType' && sortedInfo?.order,
      width: 200,
      ...filterPopup('propertyType'),
    },
    {
      title: 'ADDRESS',
      ellipsis: true,
      dataIndex: 'location',
      className: 'max-width-column',
      key: 'location',
      render: (location) =>
        `${location?.addressLine1} ${location?.city} ${location?.state} ${location?.country} ${location?.zipCode}`,
    },
    {
      title: 'BUILT YEAR',
      dataIndex: 'builtYear',
      key: 'builtYear',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'builtYear' && sortedInfo?.order,
      width: 150,
      ...filterPopup('builtYear'),
    },
  ];

  const onSearchChange = (value) => {
    setPropertyFilter({
      ...propertyFilter,
      skip: value ? 0 : propertyFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    fetchPropertyData({
      variables: {
        filter: {
          ...propertyFilter,
          skip: value
            ? 0
            : propertyFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
        ...(filters && { where: filters }),
      },
    });
  };

  const handleAddProperties = async () => {
    await createSalesJunction({
      variables: {
        data: {
          salesIds: selectedKeys,
          joinedTo: 'PROPERTY',
          joinedFrom: 'CUSTOMER',
        },
        where: {
          id,
        },
      },
    });
    setShowModal(false);
  };

  return (
    <ModalComponent
      width={MODAL_WIDTH}
      title="ADD PROPERTY"
      open={showModal}
      onCancel={() => setShowModal(false)}
      destroyOnClose
      wrapClassName="add-items-modal"
      footer={
        <div className="d-flex justify-end">
          <Button
            type="primary"
            disabled={!selectedKeys?.length}
            loading={salesJunctionLoading}
            className="common-button add-item-button"
            onClick={handleAddProperties}
          >
            Add
            {selectedKeys?.length > 0 && ` ${selectedKeys?.length} Property(s)`}
          </Button>
        </div>
      }
    >
      <div className="fill-width search-checkbox">
        <SearchComponent
          className="list-search-box modal-search"
          id="search-container-id"
          placeholder="Search all fields"
          name="Properties"
          getData={onSearchChange}
        />
      </div>
      <div className="common-table item-table">
        <TableComponent
          loadingData={loading}
          columns={[...columns?.filter((item) => item !== false)]}
          data={data?.properties?.data || []}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowSelection={rowSelection}
          rowKey={(obj) => obj?.id}
        />
      </div>
    </ModalComponent>
  );
};

export default CustomerPropertyModal;
