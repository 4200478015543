import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './styles/EditorComponent.less';

const EditorComponent = (props) => {
  const { setEditorValue, className = '', disabled, readOnly, ...rest } = props;

  const [value, setValue] = useState('');

  const handleChange = (newValue) => {
    setValue(newValue);
    if (setEditorValue) {
      setEditorValue(newValue);
    }
  };

  return (
    <ReactQuill
      className={`editor-component ${className} ${
        disabled || readOnly ? 'disabled' : ''
      }`}
      onChange={handleChange}
      value={value}
      placeholder="Write text here"
      readOnly={disabled || readOnly}
      {...rest}
    />
  );
};

export default EditorComponent;
