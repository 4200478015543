import Icon from '@ant-design/icons';
import React from 'react';

const filterSelectedIcon = () => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="3" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.41648 7.58333C7.63988 7.58333 6.98733 7.05218 6.80232 6.33333H3.83301V5.5H6.80232C6.98733 4.78116 7.63988 4.25 8.41648 4.25C9.33695 4.25 10.0831 4.99619 10.0831 5.91667C10.0831 6.83714 9.33695 7.58333 8.41648 7.58333ZM10.9162 5.5H12.1662V6.33333H10.9162V5.5ZM6.33287 11.7501C5.55627 11.7501 4.90373 11.219 4.71871 10.5001H3.83301V9.6668H4.71871C4.90373 8.94796 5.55627 8.4168 6.33287 8.4168C7.25335 8.4168 7.99954 9.16299 7.99954 10.0835C7.99954 11.0039 7.25335 11.7501 6.33287 11.7501ZM8.83301 10.5001H12.1663V9.6668H8.83301V10.5001ZM7.16648 10.0833C7.16648 10.5436 6.79338 10.9167 6.33314 10.9167C5.87291 10.9167 5.49981 10.5436 5.49981 10.0833C5.49981 9.6231 5.87291 9.25 6.33314 9.25C6.79338 9.25 7.16648 9.6231 7.16648 10.0833ZM9.24967 5.91653C9.24967 6.37677 8.87658 6.74987 8.41634 6.74987C7.9561 6.74987 7.58301 6.37677 7.58301 5.91653C7.58301 5.45629 7.9561 5.0832 8.41634 5.0832C8.87658 5.0832 9.24967 5.45629 9.24967 5.91653Z"
        fill="white"
      />
    </svg>
  );

const FilterSelectedIconComponent = (props) => (
  <Icon component={filterSelectedIcon} {...props} />
);

export default FilterSelectedIconComponent;
