import { ZoomInOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import { map } from 'lodash';
import React, { useState } from 'react';
import PickListCheckedIconComponent from '../../../../../app/components/iconComponents/PickListCheckedIconComponent';
import placeHolderImage from '../../../../../assets/images/place-holder-image-master.svg';
import {
  HORIZONTAL_PADDING,
  PORTAL_WIDTH,
} from '../../../../../common/constants';
import ModalComponent from './ModalComponent';
import './styles/TileGridComponent.less';

const ImageBlock = ({ selectedKey = null, item = null, imageUrl = null }) => {
  if (selectedKey !== item?.key && !item?.default) {
    return (
      <>
        <img
          alt="logo"
          className="card-image"
          src={imageUrl || placeHolderImage}
          onError={(e) => {
            e.target.src = placeHolderImage;
          }}
          height={75}
        />
        <span className="card-title" title={item?.label}>
          {item?.label}
        </span>
      </>
    );
  }
  return (
    <>
      <PickListCheckedIconComponent />
      <span className="card-title" title={item?.label}>
        {item?.label}
      </span>
    </>
  );
};

const WithoutImageBlock = ({ selectedKey = null, item = null }) => {
  if (selectedKey !== item?.key && !item?.default) {
    return (
      <span className="card-title" title={item?.label}>
        {item?.label}
      </span>
    );
  }
  return (
    <>
      <span className="card-title" title={item?.label}>
        {item?.label}
      </span>
    </>
  );
};

const TileGridComponent = (props) => {
  const {
    gridClassName,
    displayStyle = 'TILES',
    imageObjectName = 'logo',
    renderItems = [],
    renderImages = false,
    imagePreview = false,
    renderDescriptions = false,
    selectedKey = null,
    haveLabel = false,
  } = props;
  const [showPreview, setShowPreview] = useState(false);
  const [record, setRecord] = useState(null);

  const closeModel = () => {
    setRecord(null);
    setShowPreview(false);
  };

  const showPreviewModal = (imageUrl, recordObj) => {
    setRecord({ ...recordObj, imageUrl });
    setShowPreview(true);
  };

  return (
    <div className={`tile-grid ${!haveLabel ? 'mt-35' : ''}`}>
      {renderItems?.length > 0 && (
        <Card
          className={`${renderItems?.length === 1 ? 'center-aligned' : ''}`}
        >
          {map(renderItems, (item) => {
            let imageUrl;
            if (item?.[imageObjectName]?.length > 0) {
              if (item?.[imageObjectName]?.[0]?.url) {
                imageUrl = item?.[imageObjectName]?.[0]?.url;
              } else {
                imageUrl = URL?.createObjectURL(
                  item?.[imageObjectName]?.[0]?.originFileObj,
                );
              }
            } else {
              imageUrl = item?.[imageObjectName]?.url;
            }

            return (
              <Card.Grid
                key={item?.key || item?.blockKey}
                className={`grid-card ${
                  displayStyle === 'TILES' ? 'tile-view' : ''
                }${displayStyle === 'LIST' ? 'list-view' : ''} ${
                  selectedKey === item?.key ? 'card-selected' : ''
                }${gridClassName || ''} ${
                  item?.default ? 'card-selected' : ''
                }`}
                hoverable
              >
                <div className="d-flex flex-vertical align-center">
                  {renderImages && (
                    <>
                      <ImageBlock
                        item={item}
                        imageUrl={imageUrl}
                        selectedKey={selectedKey}
                      />
                    </>
                  )}
                  {!renderImages && (
                    <WithoutImageBlock item={item} selectedKey={selectedKey} />
                  )}
                  {renderDescriptions && (
                    <span
                      className="card-description"
                      title={item?.blockDescription || item?.description}
                    >
                      {item?.blockDescription || item?.description}
                    </span>
                  )}
                  {imagePreview && (
                    <span
                      className={`zoom-image ${
                        selectedKey === item?.key || item?.default
                          ? 'color-white'
                          : 'color-primary'
                      } `}
                      title="zoom-image"
                      onClick={(e) => {
                        e?.stopPropagation();
                        showPreviewModal(imageUrl);
                      }}
                    >
                      <ZoomInOutlined /> zoom
                    </span>
                  )}
                </div>
              </Card.Grid>
            );
          })}
        </Card>
      )}
      {showPreview && (
        <ModalComponent
          open={showPreview}
          footer={null}
          onCancel={closeModel}
          destroyOnClose
          width={PORTAL_WIDTH - 2 * HORIZONTAL_PADDING}
          wrapClassName="preview-modal"
        >
          <>
            <div className="modal-title">
              <span>{record?.label}</span>
            </div>
            <div className="d-flex justify-center">
              <img
                alt="logo"
                className="modal-image"
                src={record?.imageUrl || placeHolderImage}
                onError={(e) => {
                  e.target.src = placeHolderImage;
                }}
              />
            </div>
            <Button className="fill-width" type="primary">
              Select
            </Button>
          </>
        </ModalComponent>
      )}
    </div>
  );
};

export default TileGridComponent;
