import React from 'react';
import ModalComponent from '../../../components/ModalComponent';

const DefaultPriceModal = ({
  showPrompt,
  handleOK,
  okText,
  handleCancel,
  cancelText,
  description = 'We have noticed that you have changed the price for a product. Please be aware that the price across all regions for the product will not be automatically updated. To ensure that the price across regions is up-to-date, kindly navigate to Catalog -> Product Pricing section and manually update it.',
}) => (
    <ModalComponent
      title="Note"
      className="router-prompt"
      open={showPrompt}
      onOk={handleOK}
      okText={okText}
      onCancel={handleCancel}
      cancelText={cancelText}
      okButtonProps={{ className: 'common-button' }}
      cancelButtonProps={{ className: 'display-none' }}
      closable
    >
      {description}
    </ModalComponent>
  );

export default DefaultPriceModal;
