import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import EditorComponent from '../../../../../../../../components/EditorComponent';
import InputComponent from '../../../../../../../../components/InputComponent';

const CustomTextPreview = ({ widgetConfig }) => {
  const [form] = Form?.useForm();
  const textType = widgetConfig?.config?.textType;
  const minLength = widgetConfig?.config?.textLength?.minLength;
  const maxLength = widgetConfig?.config?.textLength?.maxLength;
  const label = widgetConfig?.config?.label || ' ';
  const toolTipChecked = widgetConfig?.config?.tooltip || widgetConfig?.tooltip;
  const toolTipValue =
    widgetConfig?.config?.tooltipValue || widgetConfig?.tooltipValue;
  const required = widgetConfig?.config?.rules?.required;
  const defaultCheck = widgetConfig?.config?.defaultCheck;
  const defaultValue = widgetConfig?.config?.default;
  const descriptionCheck = widgetConfig?.config?.description;
  const descriptionValue = widgetConfig?.config?.descriptionValue;
  const hideLabel = widgetConfig?.config?.rules?.hideLabel;

  const renderText = () => {
    if (textType === 'MULTI_LINE') {
      return (
        <InputComponent.TextArea
          readOnly
          showCount
          rows={3}
          className="common-textarea mb-16"
          maxLength={maxLength}
          minLength={minLength}
          placeholder={`Enter your ${label}`}
        />
      );
    }
    if (textType === 'RICH_TEXT') {
      return <EditorComponent readOnly placeholder={`Enter your ${label}`} />;
    }
    return (
      <InputComponent
        readOnly
        name="Value"
        showCount
        maxLength={maxLength}
        minLength={minLength}
        placeholder={`Enter your ${label}`}
      />
    );
  };

  useEffect(() => {
    if (defaultCheck) {
      form?.setFieldsValue({
        text: defaultValue,
      });
    } else {
      form?.setFieldsValue({
        text: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, defaultCheck]);

  return (
    <div className="mt-37">
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          text: defaultCheck ? defaultValue : '',
        }}
      >
        <Form.Item
          name="text"
          label={hideLabel ? ' ' : label}
          tooltip={
            toolTipChecked && {
              title: toolTipValue,
              icon: <InfoCircleOutlined />,
            }
          }
          normalize={(value) => {
            if (textType === 'RICH_TEXT') {
              return value?.replace('<p>', '')?.replace('</p>', '');
            }
            return value;
          }}
          rules={[{ required, message: 'Please Enter Value' }]}
          className={`${!required && 'hide-required-mark'} ${
            toolTipChecked && 'label-with-tooltip'
          } label-text-break`}
        >
          {renderText()}
        </Form.Item>
      </Form>
      {descriptionCheck && descriptionValue && (
        <span className="thin-label">{descriptionValue}</span>
      )}
    </div>
  );
};

export default CustomTextPreview;
