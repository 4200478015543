import { Tabs } from 'antd';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DocumentIconComponent from '../../../app/components/iconComponents/DocumentIconComponent';
import GalleryIconComponent from '../../../app/components/iconComponents/GalleryIconComponent';
import InfoIconComponent from '../../../app/components/iconComponents/InfoIconComponent';
import { ROUTES } from '../../../common/constants';

const infoTitle = (
  <div>
    <InfoIconComponent className="tab-icons" />
    <span className="card-title">Information</span>
  </div>
);
const galleryTitle = (
  <div>
    <GalleryIconComponent className="tab-icons" />
    <span className="card-title">Gallery</span>
  </div>
);
const documentTitle = (
  <div>
    <DocumentIconComponent className="tab-icons" />
    <span className="card-title">Document</span>
  </div>
);

const ProductItemTabs = ({ children }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const handleSelectTab = (key) => {
    switch (key) {
      case 'INFORMATION':
        if (location?.pathname?.includes('/add')) {
          navigate(`${ROUTES?.PRODUCTS}/add`, {
            state: location?.state,
          });
        } else if (location?.pathname?.includes('/view')) {
          navigate(`${ROUTES?.PRODUCTS}/view/${id}`, {
            state: location?.state,
          });
        } else {
          navigate(`${ROUTES?.PRODUCTS}/edit/${id}`, {
            state: location?.state,
          });
        }
        break;

      case 'GALLERY':
        if (location?.pathname?.includes('/view')) {
          navigate(`${ROUTES?.PRODUCTS}/view${ROUTES?.GALLERY}/${id}`, {
            state: location?.state,
          });
        } else {
          navigate(`${ROUTES?.PRODUCTS}/edit${ROUTES?.GALLERY}/${id}`, {
            state: location?.state,
          });
        }
        break;

      case 'DOCUMENT':
        if (location?.pathname?.includes('/view')) {
          navigate(`${ROUTES?.PRODUCTS}/view${ROUTES?.DOCUMENTS}/${id}`, {
            state: location?.state,
          });
        } else {
          navigate(`${ROUTES?.PRODUCTS}/edit${ROUTES?.DOCUMENTS}/${id}`, {
            state: location?.state,
          });
        }
        break;

      default:
        break;
    }
  };

  const tabItems = [
    {
      label: infoTitle,
      key: 'INFORMATION',
      children,
    },
    !location?.pathname?.includes('/add') && {
      label: galleryTitle,
      key: 'GALLERY',
      children,
    },
    !location?.pathname?.includes('/add') && {
      label: documentTitle,
      key: 'DOCUMENT',
      children,
    },
  ];

  const getActiveKey = () => {
    if (location?.pathname?.includes(ROUTES?.GALLERY)) {
      return 'GALLERY';
    }
    if (location?.pathname?.includes(ROUTES?.DOCUMENTS)) {
      return 'DOCUMENT';
    }
    return 'INFORMATION';
  };

  return (
    <div className="card-container">
      <Tabs
        type="card"
        items={tabItems}
        activeKey={getActiveKey()}
        onChange={handleSelectTab}
      />
    </div>
  );
};

export default ProductItemTabs;
