import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import {
  PERMISSION_TENANT_SPONSOR_ASSOCIATION,
  ROUTES,
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import SponsorTenantList from './pages/SponsorTenantList';
import SponsorTenantView from './pages/SponsorTenantView';
import './sponsorTenantModule.less';

const SponsorTenantsWrapper = () => (
  <div className="sponsor-tenants-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_TENANT_SPONSOR_ASSOCIATION}
            showNoAccess
          >
            <SponsorTenantList />
          </AccessControl>
        }
      />
      <Route
        path="/view/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_TENANT_SPONSOR_ASSOCIATION_VIEW']}
            showNoAccess
          >
            <SponsorTenantView />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default SponsorTenantsWrapper;
