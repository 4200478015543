import { UpOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  Empty,
  Radio,
  Row,
  Space,
} from 'antd';
import { debounce, find, get, map, uniqBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { PRODUCT_PRICING, ROUTES } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import PriceComponent from '../../../components/PriceComponent';
import SelectComponent from '../../../components/SelectComponent';
import TreeComponent from '../../../components/TreeComponent';
import {
  GET_INDUSTRIES,
  GET_LINE_OF_BUSINESSES,
  GET_MANUFACTURERS,
  GET_PRICING_MODIFIER_REGIONS,
  GET_SPONSORS,
  GET_SUB_AREAS,
} from '../graphql/Queries';

const { Option } = SelectComponent;

const LIMIT = 50;

let industryScrollDebounceJob;
let sponsorScrollDebounceJob;
let manufacturerScrollDebounceJob;
let regionScrollDebounceJob;
let lineOfBusinessScrollDebounceJob;
let subAreaScrollDebounceJob;

const subAreaFilter = {
  skip: 0,
  sortOn: 'label',
  sortBy: 'ASC',
  justShow: true,
};

const lineOfBusinessFilter = {
  skip: 0,
  sortOn: 'label',
  sortBy: 'ASC',
  justShow: true,
};

const manufacturerFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'name',
  sortBy: 'ASC',
};

const industryFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'label',
  sortBy: 'ASC',
  justShow: true,
};

const sponsorFilter = {
  skip: 0,
  limit: LIMIT,
  sortOn: 'businessName',
  sortBy: 'ASC',
  distinct: true,
  getDBField: 'businessName',
};

const regionFilter = {
  skip: 0,
  limit: LIMIT,
};

const getData = (key = '') => {
  // eslint-disable-next-line no-undef
  const data = JSON?.parse(localStorage?.getItem(PRODUCT_PRICING)) || null;
  if (key?.length > 0) {
    return get(data, key);
  }
  return data;
};

const ExtraLoaderComponent = ({ loading = false }) => (
  <LoaderComponent spinning={loading} size="small" setHeight={2} />
);

const radioOptions = [
  {
    key: 'EQUALS',
    label: 'equals',
  },
  {
    key: 'GREATER',
    label: 'is greater than',
  },
  {
    key: 'LESS',
    label: 'is lower than',
  },
];

const ProductPricingPanelComponent = () => {
  const {
    dispatch,
    state: { productPricingConfig, isSponsor },
  } = useContext(AppContext);

  const navigate = useNavigate();

  const [manufacturersLoading, setManufacturersLoading] = useState(true);
  const [manufacturersData, setManufacturersData] = useState([]);
  const [manufacturersIsEnd, setManufacturersIsEnd] = useState(false);
  const [manufacturersInitialCall, setManufacturersInitialCall] = useState(
    true,
  );
  const [manufacturersDebounceCall, setManufacturersDebounceCall] = useState(0);

  const [industriesLoading, setIndustriesLoading] = useState(true);
  const [industriesData, setIndustriesData] = useState([]);
  const [industriesIsEnd, setIndustriesIsEnd] = useState(false);
  const [industriesInitialCall, setIndustriesInitialCall] = useState(true);
  const [industriesDebounceCall, setIndustriesDebounceCall] = useState(0);

  const [sponsorsLoading, setSponsorsLoading] = useState(true);
  const [sponsorsData, setSponsorsData] = useState([]);
  const [sponsorsIsEnd, setSponsorsIsEnd] = useState(false);
  const [sponsorsInitialCall, setSponsorsInitialCall] = useState(true);
  const [sponsorsDebounceCall, setSponsorsDebounceCall] = useState(0);

  const [lineOfBusinessesLoading, setLineOfBusinessesLoading] = useState(false);
  const [lineOfBusinessesData, setLineOfBusinessesData] = useState([]);
  const [lineOfBusinessesIsEnd, setLineOfBusinessesIsEnd] = useState(false);
  const [
    lineOfBusinessesInitialCall,
    setLineOfBusinessesInitialCall,
  ] = useState(true);
  const [
    lineOfBusinessesDebounceCall,
    setLineOfBusinessesDebounceCall,
  ] = useState(0);

  const [subAreasLoading, setSubAreasLoading] = useState(false);
  const [subAreasData, setSubAreasData] = useState([]);
  const [subAreasIsEnd, setSubAreasIsEnd] = useState(false);
  const [subAreasInitialCall, setSubAreasInitialCall] = useState(true);
  const [subAreasDebounceCall, setSubAreasDebounceCall] = useState(0);

  const [regionsLoading, setRegionsLoading] = useState(true);
  const [regionsData, setRegionsData] = useState([]);
  const [regionsIsEnd, setRegionsIsEnd] = useState(false);
  const [regionsInitialCall, setRegionsInitialCall] = useState(true);
  const [regionsDebounceCall, setRegionsDebounceCall] = useState(0);

  const [productPricingConfigCopy, setProductPricingConfigCopy] = useState(
    productPricingConfig,
  );

  useEffect(() => {
    setProductPricingConfigCopy(productPricingConfig);
  }, [productPricingConfig]);

  const updateData = (data = null) => {
    // eslint-disable-next-line no-undef
    localStorage?.setItem(PRODUCT_PRICING, JSON?.stringify(data));
  };

  const handleChangeFilter = (value, objKey, objData = []) => {
    let configCopy;

    switch (objKey) {
      case 'manufacturerIds':
        configCopy = {
          ...productPricingConfigCopy,
          [objKey]: map(value, (item) => ({
            id: item,
            name: find(manufacturersData, (data) => data?.id === item)?.name,
          })),
        };
        break;
      case 'sponsorId':
        configCopy = {
          ...productPricingConfigCopy,
          [objKey]: {
            id: value,
            label: find(objData, (item) => item?.id === value)?.businessName,
          },
        };
        break;
      case 'industry':
        configCopy = {
          ...productPricingConfigCopy,
          [objKey]: {
            id: value,
            label: find(objData, (item) => item?.id === value)?.label,
          },
        };
        delete configCopy?.lineOfBusiness;
        delete configCopy?.subAreas;
        setLineOfBusinessesInitialCall(true);
        setSubAreasInitialCall(true);
        updateData(configCopy);
        break;
      case 'lineOfBusiness':
        configCopy = {
          ...productPricingConfigCopy,
          [objKey]: {
            id: value,
            label: find(objData, (item) => item?.id === value)?.label,
          },
        };
        delete configCopy?.subAreas;
        updateData(configCopy);
        setSubAreasInitialCall(true);
        break;
      case 'subAreas':
        configCopy = {
          ...productPricingConfigCopy,
          [objKey]: map(value, (item) => ({
            id: item,
            label: find(objData, (data) => data?.id === item)?.label,
          })),
        };
        break;
      case 'regionIds':
        configCopy = {
          ...productPricingConfigCopy,
          [objKey]: value,
        };
        break;
      case 'baseCostMin':
        configCopy = {
          ...productPricingConfigCopy,
          baseCost: {
            ...productPricingConfigCopy?.baseCost,
            min: value,
          },
        };
        break;
      case 'baseCostMax':
        configCopy = {
          ...productPricingConfigCopy,
          baseCost: {
            ...productPricingConfigCopy?.baseCost,
            max: value,
          },
        };
        break;
      case 'defaultCostMin':
        configCopy = {
          ...productPricingConfigCopy,
          defaultCost: {
            ...productPricingConfigCopy?.defaultCost,
            min: value,
          },
        };
        break;
      case 'defaultCostMax':
        configCopy = {
          ...productPricingConfigCopy,
          defaultCost: {
            ...productPricingConfigCopy?.defaultCost,
            max: value,
          },
        };
        break;
      case 'profitOperation':
        configCopy = {
          ...productPricingConfigCopy,
          profit: {
            ...productPricingConfigCopy?.profit,
            operation: value?.target?.value,
          },
        };
        break;
      case 'profitOperationType':
        configCopy = {
          ...productPricingConfigCopy,
          profit: {
            ...productPricingConfigCopy?.profit,
            operationType: value,
          },
        };
        break;
      case 'profitValue':
        configCopy = {
          ...productPricingConfigCopy,
          profit: {
            ...productPricingConfigCopy?.profit,
            value,
          },
        };
        break;

      default:
        break;
    }

    setProductPricingConfigCopy(configCopy);
  };

  const [manufacturers] = useLazyQuery(GET_MANUFACTURERS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setManufacturersIsEnd(res?.manufacturers?.data?.length < LIMIT);
      if (manufacturersInitialCall) {
        const manufacturerData = getData('manufacturerIds');
        if (manufacturerData) {
          setManufacturersData(
            uniqBy([...manufacturersData, ...res?.manufacturers?.data], 'id'),
          );
        } else {
          setManufacturersData(uniqBy(res?.manufacturers?.data, 'id'));
        }
        setManufacturersInitialCall(false);
      } else {
        setManufacturersData(
          uniqBy([...manufacturersData, ...res?.manufacturers?.data], 'id'),
        );
      }
      setManufacturersLoading(false);
    },
    onError() {
      setManufacturersLoading(false);
    },
  });

  const [industries] = useLazyQuery(GET_INDUSTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIndustriesIsEnd(res?.industries?.data?.length < LIMIT);
      if (industriesInitialCall) {
        const industryData = getData('industry');
        if (industryData) {
          setIndustriesData(
            uniqBy([industryData, ...res?.industries?.data], 'id'),
          );
        } else {
          const uniqueData = uniqBy(res?.industries?.data, 'id');
          setIndustriesData(uniqueData);
        }
        setIndustriesInitialCall(false);
      } else {
        setIndustriesData(
          uniqBy([...industriesData, ...res?.industries?.data], 'id'),
        );
      }
      setIndustriesLoading(false);
    },
    onError() {
      setIndustriesLoading(false);
    },
  });

  const [sponsors] = useLazyQuery(GET_SPONSORS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setSponsorsIsEnd(res?.sponsors?.data?.length < LIMIT);
      if (sponsorsInitialCall) {
        const sponsorData = getData('sponsorId');
        if (sponsorData) {
          setSponsorsData(uniqBy([sponsorData, ...res?.sponsors?.data], 'id'));
        } else {
          const uniqueData = uniqBy(res?.sponsors?.data, 'id');
          setSponsorsData(uniqueData);
        }
        setSponsorsInitialCall(false);
      } else {
        setSponsorsData(
          uniqBy([...sponsorsData, ...res?.sponsors?.data], 'id'),
        );
      }
      setSponsorsLoading(false);
    },
    onError() {
      setSponsorsLoading(false);
    },
  });

  const [lineOfBusinesses] = useLazyQuery(GET_LINE_OF_BUSINESSES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setLineOfBusinessesIsEnd(res?.lineOfBusinesses?.data?.length < LIMIT);
      if (lineOfBusinessesInitialCall) {
        const lineOfBusinessData = getData('lineOfBusiness');
        if (lineOfBusinessData) {
          setLineOfBusinessesData(
            uniqBy([lineOfBusinessData, ...res?.lineOfBusinesses?.data], 'id'),
          );
        } else {
          const uniqueData = uniqBy(res?.lineOfBusinesses?.data, 'id');
          setLineOfBusinessesData(uniqueData);
        }
        setLineOfBusinessesInitialCall(false);
      } else {
        setLineOfBusinessesData(
          uniqBy(
            [...lineOfBusinessesData, ...res?.lineOfBusinesses?.data],
            'id',
          ),
        );
      }
      setLineOfBusinessesLoading(false);
    },
    onError() {
      setLineOfBusinessesLoading(false);
    },
  });

  const [subAreas] = useLazyQuery(GET_SUB_AREAS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setSubAreasIsEnd(res?.subAreas?.data?.length < LIMIT);
      if (subAreasInitialCall) {
        const subAreaData = getData('subArea');
        if (subAreaData) {
          setSubAreasData(uniqBy([subAreaData, ...res?.subAreas?.data], 'id'));
        } else {
          setSubAreasData(uniqBy(res?.subAreas?.data, 'id'));
        }
        setSubAreasInitialCall(false);
      } else {
        setSubAreasData(
          uniqBy([...subAreasData, ...res?.subAreas?.data], 'id'),
        );
      }
      setSubAreasLoading(false);
    },
    onError() {
      setSubAreasLoading(false);
    },
  });

  const [regionPricingModifier] = useLazyQuery(GET_PRICING_MODIFIER_REGIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setRegionsIsEnd(res?.regionPricingModifier?.data?.length < LIMIT);
      if (regionsInitialCall) {
        setRegionsData(
          map(uniqBy(res?.regionPricingModifier?.data, 'id'), (item) => ({
            ...item,
            title: item?.name,
            key: item?.id,
            children: map(item?.subRegions, (subRegion) => ({
              title: subRegion?.name,
              key: subRegion?.id,
            })),
          })),
        );
        setRegionsInitialCall(false);
      } else {
        setRegionsData(
          map(
            uniqBy([...regionsData, ...res?.regionPricingModifier?.data], 'id'),
            (item) => ({
              ...item,
              title: item?.name,
              key: item?.id,
              children: map(item?.subRegions, (subRegion) => ({
                title: subRegion?.name,
                key: subRegion?.id,
              })),
            }),
          ),
        );
      }
      setRegionsLoading(false);
    },
    onError() {
      setRegionsLoading(false);
    },
  });

  useEffect(() => {
    industries({
      variables: {
        filter: industryFilter,
        where: { isActive: true },
      },
    });
    sponsors({
      variables: {
        filter: sponsorFilter,
      },
    });
    manufacturers({
      variables: {
        filter: manufacturerFilter,
        where: { isActive: true },
      },
    });
    regionPricingModifier({
      variables: {
        filter: regionFilter,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (productPricingConfigCopy?.industry?.id) {
      setLineOfBusinessesLoading(true);
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            limit: LIMIT,
            industryId: productPricingConfigCopy?.industry?.id,
          },
          where: { isActive: true },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPricingConfigCopy?.industry?.id]);

  useEffect(() => {
    if (productPricingConfigCopy?.lineOfBusiness?.id) {
      setSubAreasLoading(true);
      subAreas({
        variables: {
          filter: {
            ...subAreaFilter,
            limit: LIMIT,
            industryId: productPricingConfigCopy?.industry?.id,
            lobId: productPricingConfigCopy?.lineOfBusiness?.id,
          },
          where: { isActive: true },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPricingConfigCopy?.lineOfBusiness?.id]);

  const onManufacturerScroll = (event) => {
    if (manufacturerScrollDebounceJob) {
      manufacturerScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    manufacturerScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !manufacturersIsEnd) {
        setManufacturersLoading(true);
        setManufacturersDebounceCall((prevState) => prevState + 1);
        manufacturers({
          variables: {
            filter: {
              ...manufacturerFilter,
              skip: (manufacturersDebounceCall + 1) * LIMIT,
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);

    manufacturerScrollDebounceJob();
  };

  const onIndustryScroll = (event) => {
    if (industryScrollDebounceJob) {
      industryScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !industriesIsEnd) {
        setIndustriesLoading(true);
        setIndustriesDebounceCall((prevState) => prevState + 1);
        industries({
          variables: {
            filter: {
              ...industryFilter,
              skip: (industriesDebounceCall + 1) * LIMIT,
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);

    industryScrollDebounceJob();
  };

  const onSponsorScroll = (event) => {
    if (sponsorScrollDebounceJob) {
      sponsorScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    sponsorScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !sponsorsIsEnd) {
        setSponsorsLoading(true);
        setSponsorsDebounceCall((prevState) => prevState + 1);
        sponsors({
          variables: {
            filter: {
              ...sponsorFilter,
              skip: (sponsorsDebounceCall + 1) * LIMIT,
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);

    sponsorScrollDebounceJob();
  };

  const onLineOfBusinessScroll = (event) => {
    if (lineOfBusinessScrollDebounceJob) {
      lineOfBusinessScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    lineOfBusinessScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !lineOfBusinessesIsEnd) {
        setLineOfBusinessesLoading(true);
        setLineOfBusinessesDebounceCall((prevState) => prevState + 1);
        lineOfBusinesses({
          variables: {
            filter: {
              ...lineOfBusinessFilter,
              limit: LIMIT,
              skip: (lineOfBusinessesDebounceCall + 1) * LIMIT,
              industryId: productPricingConfigCopy?.industry?.id,
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);
  };

  const onSubAreaScroll = (event) => {
    if (subAreaScrollDebounceJob) {
      subAreaScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    subAreaScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !subAreasIsEnd) {
        setSubAreasLoading(true);
        setSubAreasDebounceCall((prevState) => prevState + 1);
        subAreas({
          variables: {
            filter: {
              ...subAreaFilter,
              limit: LIMIT,
              skip: (subAreasDebounceCall + 1) * LIMIT,
              industryId: productPricingConfigCopy?.industry?.id,
              lobId: productPricingConfigCopy?.lineOfBusiness?.id,
            },
            where: { isActive: true },
          },
        });
      }
    }, 500);
  };

  const onRegionScroll = (event) => {
    if (regionScrollDebounceJob) {
      regionScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    regionScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !regionsIsEnd) {
        setRegionsLoading(true);
        setRegionsDebounceCall((prevState) => prevState + 1);
        regionPricingModifier({
          variables: {
            filter: {
              ...regionFilter,
              skip: (regionsDebounceCall + 1) * LIMIT,
            },
          },
        });
      }
    }, 500);

    regionScrollDebounceJob();
  };

  const panelHeader = (title = '') => (
    <div className="d-flex flex-vertical">
      <span>{title}</span>
    </div>
  );

  const handleApplyFilter = () => {
    dispatch({
      type: 'SET_PRODUCT_PRICING_CONFIG',
      data: productPricingConfigCopy,
    });
    updateData(productPricingConfigCopy);
    navigate(`${ROUTES?.PRODUCT_PRICING}?sp=false`, { replace: true });
  };

  const items = [
    !isSponsor && {
      forceRender: true,
      label: panelHeader('Sponsor'),
      key: 'SPONSOR',
      collapsible: sponsorsLoading && 'disabled',
      extra: <ExtraLoaderComponent loading={sponsorsLoading} />,
      children: (
        <>
          {sponsorsData?.length > 0 ? (
            <div className="panel-section" onScroll={onSponsorScroll}>
              <LoaderComponent spinning={sponsorsLoading}>
                <Radio.Group
                  className="common-radio"
                  onChange={(event) =>
                    handleChangeFilter(
                      event?.target?.value,
                      'sponsorId',
                      sponsorsData,
                    )
                  }
                  value={productPricingConfigCopy?.sponsorId?.id}
                >
                  <Space direction="vertical">
                    {map(sponsorsData, (item) => (
                      <Radio key={item?.id} value={item?.id}>
                        {item?.businessName}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </>
      ),
    },
    {
      forceRender: true,
      label: panelHeader('Industry'),
      key: 'INDUSTRY',
      collapsible: industriesLoading && 'disabled',
      extra: <ExtraLoaderComponent loading={industriesLoading} />,
      children: (
        <>
          {industriesData?.length > 0 ? (
            <div className="panel-section" onScroll={onIndustryScroll}>
              <LoaderComponent spinning={industriesLoading}>
                <Radio.Group
                  className="common-radio"
                  onChange={(event) =>
                    handleChangeFilter(
                      event?.target?.value,
                      'industry',
                      industriesData,
                    )
                  }
                  value={productPricingConfigCopy?.industry?.id}
                >
                  <Space direction="vertical">
                    {map(industriesData, (item) => (
                      <Radio key={item?.id} value={item?.id}>
                        {item?.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </>
      ),
    },
    {
      forceRender: true,
      label: panelHeader('Line of Business'),
      key: 'LOB',
      collapsible: lineOfBusinessesLoading && 'disabled',
      extra: <ExtraLoaderComponent loading={lineOfBusinessesLoading} />,
      children: (
        <>
          {lineOfBusinessesData?.length > 0 ? (
            <div className="panel-section" onScroll={onLineOfBusinessScroll}>
              <LoaderComponent spinning={lineOfBusinessesLoading}>
                <Radio.Group
                  className="common-radio"
                  onChange={(event) =>
                    handleChangeFilter(
                      event?.target?.value,
                      'lineOfBusiness',
                      lineOfBusinessesData,
                    )
                  }
                  value={productPricingConfigCopy?.lineOfBusiness?.id}
                >
                  <Space direction="vertical">
                    {map(lineOfBusinessesData, (item) => (
                      <Radio key={item?.id} value={item?.id}>
                        {item?.label}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              description="Select Industry"
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </>
      ),
    },
    {
      forceRender: true,
      label: panelHeader('Service Type'),
      key: 'SUBAREA',
      collapsible: subAreasLoading && 'disabled',
      extra: <ExtraLoaderComponent loading={subAreasLoading} />,
      children: (
        <>
          {subAreasData?.length > 0 ? (
            <div className="panel-section" onScroll={onSubAreaScroll}>
              <LoaderComponent spinning={subAreasLoading}>
                <Checkbox.Group
                  onChange={(event) =>
                    handleChangeFilter(event, 'subAreas', subAreasData)
                  }
                  value={map(
                    productPricingConfigCopy?.subAreas,
                    (item) => item?.id,
                  )}
                >
                  <Space direction="vertical">
                    {map(subAreasData, (item) => (
                      <Checkbox
                        className="common-checkbox"
                        key={item?.id}
                        value={item?.id}
                      >
                        {item?.label}
                      </Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              description="Select Line Of Business"
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </>
      ),
    },
    {
      forceRender: true,
      label: panelHeader('Manufacturer'),
      key: 'MANUFACTURER',
      collapsible: manufacturersLoading && 'disabled',
      extra: <ExtraLoaderComponent loading={manufacturersLoading} />,
      children: (
        <>
          {manufacturersData?.length > 0 ? (
            <div className="panel-section" onScroll={onManufacturerScroll}>
              <LoaderComponent spinning={manufacturersLoading}>
                <Checkbox.Group
                  onChange={(event) =>
                    handleChangeFilter(event, 'manufacturerIds')
                  }
                  value={map(
                    productPricingConfigCopy?.manufacturerIds,
                    (item) => item?.id,
                  )}
                >
                  <Space direction="vertical">
                    {map(manufacturersData, (item) => (
                      <Checkbox
                        className="common-checkbox"
                        key={item?.id}
                        value={item?.id}
                      >
                        {`${item?.name}  ${
                          item?.sponsorName ? `- ${item?.sponsorName}` : ' '
                        }`}
                      </Checkbox>
                    ))}
                  </Space>
                </Checkbox.Group>
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </>
      ),
    },
    {
      forceRender: true,
      label: panelHeader('Product Cost'),
      key: 'BASE_COST',
      children: (
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <div className="label">Min</div>
            <PriceComponent
              value={productPricingConfigCopy?.baseCost?.min}
              onChange={(value) => handleChangeFilter(value, 'baseCostMin')}
              placeholder="Min"
            />
          </Col>
          <Col span={12}>
            <div className="label">Max</div>
            <PriceComponent
              value={productPricingConfigCopy?.baseCost?.max}
              onChange={(value) => handleChangeFilter(value, 'baseCostMax')}
              placeholder="Max"
            />
          </Col>
        </Row>
      ),
    },
    {
      forceRender: true,
      label: panelHeader('Product Price'),
      key: 'DEFAULT_PRICE',
      children: (
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <div className="label">Min</div>
            <PriceComponent
              value={productPricingConfigCopy?.defaultPrice?.min}
              onChange={(value) => handleChangeFilter(value, 'defaultCostMin')}
              placeholder="Min"
            />
          </Col>
          <Col span={12}>
            <div className="label">Max</div>
            <PriceComponent
              value={productPricingConfigCopy?.defaultPrice?.max}
              onChange={(value) => handleChangeFilter(value, 'defaultCostMax')}
              placeholder="Max"
            />
          </Col>
        </Row>
      ),
    },
    {
      forceRender: true,
      label: panelHeader('Profit Margin'),
      key: 'PROFIT_MARGIN',
      children: (
        <>
          <Radio.Group
            className="common-radio"
            onChange={(event) => handleChangeFilter(event, 'profitOperation')}
            value={productPricingConfigCopy?.profit?.operation || 'GREATER'}
          >
            <Space direction="vertical">
              {map(radioOptions, (item) => (
                <Radio key={item?.key} value={item?.key}>
                  {item?.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
          <Space.Compact>
            <SelectComponent
              value={productPricingConfigCopy?.profit?.operationType || '$'}
              onChange={(value) =>
                handleChangeFilter(value, 'profitOperationType')
              }
              allowClear={false}
              showSearch={false}
              className="profit-select-width"
            >
              <Option value="$">$</Option>
              <Option value="%">%</Option>
            </SelectComponent>
            <PriceComponent
              placeholder="Margin Target"
              isPrice
              prefix="$"
              onChange={(value) => handleChangeFilter(value, 'profitValue')}
              className="profit-price"
            />
          </Space.Compact>
        </>
      ),
    },
    {
      label: panelHeader('Region'),
      forceRender: true,
      key: 'REGION',
      collapsible: regionsLoading && 'disabled',
      extra: <ExtraLoaderComponent loading={regionsLoading} />,
      children: (
        <>
          {regionsData?.length > 0 ? (
            <div className="panel-section" onScroll={onRegionScroll}>
              <LoaderComponent spinning={regionsLoading}>
                <TreeComponent
                  data={regionsData}
                  checkedKeys={productPricingConfigCopy?.regionIds}
                  setCheckedKeys={(value) =>
                    handleChangeFilter(value, 'regionIds')
                  }
                />
              </LoaderComponent>
            </div>
          ) : (
            <Empty
              image={Empty?.PRESENTED_IMAGE_SIMPLE}
              className="panel-empty"
            />
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <div className="product-pricing-panel-component">
        <div className="product-pricing-collapse">
          <Collapse
            bordered={false}
            expandIconPosition="end"
            expandIcon={({ isActive }) => (
              <UpOutlined rotate={isActive ? 0 : 180} />
            )}
            className="common-collapse"
            items={[...items?.filter((item) => item !== false)]}
          />
        </div>
      </div>
      <div className="footer-btn-section">
        <Button
          className="common-button"
          size="small"
          id="apply-btn"
          type="primary"
          onClick={handleApplyFilter}
        >
          Apply
        </Button>
      </div>
    </>
  );
};

export default ProductPricingPanelComponent;
