import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React from 'react';
import NumberComponent from '../../../../../../../../components/NumberComponent';

const SystemNumberPreviewForm = ({ systemFieldData }) => {
  const label = systemFieldData?.config?.label || systemFieldData?.label || ' ';
  const required =
    systemFieldData?.config?.required || systemFieldData?.required;
  const toolTipChecked =
    systemFieldData?.config?.toolTipCheck || systemFieldData?.toolTipCheck;
  const toolTipValue =
    systemFieldData?.config?.toolTipText || systemFieldData?.toolTipText;

  return (
    <div>
      <Form layout="vertical">
        <Form.Item
          name="systemNumber"
          label={label}
          tooltip={
            toolTipChecked && {
              title: toolTipValue,
              icon: <InfoCircleOutlined />,
            }
          }
          rules={[{ required, message: 'Please Enter Value' }]}
          className={`${!required && 'hide-required-mark'} ${
            toolTipChecked && 'label-with-tooltip'
          } label-text-break`}
        >
          <NumberComponent placeholder={`Enter your ${label}`} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default SystemNumberPreviewForm;
