import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_PRODUCT_RANKING, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ProductRankingPage from './pages/ProductRankingPage';
import './productRanking.less';

const ProductRankingWrapper = () => (
  <div className="product-ranking-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_PRODUCT_RANKING}
            showNoAccess
          >
            <ProductRankingPage />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default ProductRankingWrapper;
