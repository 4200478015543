import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_PRODUCT_CATEGORY, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ProductCategoryCreate from './pages/ProductCategoryCreate';
import ProductCategoryEdit from './pages/ProductCategoryEdit';
import ProductCategoryList from './pages/ProductCategoryList';

const ProductCategoryWrapper = () => (
  <div className="product-category-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_PRODUCT_CATEGORY}
            showNoAccess
          >
            <ProductCategoryList />
          </AccessControl>
        }
      />
      <Route
        path="/add"
        element={
          <AccessControl
            allowedPermissions={['FET_PRODUCT_CATEGORY_CREATE']}
            showNoAccess
          >
            <ProductCategoryCreate />
          </AccessControl>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <AccessControl
            allowedPermissions={[
              'FET_PRODUCT_CATEGORY_UPDATE',
              'FET_PRODUCT_CATEGORY_VIEW',
            ]}
            showNoAccess
          >
            <ProductCategoryEdit />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default ProductCategoryWrapper;
