import { gql } from '@apollo/client';

export const GET_ACTIVITY_LOGS = gql`
  query activityLogs(
    $filter: ActivityLogFilter!
    $where: ActivityLogWhereFilter
  ) {
    activityLogs(filter: $filter, where: $where) {
      count
      data {
        id
        refType
        user {
          firstName
          lastName
        }
        type
        activityType
        source
        ip
        userAgent
        url
        httpMethod
        deviceType
        createdAt
      }
    }
  }
`;

export const GET_ACTIVITY_LOG = gql`
  query activityLog($where: ActivityLogWhereInput!) {
    activityLog(where: $where) {
      id
      refType
      user {
        firstName
        lastName
      }
      type
      activityType
      source
      ip
      differValues
      userAgent
      httpMethod
      responseStatus
      deviceType
      createdAt
      errorMessage
    }
  }
`;

export const GET_ACTIVITY_LOGS_FILTER = gql`
  query activityLogFilters($filter: ActivityLogFilter!) {
    activityLogFilters(filter: $filter) {
      count
      data {
        refType
        userName
        httpMethod
        activityType
        createdAt
      }
    }
  }
`;
