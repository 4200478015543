import { useMutation } from '@apollo/client';
import { Col, Form, Modal, Radio, Row } from 'antd';
import { map } from 'lodash';
import React, { useRef } from 'react';
import { DYNAMIC_PICK_LIST_TYPE } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';
import { DELETE_DYNAMIC_LIST_ITEM } from '../graphql/Mutations';

const { confirm } = Modal;

const PickListFormData = ({
  position = 'top',
  isDisabled = false,
  form = null,
  setRefetchData,
  dynamicFieldsData = null,
  hasPickListData = false,
}) => {
  const { params: { id } = {} } = useRouter();
  const prevValue = useRef(dynamicFieldsData?.type);
  const [deleteDynamicListItem] = useMutation(DELETE_DYNAMIC_LIST_ITEM, {
    onCompleted: () => {
      setRefetchData(true);
    },
    onError: () => {},
  });

  const handleChangeValue = (e) => {
    if (hasPickListData) {
      confirm({
        centered: true,
        wrapClassName: 'confirm-modal',
        okButtonProps: { className: 'common-button', type: 'primary' },
        cancelButtonProps: { className: 'common-button discard-button' },
        content:
          'Changing field type will result in irreversible loss of all the options added by you. Are you sure you want to proceed?',
        onOk: async () => {
          await deleteDynamicListItem({
            variables: { where: { fieldId: id, deleteAllOption: true } },
          });
          prevValue.current = e?.target?.value;
        },
        onCancel: () => {
          form?.setFieldsValue({ type: prevValue?.current });
        },
      });
    }
  };

  return (
    <div className="pick-list-form-data">
      {position === 'top' && (
        <Row gutter={16}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
            <Form.Item name="type">
              <Radio.Group
                className="common-radio"
                disabled={isDisabled}
                onChange={handleChangeValue}
              >
                {map(DYNAMIC_PICK_LIST_TYPE, (item) => (
                  <Radio key={item?.key} value={item?.key}>
                    {item?.label}
                  </Radio>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default PickListFormData;
