import {
  BarChartOutlined,
  LineChartOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { Button, Switch } from 'antd';
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import ColumnChartComponent from '../../../components/ColumnChart';
import LineChartComponent from '../../../components/LineChart';

const ChartSection = ({
  handleChangeChart,
  chartType = 'column',
  chartData = [],
  uniqueDateData = [],
  colors = [],
  loading = false,
}) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef?.current,
    pageStyle: 'margin: 10px',
  });
  return (
    <div className="chart-area">
      <div className="d-flex justify-between">
        <span className="header-title">Graph</span>
        <div className="d-flex align-center">
          <Button
            className="mr-8 common-button discard-button"
            icon={<PrinterOutlined />}
            size="small"
            id="dashboard-print-btn"
            onClick={handlePrint}
          >
            Print
          </Button>
          <LineChartOutlined
            className={
              chartType === 'line'
                ? 'margin-right-space primary-icon'
                : 'margin-right-space'
            }
          />
          <Switch
            className="common-switch chart-switch"
            onChange={handleChangeChart}
            checked={chartType === 'column'}
          />
          <BarChartOutlined
            className={
              chartType === 'column'
                ? 'margin-left-space primary-icon'
                : 'margin-left-space'
            }
          />
        </div>
      </div>
      <div className="chart" ref={componentRef}>
        <style type="text/css" media="print">
          {'\
          @page { size: landscape; }\
          '}
        </style>
        {chartType === 'column' ? (
          <ColumnChartComponent
            chartData={chartData}
            uniqueDateData={uniqueDateData}
            colors={colors}
            loading={loading}
          />
        ) : (
          <LineChartComponent
            chartData={chartData}
            colors={colors}
            loading={loading}
          />
        )}
      </div>
    </div>
  );
};

export default ChartSection;
