import { gql } from '@apollo/client';

export const GET_ACCOUNT_STEP = gql`
  query getUserNextStep {
    getUserNextStep {
      id
      nextStep
      user {
        id
        firstName
        lastName
        roles
        email
        phoneNo
        tenantId
        sponsorId
        profileImage {
          url
          key
          name
          contentType
          extension
        }
        tenantId
        emailVerified
      }
      userId
      data
      allowedPermission {
        module
        permissions
      }
    }
  }
`;

export const GET_ATTACHMENTS = gql`
  query attachments($filter: AttachmentFilter!) {
    attachments(filter: $filter) {
      count
      data {
        id
        referenceId
        key
        label
        type
        createdBy
        isActive
        url
        tags
        referenceKey
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query notifications(
    $filter: NotificationsFilter
    $where: NotificationWhereInput!
  ) {
    notifications(filter: $filter, where: $where) {
      count
      data {
        id
        templateId
        moduleType
        moduleData
        template {
          key
          title
          content
          createdAt
          updatedAt
        }
        isRead
        createdAt
        updatedAt
      }
    }
  }
`;
