import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_REGION, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import RegionCreate from './pages/RegionCreate';
import RegionEdit from './pages/RegionEdit';
import RegionList from './pages/RegionList';
import './regionModule.less';

const RegionWrapper = () => (
  <div className="region-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl allowedPermissions={PERMISSION_REGION} showNoAccess>
            <RegionList />
          </AccessControl>
        }
      />
      <Route
        path="/add"
        element={
          <AccessControl
            allowedPermissions={['FET_REGION_CREATE']}
            showNoAccess
          >
            <RegionCreate />
          </AccessControl>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_REGION_UPDATE', 'FET_REGION_VIEW']}
            showNoAccess
          >
            <RegionEdit />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default RegionWrapper;
