import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  Row,
  Switch,
  Tooltip,
} from 'antd';
import { debounce, map, uniqBy } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { SKIP_RECORD } from '../../../common/constants';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import DatePickerComponent from '../../../components/DatePickerComponent';
import InputComponent from '../../../components/InputComponent';
import LoaderComponent from '../../../components/LoaderComponent';
import ModalComponent from '../../../components/ModalComponent';
import SelectComponent from '../../../components/SelectComponent';
import TextAreaComponent from '../../../components/TextAreaComponent';
import { CREATE_BATCH, UPDATE_BATCH } from '../graphql/Mutations';
import {
  GET_INDUSTRIES,
  GET_TENANTS,
  LINE_OF_BUSINESS,
} from '../graphql/Queries';
import EmailTable from './EmailTable';

let industryScrollDebounceJob;
let lineOfBusinessScrollDebounceJob;

const { required } = formValidatorRules;

const { Option } = SelectComponent;

const industryFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true,
};

const lineOfBusinessFilter = {
  sortOn: 'label',
  sortBy: 'ASC',
  skip: 0,
  limit: 20,
  search: '',
  justShow: true,
};

const tenantFilter = {
  sortOn: 'name',
  sortBy: 'ASC',
  all: true,
  search: '',
};

const CreateBatchModal = ({
  isEdit = false,
  setIsEdit,
  showModal = false,
  setShowModal,
  emailData = [],
  setEmailData,
  initialValues = null,
  refetch,
}) => {
  const [form] = Form?.useForm();
  const [validationTriggered, setValidationTriggered] = useState(false);
  const {
    state: { currentUser, permissions },
  } = useContext(AppContext);
  const [searchValue, setSearchValue] = useState('');

  const [industryLoading, setIndustryLoading] = useState(false);
  const [industrySearchFlag, setIndustrySearchFlag] = useState(false);
  const [industriesData, setIndustriesData] = useState([]);
  const [industryIsEnd, setIndustryIsEnd] = useState(false);
  const [industryDebounceCall, setIndustryDebounceCall] = useState(0);

  const [lineOfBusinessLoading, setLineOfBusinessLoading] = useState(false);
  const [lineOfBusinessSearchFlag, setLineOfBusinessSearchFlag] = useState(
    false,
  );
  const [lineOfBusinessesData, setLineOfBusinessesData] = useState([]);
  const [lineOfBusinessIsEnd, setLineOfBusinessIsEnd] = useState(false);
  const [lineOfBusinessDebounceCall, setLineOfBusinessDebounceCall] = useState(
    0,
  );
  const [initialLobRender, setInitialLobRender] = useState(true);

  const [disableLob, setDisableLob] = useState(true);

  const [tenantLoading, setTenantLoading] = useState(false);
  const [tenantSearchFlag, setTenantSearchFlag] = useState(false);
  const [tenantsData, setTenantsData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [tenantIds, setTenantIds] = useState([]);
  const [disableTenant, setDisableTenant] = useState(true);

  const [lobLabel, setLobLabel] = useState(null);
  const [industryLabel, setIndustryLabel] = useState(null);
  const [loading, setLoading] = useState(false);

  const isSchedule = Form?.useWatch('isSchedule', form);
  const isEmailNotificationEnabled = Form?.useWatch(
    'isEmailNotificationEnabled',
    form,
  );

  const [industries] = useLazyQuery(GET_INDUSTRIES, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setIndustryIsEnd(res?.industries?.data?.length < SKIP_RECORD);
      if (industrySearchFlag) {
        setIndustriesData([...res?.industries?.data]);
      } else {
        if (isEdit) {
          const tempIndustry = [
            initialValues?.industry,
            ...res?.industries?.data,
          ];
          setIndustryLabel(initialValues?.industry?.label);
          setIndustriesData(uniqBy([...industriesData, ...tempIndustry], 'id'));
          return;
        }
        setIndustriesData([...industriesData, ...res?.industries?.data]);
      }
      setIndustryLoading(false);
    },
    onError() {
      setIndustryLoading(false);
    },
  });

  const [lineOfBusinesses] = useLazyQuery(LINE_OF_BUSINESS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setLineOfBusinessIsEnd(res?.lineOfBusinesses?.data?.length < SKIP_RECORD);
      if (lineOfBusinessSearchFlag) {
        setLineOfBusinessesData([...res?.lineOfBusinesses?.data]);
      } else {
        if (isEdit) {
          if (initialLobRender) {
            setLobLabel(initialValues?.lineOfBusiness?.label);
            const tempLineOfBusiness = [
              initialValues?.lineOfBusiness,
              ...res?.lineOfBusinesses?.data,
            ];
            setLineOfBusinessesData(
              uniqBy([...lineOfBusinessesData, ...tempLineOfBusiness], 'id'),
            );
          } else {
            setLineOfBusinessesData([...res?.lineOfBusinesses?.data]);
          }
          setInitialLobRender(false);
          return;
        }
        setLineOfBusinessesData([
          ...lineOfBusinessesData,
          ...res?.lineOfBusinesses?.data,
        ]);
      }
      setLineOfBusinessLoading(false);
    },
    onError() {
      setLineOfBusinessLoading(false);
    },
  });

  const [tenants] = useLazyQuery(GET_TENANTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const allTenantIds = res?.sponsorAssociatedTenants?.data?.map(
        (tenant) => tenant?.id,
      );
      setTenantIds([...tenantIds, ...allTenantIds]);
      if (isEdit && initialValues?.tenantIds?.length > 0) {
        setSelectedItems([...initialValues?.tenantIds]);
        form?.setFieldValue('tenantIds', initialValues?.tenantIds);
      } else if (selectedItems?.length > 0) {
        setSelectedItems([...selectedItems, ...allTenantIds]);
        form?.setFieldValue('tenantIds', [...tenantIds, ...allTenantIds]);
      }
      if (tenantSearchFlag) {
        setTenantsData([...res?.sponsorAssociatedTenants?.data]);
      } else {
        setTenantsData(
          uniqBy(
            [...tenantsData, ...res?.sponsorAssociatedTenants?.data],
            'id',
          ),
        );
      }
      setTenantLoading(false);
    },
    onError() {
      setTenantLoading(false);
    },
  });

  const [createBatch] = useMutation(CREATE_BATCH, {
    onCompleted: () => {
      setEmailData([]);
      refetch();
      setLoading(false);
      setShowModal(false);
    },
    onError() {
      setLoading(false);
      setShowModal(false);
    },
  });

  const [updateBatch] = useMutation(UPDATE_BATCH, {
    onCompleted: () => {
      setEmailData([]);
      refetch();
      setLoading(false);
      setIsEdit(false);
      setShowModal(false);
    },
    onError() {
      setLoading(false);
      setIsEdit(false);
      setShowModal(false);
    },
  });

  useEffect(() => {
    if (industryLabel && lobLabel) {
      setTenantLoading(true);
      setDisableTenant(false);
      tenants({
        variables: {
          filter: tenantFilter,
          where: {
            isActive: true,
            industry: industryLabel,
            lineOfBusiness: lobLabel,
          },
        },
      });
    }
  }, [industryLabel, lobLabel]);

  useEffect(() => {
    industries({
      variables: {
        filter: industryFilter,
        where: {
          isActive: true,
          sponsorId: currentUser?.sponsorId,
        },
      },
    });
    if (initialValues?.industryId) {
      setDisableLob(false);
      lineOfBusinesses({
        variables: {
          filter: {
            ...lineOfBusinessFilter,
            industryId: initialValues?.industryId,
          },
          where: {
            isActive: true,
          },
        },
      });
    }
  }, []);

  const handleTenantChange = (value) => {
    setTenantSearchFlag(true);
    setSearchValue(value);
    setTenantLoading(true);
    tenants({
      variables: {
        filter: {
          ...tenantFilter,
          search: value,
        },
        where: {
          isActive: true,
          industryId: form?.getFieldValue('industryId'),
          lineOfBusinessId: form?.getFieldValue('lineOfBusinessId'),
        },
      },
    });
  };

  const debouncedTenantHandler = useCallback(
    debounce(handleTenantChange, 500),
    [],
  );

  const handleTenantClear = () => {
    form?.setFieldsValue({
      industryId: null,
      lineOfBusinessId: null,
    });
    setDisableLob(true);
    setIndustriesData([]);
    setLineOfBusinessesData([]);
    tenants({
      variables: {
        filter: tenantFilter,
        where: {
          isActive: true,
          industryId: form?.getFieldValue('industryId'),
          lineOfBusinessId: form?.getFieldValue('lineOfBusinessId'),
        },
      },
    });
  };

  const handleTenantBlur = () => {
    setSearchValue('');
  };

  const onIndustryScroll = (event) => {
    setIndustrySearchFlag(false);
    if (industryScrollDebounceJob) {
      industryScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    industryScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !industryIsEnd) {
        setIndustryLoading(true);
        setIndustryDebounceCall((prevState) => prevState + 1);
        industries({
          variables: {
            filter: {
              ...industryFilter,
              skip: (industryDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
            },
            where: {
              isActive: true,
              sponsorId: currentUser?.sponsorId,
            },
          },
        });
      }
    }, 500);

    industryScrollDebounceJob();
  };

  const handleIndustryChange = (value) => {
    setIndustrySearchFlag(true);
    setSearchValue(value);
    setIndustryLoading(true);
    industries({
      variables: {
        filter: {
          ...industryFilter,
          search: value,
        },
        where: {
          isActive: true,
          sponsorId: currentUser?.sponsorId,
        },
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedIndustryHandler = useCallback(
    debounce(handleIndustryChange, 500),
    [],
  );

  const handleIndustryClear = () => {
    form?.setFieldsValue({
      industryId: null,
      lineOfBusinessId: null,
    });
    setDisableLob(true);
    setDisableTenant(true);
    setIndustriesData([]);
    setLineOfBusinessesData([]);
    industries({
      variables: {
        filter: industryFilter,
        where: {
          isActive: true,
          sponsorId: currentUser?.sponsorId,
        },
      },
    });
  };

  const handleIndustryBlur = () => {
    setSearchValue('');
    setIndustryDebounceCall(0);
    setIndustryIsEnd(false);
  };

  const handleIndustrySelect = (value, ...rest) => {
    const selectedIndustryLabel = rest?.[0]?.['data-label'];
    setIndustryLabel(selectedIndustryLabel);
    setDisableLob(false);
    setLineOfBusinessesData([]);
    setTenantsData([]);
    setTenantIds([]);
    setLobLabel(null);
    setDisableTenant(true);
    form?.setFieldsValue({
      lineOfBusinessId: null,
      tenantIds: null,
    });
    setLineOfBusinessLoading(true);
    lineOfBusinesses({
      variables: {
        filter: { ...lineOfBusinessFilter, industryId: value },
        where: {
          isActive: true,
        },
      },
    });
  };

  const handleLineOfBusinessSelect = (_, ...rest) => {
    const selectedLobLabel = rest?.[0]?.['data-label'];
    setLobLabel(selectedLobLabel);
    setTenantsData([]);
    setTenantIds([]);
    setSelectedItems([]);
    form?.setFieldsValue({
      tenantIds: null,
    });
  };

  const onLineOfBusinessScroll = (event) => {
    setLineOfBusinessSearchFlag(false);
    if (lineOfBusinessScrollDebounceJob) {
      lineOfBusinessScrollDebounceJob?.cancel();
    }
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    lineOfBusinessScrollDebounceJob = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (scrolledToBottom && !lineOfBusinessIsEnd) {
        setLineOfBusinessLoading(true);
        setLineOfBusinessDebounceCall((prevState) => prevState + 1);
        lineOfBusinesses({
          variables: {
            filter: {
              ...lineOfBusinessFilter,
              skip: (lineOfBusinessDebounceCall + 1) * SKIP_RECORD,
              search: searchValue,
              industryId: form?.getFieldValue('industryId'),
            },
            where: {
              isActive: true,
            },
          },
        });
      }
    }, 500);

    lineOfBusinessScrollDebounceJob();
  };

  const handleLineOfBusinessChange = (value) => {
    setLineOfBusinessSearchFlag(true);
    setSearchValue(value);
    setLineOfBusinessLoading(true);
    setTenantsData([]);
    setTenantIds([]);
    setSelectedItems([]);
    form?.setFieldsValue({
      tenantIds: null,
    });
    lineOfBusinesses({
      variables: {
        filter: {
          ...lineOfBusinessFilter,
          industryId: form?.getFieldValue('industryId'),
          search: value,
        },
        where: {
          isActive: true,
        },
      },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLineOfBusinessHandler = useCallback(
    debounce(handleLineOfBusinessChange, 500),
    [],
  );

  const handleLineOfBusinessClear = () => {
    form?.setFieldsValue({
      lineOfBusinessId: null,
      tenantIds: null,
    });
    setLineOfBusinessesData([]);
    setTenantsData([]);
    setTenantIds([]);
    setSelectedItems([]);
    setDisableTenant(true);
    lineOfBusinesses({
      variables: {
        filter: {
          ...lineOfBusinessFilter,
          industryId: form?.getFieldValue('industryId'),
        },
        where: {
          isActive: true,
        },
      },
    });
  };

  const handleLineOfBusinessBlur = () => {
    setSearchValue('');
    setLineOfBusinessDebounceCall(0);
    setLineOfBusinessIsEnd(false);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (!isEdit) {
      createBatch({
        variables: {
          data: {
            ...values,
            ...(values?.isEmailNotificationEnabled && {
              emailNotificationConfig: emailData,
            }),
          },
        },
      });
    } else {
      updateBatch({
        variables: {
          data: {
            ...values,
            ...(values?.isEmailNotificationEnabled && {
              emailNotificationConfig: emailData,
            }),
            status: initialValues?.pickListStatus?.key,
          },
          where: {
            id: initialValues?.id,
          },
        },
      });
    }
  };

  const onFinishFailed = () => {
    setValidationTriggered(true);
  };

  const handleSelectAllChange = (e) => {
    if (e?.target?.checked) {
      setSelectedItems([...tenantIds]);
      form?.setFieldValue('tenantIds', [...tenantIds]);
    } else {
      setSelectedItems([]);
      form?.setFieldValue('tenantIds', null);
    }
  };

  const onTenantSelect = (value) => {
    setSelectedItems([...selectedItems, value]);
  };

  return (
    <ModalComponent
      title={isEdit ? 'EDIT BATCH' : 'CREATE BATCH'}
      width={1300}
      open={showModal}
      onCancel={onCancel}
      footer={
        <div className="d-flex justify-end width-80">
          <Button
            className="common-button cta-btn mr-16"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <AccessControl allowedPermissions={['FET_DATA_ASSIGNMENT_UPDATE']}>
            <Button
              type="primary"
              loading={loading}
              className="common-button cta-btn"
              onClick={() => form?.submit()}
            >
              {isEdit ? 'Save' : 'Create'}
            </Button>
          </AccessControl>
        </div>
      }
      destroyOnClose
      wrapClassName="create-batch-modal"
    >
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={{ behavior: 'smooth', block: 'end' }}
        name="settingForm"
        layout="vertical"
        initialValues={initialValues}
        disabled={!checkPermissions(permissions, 'FET_DATA_ASSIGNMENT_UPDATE')}
        validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
      >
        <Row className="setting-content">
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form.Item
              name="name"
              label="Name"
              rules={[{ ...required, message: 'Please Enter Name' }]}
            >
              <InputComponent allowClear placeholder="Enter Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              className="label-with-tooltip"
            >
              <TextAreaComponent
                rows={5}
                cols={5}
                placeholder="Enter Description"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="industryId"
              label="Industry"
              rules={[
                {
                  required,
                  message: 'Please Select Industry',
                },
              ]}
            >
              <SelectComponent
                placeholder="Select Industry"
                allowClear
                notFoundContent={
                  industryLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handleIndustryBlur}
                onSearch={debouncedIndustryHandler}
                onClear={handleIndustryClear}
                onPopupScroll={onIndustryScroll}
                onChange={handleIndustrySelect}
              >
                {map(industriesData, (item) => (
                  <Option
                    key={item?.id}
                    value={item?.id}
                    data-label={item?.label}
                  >
                    {item?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="lineOfBusinessId"
              label="Line of Business"
              rules={[
                {
                  required,
                  message: 'Please Select Line Of Business',
                },
              ]}
            >
              <SelectComponent
                showSearch
                placeholder="Select Line Of Business"
                allowClear
                disabled={disableLob}
                notFoundContent={
                  lineOfBusinessLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handleLineOfBusinessBlur}
                onSearch={debouncedLineOfBusinessHandler}
                onClear={handleLineOfBusinessClear}
                onPopupScroll={onLineOfBusinessScroll}
                onSelect={handleLineOfBusinessSelect}
              >
                {map(lineOfBusinessesData, (item) => (
                  <Option
                    key={item?.id}
                    value={item?.id}
                    data-label={item?.label}
                  >
                    {item?.label}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="tenantIds"
              label="Tenants"
              allowClear
              rules={[
                {
                  required,
                  message: 'Please Select Tenants',
                  type: 'array',
                },
              ]}
            >
              <SelectComponent
                mode="multiple"
                disabled={disableTenant}
                maxTagCount="responsive"
                showSearch
                placeholder="Select Tenants"
                allowClear
                notFoundContent={
                  tenantLoading ? (
                    <LoaderComponent size="small" setHeight={10} />
                  ) : (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onBlur={handleTenantBlur}
                onSearch={debouncedTenantHandler}
                onClear={handleTenantClear}
                onSelect={onTenantSelect}
                dropdownRender={(menu) => (
                  <>
                    <Checkbox
                      className="common-checkbox select-all-tenants"
                      disabled={!(tenantsData?.length > 0)}
                      checked={
                        tenantsData?.length > 0 &&
                        selectedItems?.length === tenantsData?.length
                      }
                      indeterminate={
                        selectedItems?.length > 0 &&
                        selectedItems?.length < tenantsData?.length
                      }
                      onChange={handleSelectAllChange}
                    >
                      Select All
                    </Checkbox>
                    {menu}
                  </>
                )}
                maxTagPlaceholder={(omittedValues) => (
                  <Tooltip
                    className="cursor-pointer"
                    title={omittedValues?.map(({ label }) => label)?.join(', ')}
                  >
                    <span>Hover Me</span>
                  </Tooltip>
                )}
              >
                {map(tenantsData, (item) => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.name}
                  </Option>
                ))}
              </SelectComponent>
            </Form.Item>
          </Col>
        </Row>

        <span className="form-divider-text">SCHEDULE</span>
        <Divider className="form-divider" />

        <Row>
          <Col span={24}>
            <span className="description-text">
              Select the future date to schedule this batch
            </span>
          </Col>
          <Col span={24}>
            <Form.Item name="isSchedule">
              <Switch
                className="common-switch ml-8 m-0"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
          {isSchedule && (
            <Col span={6}>
              <Form.Item
                name="scheduleDate"
                label="Schedule Date"
                rules={[
                  {
                    required,
                    message: 'Please Select Schedule Date',
                  },
                ]}
              >
                <DatePickerComponent
                  disabledDate
                  showTime={{
                    format: 'HH',
                    use12Hours: false,
                  }}
                />
              </Form.Item>
            </Col>
          )}
        </Row>

        <span className="form-divider-text">AUTOMATED EMAIL NOTIFICATIONS</span>
        <Divider className="form-divider" />
        <Row>
          <Col span={24}>
            <span className="description-text">
              Enables automated email notification on job success/failure . A
              maximum of 5 email recipients can be added.
            </span>
          </Col>
          <Col span={24}>
            <Form.Item name="isEmailNotificationEnabled">
              <Switch
                className="common-switch ml-8 m-0"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>
        {isEmailNotificationEnabled && (
          <>
            <span className="form-divider-text">EMAIL RECIPIENTS</span>
            <Divider className="form-divider" />

            <Row>
              <Col span={24}>
                <span className="description-text">
                  These email recipients will be used by both automated and code
                  based email notifications.
                </span>
              </Col>
            </Row>
          </>
        )}
      </Form>
      {isEmailNotificationEnabled && (
        <>
          <EmailTable emailData={emailData} setEmailData={setEmailData} />
        </>
      )}
    </ModalComponent>
  );
};
export default CreateBatchModal;
