import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Empty,
  Popconfirm,
  Popover,
  Tag,
} from 'antd';
import { chain, debounce, filter, forEach, isEmpty, join, map } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import FilterSelectedIconComponent from '../../../app/components/iconComponents/FilterSelectedComponent';
import ExportIcon from '../../../assets/export.svg';
import FilterIcon from '../../../assets/filter.svg';
import ImportIcon from '../../../assets/import.svg';
import {
  PROSPECT_STATUS_OBJ,
  ROUTES,
  SKIP_RECORD,
  exportOptions,
} from '../../../common/constants';
import {
  checkPermissions,
  formatPhoneNumber,
  handleExportCommon,
} from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import ImportModal from '../../imports/components/ImportModal';
import {
  CANCEL_PROSPECT_INVITATION,
  DELETE_PROSPECT,
  RESEND_PROSPECT_INVITATION,
} from '../graphql/Mutations';
import {
  FETCH_STATE_CITY,
  GET_PROSPECTS,
  INDUSTRY_FILTER,
  LINE_OF_BUSINESS_FILTER,
  PROSPECT_FILTER,
} from '../graphql/Queries';

let scrollDebounce = null;

const initialPaginationValue = {
  total: 0,
  current: 1,
};

const ProspectTable = () => {
  const {
    state: { pageSize, filterData, permissions },
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const initialProspectFilter = useMemo(
    () => ({
      skip: 0,
      limit: pageSize,
      sortOn: 'createdAt',
      sortBy: 'DESC',
    }),
    [pageSize],
  );

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [showImportModal, setShowImportModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [prospectFilter, setProspectFilter] = useState(initialProspectFilter);
  const [filters, setFilters] = useState(filterData);
  const [filtersCopyState, setFiltersCopyState] = useState(filterData);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [searchValue, setSearchValue] = useState(null);

  const [deleteProspect, { loading: deleteProspectLoading }] = useMutation(
    DELETE_PROSPECT,
    {
      onError() {},
    },
  );

  const [
    cancelProspectInvitation,
    { loading: cancelProspectInvitationLoading },
  ] = useMutation(CANCEL_PROSPECT_INVITATION, {
    onError() {},
  });

  const [
    resendProspectInvitation,
    { loading: resendProspectInvitationLoading },
  ] = useMutation(RESEND_PROSPECT_INVITATION, {
    onError() {},
  });

  const [fetchProspectData, { loading, data }] = useLazyQuery(GET_PROSPECTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.prospects?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  const [fetchStateAndCity] = useLazyQuery(FETCH_STATE_CITY, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.getLocationType?.data, (item) => {
          if (item?.state) {
            optionsCopy?.push(`${item?.name} (${item?.state})`);
          } else {
            optionsCopy?.push(item?.name);
          }
        });
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.getLocationType?.data, (item) => {
          if (item?.state) {
            optionsCopy?.push(`${item?.name} (${item?.state})`);
          } else {
            optionsCopy?.push(item?.name);
          }
        });
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.getLocationType?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError() {
      setFilterLoading(false);
    },
  });

  const [industryFilters] = useLazyQuery(INDUSTRY_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.industryFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.industryFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [lineOfBusinessFilters] = useLazyQuery(LINE_OF_BUSINESS_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.lineOfBusinessFilters?.data, (item) =>
          optionsCopy?.push(item?.label),
        );
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.lineOfBusinessFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  const [prospectFilters] = useLazyQuery(PROSPECT_FILTER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (scrollFlag) {
        const optionsCopy = [...filterList];
        forEach(res?.prospectFilters?.data, (item) => {
          if (filterIndex === 'region') {
            optionsCopy?.push(item?.[filterIndex]);
          } else {
            optionsCopy?.push({
              key: item?.[filterIndex],
              label: PROSPECT_STATUS_OBJ?.[item?.[filterIndex]]?.label,
            });
          }
        });
        setFilterList(optionsCopy);
        setScrollFlag(false);
      } else {
        const optionsCopy = [];
        forEach(res?.prospectFilters?.data, (item) => {
          if (filterIndex === 'region') {
            optionsCopy?.push(item?.[filterIndex]);
          } else {
            optionsCopy?.push({
              key: item?.[filterIndex],
              label: PROSPECT_STATUS_OBJ?.[item?.[filterIndex]]?.label,
            });
          }
        });
        setFilterList(optionsCopy);
      }
      setFilterIsEnd(res?.prospectFilters?.data?.length < SKIP_RECORD);
      setFilterLoading(false);
    },
    onError: () => {
      setFilterLoading(false);
    },
  });

  useEffect(() => {
    const permitted = checkPermissions(permissions, ['FET_PROSPECT_LIST']);
    const whereFilter = location?.state;
    let parsedObject;

    if (!isEmpty(whereFilter)) {
      parsedObject = JSON?.parse(whereFilter?.stringifyObject);
    }

    if (permitted) {
      fetchProspectData({
        variables: {
          filter: parsedObject?.prospectFilter ?? prospectFilter,
          ...(parsedObject && {
            where: {
              ...parsedObject?.filters,
              ...(parsedObject?.filters?.city && {
                city: map(parsedObject?.filters?.city, (item) => ({
                  city: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[0],
                  state: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[1],
                })),
              }),
            },
          }),
        },
      });
      if (!isEmpty(parsedObject)) {
        setFilters({ ...parsedObject?.filters });
        setFiltersCopyState({
          ...parsedObject?.filters,
        });

        setSearchValue(parsedObject?.prospectFilter?.search);
        const sorter = {
          order:
            parsedObject?.prospectFilter?.sortBy === 'ASC'
              ? 'ascend'
              : 'descend',
          columnKey: parsedObject?.prospectFilter?.sortOn,
        };
        setSortedInfo({ ...sorter });
        setProspectFilter({ ...parsedObject?.prospectFilter });
        setPaginationProp({ ...parsedObject?.paginationProp });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    window?.addEventListener('beforeunload', () => {
      // eslint-disable-next-line no-undef
      if (window?.location?.pathname === ROUTES?.PROSPECTS)
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
    });
    return () => {
      // eslint-disable-next-line no-undef
      window?.removeEventListener('beforeunload', () => {
        // eslint-disable-next-line no-undef
        window?.history?.pushState({}, '', window?.location?.pathname);
      });
    };
  }, []);

  const getFilterData = (confirm) => {
    fetchProspectData({
      variables: {
        filter: { ...prospectFilter, skip: 0 },
        ...(filtersCopyState && {
          where: {
            ...filtersCopyState,
            ...(filtersCopyState?.city && {
              city: map(filtersCopyState?.city, (item) => ({
                city: item
                  ?.replace(' ', ',')
                  ?.replace('(', '')
                  ?.replace(')', '')
                  ?.split(',')?.[0],
                state: item
                  ?.replace(' ', ',')
                  ?.replace('(', '')
                  ?.replace(')', '')
                  ?.split(',')?.[1],
              })),
            }),
          },
        }),
      },
    });
    setFilters(filtersCopyState);
    setProspectFilter({
      ...prospectFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      switch (filterIndex) {
        case 'country':
          setFilterList(['USA']);
          setFilterLoading(false);
          break;
        case 'state':
          fetchStateAndCity({
            variables: {
              filter: {
                skip: 0,
                limit: 20,
                type: 'STATE',
                search: '',
                sortOn: 'name',
                sortBy: 'ASC',
              },
            },
          });
          break;
        case 'city':
          fetchStateAndCity({
            variables: {
              filter: {
                skip: 0,
                limit: 20,
                type: 'CITY',
                search: '',
                sortOn: 'name',
                sortBy: 'ASC',
              },
            },
          });
          break;
        case 'industry':
          industryFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
                getDBField: 'label',
              },
            },
          });
          break;

        case 'lineOfBusiness':
          lineOfBusinessFilters({
            variables: {
              filter: {
                sortOn: 'label',
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                justShow: true,
                getDBField: 'label',
              },
            },
          });
          break;
        default:
          prospectFilters({
            variables: {
              filter: {
                sortOn: filterIndex,
                sortBy: 'ASC',
                skip: 0,
                limit: 20,
                search: filterSearch,
                distinct: true,
                getDBField: filterIndex,
              },
            },
          });
          break;
      }
    }
    if (!filterVisible) {
      setFilterSearch('');
      setFilterIsEnd(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVisible]);

  const handleShowImportModal = (visible) => {
    setShowImportModal(visible);
  };

  const onScroll = (event, dataIndex) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};

    if (scrollDebounce) {
      scrollDebounce?.cancel();
      scrollDebounce = null;
    }
    scrollDebounce = debounce(() => {
      const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
      if (
        scrolledToBottom &&
        filterIndex === dataIndex &&
        filterVisible &&
        !filterIsEnd
      ) {
        setScrollFlag(true);

        switch (dataIndex) {
          case 'country':
            setFilterList(['USA']);
            setFilterLoading(false);
            break;
          case 'state':
            fetchStateAndCity({
              variables: {
                filter: {
                  skip: filterList?.length,
                  limit: 20,
                  type: 'STATE',
                  search: filterSearch,
                  sortOn: 'name',
                  sortBy: 'ASC',
                },
              },
            });
            break;
          case 'city':
            fetchStateAndCity({
              variables: {
                filter: {
                  skip: filterList?.length,
                  limit: 20,
                  type: 'CITY',
                  search: filterSearch,
                  sortOn: 'name',
                  sortBy: 'ASC',
                },
              },
            });
            break;
          case 'industry':
            industryFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  justShow: true,
                  getDBField: 'label',
                },
              },
            });
            break;
          case 'lineOfBusiness':
            lineOfBusinessFilters({
              variables: {
                filter: {
                  sortOn: 'label',
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  justShow: true,
                  getDBField: 'label',
                },
              },
            });
            break;
          default:
            prospectFilters({
              variables: {
                filter: {
                  sortOn: filterIndex,
                  sortBy: 'ASC',
                  skip: filterList?.length,
                  limit: 20,
                  search: filterSearch,
                  distinct: true,
                  getDBField: filterIndex,
                },
              },
            });
            break;
        }
      }
    }, 500);
    scrollDebounce();
  };

  const rowSelection = {
    fixed: 'left',
    columnWidth: 50,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys) => {
      setSelectedKeys(selectedRowKeys);
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id,
      });
    },
  };

  const handleAddEditProspect = (record) => {
    const otherFilters = { prospectFilter, paginationProp, filters };
    const stringifyObject = JSON?.stringify(otherFilters);

    if (record?.id) {
      navigate(`${ROUTES?.PROSPECTS}/edit/${record?.id}`, {
        state: { stringifyObject },
      });
    } else {
      navigate(`${ROUTES?.PROSPECTS}/add`, { state: { stringifyObject } });
    }
  };

  const importCallback = () => {
    setPaginationProp(initialPaginationValue);
    setProspectFilter(initialProspectFilter);
    setSortedInfo({});
    fetchProspectData({
      variables: { filter: initialProspectFilter },
    });
  };

  const changeFilter = (e, dataIndex) => {
    const {
      target: { value = '' },
    } = e;
    let filtersCopy = [];
    if (filtersCopyState?.[dataIndex]?.includes(value)) {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filter(
          filtersCopyState?.[dataIndex],
          (item) => item !== value,
        ),
      };
    } else {
      filtersCopy = {
        ...filtersCopyState,
        [dataIndex]: filtersCopyState?.[dataIndex]
          ? [...filtersCopyState?.[dataIndex], value]
          : [value],
      };
    }
    setFiltersCopyState(filtersCopy);
  };

  const handleTableChange = (pagination, _, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setProspectFilter({
        ...prospectFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      fetchProspectData({
        variables: {
          filter: {
            ...prospectFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
          ...(filters && {
            where: {
              ...filters,
              ...(filters?.city && {
                city: map(filters?.city, (item) => ({
                  city: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[0],
                  state: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[1],
                })),
              }),
            },
          }),
        },
      });
    } else {
      setProspectFilter({
        ...prospectFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      fetchProspectData({
        variables: {
          filter: {
            ...prospectFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
          ...(filters && {
            where: {
              ...filters,
              ...(filters?.city && {
                city: map(filters?.city, (item) => ({
                  city: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[0],
                  state: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[1],
                })),
              }),
            },
          }),
        },
      });
    }
  };

  const handleSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setScrollFlag(false);
    switch (dataIndex) {
      case 'country':
        setFilterList(['USA']);
        setFilterLoading(false);
        break;
      case 'state':
        fetchStateAndCity({
          variables: {
            filter: {
              skip: 0,
              limit: 20,
              type: 'STATE',
              search: value,
              sortOn: 'name',
              sortBy: 'ASC',
            },
          },
        });
        break;
      case 'city':
        fetchStateAndCity({
          variables: {
            filter: {
              skip: 0,
              limit: 20,
              type: 'CITY',
              search: value,
              sortOn: 'name',
              sortBy: 'ASC',
            },
          },
        });
        break;
      case 'industry':
        industryFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              justShow: true,
              getDBField: 'label',
            },
          },
        });
        break;

      case 'lineOfBusiness':
        lineOfBusinessFilters({
          variables: {
            filter: {
              sortOn: 'label',
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              justShow: true,
              getDBField: 'label',
            },
          },
        });
        break;
      default:
        prospectFilters({
          variables: {
            filter: {
              sortOn: dataIndex,
              sortBy: 'ASC',
              skip: 0,
              limit: 20,
              search: value,
              distinct: true,
              getDBField: dataIndex,
            },
          },
        });
        break;
    }
  };

  const handleDeleteFilter = (value, dataIndex) => {
    const filtersCopy = {
      ...filtersCopyState,
      [dataIndex]: filter(
        filtersCopyState?.[dataIndex],
        (item) => item !== value,
      ),
    };
    setFiltersCopyState(filtersCopy);
  };

  const handleReset = (clearFilters, dataIndex) => {
    let filtersCopy = {
      ...filters,
    };
    if (dataIndex === 'createdAt') {
      delete filtersCopy?.createdAt;
    } else {
      filtersCopy = {
        ...filters,
        [dataIndex]: [],
      };
    }

    setFilters(filtersCopy);
    setFiltersCopyState(filtersCopy);
    fetchProspectData({
      variables: {
        filter: {
          ...prospectFilter,
          skip: 0,
          sortOn: 'createdAt',
          sortBy: 'DESC',
        },
        ...(filtersCopy && {
          where: {
            ...filtersCopy,
            ...(filtersCopy?.city && {
              city: map(filtersCopy?.city, (item) => ({
                city: item
                  ?.replace(' ', ',')
                  ?.replace('(', '')
                  ?.replace(')', '')
                  ?.split(',')?.[0],
                state: item
                  ?.replace(' ', ',')
                  ?.replace('(', '')
                  ?.replace(')', '')
                  ?.split(',')?.[1],
              })),
            }),
          },
        }),
      },
    });
    setProspectFilter({
      ...prospectFilter,
      skip: 0,
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterIndex(dataIndex);
    setFilterVisible(false);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => (
      <div className="custom-filter-dropdown">
        <LoaderComponent spinning={filterLoading} setHeight={35}>
          <SearchComponent
            className="list-search-box filter-search"
            id="search-container-id-prospects"
            placeholder="Search..."
            name={dataIndex}
            getData={(value) => handleSearch(value, dataIndex)}
          />
          {filtersCopyState?.[dataIndex]?.length > 0 && (
            <div className="filter-section">
              {map(filtersCopyState?.[dataIndex], (item) => (
                <Tag
                  key={item?.toString()}
                  closable
                  onClose={() => handleDeleteFilter(item, dataIndex)}
                  className="filter-tag"
                >
                  <>
                    <span
                      title={
                        dataIndex === 'status'
                          ? PROSPECT_STATUS_OBJ?.[item?.toString()]?.label
                          : item?.label || item?.toString()
                      }
                    >
                      {dataIndex === 'status'
                        ? PROSPECT_STATUS_OBJ?.[item?.toString()]?.label
                        : item?.label || item?.toString()}
                    </span>
                  </>
                </Tag>
              ))}
            </div>
          )}
          <div
            className="filter-checkboxes"
            onScroll={(e) => onScroll(e, dataIndex)}
          >
            {filterList?.length > 0 ? (
              map(filterList, (item) => (
                <div
                  className="filter-checkbox-section"
                  key={item?.key || item}
                >
                  <Checkbox
                    value={item?.key || item}
                    checked={filtersCopyState?.[dataIndex]?.includes(
                      item?.key || item,
                    )}
                    key={item?.key || item}
                    onChange={(e) => changeFilter(e, dataIndex)}
                    className="common-checkbox"
                  >
                    <>
                      <span title={item?.label || item?.toString()}>
                        {item?.label || item?.toString()}
                      </span>
                    </>
                  </Checkbox>
                </div>
              ))
            ) : (
              <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </LoaderComponent>
        <Divider className="divider-filter" />
        <div className="d-flex justify-center">
          <Button
            size="small"
            className="common-button discard-button filter-button"
            id="roles-filter-reset"
            onClick={() => handleReset(clearFilters, dataIndex)}
          >
            Reset
          </Button>
          <Button
            size="small"
            className="common-button filter-button"
            id="roles-filter-ok"
            type="primary"
            onClick={() => getFilterData(confirm, dataIndex)}
          >
            Ok
          </Button>
        </div>
      </div>
    ),
    filterIcon: () =>
      filters?.[dataIndex]?.length > 0 ? (
        <FilterSelectedIconComponent className="primary-color" />
      ) : (
        <img src={FilterIcon} alt="filter-icon" width={16} />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
      if (visible) {
        setFiltersCopyState(filters);
      }
    },
  });

  const handleDeleteProspect = async (record) => {
    const response = await deleteProspect({
      variables: { where: { id: record?.id } },
    });
    if (response?.data?.deleteProspect) {
      fetchProspectData({
        variables: {
          filter: prospectFilter,
          ...(filters && {
            where: {
              ...filters,
              ...(filters?.city && {
                city: map(filters?.city, (item) => ({
                  city: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[0],
                  state: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[1],
                })),
              }),
            },
          }),
        },
      });
    }
  };

  const handleCancelProspect = async (record) => {
    const response = await cancelProspectInvitation({
      variables: { where: { id: record?.id } },
    });
    if (response?.data?.cancelProspectInvitation) {
      fetchProspectData({
        variables: {
          filter: prospectFilter,
          ...(filters && {
            where: {
              ...filters,
              ...(filters?.city && {
                city: map(filters?.city, (item) => ({
                  city: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[0],
                  state: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[1],
                })),
              }),
            },
          }),
        },
      });
    }
  };

  const handleViewProspect = (record) => {
    const otherFilters = { prospectFilter, paginationProp, filters };
    const stringifyObject = JSON?.stringify(otherFilters);
    navigate(`${ROUTES?.PROSPECTS}/view/${record?.id}`, {
      state: { stringifyObject },
    });
  };

  const handleResendInviteProspect = async (record) => {
    const response = await resendProspectInvitation({
      variables: { where: { id: record?.id } },
    });
    if (response?.data?.resendProspectInvitation) {
      fetchProspectData({
        variables: {
          filter: prospectFilter,
          ...(filters && {
            where: {
              ...filters,
              ...(filters?.city && {
                city: map(filters?.city, (item) => ({
                  city: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[0],
                  state: item
                    ?.replace(' ', ',')
                    ?.replace('(', '')
                    ?.replace(')', '')
                    ?.split(',')?.[1],
                })),
              }),
            },
          }),
        },
      });
    }
  };

  const renderActionButtons = (record) => (
    <div className="d-flex flex-vertical">
      <AccessControl allowedPermissions={['FET_PROSPECT_VIEW']}>
        <Button
          id="prospect-table-view-btn"
          className="b-0"
          onClick={() => {
            handleViewProspect(record);
          }}
        >
          View
        </Button>
      </AccessControl>
      {record?.status === 'INVITED' && (
        <>
          <AccessControl allowedPermissions={['FET_PROSPECT_UPDATE']}>
            <Popconfirm
              title="Are you sure to cancel?"
              onConfirm={() => handleCancelProspect(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button id="prospect-table-cancel-btn" className="b-0">
                Cancel
              </Button>
            </Popconfirm>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_PROSPECT_DELETE']}>
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => handleDeleteProspect(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button id="prospect-table-delete-btn" className="b-0">
                Delete
              </Button>
            </Popconfirm>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_PROSPECT_UPDATE']}>
            <Popconfirm
              title="Are you sure to resend invite?"
              onConfirm={() => handleResendInviteProspect(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button id="prospect-table-resend-btn" className="b-0">
                Resend Invite
              </Button>
            </Popconfirm>
          </AccessControl>
        </>
      )}
    </div>
  );
  const columns = [
    {
      title: 'BUSINESS NAME',
      ellipsis: true,
      width: 150,
      dataIndex: 'businessName',
      key: 'businessName',
      className: 'max-width-column',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'businessName' && sortedInfo?.order,
    },
    {
      title: 'CONTACT',
      width: 130,
      ellipsis: true,
      dataIndex: 'contactName',
      className: 'max-width-column',
      key: 'contactName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'contactName' && sortedInfo?.order,
    },
    {
      title: 'CONTACT NUMBER',
      width: 180,
      ellipsis: true,
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      render: (phoneNo) => formatPhoneNumber(phoneNo) || '-',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order,
    },
    {
      title: 'EMAIL',
      width: 250,
      ellipsis: true,
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'industry',
      key: 'industry',
      ellipsis: true,
      // sorter not added from backend as industry and lob are combined
      width: 190,
      ...filterPopup('industry'),
      render: (_, record = {}) => join(map(record?.industries, 'label'), ', '),
    },
    {
      title: 'LINE OF BUSINESS',
      dataIndex: 'lineOfBusiness',
      key: 'lineOfBusiness',
      ellipsis: true,
      // sorter not added from backend as industry and lob are combined
      width: 190,
      ...filterPopup('lineOfBusiness'),
      render: (_, record = {}) =>
        chain(record?.industries)
          .map(
            (industry) =>
              `${industry?.label} - ${map(
                industry?.lineOfBusinesses,
                'label',
              ).join(', ')}`,
          )
          .join(' | ')
          .value(),
    },
    {
      title: 'CITY',
      width: 120,
      ellipsis: true,
      dataIndex: 'city',
      key: 'city',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'city' && sortedInfo?.order,
      ...filterPopup('city'),
      render: (_, record = {}) => {
        if (record?.address?.city) {
          return (
            <span title={record?.address?.city}>{record?.address?.city}</span>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: 'STATE',
      width: 120,
      ellipsis: true,
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'state' && sortedInfo?.order,
      ...filterPopup('state'),
      render: (_, record = {}) => {
        if (record?.address?.state) {
          return (
            <span title={record?.address?.state}>{record?.address?.state}</span>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: 'COUNTRY',
      width: 130,
      ellipsis: true,
      dataIndex: 'country',
      key: 'country',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'country' && sortedInfo?.order,
      ...filterPopup('country'),
      render: (country, record = {}) => {
        if (record?.address?.country) {
          return (
            <span title={record?.address?.country}>
              {record?.address?.country}
            </span>
          );
        }
        return <span>-</span>;
      },
    },
    {
      title: 'REGIONS',
      width: 120,
      ellipsis: true,
      dataIndex: 'region',
      className: 'max-width-column',
      key: 'region',
      ...filterPopup('region'),
      render: (region) => region || '-',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'region' && sortedInfo?.order,
    },
    {
      title: 'INVITATION STATUS',
      width: 130,
      dataIndex: 'status',
      key: 'status',
      ...filterPopup('status'),
      sorter: true,
      fixed: 'right',
      sortOrder: sortedInfo?.columnKey === 'status' && sortedInfo?.order,
      render: (status) => {
        const statusObj = PROSPECT_STATUS_OBJ?.[status];
        if (statusObj) {
          return statusObj?.label;
        }
      },
    },
    checkPermissions(permissions, [
      'FET_PROSPECT_UPDATE',
      'FET_PROSPECT_DELETE',
      'FET_PROSPECT_VIEW',
    ]) && {
      dataIndex: 'id',
      align: 'right',
      width: 10,
      fixed: 'right',
      render: (id, record) => (
        <Popover
          placement="bottom"
          overlayClassName="action-button"
          content={renderActionButtons(record)}
        >
          <MoreOutlined />
        </Popover>
      ),
    },
  ];

  const onSearchChange = (value) => {
    setProspectFilter({
      ...prospectFilter,
      skip: value ? 0 : prospectFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    fetchProspectData({
      variables: {
        filter: {
          ...prospectFilter,
          skip: value
            ? 0
            : prospectFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
        ...(filters && {
          where: {
            ...filters,
            ...(filters?.city && {
              city: map(filters?.city, (item) => ({
                city: item
                  ?.replace(' ', ',')
                  ?.replace('(', '')
                  ?.replace(')', '')
                  ?.split(',')?.[0],
                state: item
                  ?.replace(' ', ',')
                  ?.replace('(', '')
                  ?.replace(')', '')
                  ?.split(',')?.[1],
              })),
            }),
          },
        }),
      },
    });
  };

  const handleExport = async (e) => {
    const { key } = e;
    setExportLoading(true);
    await handleExportCommon({
      filters,
      key,
      selectedKeys,
      module: 'PROSPECT',
    });
    setExportLoading(false);
  };

  return (
    <div>
      <ImportModal
        showImportModal={showImportModal}
        setShowImportModal={setShowImportModal}
        callback={importCallback}
        module="prospects"
        folder="PROSPECT"
      />
      <AccessControl allowedPermissions={['FET_PROSPECT_CREATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<PlusOutlined />}
            size="small"
            id="prospect-table-add-btn"
            type="primary"
            onClick={handleAddEditProspect}
          >
            Add Prospect
          </Button>
        </Portal>
      </AccessControl>

      <div className="mb-16 d-flex justify-between align-center">
        <div className="fill-width search-checkbox">
          <AccessControl allowedPermissions={['FET_PROSPECT_LIST']}>
            <SearchComponent
              className="list-search-box"
              id="search-container-id"
              placeholder="Business name or other detail.."
              name="Prospects"
              getData={onSearchChange}
              value={searchValue}
              setSearchValue={setSearchValue}
            />
          </AccessControl>
        </div>
        <div className="header-buttons">
          <AccessControl allowedPermissions={['FET_PROSPECT_IMPORT']}>
            <Button
              size="small"
              className="common-button import-button"
              icon={<img src={ImportIcon} alt="import-icon" width={11} />}
              id="prospect-table-import-btn"
              type="primary"
              onClick={() => handleShowImportModal(true)}
            >
              Import
            </Button>
          </AccessControl>
          <AccessControl allowedPermissions={['FET_PROSPECT_EXPORT']}>
            <Dropdown
              overlayClassName="export-btn-dropdown"
              menu={{ items: exportOptions, onClick: handleExport }}
              placement="bottom"
            >
              <Button
                className="common-button export-button"
                size="small"
                icon={<img src={ExportIcon} alt="export-icon" width={11} />}
                id="prospect-table-export-btn"
                loading={exportLoading}
                type="primary"
              >
                Export
              </Button>
            </Dropdown>
          </AccessControl>
        </div>
      </div>
      <AccessControl allowedPermissions={['FET_PROSPECT_LIST']} showNoAccess>
        <div className="common-table">
          {pageSize && (
            <TableComponent
              loadingData={
                loading ||
                cancelProspectInvitationLoading ||
                resendProspectInvitationLoading ||
                deleteProspectLoading
              }
              columns={[...columns?.filter((item) => item !== false)]}
              data={data?.prospects?.data || []}
              onChange={handleTableChange}
              paginationConfig={paginationProp}
              rowSelection={rowSelection}
              rowKey={(obj) => obj?.id}
            />
          )}
        </div>
      </AccessControl>
    </div>
  );
};

export default ProspectTable;
