import { gql } from '@apollo/client';

export const GET_PRODUCTS = gql`
  query products($filter: ProductFilter!, $where: ProductsWhereFilter) {
    products(filter: $filter, where: $where) {
      count
      data {
        id
        uuid
        productCode
        refData
        title
        productType
        productQuality
        productIndustry {
          id
          label
          key
          isActive
        }
        productLob {
          id
          label
          key
          lobCode
          isActive
        }
        productSubArea {
          id
          label
          key
          saCode
          isActive
        }
        price
        sku
        sellingPrice
        industryId
        lineOfBusinessId
        subAreaId
        isActive
        brands
        regions
        description
        startData
        endDate
        tenantId
        industryId
        sponsorName
      }
    }
  }
`;

export const GET_PRODUCT = gql`
  query product($id: ID!) {
    product(where: { id: $id }) {
      id
      uuid
      productCode
      refData
      title
      productType
      productQuality
      productIndustry {
        id
        label
        key
        isActive
      }
      productLob {
        id
        label
        key
        isActive
      }
      productSubArea {
        id
        label
        key
        isActive
      }
      productBrands {
        id
        name
        sponsorName
      }
      productRegions {
        id
        name
        subRegions {
          id
          name
        }
      }
      price
      costPerDay
      sku
      sellingPrice
      industryId
      lineOfBusinessId
      subAreaId
      isActive
      brands
      regions
      description
      startData
      endDate
      tenantId
      score
      sponsorName
    }
  }
`;

export const PRODUCT_FILTER = gql`
  query productFilters($filter: ProductFilter!) {
    productFilters(filter: $filter) {
      count
      data {
        productCode
        refData
        title
        productType
        productQuality
        price
        sku
        sellingPrice
        lineOfBusinessId
        subAreaId
        isActive
        brands
        regions
        description
        startData
        endDate
        tenantId
        sponsorName
      }
    }
  }
`;

export const BRAND = gql`
  query brands($filter: BrandFilter!, $where: BrandsWhereFilter) {
    brands(filter: $filter, where: $where) {
      count
      data {
        id
        name
        isActive
        sponsorName
      }
    }
  }
`;

export const REGION = gql`
  query regions($filter: RegionFilter!, $where: RegionsWhereFilter) {
    regions(filter: $filter, where: $where) {
      count
      data {
        id
        name
        isActive
        parentId
      }
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        label
        alias
        industryCode
        refData
        key
        tenantId
        description
        isActive
        referenceId
        id
      }
    }
  }
`;

export const LINE_OF_BUSINESS = gql`
  query lineOfBusinesses(
    $filter: LineOfBusinessFilter!
    $where: LineOfBusinessWhereFilter
  ) {
    lineOfBusinesses(filter: $filter, where: $where) {
      count
      data {
        id
        label
        isActive
        key
      }
    }
  }
`;

export const SUB_AREA = gql`
  query subAreas($filter: SubAreaFilter!, $where: SubAreasWhereFilter) {
    subAreas(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        isActive
      }
    }
  }
`;

export const INDUSTRY_FILTER = gql`
  query industryFilters($filter: IndustryFilter!) {
    industryFilters(filter: $filter) {
      count
      data {
        label
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const LINE_OF_BUSINESS_FILTER = gql`
  query lineOfBusinessFilters($filter: LineOfBusinessFilter!) {
    lineOfBusinessFilters(filter: $filter) {
      count
      data {
        label
        lobCode
        key
        description
        isActive
        tenantId
        referenceId
      }
    }
  }
`;

export const SUB_AREA_FILTER = gql`
  query subAreaFilters($filter: SubAreaFilter!) {
    subAreaFilters(filter: $filter) {
      count
      data {
        label
        saCode
        key
        description
        estimation
        isActive
        waiverText
        priceGuaranteeText
        offerText
        lineOfBusinessId
        referenceId
      }
    }
  }
`;

export const LIST_PRODUCTS_IN_PACKAGE = gql`
  query listProductsInPackage(
    $filter: ProductItemFilter!
    $where: ProductItemsWhereFilter!
  ) {
    listProductsInPackage(filter: $filter, where: $where) {
      count
      data {
        id
        sku
        uuid
        name
        isActive
        stock
        manufacturer {
          id
          name
        }
        manufacturerId
        industry {
          id
          label
        }
        industryId
        lineOfBusiness {
          id
          label
        }
        lineOfBusinessId
        subArea {
          id
          label
        }
        unitForMeasureObj {
          id
          title
        }
        unitInputParameterObj {
          id
          name
        }
        subAreaId
        costPrice
        defaultPrice
      }
    }
  }
`;

export const GET_SPONSOR_FILTER = gql`
  query sponsorFilters($filter: SponsorFilter!) {
    sponsorFilters(filter: $filter) {
      count
      data {
        firstName
        lastName
        businessName
        userId
      }
    }
  }
`;
