import { gql } from '@apollo/client';

export const GET_TENANT_BRAND = gql`
  query getUserNextStep {
    fetchTenantBrand {
      id
      isActive
      name
      uuid
      primaryColor
      logo {
        url
        key
        name
        extension
        contentType
      }
      secondaryColors
      tertiaryColors
      colorPallete
      description
      waiverText
      priceGuarantee
      offerText
      productDetails
    }
  }
`;

export const GET_TENANT_BRAND_INDUSTRY = gql`
  query fetchTenantBrandIndustry {
    fetchTenantBrandIndustry {
      id
      brandId
      lineOfBusinessId
      subAreaIds
      tenantId
      createdAt
      updatedAt
    }
  }
`;

export const GET_INDUSTRIES = gql`
  query industries($filter: IndustryFilter!, $where: IndustriesWhereFilter) {
    industries(filter: $filter, where: $where) {
      count
      data {
        id
        label
        key
        lobSaData {
          id
          label
          key
          subAreas {
            id
            label
            key
          }
        }
      }
    }
  }
`;
