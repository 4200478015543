import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_PRODUCT, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import ProductCreate from './pages/ProductCreate';
import ProductDocument from './pages/ProductDocument';
import ProductEdit from './pages/ProductEdit';
import ProductGallery from './pages/ProductGallery';
import ProductItem from './pages/ProductItem';
import ProductList from './pages/ProductList';
import ProductView from './pages/ProductView';
import './productModule.less';

const ProductWrapper = () => (
  <div className="product-module">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl allowedPermissions={PERMISSION_PRODUCT} showNoAccess>
            <ProductList />
          </AccessControl>
        }
      />
      <Route
        path="/add"
        element={
          <AccessControl
            allowedPermissions={['FET_PRODUCT_CREATE']}
            showNoAccess
          >
            <ProductCreate />
          </AccessControl>
        }
      />
      <Route
        path="/edit/:id"
        element={
          <AccessControl
            allowedPermissions={['FET_PRODUCT_UPDATE', 'FET_PRODUCT_VIEW']}
            showNoAccess
          >
            <ProductEdit />
          </AccessControl>
        }
      />
      <Route
        path="/view/:id"
        element={
          <AccessControl allowedPermissions={['FET_PRODUCT_VIEW']} showNoAccess>
            <ProductView />
          </AccessControl>
        }
      />
      <Route
        path={`/view${ROUTES?.PRODUCTS}/:id`}
        element={
          <AccessControl allowedPermissions={['FET_PRODUCT_VIEW']} showNoAccess>
            <ProductItem />
          </AccessControl>
        }
      />
      <Route
        path={`/view${ROUTES?.GALLERY}/:id`}
        element={
          <AccessControl allowedPermissions={['FET_PRODUCT_VIEW']} showNoAccess>
            <ProductGallery />
          </AccessControl>
        }
      />
      <Route
        path={`/view${ROUTES?.DOCUMENTS}/:id`}
        element={
          <AccessControl allowedPermissions={['FET_PRODUCT_VIEW']} showNoAccess>
            <ProductDocument />
          </AccessControl>
        }
      />
      <Route
        path={`/edit${ROUTES?.PRODUCTS}/:id`}
        element={
          <AccessControl
            allowedPermissions={['FET_PRODUCT_UPDATE', 'FET_PRODUCT_VIEW']}
            showNoAccess
          >
            <ProductItem />
          </AccessControl>
        }
      />
      <Route
        path={`/edit${ROUTES?.GALLERY}/:id`}
        element={
          <AccessControl
            allowedPermissions={['FET_PRODUCT_UPDATE', 'FET_PRODUCT_VIEW']}
            showNoAccess
          >
            <ProductGallery />
          </AccessControl>
        }
      />
      <Route
        path={`/edit${ROUTES?.DOCUMENTS}/:id`}
        element={
          <AccessControl
            allowedPermissions={['FET_PRODUCT_UPDATE', 'FET_PRODUCT_VIEW']}
            showNoAccess
          >
            <ProductDocument />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default ProductWrapper;
