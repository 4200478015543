import {
  CheckOutlined,
  CloseOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Col, Form, Popover, Radio, Row, Switch, TimePicker } from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {
  DYNAMIC_DATE_ALLOW_TYPE,
  DYNAMIC_DATE_DEFAULT_VALUE,
  DYNAMIC_DATE_TIME_TYPE,
  DYNAMIC_DATE_TYPE,
  REGEX,
} from '../../../../../../../../common/constants';
import { formValidatorRules } from '../../../../../../../../common/utils';
import InputComponent from '../../../../../../../../components/InputComponent';
import NumberComponent from '../../../../../../../../components/NumberComponent';
import SelectComponent from '../../../../../../../../components/SelectComponent';
import { GET_DYNAMIC_FIELD_TIME_FORMATS } from '../../../../../../../dynamicFields/graphql/Queries';

const { requiredWhiteSpaceAllowed } = formValidatorRules;
const { RangePicker } = TimePicker;

const CustomDateTimeForm = ({ form }) => {
  const [dateFormats, setDateFormats] = useState(null);
  const [filteredDateFormats, setFilteredDateFormats] = useState([]);
  const dateTimeType = Form?.useWatch(
    ['widgetConfiguration', 'config', 'dateTimeType'],
    form,
  );
  const dateType = Form?.useWatch(
    ['widgetConfiguration', 'config', 'dateConfig'],
    form,
  );
  const format = Form?.useWatch(
    ['widgetConfiguration', 'config', 'format'],
    form,
  );

  const showDescription = Form?.useWatch(
    ['widgetConfiguration', 'config', 'description'],
    form,
  );

  const showTooltip = Form?.useWatch(
    ['widgetConfiguration', 'config', 'tooltip'],
    form,
  );

  const date = dateTimeType?.DATE;
  const time = dateTimeType?.TIME;

  const [dynamicFieldTimeFormats] = useLazyQuery(
    GET_DYNAMIC_FIELD_TIME_FORMATS,
    {
      fetchPolicy: 'network-only',
      onCompleted(res) {
        const dateFormatsCopy = res?.dynamicFieldTimeFormats?.data;
        setDateFormats(dateFormatsCopy);
        if (date && time) {
          setFilteredDateFormats(dateFormatsCopy?.dateTimeFormats);
        } else if (date) {
          setFilteredDateFormats(dateFormatsCopy?.dateFormats);
        } else if (time) {
          setFilteredDateFormats(dateFormatsCopy?.timeFormats);
        } else {
          setFilteredDateFormats([]);
        }
      },
      onError() {
        setFilteredDateFormats([]);
        setDateFormats(null);
      },
    },
  );

  useEffect(() => {
    dynamicFieldTimeFormats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateTimeChange = () => {
    const dateFormatsCopy = { ...dateFormats };
    if (date && time) {
      setFilteredDateFormats(dateFormatsCopy?.dateTimeFormats);
    } else if (date) {
      setFilteredDateFormats(dateFormatsCopy?.dateFormats);
    } else if (time) {
      setFilteredDateFormats(dateFormatsCopy?.timeFormats);
    } else {
      setFilteredDateFormats([]);
    }
  };

  const handleTypeChange = () => {
    if (format) {
      form?.setFieldsValue({
        widgetConfiguration: {
          config: {
            format: null,
          },
        },
      });
    }
  };

  useEffect(() => {
    handleDateTimeChange();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTimeType]);

  return (
    <div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'label']}
        label="Label"
        rules={[{ required: true, message: 'Please Enter Label' }]}
      >
        <InputComponent name="label" />
      </Form.Item>
      {map(DYNAMIC_DATE_TIME_TYPE, (item) => (
          <div className="d-flex justify-between align-center" key={item?.key}>
            <span className="primary-color">{item?.label}</span>
            <Form.Item
              key={item?.key}
              name={[
                'widgetConfiguration',
                'config',
                'dateTimeType',
                `${item?.key}`,
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={handleTypeChange}
              />
            </Form.Item>
          </div>
        ))}
      <Form.Item
        name={['widgetConfiguration', 'config', 'format']}
        label="Format"
        className="mb-8"
        rules={[
          {
            ...requiredWhiteSpaceAllowed,
            message: 'Please Select Date/Time Format',
          },
        ]}
      >
        <SelectComponent placeholder="Select Date/Time Format">
          {map(filteredDateFormats, (item) => (
            <SelectComponent.Option key={item} value={item}>
              {item}
            </SelectComponent.Option>
          ))}
        </SelectComponent>
      </Form.Item>
      {format && (
        <div className="mb-10">
          <span className="thin-label">
            Preview: {moment()?.format(format)}
          </span>
        </div>
      )}
      <Row>
        <Form.Item name={['widgetConfiguration', 'config', 'dateConfig']}>
          <Radio.Group className="common-radio">
            {map(DYNAMIC_DATE_TYPE, (item) => (
              <Col span={24} key={item?.key}>
                <Radio key={item?.key} value={item?.key}>
                  {item?.label}
                </Radio>
              </Col>
            ))}
          </Radio.Group>
        </Form.Item>
      </Row>
      <Row hidden={!(date && dateType === 'EXACT' && !time)}>
        <Form.Item
          name={['widgetConfiguration', 'config', 'dateDefaultValue']}
          label="Default"
          hidden={!(date && dateType === 'EXACT' && !time)}
        >
          <Radio.Group className="common-radio">
            {map(DYNAMIC_DATE_DEFAULT_VALUE, (item) => (
              <Col key={item?.key}>
                <Radio key={item?.key} value={item?.key}>
                  {item?.label}
                </Radio>
              </Col>
            ))}
          </Radio.Group>
        </Form.Item>
      </Row>
      <div className="d-flex justify-between align-center">
        <span
          className="primary-color"
          hidden={!(time && dateType === 'EXACT' && !date)}
        >
          Default to system time
          <Popover className="pointer" content="This is sample tooltip">
            <QuestionCircleOutlined className="ml-4" />
          </Popover>
        </span>
        <span
          className="primary-color"
          hidden={!(time && dateType === 'EXACT' && date)}
        >
          Default
          <Popover className="pointer" content="This is sample tooltip">
            <QuestionCircleOutlined className="ml-4" />
          </Popover>
        </span>
        <Form.Item
          name={['widgetConfiguration', 'config', 'dateTimeBooleanValue']}
          className="mb-0"
          valuePropName="checked"
          hidden={
            !(((!date && time) || (date && time)) && dateType === 'EXACT')
          }
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'rangeDefaultValue']}
        label="Default Day(s)"
        hidden={!(((date && time) || date) && dateType === 'RANGE')}
        rules={
          ((date && time) || date) && dateType === 'RANGE'
            ? [
                {
                  validator(_, value) {
                    if (!REGEX?.NUMBER?.test(value)) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject('Should be a valid Number');
                    }
                    if (Number(value) < 1) {
                      return Promise?.reject(new Error('Enter at least 1 Day'));
                    }
                    return Promise?.resolve();
                  },
                },
              ]
            : []
        }
      >
        <NumberComponent
          min={1}
          allowClear
          placeholder="Enter Default Day(s)"
        />
      </Form.Item>
      <Form.Item
        name={['widgetConfiguration', 'config', 'timeDefaultValue']}
        label="Default Time"
        hidden={!(time && !date && dateType === 'RANGE')}
      >
        <RangePicker
          className="common-range-picker fill-width"
          popupClassName="common-range-picker"
          use12Hours
          minuteStep={15}
          format={format || 'HH:mm:ss'}
        />
      </Form.Item>
      {map(DYNAMIC_DATE_ALLOW_TYPE, (item) => (
          <div className="d-flex justify-between align-center" key={item?.key}>
            <span className="primary-color">{item?.label}</span>
            <Form.Item
              key={item?.key}
              name={[
                'widgetConfiguration',
                'config',
                'allowDateTime',
                `${item?.key}`,
              ]}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
        ))}
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Tooltip</span>
        <Form.Item
          name={['widgetConfiguration', 'config', 'tooltip']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'tooltipValue']}
        hidden={!showTooltip}
        rules={[
          {
            required: showTooltip,
            message: 'Please Enter Tooltip Value',
          },
        ]}
      >
        <InputComponent placeholder="Enter Tooltip Value" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Description</span>
        <Form.Item
          name={['widgetConfiguration', 'config', 'description']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'descriptionValue']}
        hidden={!showDescription}
        rules={[
          {
            required: showDescription,
            message: 'Please Enter Description',
          },
        ]}
      >
        <InputComponent.TextArea
          className="common-textarea"
          autoSize={{ minRows: 2, maxRows: 4 }}
          placeholder="Enter Description"
        />
      </Form.Item>
    </div>
  );
};

export default CustomDateTimeForm;
