import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'antd';
import { filter, map, some } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { MODAL_WIDTH } from '../../../common/constants';
import { formatPhoneNumber } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';
import SearchComponent from '../../../components/SearchComponent';
import TableComponent from '../../../components/TableComponent';
import useRouter from '../../../hooks/useRouter';
import { GET_CONTACTS } from '../../contacts/graphql/Queries';
import { CREATE_SALES_JUNCTION } from '../graphql/Mutations';

const PropertyContactModal = ({ showModal = false, setShowModal }) => {
  const { params: { id } = {} } = useRouter();
  const {
    state: { pageSize },
  } = useContext(AppContext);

  const initialPaginationValue = {
    total: 0,
    current: 1,
  };

  const initialContactFilter = {
    skip: 0,
    limit: pageSize,
    sortOn: 'createdAt',
    sortBy: 'DESC',
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [contactFilter, setContactFilter] = useState(initialContactFilter);

  const [createSalesJunction, { loading: salesJunctionLoading }] = useMutation(
    CREATE_SALES_JUNCTION,
    {
      onError() {},
    },
  );

  const [fetchContactData, { loading, data }] = useLazyQuery(GET_CONTACTS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const pagination = {
        ...paginationProp,
        defaultPageSize: pageSize,
        total: res?.contacts?.count,
      };
      setPaginationProp(pagination);
    },
    onError() {},
  });

  useEffect(() => {
    fetchContactData({
      variables: {
        filter: contactFilter,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rowSelection = {
    fixed: 'left',
    columnWidth: 38,
    selectedRowKeys: selectedKeys,
    onSelect: (record) => {
      const isAlready = some(selectedKeys, (item) => item === record?.id);
      if (isAlready) {
        const rowsCopy = filter(selectedKeys, (item) => item !== record?.id);
        setSelectedKeys(rowsCopy);
      } else {
        const rowsCopy = [...selectedKeys];
        rowsCopy?.push(record?.id);
        setSelectedKeys(rowsCopy);
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        const rowsCopy = [
          ...selectedKeys,
          ...map(changeRows, (item) => item?.id),
        ];
        setSelectedKeys(rowsCopy);
      } else {
        const remainingObject = selectedKeys?.filter(
          (elem) => !changeRows?.find(({ id: changeId }) => elem === changeId),
        );
        setSelectedKeys(remainingObject);
      }
    },
    renderCell(checked, record, index, node) {
      return React.cloneElement(node, {
        'aria-label': record?.id,
      });
    },
  };

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination?.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setContactFilter({
        ...contactFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: sorter?.field,
        sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
      });
      fetchContactData({
        variables: {
          filter: {
            ...contactFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: sorter?.field,
            sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          },
        },
      });
    } else {
      setContactFilter({
        ...contactFilter,
        skip,
        limit: pagination?.pageSize,
        sortOn: 'createdAt',
        sortBy: 'DESC',
      });
      fetchContactData({
        variables: {
          filter: {
            ...contactFilter,
            skip,
            limit: pagination?.pageSize,
            sortOn: 'createdAt',
            sortBy: 'DESC',
          },
        },
      });
    }
  };

  const columns = [
    {
      title: 'NAME',
      ellipsis: true,
      width: 130,
      dataIndex: 'firstName',
      key: 'firstName',
      sorter: true,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'firstName' && sortedInfo?.order,
      render: (text, record) =>
        `${record?.userInfo?.firstName} ${record?.userInfo?.lastName}`,
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      ellipsis: true,
      width: 250,
      className: 'max-width-column',
      sortOrder: sortedInfo?.columnKey === 'email' && sortedInfo?.order,
      render: (text, record) => `${record?.userInfo?.email}`,
    },
    {
      title: 'MOBILE',
      dataIndex: 'phoneNo',
      key: 'phoneNo',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order,
      width: 200,
      render: (phoneNo, record) =>
        formatPhoneNumber(record?.userInfo?.phoneNo) || '-',
    },
    {
      title: 'HOME PHONE',
      dataIndex: 'homePhone',
      key: 'homePhone',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'homePhone' && sortedInfo?.order,
      width: 200,
      render: (homePhone) => formatPhoneNumber(homePhone) || '-',
    },
    {
      title: 'MAIN PHONE',
      dataIndex: 'mainPhone',
      key: 'mainPhone',
      sorter: true,
      ellipsis: true,
      sortOrder: sortedInfo?.columnKey === 'mainPhone' && sortedInfo?.order,
      width: 200,
      render: (mainPhone) => formatPhoneNumber(mainPhone) || '-',
    },
    {
      title: 'ADDRESS',
      dataIndex: 'location',
      key: 'location',
      ellipsis: true,
      fixed: 'right',
      render: (location) =>
        `${location?.addressLine1} ${location?.city}, ${location?.state} ${location?.zipCode}`,
    },
  ];

  const onSearchChange = (value) => {
    setContactFilter({
      ...contactFilter,
      skip: value ? 0 : contactFilter?.limit * (paginationProp?.current - 1),
      search: value,
    });
    fetchContactData({
      variables: {
        filter: {
          ...contactFilter,
          skip: value
            ? 0
            : contactFilter?.limit * (paginationProp?.current - 1),
          search: value,
        },
      },
    });
  };

  const handleAddContacts = async () => {
    await createSalesJunction({
      variables: {
        data: {
          salesIds: selectedKeys,
          joinedTo: 'CONTACT',
          joinedFrom: 'PROPERTY',
        },
        where: {
          id,
        },
      },
    });
    setShowModal(false);
  };

  return (
    <ModalComponent
      width={MODAL_WIDTH}
      title="ADD CONTACT"
      open={showModal}
      onCancel={() => setShowModal(false)}
      destroyOnClose
      wrapClassName="add-items-modal"
      footer={
        <div className="d-flex justify-end">
          <Button
            type="primary"
            disabled={!selectedKeys?.length}
            loading={salesJunctionLoading}
            className="common-button add-item-button"
            onClick={handleAddContacts}
          >
            Add
            {selectedKeys?.length > 0 && ` ${selectedKeys?.length} Contact(s)`}
          </Button>
        </div>
      }
    >
      <div className="fill-width search-checkbox">
        <SearchComponent
          className="list-search-box modal-search"
          id="search-container-id"
          placeholder="Search all fields"
          name="Contacts"
          getData={onSearchChange}
        />
      </div>
      <div className="common-table item-table">
        <TableComponent
          loadingData={loading}
          columns={[...columns?.filter((item) => item !== false)]}
          data={data?.contacts?.data || []}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowSelection={rowSelection}
          rowKey={(obj) => obj?.id}
        />
      </div>
    </ModalComponent>
  );
};

export default PropertyContactModal;
