import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import {
  DYNAMIC_FIELDS_DATA,
  PERMISSION_DYNAMIC_FIELDS,
  ROUTES,
} from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './dynamicFieldsModule.less';
import DynamicFieldsCreate from './pages/DynamicFieldsCreate';
import DynamicFieldsEdit from './pages/DynamicFieldsEdit';
import DynamicFieldsList from './pages/DynamicFieldsList';

const DynamicFieldsWrapper = () => {
  const [selectedDynamicKey, setSelectedDynamicKey] = useState(
    DYNAMIC_FIELDS_DATA?.[0]?.key,
  );

  return (
    <div className="dynamic-fields-module">
      <Routes>
        <Route
          path={ROUTES?.MAIN}
          element={
            <AccessControl
              allowedPermissions={PERMISSION_DYNAMIC_FIELDS}
              showNoAccess
            >
              <DynamicFieldsList
                setSelectedDynamicKey={setSelectedDynamicKey}
                selectedDynamicKey={selectedDynamicKey}
              />
            </AccessControl>
          }
        />
        <Route
          path="/add"
          element={
            <AccessControl
              allowedPermissions={['FET_DYNAMIC_FIELD_CREATE']}
              showNoAccess
            >
              <DynamicFieldsCreate
                setSelectedDynamicKey={setSelectedDynamicKey}
                selectedDynamicKey={selectedDynamicKey}
              />
            </AccessControl>
          }
        />
        <Route
          path="/edit/:id"
          element={
            <AccessControl
              allowedPermissions={[
                'FET_DYNAMIC_FIELD_UPDATE',
                'FET_DYNAMIC_FIELD_VIEW',
              ]}
              showNoAccess
            >
              <DynamicFieldsEdit
                setSelectedDynamicKey={setSelectedDynamicKey}
                selectedDynamicKey={selectedDynamicKey}
              />
            </AccessControl>
          }
        />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
};

export default DynamicFieldsWrapper;
