import { useQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SponsorTenantDetails from '../components/SponsorTenantDetails';
import { GET_SPONSOR_TENANT } from '../graphql/Queries';

function SponsorTenantView() {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const { data: { sponsorAssociatedTenant } = {} } = useQuery(
    GET_SPONSOR_TENANT,
    {
      variables: { id },
      onCompleted: () => {
        setLoading(false);
      },
      fetchPolicy: 'network-only',
      onError: () => {
        setLoading(false);
      },
    },
  );

  if (!id) {
    return <Navigate to={ROUTES?.SPONSOR_TENANTS} replace />;
  }

  const tenantsData = {
    ...sponsorAssociatedTenant,
  };

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.SPONSOR_TENANTS} />
          {sponsorAssociatedTenant && (
            <span className="portal-header">
              {sponsorAssociatedTenant?.name}
            </span>
          )}
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <SponsorTenantDetails tenantsData={tenantsData} />
      )}
    </Card>
  );
}

export default SponsorTenantView;
