import { useQuery } from '@apollo/client';
import { Card } from 'antd';
import { find, map } from 'lodash';
import React, { useState } from 'react';
import { messageContext } from '../../../app/components/AppContextHolder';
import CommonGoogleFileUpload from '../../../components/CommonGoogleFileUpload';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import SelectComponent from '../../../components/SelectComponent';
import { IMPORT_TYPES } from '../graphql/Queries';

const Upload = () => {
  const { data, loading } = useQuery(IMPORT_TYPES, {
    fetchPolicy: 'network-only',
    onError: () => {
      messageContext?.error('got some problem');
    },
  });
  const [value, setValue] = useState(null);
  const { Option } = SelectComponent;
  let selectedObject = {};

  if (value) {
    selectedObject = find(data?.importTypes, (item) => item?.key === value);
  }
  if (loading) {
    return <LoaderComponent />;
  }
  return (
    <Card className="full-height-card card-body-p-0">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton />
          <span className="upload-header">Import</span>
          <SelectComponent
            id="search-container-id"
            value={value}
            onChange={(selected) => setValue(selected)}
          >
            {map(data?.importTypes, (item) => (
              <Option key={item?.key} value={item?.key}>
                {item?.label}
              </Option>
            ))}
          </SelectComponent>
        </div>
      </Portal>
      {value && (
        <CommonGoogleFileUpload
          folder={value}
          url={selectedObject?.sampleFile?.url}
        />
      )}
    </Card>
  );
};

export default Upload;
