import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Divider, Empty, Form, Row } from 'antd';
import { forEach, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { messageContext } from '../../../app/components/AppContextHolder';
import alertTriangle from '../../../assets/alert-triangle.svg';
import { formValidatorRules, formatPrice } from '../../../common/utils';
import ModalComponent from '../../../components/ModalComponent';
import PriceComponent from '../../../components/PriceComponent';
import TreeComponent from '../../../components/TreeComponent';
import { UPDATE_PRODUCT_PRICING } from '../graphql/Mutations';

const { required } = formValidatorRules;

const CostUpdateModal = (props) => {
  const [form] = Form?.useForm();

  const {
    showModal = false,
    setShowModal,
    initialValues = null,
    updateFrom,
    updateInputFrom,
    setUpdateFrom,
    productPricingList,
    variables,
  } = props;

  const [treeData, setTreeData] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);

  const [btnLoading, setBtnLoading] = useState(false);

  const [baseCostLock, setBaseCostLock] = useState(false);
  const [defaultCostLock, setDefaultCostLock] = useState(false);

  const [negativeProfit, setNegativeProfit] = useState(false);

  const [updateProductPricing] = useMutation(UPDATE_PRODUCT_PRICING, {
    onError() {
      setBtnLoading(false);
    },
  });

  const handleSubmit = async (values) => {
    if (!checkedKeys?.length) {
      messageContext?.error('Please select at least one region');
      return;
    }
    setBtnLoading(true);
    try {
      const response = await updateProductPricing({
        variables: {
          data: {
            baseCost: values?.baseCost,
            defaultCost: values?.defaultCost,
            profit: values?.profit,
            profitType: '$',
            regionIds: checkedKeys,
            updateFrom: updateFrom === 'SUBREGION' ? 'REGION' : updateFrom,
          },
          where: {
            id: initialValues?.id,
          },
        },
      });
      if (response?.data?.updateProductPricing) {
        setBtnLoading(false);
        setShowModal(false);
        setUpdateFrom('');
        productPricingList({ variables });
      }
    } catch (error) {
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      const treeDataConfig = [];
      forEach(initialValues?.tree, (item) => {
        treeDataConfig?.push({
          ...item,
          title: item?.name,
          key: item?.regionId,
          disabled: !item?.available,
          children: map(item?.subRegions, (record) => ({
            ...record,
            title: record?.name,
            key: record?.regionId,
            disabled: !record?.available,
          })),
        });
      });
      setTreeData(treeDataConfig);
      const checkedConfig = [];
      forEach(initialValues?.tree, (item) => {
        if (item?.available) {
          checkedConfig?.push(item?.regionId);
        }
        if (item?.subRegions?.length > 0) {
          forEach(item?.subRegions, (subRegion) => {
            if (subRegion?.available) {
              checkedConfig?.push(subRegion?.regionId);
            }
          });
        }
      });
      setCheckedKeys(checkedConfig);
      if (updateInputFrom) {
        if (updateInputFrom === 'baseCost') {
          setBaseCostLock(false);
        } else {
          setBaseCostLock(true);
        }
        if (updateInputFrom === 'defaultCost') {
          setDefaultCostLock(false);
        } else {
          setDefaultCostLock(true);
        }
      } else {
        setBaseCostLock(false);
        setDefaultCostLock(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  const handleClickSelect = () => {
    if (checkedKeys?.length > 0) {
      setCheckedKeys([]);
    } else {
      const checkedKeysConfig = [];
      forEach(treeData, (item) => {
        if (item?.available) {
          checkedKeysConfig?.push(item?.regionId);
        }
        if (item?.children?.length > 0) {
          forEach(item?.children, (record) => {
            if (record?.available) {
              checkedKeysConfig?.push(record?.regionId);
            }
          });
        }
      });
      setCheckedKeys(checkedKeysConfig);
    }
  };

  const changeCostValue = (value, type, sourceType) => {
    if (sourceType?.source === 'prop') {
      return;
    }
    let profitNewValue;
    let profitNewValuePercent;
    let defaultCost;
    const profit = form?.getFieldValue('profit');
    const baseCostValue = form?.getFieldValue('baseCost');
    switch (type) {
      case 'baseCost':
        defaultCost = value + profit;
        form?.setFieldsValue({ defaultCost });
        break;

      case 'defaultCost':
        profitNewValue = value - baseCostValue;
        profitNewValuePercent = (profitNewValue * 100) / baseCostValue;
        form?.setFieldsValue({
          profit: profitNewValue,
          profitPercent: profitNewValuePercent,
        });
        if (profitNewValue < 0) {
          setNegativeProfit(true);
        } else {
          setNegativeProfit(false);
        }
        break;

      default:
        break;
    }
  };

  const handleChangeProfit = (value, sourceType, typeValue) => {
    if (sourceType?.source === 'prop') {
      return;
    }
    let defaultCost;
    let profit;
    let profitPercent;
    setNegativeProfit(value < 0);
    const baseCostValue = form?.getFieldValue('baseCost');
    switch (typeValue) {
      case '$':
        defaultCost = baseCostValue + value;
        profitPercent =
          ((defaultCost - baseCostValue) * 100) / initialValues?.baseCost;
        break;

      case '%':
        defaultCost = baseCostValue + (baseCostValue / 100) * value;
        profit = defaultCost - baseCostValue;
        break;

      default:
        break;
    }
    form?.setFieldsValue({ defaultCost });
    if (typeValue === '$') {
      form?.setFieldsValue({ profitPercent });
    } else {
      form?.setFieldsValue({ profit });
    }
  };

  const handleChangeBaseCostLockValue = () => {
    setBaseCostLock(!baseCostLock);
  };

  const handleChangeDefaultCostLockValue = () => {
    setDefaultCostLock(!defaultCostLock);
  };

  return (
    <ModalComponent
      width={417}
      forceRender
      title="Change Costs"
      open={showModal}
      footer={
        <>
          <Button
            className="common-button discard-button"
            size="small"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </Button>
          <Button
            loading={btnLoading}
            className="common-button save-btn apply-btn"
            type="primary"
            htmlType="submit"
            size="small"
            onClick={form?.submit}
          >
            Apply Changes
          </Button>
        </>
      }
      closable
      onCancel={() => setShowModal(false)}
      destroyOnClose
      wrapClassName="cost-update-modal"
    >
      <Form
        form={form}
        initialValues={{
          ...initialValues,
          profitPercent:
            (initialValues?.profit * 100) / initialValues?.baseCost,
        }}
        layout="vertical"
        onFinish={handleSubmit}
      >
        <Row align="middle" className="mb-15">
          <Col span={8}>
            <span className="cost-modal-label">PRODUCT</span>
          </Col>
          <Col span={16}>
            <div className="product-name" title={initialValues?.productName}>
              {initialValues?.productName}
            </div>
            <Row>
              <Col span={12}>
                <div
                  className="product-second-row"
                  title={initialValues?.manufacturer}
                >
                  MFT: {initialValues?.manufacturer}
                </div>
              </Col>
              <Col span={12}>
                <div
                  className="ml-12 product-second-row"
                  title={initialValues?.sku}
                >
                  SKU: {initialValues?.sku}
                </div>
              </Col>
            </Row>
            <Row className="mt-8">
              <Col span={12}>
                <div
                  className="product-second-row"
                  title={initialValues?.unitOfMeasure}
                >
                  UoM: {initialValues?.unitOfMeasure || '-'}
                </div>
              </Col>
              <Col span={12}>
                <div
                  className="ml-12 product-second-row"
                  title={initialValues?.conversionParameter}
                >
                  Conversion Parameter:{' '}
                  {initialValues?.conversionParameter || '-'}
                </div>
              </Col>
            </Row>
            <Row className="mt-8">
              <Col span={12}>
                <div className="product-second-row" title={initialValues?.msrp}>
                  MSRP: {initialValues?.msrp || '-'}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row align="top" gutter={8} className="h-42">
          <Col span={8} className="pt-4">
            <span className="cost-modal-label">PRODUCT COST</span>
          </Col>
          <Col span={14}>
            <Form.Item
              rules={[
                {
                  ...required,
                  message: 'Please Enter Product Cost',
                  type: 'number',
                },
              ]}
              name="baseCost"
            >
              <PriceComponent
                placeholder="Product Cost"
                isPrice
                prefix="$"
                disabled={baseCostLock}
                onChange={(value, sourceType) =>
                  changeCostValue(value, 'baseCost', sourceType)
                }
              />
            </Form.Item>
          </Col>
          <Col span={2} className="pt-4">
            <Button
              type={baseCostLock && 'primary'}
              icon={baseCostLock ? <LockOutlined /> : <UnlockOutlined />}
              size="small"
              className={!baseCostLock ? 'lock-btn' : ''}
              onClick={handleChangeBaseCostLockValue}
            />
          </Col>
        </Row>
        <Row align="top" gutter={8} className="h-42">
          <Col span={8} className="pt-4">
            <span className="cost-modal-label">PRODUCT PRICE</span>
          </Col>
          <Col span={14}>
            <Form.Item
              rules={[
                {
                  ...required,
                  message: 'Please Enter Product Price',
                  type: 'number',
                },
              ]}
              name="defaultCost"
            >
              <PriceComponent
                placeholder="Product’s Price"
                isPrice
                disabled={defaultCostLock}
                onChange={(value, sourceType) =>
                  changeCostValue(value, 'defaultCost', sourceType)
                }
              />
            </Form.Item>
          </Col>
          <Col span={2} className="pt-4">
            <Button
              type={defaultCostLock && 'primary'}
              size="small"
              icon={defaultCostLock ? <LockOutlined /> : <UnlockOutlined />}
              className={!defaultCostLock ? 'lock-btn' : ''}
              onClick={handleChangeDefaultCostLockValue}
            />
          </Col>
        </Row>
        <Row align="top" gutter={8} className="h-42">
          <Col span={8} className="pt-4">
            <span className="cost-modal-label">MARGIN TARGET</span>
          </Col>
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  ...required,
                  message: 'Please Enter Margin Target',
                  type: 'number',
                },
              ]}
              name="profitPercent"
            >
              <PriceComponent
                placeholder="% Margin Target"
                prefix=""
                suffix="%"
                onChange={(value, sourceType) =>
                  handleChangeProfit(value, sourceType, '%')
                }
                disabled={defaultCostLock}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              rules={[
                {
                  ...required,
                  message: 'Please Enter Margin Target',
                  type: 'number',
                },
              ]}
              name="profit"
            >
              <PriceComponent
                placeholder="$ Margin Target"
                prefix="$"
                onChange={(value, sourceType) =>
                  handleChangeProfit(value, sourceType, '$')
                }
                disabled={defaultCostLock}
              />
            </Form.Item>
          </Col>
        </Row>
        {negativeProfit && (
          <span className="error-text">
            Your profit margin goes in negative, please check the cost & price.
          </span>
        )}
        <Divider className="divider" />
        <Row className="mb-16">
          <Col span={8}>
            <div className="d-flex flex-vertical">
              <span className="cost-modal-label">APPLY IN REGIONS</span>
              {treeData?.length > 0 && (
                <span className="select-all-text" onClick={handleClickSelect}>
                  {checkedKeys?.length > 0 ? 'Deselect All' : 'Select All'}
                </span>
              )}
            </div>
          </Col>
          <Col span={8} className="d-flex justify-end">
            <span className="cost-modal-label">PRODUCT COST</span>
          </Col>
          <Col span={8} className="d-flex justify-end">
            <span className="cost-modal-label">PRODUCT PRICE</span>
          </Col>
        </Row>
        {treeData?.length > 0 ? (
          <TreeComponent
            className="product-pricing-panel-tree"
            data={treeData}
            checkedKeys={checkedKeys}
            defaultExpandedKeys={map(treeData, (region) => {
              if (region?.children?.length > 0) {
                return region?.regionId;
              }
            })}
            blockNode
            titleRender={(item) => (
              <div className="d-flex justify-between">
                <div
                  className={`d-flex align-center ${
                    item?.children ? 'parent-width' : 'child-width'
                  }`}
                >
                  <span className="title-max-width">{item?.title}</span>
                  {item?.disabled && (
                    <span title="Product not available">
                      <img
                        src={alertTriangle}
                        alt="disabled"
                        width={16}
                        className="ml-8"
                      />
                    </span>
                  )}
                </div>
                <div className="cost-section mr-16">
                  <span title={formatPrice(item?.baseCost)}>
                    {formatPrice(item?.baseCost)}
                  </span>
                </div>
                <div className="cost-section">
                  <span title={formatPrice(item?.defaultCost)}>
                    {formatPrice(item?.defaultCost)}
                  </span>
                </div>
              </div>
            )}
            setCheckedKeys={setCheckedKeys}
          />
        ) : (
          <Empty />
        )}
      </Form>
    </ModalComponent>
  );
};

export default CostUpdateModal;
