import Icon from '@ant-design/icons';
import React from 'react';

const showMoreIcon = () => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 1.5V7C9.55229 7 10 6.55228 10 6V1C10 0.447715 9.55229 0 9 0H4C3.44772 0 3 0.447715 3 1H8.5C8.77614 1 9 1.22386 9 1.5ZM7.5 8.5V3C7.5 2.72386 7.27614 2.5 7 2.5H1.5C1.5 1.94772 1.94772 1.5 2.5 1.5H7.5C8.05229 1.5 8.5 1.94772 8.5 2.5V7.5C8.5 8.05229 8.05229 8.5 7.5 8.5ZM6 3H1C0.447715 3 0 3.44772 0 4V9C0 9.55228 0.447715 10 1 10H6C6.55228 10 7 9.55228 7 9V4C7 3.44772 6.55228 3 6 3ZM1 9V4H6V9H1Z"
        fill="currentColor"
      />
    </svg>
  );

const ShowMoreIconComponent = (props) => (
  <Icon component={showMoreIcon} aria-label="Show more" {...props} />
);

export default ShowMoreIconComponent;
