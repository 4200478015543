import { CheckCircleTwoTone, EyeOutlined } from '@ant-design/icons';
import { Badge, Col, Divider, Row } from 'antd';
import { capitalize, groupBy, isNull } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { DEFAULT_DATE_FORMAT, ROUTES } from '../../../common/constants';
import { formatDate, formatPhoneNumber } from '../../../common/utils';
import TableComponent from '../../../components/TableComponent';
import history from '../../../historyData';
import '../quoteViewerModule.less';

function QuoteViewerDetail({ data = [] }) {
  const {
    state: { isSponsor },
  } = useContext(AppContext);

  const [scheduleSlots, setScheduleSlots] = useState([]);
  const [address, setAddress] = useState('-');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isNull(data?.scheduleTime)) {
      const dateWiseSlots = groupBy(data?.scheduleTime, 'date');

      setScheduleSlots(dateWiseSlots);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.scheduleTime]);

  useEffect(() => {
    if (
      !isNull(data?.location?.addressLine1) ||
      !isNull(data?.location?.city) ||
      !isNull(data?.location?.state) ||
      !isNull(data?.location?.zipCode)
    ) {
      setAddress(
        `${data?.location?.addressLine1 ? data?.location?.addressLine1 : ''} ${
          data?.location?.city ? `${data?.location?.city},` : ''
        } ${data?.location?.state ? data?.location?.state : ''} ${
          data?.location?.country ? data?.location?.country : ''
        } ${data?.location?.zipCode ? data?.location?.zipCode : ''}`,
      );
    } else {
      setAddress('-');
    }
  }, [data?.location]);

  useEffect(() => {
    const unListen = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, { state: { ...location?.state } });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  const columns = useMemo(
    () => [
      {
        title: 'SELECTED PACKAGE',
        dataIndex: 'package',
        key: 'package',
        className: 'max-width-column',
      },
      {
        title: 'Package SKU',
        dataIndex: 'sku',
        key: 'sku',
        className: 'max-width-column',
      },
      {
        title: 'MANUFACTURER(PRIMARY PRODUCT)',
        dataIndex: 'manufacturer',
        key: 'manufacturer',
        className: 'max-width-column',
      },
      {
        title: 'QUOTE AMT.',
        dataIndex: 'amount',
        key: 'amount',
        className: 'max-width-column',
      },
      {
        title: 'PACKAGE LEVEL',
        dataIndex: 'quality',
        key: 'amount',
        className: 'max-width-column',
      },
      {
        title: 'LINE OF BUSINESS',
        dataIndex: 'lineOfBusiness',
        key: 'lineOfBusiness',
        className: 'max-width-column',
      },
      {
        title: 'SUBAREA',
        dataIndex: 'subArea',
        key: 'subArea',
        className: 'max-width-column',
      },
      {
        title: 'ROQ PATH',
        dataIndex: 'roqPathName',
        key: 'roqPathName',
        className: 'max-width-column',
      },
      {
        title: 'VIEW DETAIL',
        dataIndex: 'id',
        key: 'package-suggested',
        className: 'max-width-column',
        render: (record) => (
          <Link to={`${ROUTES?.LEADS}/view/lead/${record}`}>
            <span className="gx-avatar-name d-flex flex-row align-items-center">
              <EyeOutlined className="gx-fs-xxs ml-2" />
            </span>
          </Link>
        ),
      },
    ],
    [],
  );

  return (
    <div className="quote-viewer">
      <div className="content-section quote-viewer-detail">
        <Row className="required-row">
          <span className="form-divider-text">Customer</span>
          <Divider className="form-divider" />

          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">First Name</span>
              <span className="field-value">{data?.firstName || '-'}</span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Last Name</span>
              <span className="field-value">{data?.lastName || '-'}</span>
            </div>
          </Col>

          {!isSponsor && data?.sponsorName && (
            <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
              <div className="field-detail">
                <span className="field-label">Sponsor Name</span>
                <span className="field-value">{data?.sponsorName || '-'}</span>
              </div>
            </Col>
          )}

          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={18}>
            <div className="field-detail">
              <span className="field-label">Address</span>
              <span className="field-value">{address}</span>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Email</span>
              <span className="field-value">{data?.email || '-'}</span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Phone number</span>
              <span className="field-value">
                {formatPhoneNumber(data?.phoneNumber) || '-'}
              </span>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Status</span>
              <span className="field-value">
                {capitalize(data?.status) || '-'}
              </span>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Optional Comments</span>
              <span className="field-value">{data?.comments || '-'}</span>
            </div>
          </Col>
        </Row>
        <Row className="required-row">
          <span className="form-divider-text">Order</span>
          <Divider className="form-divider" />
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Order Time & Date</span>
              <span className="field-value">
                {formatDate(data?.createdAt) || '-'}
              </span>
            </div>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Appointment Request</span>
              {Object.entries(scheduleSlots)?.map(([key, value]) => {
                let multipleTime = '';
                if (value?.[0]?.startTime && value?.[0]?.endTime) {
                  multipleTime = value
                    ?.map(
                      (item) =>
                        `${item?.label ?? ''}: ${`${moment(
                          item?.startTime || moment()?.startOf('day'),
                        )?.format('LT')} -  ${moment(
                          item?.endTime || moment()?.endOf('day'),
                        )?.format('LT')}`}`,
                    )
                    .join(' , ');
                }
                if (value?.[0]?.startTime && value?.[0]?.endTime) {
                  return (
                    <span className="field-value slots  mt-8">
                      {key ? moment(key)?.format(DEFAULT_DATE_FORMAT) : '-'}{' '}
                      {multipleTime ? `: ${multipleTime}` : ''}
                    </span>
                  );
                }
                return (
                  <>
                    <span className="field-value slots mt-8">
                      {key ? moment(key)?.format(DEFAULT_DATE_FORMAT) : ''}
                    </span>
                  </>
                );
              })}
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Urgent</span>
              <span className="field-value">
                {data?.emailMeInfo?.urgent ? 'Yes' : 'No' || '-'}
              </span>
            </div>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Campaign</span>
              {data?.campaign?.code ? (
                <Link
                  className="link-btn-campaign-code"
                  to={
                    data?.campaign?.id
                      ? `${ROUTES?.CAMPAIGNS}/edit/${data?.campaign?.id}`
                      : '#'
                  }
                >
                  <span className="field-value">
                    {data?.campaign?.code || '-'}
                  </span>
                </Link>
              ) : (
                <span className="field-value">-</span>
              )}
            </div>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Export Status</span>
              <span className="field-value">
                {data?.exportStatus === 'SUCCESS' ? (
                  <div>
                    <CheckCircleTwoTone twoToneColor="#52C41A" />
                    <span className="success-color success-text">Success</span>
                  </div>
                ) : (
                  <Badge
                    color="#FF8845"
                    text="Pending"
                    className="pending-color pending-text"
                  />
                )}
              </span>
            </div>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Email Confirmation</span>
              <span className="field-value">
                {data?.emailMeInfo?.emailConfirmation ? 'Yes' : 'No' || '-'}
              </span>
            </div>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Retailers</span>
              <span className="field-value">{data?.brand?.name || '-'}</span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Region</span>
              <span className="field-value">{data?.region?.name || '-'}</span>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>
            <div className="field-detail">
              <span className="field-label">Industry</span>
              <span className="field-value">
                {data?.industry?.label || '-'}
              </span>
            </div>
          </Col>
        </Row>
      </div>
      <div className="card-container">
        <TableComponent
          columns={[...columns?.filter((item) => item !== false)]}
          data={data?.quotes || []}
          fullHeight={false}
        />
      </div>
    </div>
  );
}

export default QuoteViewerDetail;
