import { useLazyQuery } from '@apollo/client';
import { Card } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../AppContext';
import { MODULES } from '../../../common/constants';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import TenantSettingForm from '../components/TenantSettingForm';
import { GET_SPONSOR, GET_TENANT } from '../graphql/Queries';

const TenantSettingPage = () => {
  const [loading, setLoading] = useState(true);
  const [tenantData, setTenantData] = useState({});
  const {
    state: { currentUser, isSponsor },
  } = useContext(AppContext);

  const [fetchTenant] = useLazyQuery(GET_TENANT, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setLoading(false);

      const links = res?.tenant?.socialMediaLinks;

      const tenantObj = {
        ...res?.tenant,
        name: res?.tenant?.name,
        tagLine: res?.tenant?.tagLine,
        phoneNumber: res?.tenant?.phoneNo,
        helpPageChecked: res?.tenant?.helpPage?.checked,
        helpPage: res?.tenant?.helpPage?.key,
        liveChat: res?.tenant?.liveChat?.key,
        liveChatChecked: res?.tenant?.liveChat?.checked,
        zipCode: res?.tenant?.location?.zipCode,
        street: res?.tenant?.location?.addressLine1,
        city: res?.tenant?.location?.city,
        state: res?.tenant?.location?.state,
        timeFormat: res?.tenant?.timeFormat,
      };

      links?.forEach((element) => {
        tenantObj[element?.title] = element?.url;
      });

      setTenantData(tenantObj);
    },
    onError() {
      setLoading(false);
    },
  });

  const [fetchSponsor] = useLazyQuery(GET_SPONSOR, {
    fetchPolicy: 'network-only',
    onCompleted(res) {
      setLoading(false);

      const links = res?.sponsor?.socialMediaLinks;

      const tenantObj = {
        ...res?.sponsor,
        name: res?.sponsor?.businessName,
        tagLine: res?.sponsor?.tagLine,
        phoneNumber: res?.sponsor?.phoneNo,
        helpPageChecked: res?.sponsor?.helpPage?.checked,
        helpPage: res?.sponsor?.helpPage?.key,
        liveChat: res?.sponsor?.liveChat?.key,
        liveChatChecked: res?.sponsor?.liveChat?.checked,
        zipCode: res?.sponsor?.location?.zipCode,
        street: res?.sponsor?.location?.addressLine1,
        city: res?.sponsor?.location?.city,
        state: res?.sponsor?.location?.state,
        timeFormat: res?.sponsor?.timeFormat,
      };

      links?.forEach((element) => {
        tenantObj[element?.title] = element?.url;
      });

      setTenantData(tenantObj);
    },
    onError() {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (isSponsor) {
      fetchSponsor({
        variables: {
          where: {
            id: currentUser?.sponsorId,
          },
        },
      });
    } else {
      fetchTenant({
        variables: {
          where: {
            id: currentUser?.tenantId,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSponsor]);

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <span className="portal-header">
            {isSponsor ? MODULES?.SPONSOR_SETTING : MODULES?.TENANT_SETTING}
          </span>
        </div>
      </Portal>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <TenantSettingForm initialValue={tenantData} />
      )}
    </Card>
  );
};

export default TenantSettingPage;
