import { Modal } from 'antd';
import React, { useEffect } from 'react';
import './styles/ModalComponent.less';

const ModalComponent = (props) => {
  const {
    centered = true,
    maskClosable = false,
    loading,
    children,
    open = '',
    title = '',
    wrapClassName = '',
    classNames,
    ...rest
  } = props;

  useEffect(() => {
    if (open) {
      // eslint-disable-next-line no-undef
      const modalContainer = document.querySelector('.ant-modal');
      modalContainer?.setAttribute(
        'aria-labelledby',
        `${title || wrapClassName}`,
      );
      modalContainer?.setAttribute('aria-label', `${title || wrapClassName}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      classNames={{
        header: `modal-header ${classNames?.header || ''}`,
        body: `modal-body ${classNames?.body || ''}`,
        content: `modal-content ${classNames?.content || ''}`,
        footer: `modal-footer ${classNames?.footer || ''}`,
      }}
      centered={centered} // centered modal
      maskClosable={maskClosable} // whether to close the modal when clicked outside the modal
      open={open}
      title={title}
      wrapClassName={wrapClassName}
      loading={loading}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;

ModalComponent.confirm = Modal?.confirm;
