import { useQuery } from '@apollo/client';
import { Button, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import EditIcon from '../../../assets/edit.svg';
import { ROUTES } from '../../../common/constants';
import AccessControl from '../../../components/AccessControl';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import history from '../../../historyData';
import BrandDetails from '../components/BrandDetails';
import { GET_BRAND } from '../graphql/Queries';

const BrandsView = () => {
  const [logoUrl, setLogoUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { data: { brand } = {} } = useQuery(GET_BRAND, {
    variables: { id },
    onCompleted: (res) => {
      if (res?.brand?.logo?.url) {
        setLogoUrl({
          url: res?.brand?.logo?.url,
          title: res?.brand?.logo?.url?.split('_')?.[1],
        });
      }
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    const unListen = history?.listen((props) => {
      if (props?.action === 'POP') {
        navigate(props?.location?.pathname, { state: { ...location?.state } });
      }
    });
    return () => {
      if (unListen) {
        unListen();
      }
    };
  }, []);

  if (!id) {
    return <Navigate to={ROUTES?.RETAILERS} replace />;
  }

  return (
    <Card className="full-height-card card-body-padding">
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.RETAILERS} />
          {brand && <span className="portal-header">{brand?.name}</span>}
        </div>
      </Portal>
      <AccessControl allowedPermissions={['FET_BRAND_UPDATE']}>
        <Portal portalId="header-right-content">
          <Button
            className="common-button"
            icon={<img src={EditIcon} alt="save-icon" width={12} />}
            size="small"
            htmlType="submit"
            id="brand-table-save-btn"
            type="primary"
            onClick={() =>
              navigate(`${ROUTES?.RETAILERS}/edit/${id}`, {
                state: {
                  ...location?.state,
                },
              })
            }
          >
            Edit
          </Button>
        </Portal>
      </AccessControl>
      {loading ? (
        <LoaderComponent setHeight={80} />
      ) : (
        <BrandDetails data={brand} logoUrl={logoUrl} />
      )}
    </Card>
  );
};

export default BrandsView;
