import { Button } from 'antd';
import React from 'react';
import ModalComponent from '../../../components/ModalComponent';

const PublishModal = (props) => {
  const {
    modalVisible = false,
    setModalVisible,
    submitLoading = false,
    handleSubmit,
  } = props;

  return (
    <ModalComponent
      wrapClassName="publish-modal"
      width={467}
      destroyOnClose
      open={modalVisible}
      title="PUBLISH DYNAMIC FIELD"
      footer={
        <div className="d-flex justify-end">
          <Button
            className="common-button discard-button discard-margin footer-btn"
            size="small"
            id="publish-modal-discard-btn"
            disabled={submitLoading}
            onClick={() => setModalVisible(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            loading={submitLoading}
            size="small"
            className="common-button footer-btn"
            onClick={handleSubmit}
          >
            Publish
          </Button>
        </div>
      }
      onCancel={() => {
        if (setModalVisible) {
          setModalVisible(false);
        }
      }}
    >
      <div className="font-size-12 primary-color">
        <span>
          Only the following values will be editable after publishing:
        </span>
        <ul className="mt-8">
          <li>Order</li>
          <li>Default</li>
          <li>Label</li>
          <li>Tooltip</li>
          <li>Picklist Options</li>
        </ul>
      </div>
    </ModalComponent>
  );
};

export default PublishModal;
