import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Card } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../common/constants';
import GoBackButton from '../../../components/GoBackButton';
import LoaderComponent from '../../../components/LoaderComponent';
import Portal from '../../../components/Portal';
import PropertyCreationModal from '../components/PropertyCreationModal';
import PropertyForm from '../components/PropertyForm';
import CardWrapper from '../components/PropertyTabs';
import { UPDATE_PROPERTY } from '../graphql/Mutations';
import { GET_PROPERTIES, GET_PROPERTY } from '../graphql/Queries';

function PropertyEdit() {
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [duplicateData, setDuplicateData] = useState([]);
  const [duplicateModalVisible, setDuplicateModalVisible] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const { data: { property } = {} } = useQuery(GET_PROPERTY, {
    variables: { id },
    onCompleted: () => {
      setLoading(false);
    },
    fetchPolicy: 'network-only',
    onError: () => {
      setLoading(false);
      setSubmitLoading(false);
    },
  });

  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const submitData = async () => {
    setSubmitLoading(true);

    const newFormValues = {
      ...formData,
      location: {
        addressLine1: formData?.addressLine1,
        city: formData?.city,
        state: formData?.state,
        country: formData?.country,
        zipCode: formData?.zipCode,
      },
      waterHookUp: formData?.waterHookUp || null,
      heatSource: formData?.heatSource || null,
      propertyFinishedBasement: formData?.propertyFinishedBasement || null,
      propertyFinishedCeiling: formData?.propertyFinishedCeiling || null,
      builtYear: moment(formData?.builtYear).format('YYYY'),
    };

    delete newFormValues?.addressLine1;
    delete newFormValues?.city;
    delete newFormValues?.state;
    delete newFormValues?.country;
    delete newFormValues?.zipCode;

    const variables = { data: newFormValues, where: { id: property?.id } };

    try {
      const response = await updateProperty({
        variables: { ...variables },
      });
      if (response?.data?.updateProperty) {
        setSubmitLoading(false);
        setDuplicateModalVisible(false);
        navigate(ROUTES?.PROPERTIES);
      }
    } catch (error) {
      setSubmitLoading(false);
      setDuplicateModalVisible(false);
      return error;
    }
  };

  const [fetchPropertiesData] = useLazyQuery(GET_PROPERTIES, {
    fetchPolicy: 'network-only',
    onCompleted: async (res) => {
      if (res?.properties?.data?.length > 0) {
        setDuplicateData(res?.properties?.data);
        setDuplicateModalVisible(true);
        setSubmitLoading(false);
      } else {
        submitData();
      }
    },
    onError: () => {
      setSubmitLoading(false);
    },
  });

  const handleSubmit = async (formValues) => {
    setSubmitLoading(true);
    setFormData(formValues);

    fetchPropertiesData({
      variables: {
        filter: {
          sortOn: 'createdAt',
          sortBy: 'DESC',
        },
        where: {
          id: [id],
          duplicateCheck: true,
          addressLine: [formValues?.addressLine1],
        },
      },
    });
  };

  if (!id) {
    return <Navigate to={ROUTES?.PROPERTIES} replace />;
  }

  const initialValues = {
    ...property,
    ...property?.location,
    isActive: property?.isActive,
    historyDistrict: property?.historyDistrict,
    elevator: property?.elevator,
    cornerLot: property?.cornerLot,
    parking: property?.parking,
    taxParcel: property?.taxParcel,
    centralAir: property?.centralAir,
    builtYear: property?.builtYear ? moment(property?.builtYear) : undefined,
    propertyLastConfirmed: property?.propertyLastConfirmed
      ? moment(property?.propertyLastConfirmed)
      : undefined,
  };

  return (
    <Card className="full-height-card card-body-padding">
      <PropertyCreationModal
        showModal={duplicateModalVisible}
        setShowModal={setDuplicateModalVisible}
        duplicateData={duplicateData}
        submitData={submitData}
        submitLoading={submitLoading}
        isEdit
      />
      <Portal portalId="header-left-content">
        <div className="d-flex align-center">
          <GoBackButton customLink={ROUTES?.PROPERTIES} />
          {property && <span className="portal-header">{property?.name}</span>}
        </div>
      </Portal>
      <CardWrapper>
        {loading ? (
          <LoaderComponent setHeight={80} />
        ) : (
          <PropertyForm
            propertyData={initialValues}
            handleSubmit={handleSubmit}
            isSubmit={submitLoading}
            isEdit
          />
        )}
      </CardWrapper>
    </Card>
  );
}

export default PropertyEdit;
