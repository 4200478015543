import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Row, Switch } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import deleteIcon from '../../../assets/delete-red.svg';
import { checkPermissions, formValidatorRules } from '../../../common/utils';
import AccessControl from '../../../components/AccessControl';
import InputComponent from '../../../components/InputComponent';
import TableComponent from '../../../components/TableComponent';

const { required, email } = formValidatorRules;

const MAX_EMAIL_LIMIT = 5;

const EmailTable = ({ emailData, setEmailData }) => {
  const [emailForm] = Form?.useForm();
  const {
    state: { permissions },
  } = useContext(AppContext);

  const removeEmail = (emailToBeRemoved) => {
    const newEmailData = emailData?.filter(
      (item) => item.email !== emailToBeRemoved,
    );
    setEmailData(newEmailData);
  };

  const handleChangeUserEmailConfig = (value, record, key) => {
    const filteredEmailList = emailData?.map((item) => {
      if (item?.email === record?.email) {
        return {
          ...item,
          [key]: value,
        };
      }
      return item;
    });
    setEmailData([...filteredEmailList]);
  };

  const columns = [
    {
      title: 'EMAIL',
      dataIndex: 'email',
      key: 'email',
      className: 'max-width-column',
    },
    {
      title: 'FAILED NOTIFICATION',
      width: 200,
      align: 'center',
      dataIndex: 'failedNotification',
      key: 'email',
      className: 'max-width-column',
      render: (_, record) => (
        <Switch
          disabled={
            !checkPermissions(permissions, 'FET_DATA_ASSIGNMENT_UPDATE')
          }
          checked={record?.failedNotification}
          onChange={(e) =>
            handleChangeUserEmailConfig(e, record, 'failedNotification')
          }
          className="common-switch ml-8 m-0"
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      ),
    },
    {
      title: 'SUCCESS NOTIFICATION',
      width: 200,
      align: 'center',
      dataIndex: 'successNotification',
      key: 'successNotification',
      className: 'max-width-column',
      render: (_, record) => (
        <Switch
          checked={record?.successNotification}
          disabled={
            !checkPermissions(permissions, 'FET_DATA_ASSIGNMENT_UPDATE')
          }
          onChange={(e) =>
            handleChangeUserEmailConfig(e, record, 'successNotification')
          }
          className="common-switch ml-8 m-0"
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      ),
    },
    {
      title: 'ACTION',
      align: 'center',

      width: 200,
      render: (action, record = {}) => (
        <img
          onClick={() => removeEmail(record?.email)}
          src={deleteIcon}
          className="remove-email-btn"
          alt="delete-icon"
        />
      ),
    },
  ];

  const addEmail = ({ userEmail }) => {
    const isAlreadyAdded = emailData?.find((item) => item?.email === userEmail);
    if (!isAlreadyAdded) {
      setEmailData([
        ...emailData,
        {
          email: userEmail,
          failedNotification: false,
          successNotification: false,
        },
      ]);
      emailForm?.setFieldValue('userEmail', null);
    } else {
      message.destroy();
      message?.error('Email already added');
    }
  };

  return (
    <>
      <Form name="email-form" onFinish={addEmail} form={emailForm}>
        <Row gutter={[10, 10]}>
          <Col span={20} className="mt-10">
            <Form.Item
              validateTrigger="onChange"
              name="userEmail"
              rules={[required, email]}
            >
              <InputComponent placeholder="Email" aria-label="Email" />
            </Form.Item>
          </Col>
          <Col span={4} className="mt-10">
            <AccessControl allowedPermissions={['FET_DATA_ASSIGNMENT_UPDATE']}>
              <Button
                className="common-button login-button fill-width font-size-14"
                htmlType="submit"
                disabled={emailData?.length === MAX_EMAIL_LIMIT}
              >
                ADD
              </Button>
            </AccessControl>
          </Col>
        </Row>
      </Form>
      <TableComponent data={emailData} columns={columns} />
    </>
  );
};

export default EmailTable;
