import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Checkbox, Col, Form, Radio, Row, Switch } from 'antd';
import { map } from 'lodash';
import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../../../../../../../AppContext';
import {
  DYNAMIC_UPLOAD_FILE_TYPES,
  DYNAMIC_UPLOAD_TYPE_AUDIO,
  DYNAMIC_UPLOAD_TYPE_DOCS,
  DYNAMIC_UPLOAD_TYPE_IMAGES,
  DYNAMIC_UPLOAD_TYPE_VIDEO,
  REGEX,
} from '../../../../../../../../common/constants';
import InputComponent from '../../../../../../../../components/InputComponent';

const CustomUploadForm = ({
  form,
  checkedList,
  setCheckedList,
  validationTriggered,
  setDisableBtn,
  isSponsorQuestionnaire = false,
}) => {
  const {
    state: { isSponsor },
  } = useContext(AppContext);
  const location = useLocation();
  const fileType = Form?.useWatch(
    ['widgetConfiguration', 'config', 'fileType'],
    form,
  );
  const showDescription = Form?.useWatch(
    ['widgetConfiguration', 'config', 'description'],
    form,
  );

  const showTooltip = Form?.useWatch(
    ['widgetConfiguration', 'config', 'tooltip'],
    form,
  );

  const allowMultiple = Form?.useWatch(
    ['widgetConfiguration', 'config', 'allowMultiple'],
    form,
  );

  const limitFileSize = Form?.useWatch(
    ['widgetConfiguration', 'config', 'limitFileSize'],
    form,
  );

  const onChangeCheckbox = (list) => {
    setCheckedList(list);
    setDisableBtn(false);
  };

  const handleChangeFileType = () => {
    setCheckedList([]);
  };

  return (
    <div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'label']}
        label="Label"
        rules={[{ required: true, message: 'Please Enter Label' }]}
      >
        <InputComponent name="label" />
      </Form.Item>
      <Form.Item name={['widgetConfiguration', 'config', 'fileType']}>
        <Radio.Group
          onChange={handleChangeFileType}
          className="common-radio fill-width"
        >
          <Row>
            {map(DYNAMIC_UPLOAD_FILE_TYPES, (item) => (
              <Col key={item?.value} xs={4} sm={4} md={4} lg={4} xl={6} xxl={6}>
                <Radio value={item?.value}>{item?.label}</Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </Form.Item>
      <Row>
        <Col xs={4} sm={4} md={4} lg={4} xl={6} xxl={6}>
          <Checkbox.Group
            className="common-checkbox"
            options={DYNAMIC_UPLOAD_TYPE_DOCS}
            value={checkedList}
            onChange={(list) => onChangeCheckbox(list)}
            disabled={
              fileType !== 'DOCS' ||
              (isSponsorQuestionnaire &&
                !location?.pathname?.includes('/create') &&
                !isSponsor)
            }
          />
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={6} xxl={6}>
          <Checkbox.Group
            className="common-checkbox"
            options={DYNAMIC_UPLOAD_TYPE_IMAGES}
            value={checkedList}
            onChange={(list) => onChangeCheckbox(list)}
            disabled={
              fileType !== 'IMAGE' ||
              (isSponsorQuestionnaire &&
                !location?.pathname?.includes('/create') &&
                !isSponsor)
            }
          />
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={6} xxl={6}>
          <Checkbox.Group
            className="common-checkbox"
            options={DYNAMIC_UPLOAD_TYPE_AUDIO}
            value={checkedList}
            onChange={(list) => onChangeCheckbox(list)}
            disabled={
              fileType !== 'AUDIO' ||
              (isSponsorQuestionnaire &&
                !location?.pathname?.includes('/create') &&
                !isSponsor)
            }
          />
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={6} xxl={6}>
          <Checkbox.Group
            className="common-checkbox"
            options={DYNAMIC_UPLOAD_TYPE_VIDEO}
            value={checkedList}
            onChange={(list) => onChangeCheckbox(list)}
            disabled={
              fileType !== 'VIDEO' ||
              (isSponsorQuestionnaire &&
                !location?.pathname?.includes('/create') &&
                !isSponsor)
            }
          />
        </Col>
      </Row>
      {validationTriggered && !checkedList?.length && (
        <span className="danger">Please select at least one format</span>
      )}
      <Row className="d-flex justify-between">
        <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">File Size Limit</span>
            <Form.Item
              name={['widgetConfiguration', 'config', 'limitFileSize']}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
        </Col>
        <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
          <div className="d-flex justify-between align-center">
            <span className="switch-logo">Max. Number of Files</span>
            <Form.Item
              name={['widgetConfiguration', 'config', 'allowMultiple']}
              className="mb-0"
              valuePropName="checked"
            >
              <Switch
                className="common-switch"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </div>
        </Col>
      </Row>
      <Row className="d-flex justify-between">
        <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
          <Form.Item
            name={['widgetConfiguration', 'config', 'size']}
            hidden={!limitFileSize}
            dependencies={[['widgetConfiguration', 'config', 'limitFileSize']]}
            rules={[
              {
                required: limitFileSize,
                message: 'Please Enter Size',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    value &&
                    getFieldValue([
                      'widgetConfiguration',
                      'config',
                      'limitFileSize',
                    ])
                  ) {
                    if (!REGEX?.NUMBER?.test(value)) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject('Should be a valid Number');
                    }
                    if (Number(value) < 1) {
                      return Promise?.reject(
                        new Error('Minimum size should be 1'),
                      );
                    }
                    if (Number(value) > 50) {
                      return Promise?.reject(
                        new Error('Maximum size should be 50'),
                      );
                    }
                  }
                  return Promise?.resolve();
                },
              }),
            ]}
          >
            <InputComponent placeholder="Enter Size" />
          </Form.Item>
        </Col>
        <Col xs={11} sm={11} md={11} lg={11} xl={11} xxl={11}>
          <Form.Item
            name={['widgetConfiguration', 'config', 'maxFile']}
            hidden={!allowMultiple}
            dependencies={[['widgetConfiguration', 'config', 'allowMultiple']]}
            rules={[
              {
                required: allowMultiple,
                message: 'Please Enter Number',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    value &&
                    getFieldValue([
                      'widgetConfiguration',
                      'config',
                      'allowMultiple',
                    ])
                  ) {
                    if (!REGEX?.NUMBER?.test(value)) {
                      // eslint-disable-next-line prefer-promise-reject-errors
                      return Promise?.reject('Should be a valid Number');
                    }
                    if (Number(value) < 1) {
                      return Promise?.reject(
                        new Error('Minimum size should be 1'),
                      );
                    }
                    if (Number(value) > 10) {
                      return Promise?.reject(
                        new Error('Maximum number of files should be 10'),
                      );
                    }
                  }
                  return Promise?.resolve();
                },
              }),
            ]}
          >
            <InputComponent placeholder="Enter number" />
          </Form.Item>
        </Col>
      </Row>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Tooltip</span>
        <Form.Item
          name={['widgetConfiguration', 'config', 'tooltip']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'tooltipValue']}
        hidden={!showTooltip}
        rules={[
          {
            required: showTooltip,
            message: 'Please Enter Tooltip Value',
          },
        ]}
      >
        <InputComponent placeholder="Enter Tooltip Value" />
      </Form.Item>
      <div className="d-flex justify-between align-center">
        <span className="switch-logo">Description</span>
        <Form.Item
          name={['widgetConfiguration', 'config', 'description']}
          className="mb-0"
          valuePropName="checked"
        >
          <Switch
            className="common-switch"
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
          />
        </Form.Item>
      </div>
      <Form.Item
        name={['widgetConfiguration', 'config', 'descriptionValue']}
        hidden={!showDescription}
        rules={[
          {
            required: showDescription,
            message: 'Please Enter Description',
          },
        ]}
      >
        <InputComponent.TextArea
          className="common-textarea"
          autoSize={{ minRows: 2, maxRows: 4 }}
          placeholder="Enter Description"
        />
      </Form.Item>
    </div>
  );
};

export default CustomUploadForm;
