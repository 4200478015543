import { Button } from 'antd';
import React from 'react';
import ModalComponent from '../../../previewComponents/globalComponents/ModalComponent';

const MeasurementTipsForMapModal = ({
  showMeasurementTipForMapEntry = false,
  setShowMeasurementTipForMapEntry,
  widgetConfig,
}) => (
    <ModalComponent
      open={showMeasurementTipForMapEntry}
      setOpen={showMeasurementTipForMapEntry}
      onCancel={() => setShowMeasurementTipForMapEntry(false)}
      footer={null}
      destroyOnClose
      wrapClassName="measurement-tips-modal"
      getContainer="#centerContent"
      closable={false}
      keyboard={false}
    >
      <div className="measurement-tip-wrapper">
        <div className="title">{widgetConfig?.titleText}</div>
        <div
          className="editor-render tips-content"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: widgetConfig?.descriptionText || '-',
          }}
        />
        <div className="cta-button">
          <Button className="common-button-cpq" type="primary">
            {widgetConfig?.actionButtonLabel}
          </Button>
        </div>
      </div>
    </ModalComponent>
  );

export default MeasurementTipsForMapModal;
