import { gql } from '@apollo/client';

export const CREATE_BATCH = gql`
  mutation createBatch($data: BatchInput!) {
    createBatch(data: $data) {
      message
    }
  }
`;

export const UPDATE_BATCH = gql`
  mutation updateBatch($data: BatchInput!, $where: BatchWhereInput!) {
    updateBatch(data: $data, where: $where) {
      message
    }
  }
`;

export const CREATE_BATCH_ENTITY = gql`
  mutation createBatchEntity($data: BatchEntityInput!) {
    createBatchEntity(data: $data) {
      message
    }
  }
`;

export const UPDATE_BATCH_ENTITY = gql`
  mutation updateBatchEntity(
    $data: BatchEntityInput!
    $where: BatchEntityWhereInput!
  ) {
    updateBatchEntity(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_BATCH = gql`
  mutation deleteBatch($where: BatchWhereInput!) {
    deleteBatch(where: $where) {
      message
      status
    }
  }
`;

export const DELETE_BATCH_ENTITY = gql`
  mutation deleteBatchEntity($where: BatchEntityWhereInput!) {
    deleteBatchEntity(where: $where) {
      message
      status
    }
  }
`;
