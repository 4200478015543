import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Error404 from '../../Error404';
import { PERMISSION_API_SETTING, ROUTES } from '../../common/constants';
import AccessControl from '../../components/AccessControl';
import './apiSettingModule.less';
import ApiSettingPage from './pages/ApiSettingPage';

const ApiSettingWrapper = () => (
  <div className="api-setting-wrapper">
    <Routes>
      <Route
        path={ROUTES?.MAIN}
        element={
          <AccessControl
            allowedPermissions={PERMISSION_API_SETTING}
            showNoAccess
          >
            <ApiSettingPage />
          </AccessControl>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </div>
);

export default ApiSettingWrapper;
