import { useMutation } from '@apollo/client';
import { Button, Result } from 'antd';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { messageContext } from '../../../app/components/AppContextHolder';
import { AppContext } from '../../../AppContext';
import { ROUTES } from '../../../common/constants';
import { fetchStep } from '../../../common/utils';
import { AUTO_LOGIN } from '../../onboarding/graphql/Mutations';
import Signup from '../pages/Signup';

const Success = () => {
  const navigate = useNavigate();
  const { dispatch, initializeAuth } = useContext(AppContext);
  const [autoLogin, { loading }] = useMutation(AUTO_LOGIN, {
    onCompleted: async () => {
      await fetchStep({ dispatch, setLoading: false, changeRoute: false });
      navigate(`${ROUTES?.ONBOARDING}/add-logo`);
    },
    onError: () => {},
  });

  const handleAutoLogin = async () => {
    dispatch({ type: 'SET_SIGNUP_AUTH', data: true });

    try {
      const response = await autoLogin();
      const accessToken = response?.data?.autoLogin?.authToken;
      const userData = response?.data?.autoLogin?.user;
      initializeAuth(accessToken, userData);
    } catch {
      messageContext?.error('got some problem');
    }
  };
  return (
    <Signup>
      <div className="signup-success-page">
        <Result
          status="success"
          title="Your Account is set!"
          subTitle="Tenant Portal is free to use."
        />
        <Button
          type="primary"
          id="btn-success"
          loading={loading}
          onClick={handleAutoLogin}
          className="fill-width onboarding-btn"
        >
          Continue with Onboarding
        </Button>
      </div>
    </Signup>
  );
};

export default Success;
